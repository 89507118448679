import { ApplicationRef, ElementRef, Injectable, NgZone, Renderer2 } from '@angular/core';
import { Section } from '@kuki/tv/shared/modules/section-control/section';
import { Observable, Subject } from 'rxjs';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { SectionsChange } from '@kuki/tv/shared/modules/section-control/section-change';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ScrollService } from '@kuki/global/shared/services/scroll.service';
import { throttleTime } from 'rxjs/operators';
import { hal } from '@kuki/root/platforms/hal';

export interface SectionControlConfig {
    duration?: number;
    disabledControls?: boolean;
    watchScroll?: boolean;
    storeSections?: boolean;
    sectionArrows?: boolean;
}

export interface SectionControlState {
    activeIndex?: number;
    sections?: Array<Section>;
}

@Injectable()
export class SectionControlService {
    private readonly defaultConfig: SectionControlConfig = {
        duration: 300,
        disabledControls: false,
        sectionArrows: false,
        watchScroll: true,
        storeSections: true
    };
    private config: SectionControlConfig;
    private activeIndex: number;
    private activeMouseIndex: number = null;
    private sections: Array<Section> = [];
    private scrolling: boolean = false;
    private ident: string = 'section-control';
    private subscription: SubscriptionObject = {};

    private onRequestSections: Subject<void> = new Subject<void>();
    public onRequestSections$: Observable<void> = this.onRequestSections.asObservable();

    private onSectionAdded: Subject<void> = new Subject<void>();
    public onSectionAdded$: Observable<void> = this.onSectionAdded.asObservable();

    private onSectionScrollBeforeStart: Subject<void> = new Subject<void>();
    public onSectionScrollBeforeStart$: Observable<void> = this.onSectionScrollBeforeStart.asObservable();

    private onSectionScrollStart: Subject<SectionsChange> = new Subject<SectionsChange>();
    public onSectionScrollStart$: Observable<SectionsChange> = this.onSectionScrollStart.asObservable();

    private onSectionScrollEnd: Subject<SectionsChange> = new Subject<SectionsChange>();
    public onSectionScrollEnd$: Observable<SectionsChange> = this.onSectionScrollEnd.asObservable();

    private onRequestActionBlocks: Subject<void> = new Subject<void>();
    public onRequestActionBlocks$: Observable<void> = this.onRequestActionBlocks.asObservable();

    private onSectionArrowsEnabled: Subject<boolean> = new Subject<boolean>();
    public onSectionArrowsEnabled$: Observable<boolean> = this.onSectionArrowsEnabled.asObservable();

    private onSectionArrowUpEnabled: Subject<boolean> = new Subject<boolean>();
    public onSectionArrowUpEnabled$: Observable<boolean> = this.onSectionArrowUpEnabled.asObservable();

    private onSectionArrowDownEnabled: Subject<boolean> = new Subject<boolean>();
    public onSectionArrowDownEnabled$: Observable<boolean> = this.onSectionArrowDownEnabled.asObservable();

    public scrollWrapper: ElementRef<HTMLElement>;
    private renderer: Renderer2;
    private actionBlocks: Array<any>;

    // TODO: move to better place
    public verticalPosition: number = 0;

    constructor(
        private controllerService: ControllerService,
        private scrollService: ScrollService,
        private generalService: GeneralService,
        private ngZone: NgZone,
        private applicationRef: ApplicationRef) {
    }

    public init(state?: SectionControlState,
                config?: SectionControlConfig) {
        this.config = { ...this.defaultConfig, ...config };
        this.sections = [];
        this.activeIndex = 0;
        this.actionBlocks = [];
        this.scrolling = false;
        if (state) {
            if (state.activeIndex !== undefined) {
                this.activeIndex = state.activeIndex;
            }
            if (state.sections !== undefined) {
                this.sections = state.sections;
            }
        }
        if (!this.config.disabledControls) {
            this.registerControls();
        }
        if (this.config.sectionArrows) {
            this.enableSectionArrows();
        }
        if (this.activeIndex > 0) {
            if (this.sections.length === 0) {
                this.requestSections();
                this.requestActionBlocks();
            } else {
                this.requestActionBlocks();
            }
            this.onSectionScrollStart.next({
                prevSection: null,
                nextSection: this.sections[ this.activeIndex ],
            });
            const scrollTo = this.getScrollTo(this.activeIndex);
            this.scrollService.scrollVertical(this.scrollWrapper, scrollTo, 0, {
                renderer: this.renderer,
                actualPosition: this.verticalPosition
            }, 'request-animation-frame').then(() => {
                this.verticalPosition = scrollTo;
                this.sectionScrollEnd({
                    prevSection: null,
                    nextSection: this.sections[ this.activeIndex ]
                });
                this.scrolling = false;
            });
        } else {
            this.resetScroll();
            this.requestSections();
            this.requestActionBlocks();
            this.sectionScrollEnd({
                prevSection: null,
                nextSection: this.sections[ 0 ]
            });
            this.scrolling = false;
        }
        if (hal.platform === 'TV.WEBOS') {
            SOM.clearSubscriptions(this.subscription.watchScroll);
            if (this.config.watchScroll) {
                this.ngZone.runOutsideAngular(() => {
                    this.subscription.watchScroll = this.generalService.scroll$.pipe(
                        throttleTime(800)
                    ).subscribe(delta => {
                        if (delta > 0) {
                            this.moveDown();
                        } else {
                            this.moveUp();
                        }
                    });
                });
            }
        }
    }

    public setScrollWrapper(scrollWrapperEl: ElementRef<HTMLElement>) {
        this.scrollWrapper = scrollWrapperEl;
    }

    public setRenderer(renderer: Renderer2) {
        this.renderer = renderer;
    }

    public setActionBlocks(...actionBlocks: Array<any>) {
        this.actionBlocks = actionBlocks;
    }

    public enableSectionArrows() {
        this.onSectionArrowsEnabled.next(true);
    }

    public disableSectionArrows() {
        this.onSectionArrowsEnabled.next(false);
    }

    public addSection(section: Section) {
        const sectionExists = this.sections.find(item => item.index === section.index);
        if (!sectionExists) {
            this.sections.push(section);
            this.sections.sort((a, b) => a.index > b.index ? 1 : -1);
        } else {
            sectionExists.position = section.position;
            sectionExists.positionEnd = section.positionEnd;
            sectionExists.height = section.height;
        }
        this.onSectionAdded.next();
    }

    public deleteSection(sectionIndex: number) {
        this.sections = this.sections.filter(section => section.index !== sectionIndex);
    }

    public moveUp() {
        this.activeMouseIndex = null;
        this.sectionScrollBeforeStart();
        if (this.activeIndex > 0 && !this.scrolling) {
            this.sectionScrollStart(-1);
            this.activeIndex--;
            this.scrolling = true;
            const scrollTo = this.getScrollTo(this.activeIndex, -1);
            const promise = (scrollTo !== this.verticalPosition) ?
                this.scrollService.scrollVertical(this.scrollWrapper, scrollTo, this.config.duration, {
                    renderer: this.renderer,
                    actualPosition: this.verticalPosition
                }, 'request-animation-frame') : Promise.resolve();
            promise.then(() => {
                this.verticalPosition = scrollTo;
                this.sectionScrollEnd({
                    prevSection: this.sections[ this.activeIndex + 1 ],
                    nextSection: this.sections[ this.activeIndex ],
                });
                this.scrolling = false;
            });
            return promise;
        }
    }

    public moveDown() {
        this.activeMouseIndex = null;
        this.sectionScrollBeforeStart();
        if (this.activeIndex < this.sections.length - 1 && !this.scrolling) {
            this.sectionScrollStart(1);
            this.activeIndex++;
            this.scrolling = true;
            const scrollTo = this.getScrollTo(this.activeIndex, 1);
            const promise = (scrollTo !== this.verticalPosition) ?
                this.scrollService.scrollVertical(this.scrollWrapper, scrollTo, this.config.duration, {
                    renderer: this.renderer,
                    actualPosition: this.verticalPosition
                }, 'request-animation-frame') : Promise.resolve();
            promise.then(() => {
                this.verticalPosition = scrollTo;
                this.sectionScrollEnd({
                    prevSection: this.sections[ this.activeIndex - 1 ],
                    nextSection: this.sections[ this.activeIndex ],
                });
                this.scrolling = false;
            });
            return promise;
        }
    }

    public moveTo(index: number) {
        const dir = this.activeIndex < index ? 1 : -1;
        this.activeIndex = index;
        this.scrolling = true;
        this.activeMouseIndex = null;
        this.sectionScrollBeforeStart();
        this.sectionScrollStart(dir);
        const scrollTo = this.getScrollTo(this.activeIndex);
        return this.scrollService.scrollVertical(this.scrollWrapper, scrollTo, this.config.duration, {
            renderer: this.renderer,
            actualPosition: this.verticalPosition
        }, 'request-animation-frame').then(() => {
            this.verticalPosition = scrollTo;
            this.sectionScrollEnd({
                prevSection: null,
                nextSection: this.sections[ this.activeIndex ]
            });
            this.scrolling = false;
        });
    }

    private sectionScrollBeforeStart() {
        this.onSectionScrollBeforeStart.next();
        this.applicationRef.tick();
        this.requestSections();
        this.requestActionBlocks();
    }

    private sectionScrollStart(dir = 1) {
        // emit last active section
        this.onSectionScrollStart.next({
            prevSection: this.sections[ this.activeIndex ],
            nextSection: this.sections[ this.activeIndex + dir ],
        });
        this.deactivateActionBlocks();
    }

    private sectionScrollEnd(sectionChange: SectionsChange) {
        if (!sectionChange.nextSection) {
            return;
        }
        this.activateActionBlock(sectionChange.nextSection.index);
        this.onSectionArrowUpEnabled.next(!this.isFirst());
        this.onSectionArrowDownEnabled.next(!this.isLast());
        this.onSectionScrollEnd.next({
            prevSection: this.sections[ this.activeIndex - 1 ],
            nextSection: this.sections[ this.activeIndex ],
        });
    }

    public getActiveSection() {
        return this.sections[ this.getActiveSectionIndex() ];
    }

    public getActiveSectionIndex() {
        return this.activeMouseIndex !== null ? this.activeMouseIndex : this.activeIndex;
    }

    private registerControls() {
        this.controllerService.registerActionKey(CommonKeys.UP, this.ident, () => {
            this.moveUp();
        });
        this.controllerService.registerActionKey(CommonKeys.DOWN, this.ident, () => {
            this.moveDown();
        });
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    private requestSections() {
        this.onRequestSections.next();
    }

    private requestActionBlocks() {
        this.onRequestActionBlocks.next();
    }

    public isFirst(index: number = this.activeIndex) {
        if (!this.sections || !this.sections.length) {
            return;
        }
        return index === 0;
    }

    public isLast(index: number = this.activeIndex) {
        if (!this.sections || !this.sections.length) {
            return;
        }
        return index === this.sections.length - 1;
    }

    public getState() {
        if (!this.config) {
            return;
        }
        const state: SectionControlState = {
            activeIndex: this.activeIndex,
        };
        if (this.config.storeSections) {
            state.sections = this.sections;
        }
        return state;
    }

    public isScrolling() {
        return this.scrolling;
    }

    private getScrollTo(index: number, dir?: number) {
        const section = this.sections[ index ];
        if (!section) {
            return 0;
        }
        if (section.noScroll) {
            if (dir > 0) {
                return this.verticalPosition;
            } else if (dir < 0) {
                // Don't allow to have two no scroll sections in row
                const prevSection = this.sections[ index - 1 ];
                return prevSection ? prevSection.position : 0;
            }
            return this.verticalPosition;
        }
        if (section.limitByWindow) {
            if (section.position + window.innerHeight > this.scrollWrapper.nativeElement.scrollHeight) {
                return this.scrollWrapper.nativeElement.scrollHeight - window.innerHeight;
            }
        }
        return dir > 0 || dir === undefined ? section.position : section.positionEnd;
    }


    public activateSection(index: number, withMouse: boolean = false) {
        if (withMouse) {
            this.activeMouseIndex = index;
        } else {
            this.activeIndex = index;
            this.activeMouseIndex = null;
        }
        this.activateActionBlock(index);
    }

    public activateActionBlock(index: number) {
        if (!this.actionBlocks) {
            return;
        }
        if (this.actionBlocks[ index ]) {
            this.actionBlocks[ index ].activate();
        }
    }

    public deactivateActionBlocks() {
        if (!this.actionBlocks) {
            return;
        }
        this.actionBlocks.forEach((actionBlock) => {
            if (actionBlock) {
                actionBlock.deactivate();
            }
        });
    }

    private resetScroll() {
        if (this.scrollWrapper) {
            this.renderer.setStyle(this.scrollWrapper.nativeElement, this.generalService.transformFunction, null);
            this.verticalPosition = 0;
        }
    }

    public destroy() {
        this.config = null;
        this.sections = [];
        this.actionBlocks = [];
        this.activeIndex = 0;
        this.activeMouseIndex = null;
        this.scrolling = false;
        this.unregisterControls();
        this.resetScroll();
        this.disableSectionArrows();
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
