import { Inject, Injectable, NgZone } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { SOM } from '@kuki/global/shared/others/subscription/subscription-object';
import { PowerControlService } from '@kuki/global/shared/services/power-control.service';
import { Notification } from '@kuki/global/shared/types/notification';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CoreService } from './core.service';

@Injectable()
export class ConnectionCheckService {

    private readonly checkingInterval: number = 1000;
    private readonly rebootOnConnectionLostInterval: number = 60000;
    private linkOffSince: number;
    private connectionCheckSubscription: Subscription;
    private wifiNotification: Notification;
    private linkNotification: Notification;

    private connectionDown: Subject<void> = new Subject<void>();
    private connectionUp: Subject<void> = new Subject<void>();
    public connectionDown$: Observable<void> = this.connectionDown.asObservable();
    public connectionUp$: Observable<void> = this.connectionUp.asObservable();

    constructor(
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        private powerControlService: PowerControlService,
        private coreService: CoreService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private ngZone: NgZone) {
    }

    public init() {
        this.ngZone.runOutsideAngular(() => {
            this.connectionCheckSubscription = timer(this.checkingInterval, this.checkingInterval)
                .pipe(
                    switchMap(() => Promise.resolve(this.platformHalService.checkConnection()))
                )
                .subscribe(result => {
                    if (result === 'fail') {
                        this.showLinkWarning();
                        this.hideWirelessWarning();
                    } else if (hal.cap.connectionCheckWifi && result === 'wireless') {
                        this.showWirelessWarning();
                        this.hideLinkWarning();
                    } else {
                        this.hideLinkWarning();
                        this.hideWirelessWarning();
                    }
                });
        });
    }

    public destroy() {
        SOM.clearSubscriptions(this.connectionCheckSubscription);
    }

    private showLinkWarning() {
        if (!this.linkNotification) {
            this.linkOffSince = Date.now();
            this.connectionDown.next();
        }
        if (this.linkOffSince) {
            if (Date.now() - this.linkOffSince > this.rebootOnConnectionLostInterval) {
                if (hal.cap.rebootOnConnectionLost) {
                    // this.platformHalService.reboot();
                    this.platformHalService.restart();
                }
            }
        }
        // hide wifi warning
        this.hideWirelessWarning();
        // refresh because of clearings
        const message = this.coreService.isMobilePlatform() ?
            _('NOTIFICATIONS.GENERAL.NO_CONNECTION_MOBILE') : _('NOTIFICATIONS.GENERAL.NO_CONNECTION');
        this.linkNotification = this.notificationService.show(
            this.translateService.instant(message),
            { duration: 0 });
    }

    private hideLinkWarning() {
        if (this.linkNotification) {
            if (this.linkOffSince) {
                if (Date.now() - this.linkOffSince > 10 * 1000) {
                    if (hal.cap.powerupOnConnectionLost) {
                        this.powerControlService.onPowerOn();
                    }
                }
            }
            this.notificationService.close(this.linkNotification);
            this.linkOffSince = null;
            this.linkNotification = null;
            this.connectionUp.next();
        }
    }

    private showWirelessWarning() {
        // refresh because of clearings
        if (this.wifiNotification) {
            return;
        }
        if (!this.notificationService.someNotifications()) {
            this.wifiNotification = this.notificationService.show(
                this.translateService.instant('NOTIFICATIONS.GENERAL.WIRELESS_CONNECTION'));
        }
    }

    private hideWirelessWarning() {
        this.notificationService.close(this.wifiNotification);
        this.wifiNotification = null;
    }
}
