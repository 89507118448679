import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { VolumeComponent } from './volume.component';

@NgModule({
    declarations: [ VolumeComponent ],
    imports: [
        CommonModule,
        DirectivesModule
    ],
    exports: [
        VolumeComponent
    ],
    providers: []
})
export class VolumeModule {
}
