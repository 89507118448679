import { Inject, Injectable, NgZone } from '@angular/core';
import { ArrisPlatformHalService } from '@kuki/platforms/tv/arris/arris-platform-hal.service';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { hal } from '@kuki/platforms/hal';
import { Observable, Subject, timer } from 'rxjs';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { toiVersion } from '@kuki/platforms/tv/arris/toi-version';
import { DeviceTypes } from '@kuki/global/shared/types/device';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { environment } from '@kuki/environments/environment';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { ArrisKeys, CommonKeys } from '@kuki/global/shared/types/controller/keymap';

declare var toi;

@Injectable()
export class PowerControlService {
    private readonly hdmiCheckInterval = 30 * 1000;
    private powerControl: any;
    private hdmiWasConnected: boolean = false;
    private hdmiDisconnected: number = 0;
    private powerOff: Subject<void> = new Subject<void>();
    private powerOn: Subject<void> = new Subject<void>();
    public powerOff$: Observable<void> = this.powerOff.asObservable();
    public powerOn$: Observable<void> = this.powerOn.asObservable();

    private standBy: boolean = false;

    private subscription: SubscriptionObject = {};

    constructor(
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        private settingsService: SettingsService,
        private controllerService: ControllerService,
        private ngZone: NgZone) {
    }

    public init() {
        this.ngZone.runOutsideAngular(() => {
            if (!environment.production) {
                if (hal.deviceType === DeviceTypes.WEB) {
                    this.controllerService.registerGlobalKey(CommonKeys.PC_TURN_OFF, () => {
                        this.onPowerOff();
                    });
                    this.controllerService.registerGlobalKey(CommonKeys.PC_TURN_ON, () => {
                        this.onPowerOn();
                    });
                }
            }
            switch (hal.platform) {
                case 'TV.ARRIS':
                    if (hal.deviceType !== DeviceTypes.STB) {
                        return;
                    }
                    this.reinitCec();
                    this.powerControl = this.platformHalService.getPowerControl();
                    this.powerControl.addEventListener(
                        toi.consts.ToiPowerControl.ON_POWER_PROFILE_REQUESTED,
                        (e) => this.onPowerProfileRequested(e));
                    this.powerControl.addEventListener(
                        toi.consts.ToiPowerControl.ON_POWER_PROFILE_SELECTED,
                        (e) => this.onPowerProfileSelected(e));

                    this.subscription.hdmiWatcherInterval = timer(this.hdmiCheckInterval, this.hdmiCheckInterval).subscribe(() => {
                        this.checkHdmiState();
                    });
                    this.controllerService.registerGlobalKey(ArrisKeys.POWERBTN, () => {
                        this.togglePower();
                    });
                    break;
                case 'TV.TIZEN':
                    document.addEventListener('visibilitychange', () => {
                        if (document.hidden) {
                            this.onPowerOff();
                        } else {
                            this.onPowerOn();
                        }
                    });
                    document.addEventListener('beforeunload', () => {
                        this.onPowerOff();
                    });
                    break;
                case 'TV.ANDROID':
                case 'MOBILE.ANDROID':
                case 'MOBILE.IOS':
                    if (!this.platformHalService.appStateChanged$) {
                        return;
                    }
                    this.subscription.appStateChanged = this.platformHalService.appStateChanged$.subscribe(state => {
                        switch (state) {
                            case 'appPause':
                                this.onPowerOff();
                                return;
                            case 'appResume':
                                this.onPowerOn();
                        }
                    });
                    break;
            }
        });
    }

    public destroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }

    public isStandBy() {
        return (this.platformHalService.isStandBy && this.platformHalService.isStandBy()) || this.standBy;
    }

    public requestPowerOn() {
        if (this.platformHalService.powerOn) {
            this.platformHalService.powerOn();
        } else {
            this.onPowerOn();
        }
    }

    public requestPowerOff(reason?: string) {
        if (this.platformHalService.powerOff) {
            this.platformHalService.powerOff(reason);
        } else {
            this.onPowerOff();
        }
    }

    public canPowerOff() {
        return !!this.platformHalService.powerOff;
    }

    public onPowerOn() {
        // console.log('onPowerOn');
        this.powerOn.next();
        this.standBy = false;
    }

    public onPowerOff() {
        // console.log('onPowerOff');
        this.powerOff.next();
        this.standBy = true;
    }

    public reinitCec() {
        const settings = this.settingsService.getSettings();
        (this.platformHalService as ArrisPlatformHalService).setIsObject('cfg.cec.osdOpened.name', 'Kuki');
        try {
            if (hal.cap.hdmioff) {
                if (settings.hdmiStbOff === '1') {
                    toi.hdmiService.setCecFeatures([
                        {
                            feature: toi.hdmiService.CEC_FEATURE_SYSTEM_STANDBY_FOLLOWER,
                            isEnabled: true
                        }
                    ]);
                    console.log('Turned CEC standby follower feature to ON');
                } else {
                    // should NOT follow HDMI
                    toi.hdmiService.setCecFeatures([
                        {
                            feature: toi.hdmiService.CEC_FEATURE_SYSTEM_STANDBY_FOLLOWER,
                            isEnabled: false
                        }
                    ]);
                    console.log('Turned CEC standby follower feature to OFF');
                }
            }
        } catch (e) {
            console.log('Unable to set CEC standby follower feature');
            console.log(e);
        }

        try {
            if (hal.cap.tvoff) {
                if (settings.hdmiTvOff === '1') {
                    toi.hdmiService.setCecFeatures([
                        {
                            feature: toi.hdmiService.CEC_FEATURE_SYSTEM_STANDBY,
                            isEnabled: true
                        }
                    ]);
                    console.log('Turned CEC standby feature to ON');
                } else {
                    // should NOT send CEC
                    toi.hdmiService.setCecFeatures([
                        {
                            feature: toi.hdmiService.CEC_FEATURE_SYSTEM_STANDBY,
                            isEnabled: false
                        }
                    ]);
                    console.log('Turned CEC standby feature to OFF');
                }
            }
        } catch (e) {
            console.log('Unable to set CEC standby feature');
            console.log(e);
        }
    }

    private onPowerProfileRequested(e) {
        let p = toi.consts.ToiPowerControl.PROFILE_ACTIVE;
        console.log('onPowerProfileRequested in: ' + e.profile);
        switch (e.profile) {
            case toi.consts.ToiPowerControl.PROFILE_ACTIVE:
                p = toi.consts.ToiPowerControl.PROFILE_ACTIVE;
                break;
            case toi.consts.ToiPowerControl.PROFILE_ACTIVE_STANDBY:
                p = toi.consts.ToiPowerControl.PROFILE_ACTIVE_STANDBY;
                break;
            case toi.consts.ToiPowerControl.PROFILE_ACTIVE_STANDBY_LOW:
                p = toi.consts.ToiPowerControl.PROFILE_ACTIVE_STANDBY;
                break;
            case toi.consts.ToiPowerControl.PROFILE_PASSIVE_STANDBY:
                p = toi.consts.ToiPowerControl.PROFILE_ACTIVE_STANDBY;
        }
        console.log('onPowerProfileRequested out: ' + p);
        this.powerControl.reportPowerProfile(p);
    }

    private onPowerProfileSelected(e) {
        console.log('onPowerProfileSelected: ' + e.profile);
        this.powerControl.reportPowerProfileActionsComplete();
        switch (e.profile) {
            case toi.consts.ToiPowerControl.PROFILE_ACTIVE:
                this.onPowerOn();
                break;
            case toi.consts.ToiPowerControl.PROFILE_ACTIVE_STANDBY:
                this.onPowerOff();
                break;
        }
    }

    private checkHdmiState() {
        if ((this.platformHalService as ArrisPlatformHalService).getDeviceState() === 'normal') {
            const hdmisinkinfo = (toiVersion === 3) ? toi.hdmiService.getSinkInfo() : toi.hdmiService.getHdmiSinkInfo();
            if (hdmisinkinfo.hdcpStatus === toi.hdmiService.HDCP_STATUS_OK) {
                this.hdmiWasConnected = true;
                this.hdmiDisconnected = 0;
            } else if (hdmisinkinfo.hdcpStatus === toi.hdmiService.HDCP_STATUS_INITIALIZING && this.hdmiWasConnected) {
                this.hdmiDisconnected++;
                if (this.hdmiDisconnected >= 3) {
                    this.hdmiWasConnected = false;
                    const hdmiStbOff = this.settingsService.getSettingsValue('hdmiStbOff');
                    if (hdmiStbOff === '1') {
                        console.log('going to STANDBY due to disconnected HDMI (HDCP died)');
                        this.platformHalService.powerOff();
                    }
                }
            }
        }
    }

    private togglePower() {
        if (this.platformHalService.getDeviceState() === 'normal') {
            this.platformHalService.powerOff(toi.consts.ToiPowerControl.REASON_STANDARD);
        } else {
            this.platformHalService.powerOn();
        }
    }
}
