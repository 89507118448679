import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AudioOutputService } from '@kuki/platforms/tv/arris/plugins/audio-output.service';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { MediaPlayerV2Component } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.component';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';

@Injectable()
export class VolumeService {

    private volume: number = hal.volume?.defaultVolume || 20;
    private muted: boolean = false;

    private volumeAvailable: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public volumeAvailable$: Observable<boolean> = this.volumeAvailable.asObservable();

    private volumeChanged: ReplaySubject<number> = new ReplaySubject<number>();
    public volumeChanged$: Observable<number> = this.volumeChanged.asObservable();

    // private onMute: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // public onMute$: Observable<boolean> = this.onMute.asObservable();
    //
    // private onVolume: BehaviorSubject<number> = new BehaviorSubject<number>(this.volume);
    // public onVolume$: Observable<number> = this.onVolume.asObservable();
    //
    // private volumeAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    // public volumeAvailable$: Observable<boolean> = this.volumeAvailable.asObservable();

    constructor(
        @Optional() private audioOutputService: AudioOutputService,
        @Inject('MediaPlayerHalService') private mediaPlayerHalService: MediaPlayerHalInterface,
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        private componentRegisterService: ComponentRegisterService) {
    }

    public init() {
        if (this.platformHalService.getDefaultVolume) {
            this.volume = this.platformHalService.getDefaultVolume();
        }
        if (this.platformHalService.getDefaultMute) {
            this.muted = this.platformHalService.getDefaultMute();
        }
        if (hal.volume?.setVolumeOnBoot) {
            if (this.platformHalService.setVolume) {
                this.platformHalService.setVolume(this.volume);
            }
            if (this.platformHalService.setMute) {
                this.platformHalService.setMute(this.muted);
            }
        }
    }

    public setVolume(volume: number) {
        if (!hal.volume) {
            return;
        }
        if (this.checkAudioInPassthrough()) {
            return;
        }
        this.volume = volume;
        if (this.volume > hal.volume.volumeMax) {
            this.volume = hal.volume.volumeMax;
        }
        if (this.volume < hal.volume.volumeMin) {
            this.volume = hal.volume.volumeMin;
        }
        if (this.platformHalService.setVolume) {
            this.platformHalService.setVolume(this.volume);
        }
        this.volumeChanged.next();
        this.unmute();
    }

    public volUp() {
        if (!hal.volume) {
            return;
        }
        if (this.checkAudioInPassthrough()) {
            this.volumeChanged.next();
            return;
        }
        this.volume += Math.round((hal.volume.volumeMax - hal.volume.volumeMin) / hal.volume.volumeSteps);
        if (this.volume > hal.volume.volumeMax) {
            this.volume = hal.volume.volumeMax;
        }

        if (this.platformHalService.setVolume) {
            this.platformHalService.setVolume(this.volume);
        }
        this.volumeChanged.next();
        this.unmute();
    }

    public volDown() {
        if (!hal.volume) {
            return;
        }
        if (this.checkAudioInPassthrough()) {
            this.volumeChanged.next();
            return;
        }
        this.volume -= Math.round((hal.volume.volumeMax - hal.volume.volumeMin) / hal.volume.volumeSteps);
        if (this.volume < hal.volume.volumeMin) {
            this.volume = hal.volume.volumeMin;
        }
        if (this.platformHalService.setVolume) {
            this.platformHalService.setVolume(this.volume);
        }
        this.volumeChanged.next();
        this.unmute();
    }

    public mute() {
        if (!hal.volume) {
            return;
        }
        if (this.checkAudioInPassthrough()) {
            this.volumeChanged.next();
            return;
        }
        if (!this.muted) {
            if (this.platformHalService.setMute) {
                this.platformHalService.setMute(true);
            }
            this.muted = true;
            this.volumeChanged.next(5000);
        }
    }

    public unmute() {
        if (!hal.volume) {
            return;
        }
        if (this.checkAudioInPassthrough()) {
            this.volumeChanged.next();
            return;
        }
        if (this.muted) {
            if (this.platformHalService.setMute) {
                this.platformHalService.setMute(false);
            }
            this.muted = false;
            this.volumeChanged.next();
        }
    }

    private checkAudioInPassthrough(): boolean {
        if (!hal.volume) {
            return;
        }
        const check = () => {
            if (!hal.cap.audioPassthrough) {
                return false;
            }
            const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
            if (!this.mediaPlayerHalService || !this.audioOutputService || !mediaPlayerV2Component) {
                return false;
            }
            const activeAudioTrack = this.mediaPlayerHalService.getActiveAudioTrack();
            if (!activeAudioTrack) {
                return false;
            }
            return this.audioOutputService.getPassthroughCodecs().indexOf(activeAudioTrack.encoding) >= 0;
        };
        const res = check();
        if (this.volumeAvailable.getValue() !== !res) {
            this.volumeAvailable.next(!res);
        }
        return res;
    }

    public getVolume() {
        return this.volume;
    }

    public getMuted() {
        return this.muted;
    }

    public getAudioState() {
        return {
            volume: this.getVolume(),
            muted: this.getMuted()
        };
    }
}
