import { Inject, Injectable, NgZone } from '@angular/core';
import { hal } from '@kuki/platforms/hal';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { ArrisPlatformHalService } from '@kuki/platforms/tv/arris/arris-platform-hal.service';
import { Plugin } from '@kuki/global/shared/types/general';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { PowerControlService } from '@kuki/global/shared/services/power-control.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { MediaPlayerV2Component } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.component';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AfrService implements Plugin {

    private readonly afrCheckIntervalValue: number = 1000;
    private lastActivity: number;

    constructor(
        private authService: AuthService,
        private navigationService: NavigationService,
        private ngZone: NgZone,
        private generalService: GeneralService,
        private translateService: TranslateService,
        private powerControlService: PowerControlService,
        private componentRegisterService: ComponentRegisterService,
        @Inject('PlatformHalService') private arrisPlatformHalService: ArrisPlatformHalService,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    private afrCheckerInterval: any;
    private subscription: SubscriptionObject = {};

    public init() {
        if (!hal.cap.afr) {
            return;
        }
        this.ping();

        this.ngZone.runOutsideAngular(() => {
            this.afrCheckerInterval = setInterval(() => {
                this.checkAfr();
            }, this.afrCheckIntervalValue);
            this.subscription.powerOn = this.powerControlService.powerOn$.subscribe(() => {
                this.ping();
            });
            document.addEventListener('keydown', () => this.ping());
        });
    }

    public destroy() {
        if (this.afrCheckerInterval) {
            clearInterval(this.afrCheckerInterval);
        }
        SOM.clearSubscriptionsObject(this.subscription);
    }

    public ping() {
        this.lastActivity = Date.now();
    }

    private checkAfr() {
        const state = this.arrisPlatformHalService.getDeviceState();
        if (Date.now() - this.lastActivity > hal.afrTimeout && state === 'normal') {
            this.ping();
            const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
            if (mediaPlayerV2Component) {
                if (mediaPlayerV2Component.mediaPlayerV2Service.canPlayMcast()) {
                    console.log('can play mcast');
                    // onnet mcast
                    if (mediaPlayerV2Component.mediaPlayerV2Service.isLive()) {
                        console.log('play live');
                        // do nothing for live (it's mcast)
                    } else if (mediaPlayerV2Component.mediaPlayerV2Service.isTs()) {
                        console.log('play unicast');
                        this.tryLive();
                        return;
                    }
                } else {
                    console.log('play unicast offnet');
                }
            }
            this.tryShutdown();
        }
    }

    private tryShutdown() {
        this.ngZone.run(() => {
            this.modalsService.openConfirmModal({
                message: this.translateService.instant('GENERAL.SHUTDOWN_CONFIRM_MESSAGE'),
                timeout: 1000 * 30
            }).then(() => {
                this.arrisPlatformHalService.powerOff();
            }, () => {
                this.ping();
            });
        });
    }

    private tryLive() {
        this.ngZone.run(() => {
            this.modalsService.openConfirmModal({
                message: this.translateService.instant('GENERAL.LIVE_CONFIRM_MESSAGE'),
                timeout: 1000 * 30
            }).then(() => {
                this.switchLive();
            }).catch(() => {
                this.ping();
            });
        });
    }

    private switchLive() {
        const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
        if (mediaPlayerV2Component) {
            mediaPlayerV2Component.mediaPlayerV2Service.playLive(mediaPlayerV2Component.mediaPlayerV2Service.getChannel()?.id).subscribe();
        } else {
            this.navigationService.navigateHome();
        }
    }
}
