import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SourceLogoComponent } from './source-logo.component';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';


@NgModule({
    declarations: [ SourceLogoComponent ],
    exports: [
        SourceLogoComponent
    ],
    imports: [
        CommonModule,
        ImageLinkFactoryModule
    ]
})
export class SourceLogoModule {
}
