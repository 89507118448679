import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConnectableObservable, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { ButtonRow, TileRow } from '@kuki/global/shared/types/general';
import { environment } from '@kuki/environments/environment';
import { debounceTime, share, switchMap } from 'rxjs/operators';
import { MediaTile } from '@kuki/global/shared/types/tile';

@Injectable()
export class SearchService {

    private _filterActive = false;
    private _query: string = '';
    private _filter: { [ key: string ]: Array<string> } = {};
    private features: Array<string> = [ 'channel-search' ];

    private onSearch: Subject<void> = new Subject();
    // connectable observable since used in two services
    public searchHandler$: ConnectableObservable<TileRow<MediaTile>> = this.onSearch
        .pipe(debounceTime(400), switchMap(() => {
            if ((!this.query || this.query.length === 0) && (!this._filter || Object.keys(this._filter).length === 0)) {
                return of(null);
            }
            let httpParams = new HttpParams();
            if (this.features) {
                httpParams = httpParams.append('features', this.features.join(','));
            }
            return this.httpClient.post<TileRow>(environment.apiUrl + 'search', {
                query: this._query && this._query.length > 0 ? this._query : undefined,
                filter: this._filter
            }, {
                params: httpParams
            });
        }), share()) as ConnectableObservable<TileRow<MediaTile>>;

    public queryChanged: Subject<string> = new Subject();
    public filterChanged: Subject<void> = new Subject();

    private onToggleFilter: ReplaySubject<void> = new ReplaySubject(1);
    public onToggleFilter$: Observable<void> = this.onToggleFilter.asObservable();

    constructor(private httpClient: HttpClient) {
    }

    public getFilters() {
        return this.httpClient.get<Array<ButtonRow>>(environment.apiUrl + 'search/filters');
    }

    public search() {
        this.onSearch.next();
    }

    public storeSearch(guid: string) {
        return this.httpClient.post(environment.apiUrl + 'search/store-result', {
            guid: guid
        });
    }

    public getLastResults(): Observable<TileRow<MediaTile>> {
        let httpParams = new HttpParams();
        if (this.features) {
            httpParams = httpParams.append('features', this.features.join(','));
        }
        return this.httpClient.get(environment.apiUrl + 'search/last-results', { params: httpParams });
    }

    public set query(query: string) {
        this._query = query;
        this.queryChanged.next(query);
    }

    public get query(): string {
        return this._query;
    }

    public set filter(filter: { [ key: string ]: Array<string> }) {
        this._filter = filter;
        this.filterChanged.next();
    }

    public get filter(): { [ key: string ]: Array<string> } {
        return this._filter;
    }

    // WEB/MOBILE part
    public toggleFilter() {
        this.onToggleFilter.next();
    }

    // TODO: TV part, put somewhere else!
    public set filterActive(filterActive) {
        this._filterActive = filterActive;
    }

    public get filterActive() {
        return this._filterActive;
    }

    public destroy() {
        this._filterActive = false;
        this._query = '';
        this._filter = {};
        this.onToggleFilter = new ReplaySubject(1);
        this.onToggleFilter$ = this.onToggleFilter.asObservable();
        this.filterChanged.next();
    }

}
