import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageLinkFactoryService } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.service';
import { ImageLinkFactoryComponent } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ImageLinkFactoryComponent
    ],
    exports: [
        ImageLinkFactoryComponent
    ]
})
export class ImageLinkFactoryModule {
    static forRoot(): ModuleWithProviders<ImageLinkFactoryModule> {
        return {
            ngModule: ImageLinkFactoryModule,
            providers: [
                ImageLinkFactoryService
            ]
        };
    }
}
