import { Inject, Injectable, Optional } from '@angular/core';
import { ArrisPlatformHalService } from '../arris-platform-hal.service';
import { toiVersion } from '../toi-version';
import { Plugin } from '@kuki/global/shared/types/general';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { TranslateService } from '@ngx-translate/core';

declare var toi;

@Injectable()
export class AudioOutputService implements Plugin {

    private passthroughCodecs;
    private session: any;

    constructor(
        @Optional() @Inject('PlatformHalService') private arrisPlatformHalService: ArrisPlatformHalService,
        private settingsService: SettingsService,
        private notificationService: NotificationService,
        private translateService: TranslateService) {
    }

    public init() {
        this.setAudioOutput();
    }

    public setAudioOutput() {
        const dolbyDigital = this.settingsService.getSettingsValue('dolbyDigital');
        const supportDolby = dolbyDigital || '0';
        if (supportDolby === '0') {
            this.arrisPlatformHalService.setIsObject('nbx.media.audio.supported_audio_codecs_text', '');
        }

        const connections = toi.audioOutputService.getConnections();
        const cfg = toi.audioOutputService.getConfiguration();
        this.session = toi.audioOutputService.createConfigurationSession();

        const supportedAudioList = this.getSupportedAudioList();
        let supportedAudioCodecs = [];
        let supportedAudioCodecsText = '';
        const formatpriority = [];
        this.passthroughCodecs = [];

        this.resetMapping(cfg.AUDIO_FORMAT_MPEG_1);
        this.resetMapping(cfg.AUDIO_FORMAT_MPEG_2);
        this.resetMapping(cfg.AUDIO_FORMAT_AC3);
        this.resetMapping(cfg.AUDIO_FORMAT_AC3_PLUS);
        this.resetMapping(cfg.AUDIO_FORMAT_AAC_HE);
        this.resetMapping(cfg.AUDIO_FORMAT_AAC_LC);
        this.resetMapping(cfg.AUDIO_FORMAT_DTS);
        this.resetMapping(cfg.AUDIO_FORMAT_DTS_HD);
        this.resetMapping(cfg.AUDIO_FORMAT_PCM_2CH);
        this.resetMapping(cfg.AUDIO_FORMAT_MP3);

        connections.forEach(c => {
            if (c.direction === toi.audioOutputService.AUDIO_CONNECTION_DIRECTION_OUTPUT) {
                this.setMapping(c.id, cfg.AUDIO_FORMAT_MPEG_1, cfg.AUDIO_FORMAT_PCM_2CH);
                this.setMapping(c.id, cfg.AUDIO_FORMAT_MPEG_2, cfg.AUDIO_FORMAT_PCM_2CH);

                if (supportDolby === '0' ||
                    [ toi.audioOutputService.AUDIO_CONNECTION_TYPE_HDMI,
                        toi.audioOutputService.AUDIO_CONNECTION_TYPE_SPDIF ].indexOf(c.type) === -1) {
                    this.setMapping(c.id, cfg.AUDIO_FORMAT_AC3, cfg.AUDIO_FORMAT_PCM_2CH);
                    this.setMapping(c.id, cfg.AUDIO_FORMAT_AC3_PLUS, cfg.AUDIO_FORMAT_PCM_2CH);
                    this.setMapping(c.id, cfg.AUDIO_FORMAT_AAC_HE, cfg.AUDIO_FORMAT_PCM_2CH);
                    this.setMapping(c.id, cfg.AUDIO_FORMAT_AAC_LC, cfg.AUDIO_FORMAT_PCM_2CH);
                } else if (supportDolby === '1') {
                    if ([ toi.audioOutputService.AUDIO_CONNECTION_TYPE_HDMI,
                        toi.audioOutputService.AUDIO_CONNECTION_TYPE_SPDIF ].indexOf(c.type) >= 0) {
                        if (supportedAudioList.indexOf(cfg.AUDIO_FORMAT_AC3) >= 0) {
                            this.setMapping(c.id, cfg.AUDIO_FORMAT_AC3, cfg.AUDIO_FORMAT_AC3);
                            supportedAudioCodecs.push('AC3');
                            this.passthroughCodecs.push('AC3');
                            if (formatpriority.indexOf('AC3') === -1) {
                                formatpriority.push('AC3');
                            }
                        } else {
                            this.setMapping(c.id, cfg.AUDIO_FORMAT_AC3, cfg.AUDIO_FORMAT_PCM_2CH);
                        }

                        if (supportedAudioList.indexOf(cfg.AUDIO_FORMAT_AC3_PLUS) >= 0) {
                            this.setMapping(c.id, cfg.AUDIO_FORMAT_AC3_PLUS, cfg.AUDIO_FORMAT_AC3_PLUS);
                            supportedAudioCodecs.push('AC3 Plus');
                            this.passthroughCodecs.push('AC3_PLUS');
                            this.passthroughCodecs.push('EAC3');

                            if (formatpriority.indexOf('AC3') === -1) {
                                formatpriority.push('AC3');
                            }
                        } else {
                            this.setMapping(c.id, cfg.AUDIO_FORMAT_AC3_PLUS, cfg.AUDIO_FORMAT_PCM_2CH);
                        }

                        if (supportedAudioList.indexOf(cfg.AUDIO_FORMAT_AAC_HE) >= 0) {
                            this.setMapping(c.id, cfg.AUDIO_FORMAT_AAC_HE, cfg.AUDIO_FORMAT_AAC_HE);
                            supportedAudioCodecs.push('AAC HE');
                            this.passthroughCodecs.push('AAC_ADTS');
                            this.passthroughCodecs.push('AAC_LATM');
                            this.passthroughCodecs.push('AAC');
                            if (formatpriority.indexOf('AAC') === -1) {
                                formatpriority.push('AAC');
                            }
                        } else {
                            this.setMapping(c.id, cfg.AUDIO_FORMAT_AAC_HE, cfg.AUDIO_FORMAT_PCM_2CH);
                        }

                        if (supportedAudioList.indexOf(cfg.AUDIO_FORMAT_AAC_LC) >= 0) {
                            this.setMapping(c.id, cfg.AUDIO_FORMAT_AAC_LC, cfg.AUDIO_FORMAT_AAC_LC);
                            supportedAudioCodecs.push('AAC LC');
                            this.passthroughCodecs.push('AAC_ADTS');
                            this.passthroughCodecs.push('AAC_LATM');
                            this.passthroughCodecs.push('AAC');
                            if (formatpriority.indexOf('AAC') === -1) {
                                formatpriority.push('AAC');
                            }
                        } else {
                            this.setMapping(c.id, cfg.AUDIO_FORMAT_AAC_LC, cfg.AUDIO_FORMAT_PCM_2CH);
                        }

                        if (supportedAudioCodecs.length === 0) {
                            supportedAudioCodecsText = 'žádné';
                        } else {
                            supportedAudioCodecs = supportedAudioCodecs.filter((value, index, self) => self.indexOf(value) === index);
                            supportedAudioCodecsText = supportedAudioCodecs.join(', ');
                        }
                        if (c.type === toi.audioOutputService.AUDIO_CONNECTION_TYPE_HDMI) {
                            if (supportedAudioCodecsText !==
                                this.arrisPlatformHalService.getIsObject('nbx.media.audio.supported_audio_codecs_text')) {
                                this.arrisPlatformHalService.setIsObject(
                                    'nbx.media.audio.supported_audio_codecs_text',
                                    supportedAudioCodecsText);
                                this.notificationService.show(
                                    this.translateService.instant(
                                        'NOTIFICATIONS.GENERAL.AUDIO_CODECS_SUPPORT',
                                        { supportedAudioCodecsText: supportedAudioCodecsText }));
                            }
                        }
                    }
                } else if (supportDolby === '2') {
                    if ([ toi.audioOutputService.AUDIO_CONNECTION_TYPE_HDMI,
                        toi.audioOutputService.AUDIO_CONNECTION_TYPE_SPDIF ].indexOf(c.type) >= 0) {
                        this.setMapping(c.id, cfg.AUDIO_FORMAT_AC3, cfg.AUDIO_FORMAT_AC3);
                        this.passthroughCodecs.push('AC3');
                        if (formatpriority.indexOf('AC3') === -1) {
                            formatpriority.push('AC3');
                        }
                        if (this.arrisPlatformHalService.getIsObject('nbx.media.audio.supported_audio_codecs_text') !== 'AC3') {
                            this.arrisPlatformHalService.setIsObject('nbx.media.audio.supported_audio_codecs_text', 'AC3');
                            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.FORCE_AUDIO_AC3_CODEC'));
                        }
                        this.setMapping(c.id, cfg.AUDIO_FORMAT_AC3_PLUS, cfg.AUDIO_FORMAT_PCM_2CH);
                        this.setMapping(c.id, cfg.AUDIO_FORMAT_AAC_LC, cfg.AUDIO_FORMAT_PCM_2CH);
                        this.setMapping(c.id, cfg.AUDIO_FORMAT_AAC_HE, cfg.AUDIO_FORMAT_PCM_2CH);
                    }
                }
                this.setMapping(c.id, cfg.AUDIO_FORMAT_DTS, cfg.AUDIO_FORMAT_PCM_2CH);
                this.setMapping(c.id, cfg.AUDIO_FORMAT_DTS_HD, cfg.AUDIO_FORMAT_PCM_2CH);
                this.setMapping(c.id, cfg.AUDIO_FORMAT_PCM_2CH, cfg.AUDIO_FORMAT_PCM_2CH);
                this.setMapping(c.id, cfg.AUDIO_FORMAT_MP3, cfg.AUDIO_FORMAT_PCM_2CH);
            }
        });
        this.session.apply();
        try {
            this.session.releaseInstance();
        } catch (e) {
            console.log('Unable to release releaseInstance');
            console.log(e);
        }

        if (formatpriority.indexOf('AAC') === -1) {
            formatpriority.push('AAC');
        }
        if (formatpriority.indexOf('MPEG') === -1) {
            formatpriority.push('MPEG');
        }
        this.arrisPlatformHalService.setIsObject('cfg.media.audio.formatpriority', formatpriority.join(','));
    }

    public getPassthroughCodecs() {
        return this.passthroughCodecs;
    }

    private getSupportedAudioList() {
        if (toiVersion === 3) {
            return toi.hdmiService.getSinkInfo().supportedAudioFormats;
        } else {
            return toi.hdmiService.getHdmiSinkInfo().audioFormatList;
        }
    }

    private setMapping(conn, fmt, out) {
        if (fmt !== undefined && fmt !== null) {
            try {
                this.session.setFormatMapping(conn, fmt, { format: out, mixFlag: false });
            } catch {
                console.log(`AOS: unable to set ${ fmt } mapping to ${ out }`);
            }
        } else {
            console.log(`AOS: unknown fmt ${ fmt }`);
        }
    }

    private resetMapping(fmt) {
        if (fmt !== undefined && fmt !== null) {
            try {
                this.session.resetFormatMapping(fmt);
            } catch {
                console.log(`AOS: unable to reset ${ fmt }`);
            }
        }
    }
}
