import { Inject, Injectable, NgZone } from '@angular/core';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { environment } from '@kuki/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UpgraderService {

    private readonly upgradeCheckIntervalValue: number =  60 * 60 * 1000; // hour
    private readonly pendingIntervalValue: number = 60 * 1000; // minute
    private readonly updateTimeout: number = 5 * 1000; // 5s

    private subscription: SubscriptionObject = {};
    private manifestFile: string = 'version-smart.json';
    private requiredOperationOnOff: string = null;

    private pendingOpInterval: any;

    constructor(
        private notificationService: NotificationService,
        private httpService: HttpClient,
        private ngZone: NgZone,
        private translateService: TranslateService,
        @Inject('PlatformHalService') private platformHalService: PlatformHal) {
    }

    public init() {
        this.ngZone.runOutsideAngular(() => {
            this.subscription.upgradeCheckInterval =
                // use timer due simplicity (not necessary to use setInterval and setTimeout together)
                timer(Math.floor(Math.random() * this.upgradeCheckIntervalValue), this.upgradeCheckIntervalValue)
                    .pipe(
                        switchMap(() => this.upgradeCheck())
                    )
                    .subscribe();

            this.pendingOpInterval = setInterval(() => {
                this.pendingOpCheck();
            }, this.pendingIntervalValue);
        });
    }

    public destroy() {
        if (this.pendingOpInterval) {
            clearInterval(this.pendingOpInterval);
        }
        SOM.clearSubscriptionsObject(this.subscription);
    }

    private upgradeCheck() {
        console.log(`Running portal version: ${ environment.versionBuild }`);
        return this.httpService.get(this.manifestFile + `?v=${ Date.now() }`) // force cache clear
            .pipe(
                tap((data: Array<any>) => {
                    data.forEach(rule => {
                        if (this.validateRule(rule)) {
                            if (rule.force) {
                                this.performOperation(rule.op);
                            } else {
                                if (this.requiredOperationOnOff !== 'reboot') {
                                    this.requiredOperationOnOff = rule.op;
                                    console.log(`Scheduled op -> ${ this.requiredOperationOnOff }`);
                                }
                            }
                        }
                    });
                }));
    }

    private validateRule(rule) {
        const hours = new Date().getHours();
        if (hours >= rule.valid_from_hour && hours <= rule.valid_to_hour) {
            switch (rule.when) {
                case 'ne':
                    return environment.versionBuild !== rule.version_build;
                case 'eq':
                    return environment.versionBuild === rule.version_build;
                case 'lt':
                    return environment.versionBuild < rule.version_build;
                case 'lte':
                    return environment.versionBuild <= rule.version_build;
            }
        }
        return false;
    }

    private performOperation(op) {
        console.log('Upgrader - performOperation: ' + op);
        this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.UPDATING_APP'));
        setTimeout(() => {
            if (op === 'restart') {
                this.platformHalService.restart();
            } else if (op === 'reboot') {
                this.platformHalService.reboot();
            }
        }, this.updateTimeout);
    }

    private pendingOpCheck() {
        if (!this.platformHalService.getDeviceState) {
            return;
        }
        if (this.requiredOperationOnOff && this.platformHalService.getDeviceState() === 'standby') {
            this.performOperation(this.requiredOperationOnOff);
            this.requiredOperationOnOff = null;
        }
    }
}
