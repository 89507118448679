import { Injectable } from '@angular/core';
import { TileRow } from '@kuki/global/shared/types/general';
import { environment } from '@kuki/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CarrouselTile } from '@kuki/global/shared/types/tile/carrousel-tile';

@Injectable()
export class CarrouselService {

    constructor(private httpClient: HttpClient) {
    }

    public getCarrouselList(ident: string): Observable<TileRow<CarrouselTile>> {
        return this.httpClient.get<TileRow<CarrouselTile>>(environment.apiUrl + 'carrousel-list/' + ident);
    }
}
