export const UI_VARIABLES_BROWSER = {
    default: {
        relativeWidth: 375,
        gridColumns: 12,
        gridColumn: 23,
        gridGutter: 7,
        baseline: 8
    },
    res: {
        992: {
            relativeWidth: 1440,
            gridColumns: 12,
            gridColumn: 109,
            gridGutter: 12,
            baseline: 10
        }
    }
};
