import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { merge, Observable, of, timer } from 'rxjs';
import { EpgTile } from '@kuki/global/shared/types/tile';
import { map, skip, switchMap, take } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ChannelService } from '@kuki/global/shared/services/channel.service';
import { environment } from '@kuki/environments/environment';

@Injectable()
export class EpgService {

    private readonly EPG_PRELOAD_PRE = 8 * 3600 * 1000;
    private readonly EPG_PRELOAD_POST = 8 * 3600 * 1000;
    private readonly PRELOAD_CACHE_INTERVAL = 60 * 60 * 1000;

    private cache: { [ key: number ]: { [ key: string ]: EpgTile } } = {};
    private cacheIntervals: { [ key: number ]: Array<{ start: number, end: number }> } = {};
    private usedBoundaryLeft: number = null;
    private usedBoundaryRight: number = null;
    private subscription: SubscriptionObject = {};


    constructor(
        private channelService: ChannelService,
        private httpClient: HttpClient,
        private ngZone: NgZone) {
    }

    public init() {
        if (this.subscription.cachingInterval) {
            return of(null);
        }
        this.ngZone.runOutsideAngular(() => {
            this.subscription.cachingInterval = merge(
                this.channelService.channelsUpdated$.pipe(skip(1)),
                timer(Math.floor(Math.random() * this.PRELOAD_CACHE_INTERVAL), this.PRELOAD_CACHE_INTERVAL)
            ).pipe(switchMap(() => {
                return this.preloadCache();
            })).subscribe();
        });

        return this.channelService.channelsUpdated$.pipe(take(1), switchMap(() => {
            return this.preloadCache();
        }));
    }

    public destroy() {
        SOM.clearSubscriptions(this.subscription.cachingInterval);
        this.subscription.cachingInterval = undefined;
    }

    public getEntities(channelIds: Array<number>, start: number, end: number): Observable<{ entities: Array<EpgTile>, cached: boolean }> {
        const loadStart = start - (start % (3600 * 1000));
        const loadEnd = end - (end % (3600 * 1000)) + (3600 * 1000);
        if (start !== end) {
            start = loadStart;
            end = loadEnd;
        }
        const toLoadChannelIds = [];
        for (const channelId of channelIds) {
            let gotIt = false;
            if (this.cacheIntervals[ channelId ]) {
                for (const cacheInterval of this.cacheIntervals[ channelId ]) {
                    if (cacheInterval.start <= start && start <= cacheInterval.end &&
                        cacheInterval.start <= end && end <= cacheInterval.end) {
                        gotIt = true;
                        break;
                    }
                }
            }
            if (!gotIt) {
                toLoadChannelIds.push(channelId);
            }
        }

        if (toLoadChannelIds.length > 0) {
            let httpParams = new HttpParams();
            httpParams = httpParams.append('channelIdList', toLoadChannelIds.join());
            httpParams = httpParams.append('from', loadStart.toString());
            httpParams = httpParams.append('to', loadEnd.toString());
            return this.httpClient.get<Array<EpgTile>>(environment.apiUrl + 'epg', { params: httpParams }).pipe(
                map((entities) => {
                        entities.forEach((entity) => {
                            this.cache[ entity.channelId ] = this.cache[ entity.channelId ] || {};
                            for (const cachedId in this.cache[ entity.channelId ]) {
                                if (this.cache[ entity.channelId ][ cachedId ]) {
                                    const cached = this.cache[ entity.channelId ][ cachedId ];
                                    if ((entity.raw.start <= cached.raw.start && cached.raw.start < entity.raw.end) ||
                                        (entity.raw.start < cached.raw.end && cached.raw.end <= entity.raw.end)) {
                                        delete (this.cache[ entity.channelId ][ cachedId ]);
                                    }
                                }
                            }
                            if (!this.cache[ entity.channelId ][ entity.guid ]) {
                                this.markUsedBoundary(entity);
                                this.cache[ entity.channelId ][ entity.guid ] = entity;
                            }
                        });
                        channelIds.forEach(channelId => {
                            this.cacheIntervals[ channelId ] = this.cacheIntervals[ channelId ] || [];
                            this.cacheIntervals[ channelId ].push({
                                start: loadStart,
                                end: loadEnd
                            });
                        });
                        return { cached: false, entities: this.cacheComplete(channelIds, start, end) };
                    }
                ));
        } else {
            return of({ cached: true, entities: this.cacheComplete(channelIds, start, end) });
        }
    }

    public clear() {
        this.cache = {};
        this.cacheIntervals = {};
    }

    private markUsedBoundary(entity: EpgTile) {
        if (this.usedBoundaryLeft === null || this.usedBoundaryLeft > entity.raw.start) {
            this.usedBoundaryLeft = entity.raw.start;
        }
        if (this.usedBoundaryRight === null || this.usedBoundaryRight < entity.raw.end) {
            this.usedBoundaryRight = entity.raw.end;
        }
    }

    private cacheComplete(channelIds: Array<number>, start: number, end: number): Array<EpgTile> {
        const result: Array<EpgTile> = [];
        channelIds.forEach(channelId => {
            for (const cachedId in this.cache[ channelId ]) {
                if (this.cache[ channelId ][ cachedId ]) {
                    const cached = this.cache[ channelId ][ cachedId ];
                    if ((start <= cached.raw.start && cached.raw.start <= end) ||
                        (start < cached.raw.end && cached.raw.end <= end) ||
                        (cached.raw.start < start && cached.raw.end > end) ||
                        (cached.raw.start === start && cached.raw.end === end)) {
                        this.markUsedBoundary(cached);
                        result.push(cached);
                    }
                }
            }
        });
        return result;
    }

    private preloadCache() {
        this.flushCache();
        console.log('preloading EPG cache');
        const now = Date.now();
        return this.getEntities(
            this.channelService.getChannelList().map(channel => channel.id),
            now - this.EPG_PRELOAD_PRE, now + this.EPG_PRELOAD_POST);
    }

    private flushCache() {
        console.log('flushing EPG cache');
        this.cacheIntervals = {};
        let removedEntries = 0;
        let totalEntries = 0;
        if (this.usedBoundaryLeft !== null && this.usedBoundaryRight !== null) {
            for (const channelId in this.cache) {
                if (this.cache[ channelId ]) {
                    for (const cachedId in this.cache[ channelId ]) {
                        if (this.cache[ channelId ][ cachedId ]) {
                            const cached = this.cache[ channelId ][ cachedId ];
                            totalEntries++;
                            if (cached.raw.end < this.usedBoundaryLeft || cached.raw.start > this.usedBoundaryRight) {
                                removedEntries++;
                                delete this.cache[ channelId ][ cachedId ];
                            }
                        }
                    }
                }
            }
        }
        this.usedBoundaryLeft = null;
        this.usedBoundaryRight = null;

        console.log(`epg cache flush: removed ${ removedEntries } entries, count was: ${ totalEntries }, now it's ${ totalEntries - removedEntries }`);
    }

}
