import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Media } from '@kuki/global/shared/types/media';
import { TileRow } from '@kuki/global/shared/types/general';
import { MediaTile, PersonTile } from '@kuki/global/shared/types/tile';
import { environment } from '@kuki/environments/environment';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';

@Injectable()
export class EpgEntityService {

    constructor(
        private httpClient: HttpClient,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    getEpgEntity(epgEntityId: number): Observable<Media> {
        return this.httpClient
            .get<Media>(environment.apiUrl + 'epg-entity/' + epgEntityId);
    }

    getRelated(epgEntityId: number): Observable<TileRow<MediaTile>> {
        return this.httpClient
            .get<TileRow<MediaTile>>(environment.apiUrl + 'epg-entity/' + epgEntityId + '/related');
    }

    getActors(epgEntityId: number): Observable<TileRow<PersonTile>> {
        return this.httpClient.get<TileRow<PersonTile>>(environment.apiUrl + 'epg-entity/' + epgEntityId + '/actors');
    }

    getCreators(epgEntityId: number): Observable<TileRow<PersonTile>> {
        return this.httpClient.get<TileRow<PersonTile>>(environment.apiUrl + 'epg-entity/' + epgEntityId + '/creators');
    }
}
