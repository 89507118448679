<div class="buttons-wrapper" #buttonsWrapper>
    <div *ngFor="let button of buttons; index as i"
         class="button"
         [ngClass]="getButtonClass(button, i)"
         appMouseEvents
         (appMouseEnter)="onMouseEnter(button)"
         (appMouseClick)="onMouseClick(button)"
         #buttonEl>
        <div *ngIf="button.icon" class="icon" [ngClass]="button.icon"></div>
        <app-auto-scroll-container *ngIf="!iconsOnly" class="button-label"
                                   [active]="buttonIsActive(i)">{{ button.label }}</app-auto-scroll-container>
    </div>
</div>
