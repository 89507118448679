import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AudioTrack, SubtitleTrack } from '../types/general';
import { SettingsParsed } from '../types/settings';
import { ProfileService } from './profile.service';

@Injectable()
export class PreferredChannelTrackService {

    private readonly settingsKey = 'preferredChannelTrack';

    /*
     * { CHANNEL_ID: { audioTrack: {}, subtitleTrack: {} }
     */

    constructor(private profileService: ProfileService) {
    }

    public storePreferredAudioTrack(channelId: number, audioTrack: AudioTrack, settings: SettingsParsed): Observable<any> {
        if (!audioTrack) {
            return this.deletePreferredAudioTrack(channelId, settings);
        }
        return this.storePreferredTrack(channelId, settings, 'audioTrack', {
            language: audioTrack.language,
            layout: audioTrack.layout
        });
    }


    public storePreferredSubtitleTrack(channelId: number, subtitleTrack: SubtitleTrack, settings: SettingsParsed): Observable<any> {
        if (!subtitleTrack) {
            return this.deletePreferredSubtitleTrack(channelId, settings);
        }
        return this.storePreferredTrack(channelId, settings, 'subtitleTrack', {
            language: subtitleTrack.language
        });
    }

    public getPreferredAudioTrack(channelId, settings: SettingsParsed) {
        return this.getPreferredTrack(channelId, settings, 'audioTrack');
    }

    public getPreferredSubtitleTrack(channelId, settings: SettingsParsed) {
        return this.getPreferredTrack(channelId, settings, 'subtitleTrack');
    }

    public deletePreferredAudioTrack(channelId, settings: SettingsParsed): Observable<any> {
        return this.deletePreferredTrack(channelId, settings, 'audioTrack');
    }

    public deletePreferredSubtitleTrack(channelId, settings: SettingsParsed): Observable<any> {
        return this.deletePreferredTrack(channelId, settings, 'subtitleTrack');
    }

    public getPreferred(settings: SettingsParsed) {
        return this.parsePreferred(settings[ this.settingsKey ]);
    }

    private storePreferredObject(preferred: any, settings: SettingsParsed): Observable<any> {
        const preferredString = JSON.stringify(preferred);
        settings[ this.settingsKey ] = preferredString;
        return this.profileService.storeSettingsKey(this.settingsKey, preferredString);
    }

    private getPreferredTrack(channelId: number, settings: SettingsParsed, type: string) {
        const preferred = this.parsePreferred(settings[ this.settingsKey ]);
        return preferred[ channelId ] ? preferred[ channelId ][ type ] : undefined;
    }

    private storePreferredTrack(channelId: number, settings: SettingsParsed, type: string, track: any): Observable<any> {
        const preferred = this.parsePreferred(settings[ this.settingsKey ]);
        preferred[ channelId ] = preferred[ channelId ] || {};
        preferred[ channelId ][ type ] = track;
        return this.storePreferredObject(preferred, settings);
    }

    private deletePreferredTrack(channelId: number, settings: SettingsParsed, type: string) {
        const preferred = this.parsePreferred(settings[ this.settingsKey ]);
        if (!preferred[ channelId ]) {
            return of(undefined);
        }
        preferred[ channelId ][ type ] = undefined;
        return this.storePreferredObject(preferred, settings).pipe(
            tap(() => {
                delete (preferred[ channelId ][ type ]);
                if (!Object.keys(preferred[ channelId ]).length) {
                    delete (preferred[ channelId ]);
                }
            })
        );
    }

    private parsePreferred(preferredString: string) {
        try {
            return JSON.parse(preferredString);
        } catch {
            return {};
        }
    }
}
