import { Inject, Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { EpgEntityService } from '@kuki/global/shared/modules/media/epg-entity.service';
import { SerialService } from '@kuki/global/shared/modules/media/serial.service';
import { EpisodeService } from '@kuki/global/shared/modules/media/episode.service';
import { VodService } from '@kuki/global/shared/modules/media/vod.service';
import { Media } from '@kuki/global/shared/types/media';
import { TileRow } from '@kuki/global/shared/types/general';
import { MediaTile } from '@kuki/global/shared/types/tile';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@kuki/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { TagService } from '@kuki/global/shared/services/tag.service';
import { NpvrService } from '@kuki/global/shared/modules/media/npvr.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaTypes, Tags } from '@kuki/global/shared/types/enum';

@Injectable()
export class MediaService {

    public onRecordChanged$: Observable<number> = this.npvrService.onRecordChanged$;
    private mediaStorage: { [ key: string ]: Media } = {};
    private mediaStorageKeys: Array<string> = [];
    private readonly MEDIA_STORAGE_LIMIT = 100;

    constructor(
        private epgEntityService: EpgEntityService,
        private serialService: SerialService,
        private episodeService: EpisodeService,
        private npvrService: NpvrService,
        private vodService: VodService,
        private notificationService: NotificationService,
        @Inject('ModalsService') private modalsService: ModalsInterface,
        private tagService: TagService,
        private httpClient: HttpClient,
        private translateService: TranslateService) {
    }

    public setMediaStorage(key: string, media: Media) {
        if (this.mediaStorage[ key ]) {
            return;
        }
        this.mediaStorage[ key ] = media;
        this.mediaStorageKeys.push(key);
        if (this.mediaStorageKeys.length > this.MEDIA_STORAGE_LIMIT) {
            const removed = this.mediaStorageKeys.splice(0, this.mediaStorageKeys.length - this.MEDIA_STORAGE_LIMIT);
            removed.forEach((removedKey) => {
                delete (this.mediaStorage[ removedKey ]);
            });
        }
    }

    public getMediaStorage(key: string) {
        return this.mediaStorage[ key ];
    }

    public getMediaBoardGeneral(guid: string): Observable<TileRow<MediaTile>> {
        return this.httpClient.get<TileRow<MediaTile>>(environment.apiUrl + 'board/' + guid);
    }

    public getMedia(id: number, mediaType: MediaTypes): Observable<Media> {
        switch (mediaType) {
            case MediaTypes.EPG_ENTITY:
                return this.epgEntityService.getEpgEntity(id);
            case MediaTypes.SERIAL:
                return this.serialService.getSerial(id);
            case MediaTypes.VOD:
                return this.vodService.getVod(id);
            case MediaTypes.EPISODE:
                return this.episodeService.getEpisode(id);
            case MediaTypes.NPVR:
                return this.npvrService.getNpvr(id);
        }
        return of(null);
    }

    public record(tile: MediaTile, id?: number): Observable<any> {
        if ([ MediaTypes.EPG_ENTITY, MediaTypes.NPVR ].indexOf(tile.mediaType) === -1) {
            return of(null);
        }
        if (!this.tagService.tagExists(id || tile.id, tile.mediaType, Tags.REC)) {
            return this.npvrService.createRecord(id || tile.id).pipe(
                tap(() => this.tagService.addTag(id || tile.id, tile.mediaType, Tags.REC)),
                tap(() => this.notificationService.show(
                    this.translateService.instant('NOTIFICATIONS.GENERAL.RECORD_CREATED'), {
                        guid: 'npvr'
                    }),
                    map(() => 1)), // 1 - record created
                catchError(() => of(null)));
        } else {
            return from(this.modalsService.openConfirmModal({
                message: this.translateService.instant('GENERAL.DELETE_RECORD_CONFIRM_MESSAGE')
            })).pipe(switchMap(() => {
                    return this.npvrService.deleteRecord(id || tile.id);
                }),
                tap(() => this.tagService.removeTag(id || tile.id, tile.mediaType, Tags.REC)),
                tap(() => {
                    this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.RECORD_DELETED'), {
                        guid: 'npvr'
                    });
                }),
                map(() => -1),  // -1 - record deleted
                catchError(() => of(null)));
        }
    }

    public notify(tile: MediaTile, id?: number): Observable<any> {
        if ([ MediaTypes.EPG_ENTITY, MediaTypes.NPVR ].indexOf(tile.mediaType) === -1) {
            return of(null);
        }
        if (!this.tagService.tagExists(id || tile.id, tile.mediaType, Tags.NOTIFY)) {
            return this.createNotification(id || tile.id).pipe(
                tap(() => this.tagService.addTag(id || tile.id, tile.mediaType, Tags.NOTIFY)),
                tap(() => this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.NOTIFY_CREATED'), {
                    guid: 'notify'
                })),
                map(() => 1), // 1 - notify created
                catchError(() => of(null)));
        } else {
            return this.deleteNotification(id || tile.id).pipe(
                tap(() => this.tagService.removeTag(id || tile.id, tile.mediaType, Tags.NOTIFY)),
                tap(() => this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.NOTIFY_DELETED'), {
                    guid: 'notify'
                })),
                map(() => -1),  // -1 - notify deleted
                catchError(() => of(null)));
        }
    }

    public favourite(tile: MediaTile) {
        switch (tile.mediaType) {
            case MediaTypes.SERIAL:
                if (!this.tagService.tagExists(tile.id, tile.mediaType, Tags.FAVOURITE)) {
                    return this.serialService.createFavourite(tile).pipe(
                        tap(() => this.tagService.addTag(tile.id, tile.mediaType, Tags.FAVOURITE)),
                        tap(() => this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.FAVOURITE_CREATED'), {
                            guid: 'favourite'
                        })),
                        map(() => 1), // 1 - favourite created
                        catchError(() => of(null)));
                } else {
                    return this.serialService.deleteFavourite(tile).pipe(
                        tap(() => this.tagService.removeTag(tile.id, tile.mediaType, Tags.FAVOURITE)),
                        tap(() => this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.FAVOURITE_DELETED'), {
                            guid: 'favourite'
                        })),
                        map(() => -1),  // -1 - favourite deleted
                        catchError(() => of(null)));
                }
        }
        return of(null);
    }

    private createNotification(id: number) {
        return this.httpClient.post(`${ environment.apiUrl }epg-entity/${ id }/notify`, undefined).pipe(
            map(() => {
                return 1;
            }));
    }

    private deleteNotification(id: number) {
        return this.httpClient.delete(`${ environment.apiUrl }epg-entity/${ id }/notify`).pipe(
            map(() => {
                return -1;
            }));
    }
}
