import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArrayByParam',
    pure: false
})
export class FilterArrayByParamPipe implements PipeTransform {

    transform(items: any[], attribute, value): any {
        if (!items) {
            return [];
        }
        return items.filter(item => item[ attribute ] === value);
    }

}
