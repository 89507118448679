import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { PlaybackTypes } from '@kuki/global/shared/types/enum';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { AudioTrack, SubtitleTrack } from '@kuki/global/shared/types/general';

@Injectable()
export class TeleportService {

    constructor(
        private httpClient: HttpClient,
        private deviceService: DeviceService) {
    }

    public teleport(targetDeviceId: number, data: {
        type: PlaybackTypes,
        id: number,
        start?: number,
        audioTrack?: Partial<AudioTrack>,
        subtitleTrack?: Partial<SubtitleTrack>
    }) {
        return this.httpClient.post(`${ environment.apiUrl }teleport/send`, {
            deviceId: targetDeviceId,
            ...data
        });
    }

    public switch(sourceDeviceId: number, targetDeviceId: number, continuePlay?: boolean) {
        return this.httpClient.post(`${ environment.apiUrl }teleport/switch`, {
            sourceDeviceId: sourceDeviceId,
            targetDeviceId: targetDeviceId
        });
    }

    public isTeleportRestricted(): boolean {
        const activeDevice = this.deviceService.getActiveDevice();
        return !activeDevice?.canTeleport;
    }
}
