import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { Session } from '@kuki/global/shared/types/general';
import { Observable, Subject, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Injectable()
export class SessionService {

    private readonly sessionRefreshInterval: number = 5 * 60 * 1000; // 5 minutes
    private session: Session;
    private subscription: SubscriptionObject = {};

    private sessionUpdated: Subject<void> = new Subject<void>();
    public sessionUpdated$: Observable<void> = this.sessionUpdated.asObservable();

    constructor(
        private httpClient: HttpClient,
        private ngZone: NgZone) {
    }

    public startCheckingSession() {
        if (!this.subscription.sessionRefreshInterval) {
            this.ngZone.runOutsideAngular(() => {
                this.subscription.sessionRefreshInterval = timer(
                    Math.floor(Math.random() * this.sessionRefreshInterval), this.sessionRefreshInterval
                ).pipe(switchMap(() => this.fetchSession())).subscribe();
            });
        }
    }


    public fetchSession() {
        if (!this.subscription.sessionRefreshInterval) {
            this.startCheckingSession();
        }
        return this.httpClient.get<Session>(environment.apiUrl + 'check-session').pipe(tap(session => {
            this.session = session;
            this.sessionUpdated.next();
        }));
    }

    public getSession() {
        return this.session;
    }

    public clearSession() {
        this.session = null;
    }

    public destroy() {
        SOM.clearSubscriptionsObject(this.subscription);
        this.subscription = {};
    }
}
