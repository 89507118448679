import { Injectable, NgZone } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '@kuki/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SOM } from '@kuki/global/shared/others/subscription/subscription-object';

@Injectable()
export class CacheMapService {

    public cacheMapRefreshInterval: number = 60 * 60 * 1000;
    private cacheMapRefreshTimer: Subscription;

    constructor(
        private httpClient: HttpClient,
        private ngZone: NgZone) {
    }

    public init() {
        this.ngZone.runOutsideAngular(() => {
            this.cacheMapRefreshTimer =
                timer(Math.floor(Math.random() * this.cacheMapRefreshInterval), this.cacheMapRefreshInterval).pipe(
                    switchMap(() => this.updateCacheMap())).subscribe();
        });
    }

    public destroy() {
        SOM.clearSubscriptions(this.cacheMapRefreshInterval);
    }

    public setCacheMap(cacheMapChunk: any, cacheMapPlaylist: any) {
        const encode = (data) => data.map(r => r.map(s => s.join(':')).join(',')).join(';');
        const httpParams = new HttpParams()
            .set('chunk', encode(cacheMapChunk))
            .set('playlist', encode(cacheMapPlaylist));
        return this.httpClient.get(`http://127.0.0.1:7744/update-cache-map?${ httpParams.toString() }`);
    }

    public updateCacheMap() {
        return this.httpClient.get<any>(environment.apiUrl + 'cache-map').pipe(
            switchMap((data) => this.setCacheMap(data.cache_map_chunk, data.cache_map_playlist)));
    }
}
