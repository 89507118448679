import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileIconComponent } from './profile-icon.component';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';


@NgModule({
    declarations: [ ProfileIconComponent ],
    exports: [
        ProfileIconComponent
    ],
    imports: [
        CommonModule,
        ImageLinkFactoryModule
    ]
})
export class ProfileIconModule {
}
