export const UI_VARIABLES_TV = {
    576: {
        maxWidth: 960,
        maxHeight: 540,
        containerWidth: 956,
        gridColumns: 42,
        gridColumn: 13,
        gridGutter: 10,
        baseline: 8,
        flush: 2,
    },
    720: {
        maxWidth: 1280,
        maxHeight: 720,
        containerWidth: 1246,
        gridColumns: 42,
        gridColumn: 16,
        gridGutter: 14,
        baseline: 10,
        flush: 16,
    },
    1080: {
        maxWidth: 1920,
        maxHeight: 1080,
        containerWidth: 1910,
        gridColumns: 42,
        gridColumn: 24,
        gridGutter: 22,
        baseline: 15,
        flush: 5,
    },
    2160: {
        maxWidth: 3840,
        maxHeight: 2160,
        containerWidth: 3822,
        gridColumns: 42,
        gridColumn: 50,
        gridGutter: 42,
        baseline: 30,
        flush: 9
    }
};

