import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { Serial } from '@kuki/global/shared/types/media';
import { TileRow } from '@kuki/global/shared/types/general';
import { Observable } from 'rxjs';
import { MediaTile, PersonTile } from '@kuki/global/shared/types/tile';
import { map } from 'rxjs/operators';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';

@Injectable()
export class SerialService {

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService) {
    }

    getSerials(): Observable<Array<MediaTile>> {
        return this.httpClient.get<Array<MediaTile>>(environment.apiUrl + 'serial-board');
    }

    public getSerial(serialId: number) {
        return this.httpClient
            .get<Serial>(environment.apiUrl + 'serial/' + serialId);
    }

    public getSeasons(serialId: number) {
        return this.httpClient
            .get<Array<TileRow<MediaTile>>>(environment.apiUrl + 'serial/' + serialId + '/seasons');
    }

    public getActors(serialId: number): Observable<TileRow<PersonTile>> {
        return this.httpClient.get<TileRow<PersonTile>>(environment.apiUrl + 'serial/' + serialId + '/actors');
    }

    public getCreators(serialId: number): Observable<TileRow<PersonTile>> {
        return this.httpClient.get<TileRow<PersonTile>>(environment.apiUrl + 'serial/' + serialId + '/creators');
    }

    public createFavourite(tile: MediaTile) {
        return this.httpClient.post(`${ environment.apiUrl }serial/${ tile.id }/favourite`, undefined).pipe(
            map(() => {
                return 1;
            }));
    }

    public deleteFavourite(tile: MediaTile) {
        return this.httpClient.delete(`${ environment.apiUrl }serial/${ tile.id }/favourite`).pipe(
            map(() => {
                return -1;
            }));
    }
}
