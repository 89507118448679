import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { BehaviorSubject, from, fromEvent, merge, Observable, of, Subject, timer } from 'rxjs';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ModalsInterface } from '../../modals/modals.interface';
import { ActionButton } from '../types/button';
import { Errors } from '../types/enum/errors';
import { LocationInfo, News } from '../types/general';
import { SearchCity } from '../types/general/search-city';

@Injectable()
export class ScreenSaverService {

    private readonly SCREEN_SAVER_TIMEOUT = 3 * 60 * 1000; // 3 minutes
    private onRefreshScreenSaverTimeout: Subject<void> = new Subject<void>();

    private onScreenSaverShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onScreenSaverShow$: Observable<boolean> = this.onScreenSaverShow.asObservable();

    private onLocationUpdated: Subject<void> = new Subject();
    public onLocationUpdated$: Observable<void> = this.onLocationUpdated.asObservable();

    private subscription: SubscriptionObject = {};

    constructor(
        private settingsService: SettingsService,
        private controllerService: ControllerService,
        private httpClient: HttpClient,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    public init() {
        SOM.clearSubscriptionsObject(this.subscription);
        if (!this.settingsService.getParsedSettingsValue<boolean>('screenSaver')) {
            return;
        }
        this.subscription.showScreenSaver = this.onRefreshScreenSaverTimeout
            .pipe(
                startWith(true),
                switchMap(() => timer(this.SCREEN_SAVER_TIMEOUT)),
            )
            .subscribe(() => {
                this.onScreenSaverShow.next(true);
            });

        // detect user actions to refresh timeout
        this.subscription.watchUserActions = merge(
            this.controllerService.keyUpEvent$,
            fromEvent(document, 'mouseup')
        ).subscribe((eventData) => {
            if (this.onScreenSaverShow.getValue()) {
                // if visible, hide
                this.destroy();
            } else {
                // if not visible, refresh timeout
                this.onRefreshScreenSaverTimeout.next();
            }
        });
    }

    public isVisible() {
        return this.onScreenSaverShow.getValue();
    }

    public chooseLocation() {
        return from(this.modalsService.openKeyboardModal()).pipe(
            switchMap((text) => this.searchCity(text)),
            switchMap((results) => {
                const buttons: Array<ActionButton> = results.map((searchCity) => ({
                    guid: searchCity.cityCode + '',
                    label: searchCity.label,
                    data: searchCity
                }));
                return from(this.modalsService.openActionsModalV2({
                    buttons: buttons
                }));
            }),
            switchMap(((actionButton) => this.setLocationCity(actionButton.data.realName))),
            tap(() => this.onLocationUpdated.next()),
            catchError((error) => {
                if (error.message === Errors.MODAL_CLOSE) {
                    return of(undefined);
                }
            })
        );
    }

    public destroy(init: boolean = true) {
        this.onScreenSaverShow.next(false);
        SOM.clearSubscriptionsObject(this.subscription);
        if (init) {
            this.init();
        }
    }

    public getNews() {
        return this.httpClient.get<News[]>(environment.apiUrl + 'screensaver/news');
    }

    public getCurrentDayname() {
        return this.httpClient.get<string>(environment.apiUrl + 'screensaver/current-dayname');
    }

    public getCurrentLocationInfo() {
        return this.httpClient.get<LocationInfo>(environment.apiUrl + 'screensaver/current-location-info');
    }

    public setLocationCity(realName: string) {
        return this.httpClient.post<any>(environment.apiUrl + 'screensaver/location-city-name', {
            locationCityName: realName
        });
    }

    private searchCity(city: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('label', city);
        return this.httpClient.get<SearchCity[]>(environment.apiUrl + 'screensaver/search-city', {
            params: httpParams
        });
    }
}
