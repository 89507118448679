import { Inject, Injectable, NgZone } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { PowerControlService } from '@kuki/global/shared/services/power-control.service';
import { filter } from 'rxjs/operators';

export interface CacheOptions {
    maxAge: number;
}

export interface CacheObject extends CacheOptions {
    createdAt: number;
}

@Injectable()
export class CacheControlService {

    // rework this style everywhere
    private readonly CACHE_CONTROL_INTERVAL = 30 * 60 * 1000; // 30 minutes
    private cacheControlInterval: any;

    private cache: { [ key: string ]: CacheObject } = {};
    private defaultOptions: CacheOptions = {
        maxAge: 2 * 60 * 60 * 1000 // 2 hours
    };

    private cacheExpired: Subject<string> = new Subject<string>();

    constructor(
        private ngZone: NgZone,
        private powerControlService: PowerControlService,
        @Inject('PlatformHalService') private platformHalService: PlatformHal) {
    }

    public init() {
        merge(this.powerControlService.powerOff$, this.powerControlService.powerOn$).subscribe(() => {
            Object.keys(this.cache).forEach((key) => {
                if (this.isCacheExpired(key)) {
                    this.delete(key);
                    this.cacheExpired.next(key);
                }
            });
        });
    }

    public startExpirationCheck(key: string) {
        this.ngZone.runOutsideAngular(() => {
            this.stopExpirationCheck();
            this.cacheControlInterval = setInterval(() => {
                if (this.isCacheExpired(key)) {
                    this.delete(key);
                    this.cacheExpired.next(key);
                }
            }, this.CACHE_CONTROL_INTERVAL);
        });
    }

    public stopExpirationCheck() {
        if (this.cacheControlInterval) {
            clearInterval(this.cacheControlInterval);
            this.cacheControlInterval = null;
        }
    }

    public create(key: string, options: CacheOptions = this.defaultOptions) {
        if (this.cache[ key ]) {
            this.cache[ key ] = {
                ...this.cache[ key ],
                ...options
            };
        } else {
            this.cache[ key ] = {
                ...options,
                createdAt: Date.now()
            };
        }
    }

    public cacheExists(key: string): boolean {
        if (this.isCacheExpired(key)) {
            this.delete(key);
        }
        return !!this.cache[ key ];
    }

    public getCacheExpired$(key: string): Observable<string> {
        return this.cacheExpired.pipe(filter(cacheKey => cacheKey === key));
    }

    public delete(key: string) {
        if (this.cache[ key ]) {
            delete (this.cache[ key ]);
        }
    }

    public clear() {
        this.cache = {};
    }

    public destroy() {
        this.clear();
        this.stopExpirationCheck();
    }

    private isCacheExpired(key: string) {
        if (!this.cache[ key ]) {
            return true;
        }
        const cache = this.cache[ key ];
        return Date.now() - cache.createdAt > cache.maxAge;
    }
}
