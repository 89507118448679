import { HttpClient } from '@angular/common/http';
import { ElementRef, Inject, Injectable, NgZone, Optional, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { environment } from '@kuki/environments/environment';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { MediaPlayerV2Component } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.component';
import { VolumeService } from '@kuki/global/features/volume-v2/volume.service';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { wsLogger } from '@kuki/global/shared/loggers/ws-logger';
import { ImageLinkFactoryService } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.service';
import { NpvrService } from '@kuki/global/shared/modules/media/npvr.service';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { CacheControlService } from '@kuki/global/shared/services/cache-control.service';
import { CacheMapService } from '@kuki/global/shared/services/cache-map.service';
import { ChannelService } from '@kuki/global/shared/services/channel.service';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { HistoryService } from '@kuki/global/shared/services/history.service';
import { LoggingService } from '@kuki/global/shared/services/logging.service';
import { MessagingService } from '@kuki/global/shared/services/messaging.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { ParentalControlService } from '@kuki/global/shared/services/parental-control.service';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { PowerControlService } from '@kuki/global/shared/services/power-control.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { RestrictionService } from '@kuki/global/shared/services/restriction.service';
import { SessionService } from '@kuki/global/shared/services/session.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { TagService } from '@kuki/global/shared/services/tag.service';
import { WatchedService } from '@kuki/global/shared/services/watched.service';
import { WsLogService } from '@kuki/global/shared/services/ws-log.service';
import { WSService } from '@kuki/global/shared/services/ws.service';
import { DeviceTypes } from '@kuki/global/shared/types/device';
import { LogEvents } from '@kuki/global/shared/types/enum';
import { Language } from '@kuki/global/shared/types/general';
import { NotificationTypes } from '@kuki/global/shared/types/notification';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { ArrisPlatformHalService } from '@kuki/platforms/tv/arris/arris-platform-hal.service';
import { EpgService } from '@kuki/tv/features/epg/epg.service';
import { SectionControlService } from '@kuki/tv/shared/modules/section-control/section-control.service';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { forkJoin, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { UiHelper } from '../helpers/ui.helper';
import { JwtOptionsClass } from '../others/jwt-options.class';
import { ScreenSaverService } from './screen-saver.service';

// import { ContextMenuService } from '@kuki/web/features/context-menu/context-menu.service';

declare function ga(a, b, c?);

@Injectable()
export class CoreService {

    private locked: boolean;
    private exitModalOpened: Promise<any>;
    private subscription: SubscriptionObject = {};

    private renderer: Renderer2;
    private mainEl: ElementRef<HTMLElement>;
    private appCssClass: string;
    private activeTheme: string;

    private onMouseControlEnabled: Subject<boolean> = new Subject<boolean>();
    public onMouseControlEnabled$: Observable<boolean> = this.onMouseControlEnabled.asObservable();

    private onMouseControlUpEnabled: Subject<boolean> = new Subject<boolean>();
    public onMouseControlUpEnabled$: Observable<boolean> = this.onMouseControlUpEnabled.asObservable();

    private onMouseControlDownEnabled: Subject<boolean> = new Subject<boolean>();
    public onMouseControlDownEnabled$: Observable<boolean> = this.onMouseControlDownEnabled.asObservable();
    private activeLangTheme: string;

    constructor(
        private deviceService: DeviceService,
        private cacheControlService: CacheControlService,
        private powerControlService: PowerControlService,
        private generalService: GeneralService,
        private authService: AuthService,
        private parentalControlService: ParentalControlService,
        private sessionService: SessionService,
        private settingsService: SettingsService,
        private channelService: ChannelService,
        private npvrService: NpvrService,
        private profileService: ProfileService,
        private watchedService: WatchedService,
        private tagService: TagService,
        private historyService: HistoryService,
        private restrictionService: RestrictionService,
        private notificationService: NotificationService,
        private navigationService: NavigationService,
        private sectionControlService: SectionControlService,
        private controllerService: ControllerService,
        private portalSettingsService: PortalSettingsService,
        private imageLinkFactoryService: ImageLinkFactoryService,
        private cacheMapService: CacheMapService,
        private wsLogService: WsLogService,
        private wsService: WSService,
        private loggingService: LoggingService,
        private componentRegisterService: ComponentRegisterService,
        private router: Router,
        private ngZone: NgZone,
        private title: Title,
        private meta: Meta,
        private httpClient: HttpClient,
        private translateService: TranslateService,
        private messagingService: MessagingService,
        @Optional() private screenSaverService: ScreenSaverService,
        @Optional() private volumeService: VolumeService,
        @Optional() private epgService: EpgService,
        // @Optional() private contextMenuService: ContextMenuService,
        @Inject('ModalsService') private modalsService: ModalsInterface,
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        @Inject('MediaPlayerHalService') private mediaPlayerHalService: MediaPlayerHalInterface) {
    }

    public setRenderer(renderer: Renderer2) {
        this.renderer = renderer;
    }

    public setMainEl(mainEl: ElementRef<HTMLElement>) {
        this.mainEl = mainEl;
    }

    public initTvPlatforms() {
        if (this.generalService.isTizen2015() && this.mediaPlayerHalService.initOnStartup) {
            this.mediaPlayerHalService.initOnStartup();
        }
    }

    public authenticate(initProfile: boolean = true) {
        return this.authService.auth().pipe(switchMap((authResponse) => {
            this.updateSentryMeta();
            this.initWsPubSub();
            this.loggingService.log(LogEvents.RUN);
            if (this.mediaPlayerHalService.setSessionKey) {
                this.mediaPlayerHalService.setSessionKey(this.authService.getAuthData().session_key);
            }
            if (hal.platform === 'TV.ARRIS' && hal.deviceType === DeviceTypes.STB) {
                (this.platformHalService as ArrisPlatformHalService).setIsObject(
                    'nbx.visp-code',
                    this.authService.getAuthData().visp_code || 'undefined');
            }
            if ([ 'OK', 'OK_FREE' ].indexOf(authResponse.state) >= 0 && this.authService.getAuthData().session_key) {
                return this.fetchAfterRegister().pipe(
                    switchMap(() => {
                        this.authService.register();
                        this.loggingService.init();
                        if (this.platformHalService.setStopVideoOnClose) {
                            this.platformHalService.setStopVideoOnClose(
                                this.settingsService.getParsedSettingsValue<boolean>('backgroundPlay') === false);
                        }
                        return initProfile ? this.profileService.initProfile() : of(undefined);
                    }),
                    map(() => true)
                );
            }
            return of(false);
        }));
    }

    public logout(deleteDevice: boolean = true) {
        return this.authService.logout(deleteDevice).pipe(
            tap(() => {
                this.clearAuthData();
                this.loggingService.log(LogEvents.EXIT);
            }),
            switchMap(() => this.authenticate())
        );
    }

    public initWsPubSub() {
        this.wsService.init({
            remoteUrl: this.generalService.getRemoteUrl(),
            pubSubKey: this.authService.getAuthData().pubsub_key,
            serial: this.authService.serial
        });
    }

    public initWsLog() {
        if (hal.wsLogging) {
            const portalSettings = this.portalSettingsService.getPortalSettings();
            this.wsLogService.init(portalSettings[ 'platform.wslogserver' ]);
            wsLogger();
        }
    }

    public initLogging() {
        this.loggingService.init();
    }

    private updateSentryMeta() {
        const deviceId = this.authService.getAuthData()?.device_id;
        if (deviceId) {
            Sentry.setUser({ id: `${ deviceId }` });
        }
    }

    public clearAuthData() {
        this.parentalControlService.lock();
        this.sessionService.clearSession();
        this.settingsService.clearLocalSettings();
        this.channelService.clearChannels();
        this.npvrService.clearNpvrStats();
        this.cacheControlService.clear();
        this.watchedService.clear();
        this.historyService.clear();
        this.tagService.clear();
        this.parentalControlService.destroy();
        this.profileService.destroy();
        this.channelService.destroy();
        this.sessionService.destroy();
        this.loggingService.destroy();
        this.deviceService.destroy();
        this.clearProfileTheme();
    }

    public fetchLanguages(): Observable<Array<Language>> {
        return this.generalService.fetchLanguages();
    }

    private fetchAfterRegister() {
        return forkJoin([
            this.fetchSession(),
            this.fetchSettings().pipe(switchMap(() => this.fetchSettingSchema())),
            this.fetchChannelList().pipe(switchMap(() => this.fetchEpg())),
            this.fetchNpvrStats(),
            this.fetchProfiles(),
            this.fetchProfileTypes(),
            this.fetchPlatformSwitch(),
            this.fetchNewMessages(),
            this.fetchDevices(),
            this.fetchPortalSettings()
        ]);
    }

    public fetchSession() {
        return this.sessionService.fetchSession();
    }

    public fetchSettingSchema() {
        return this.settingsService.fetchSettingsSchema();
    }

    public fetchSettings() {
        return this.settingsService.fetchSettings();
    }

    public fetchPortalSettings() {
        return this.portalSettingsService.fetchPortalSettings();
    }

    public fetchChannelList() {
        return this.channelService.fetchChannelList();
    }

    public fetchNpvrStats() {
        return this.npvrService.fetchNpvrStats();
    }

    public fetchProfiles() {
        return this.profileService.fetchProfiles();
    }

    public fetchProfileTypes() {
        return this.profileService.fetchProfileTypes();
    }

    public fetchPlatformSwitch() {
        if (hal.platform === 'TV.ARRIS') {
            return this.settingsService.fetchPlatformSwitch();
        }
        return of(null);
    }

    public fetchEpg() {
        if (this.epgService && this.settingsService.getSettingsValue('dashboardMode') === 'classic') {
            return this.epgService.init();
        }
        return of(null);
    }

    private fetchNewMessages() {
        return this.messagingService.fetchNewMessages();
    }

    private fetchDevices() {
        return this.deviceService.fetchDevices(this.authService.serial);
    }

    public initMeta() {
        if ([ 'TV.TIZEN', 'TV.WEBOS', 'TV.ARRIS', 'TV.ANDROID' ].indexOf(hal.platform) >= 0) {
            this.title.setTitle(this.translateService.instant('WEB.META.TV_TITLE'));
        } else {
            this.title.setTitle(this.translateService.instant('WEB.META.WEB_TITLE'));
        }
        this.meta.addTag({ name: 'description', content: this.translateService.instant('WEB.META.DESCRIPTION') });
        this.meta.addTag({ name: 'keywords', content: this.translateService.instant('WEB.META.KEYWORDS') });
        this.meta.addTag({ name: 'og:title', content: this.translateService.instant('WEB.META.OG_TITLE') });
    }

    public initGa() {
        if (environment.gaId && hal.cap.ga) {
            // init ga
            ga('create', environment.gaId, 'auto');
            // THIS IS FOR FILE URL SUPPORT
            ga('set', 'checkProtocolTask', function () { /* noop */
            });
            ga('set', 'dimension1', hal.platform);
        }
    }

    public initLangTheme() {
        this.clearLangTheme();
        const lang = this.translateService.currentLang;
        this.activeLangTheme = 'lang-' + lang;
        this.renderer.addClass(document.body, this.activeLangTheme);
    }

    public clearLangTheme() {
        this.renderer.removeClass(document.body, this.activeLangTheme);
    }

    public initProfileTypeTheme() {
        if (this.isTvPlatform()) {
            this.clearProfileTheme();
            const profile = this.profileService.getActiveProfile();
            if (profile) {
                this.activeTheme = 'theme-' + profile.profileType;
                this.renderer.addClass(document.body, this.activeTheme);
            } else {
                this.activeTheme = null;
            }
        }
    }

    public initFwClass() {
        if (hal.platform === 'TV.ARRIS') {
            if ((this.platformHalService as ArrisPlatformHalService).getFwVersionBase) {
                const fwVersion = (this.platformHalService as ArrisPlatformHalService).getFwVersionBase();
                this.renderer.addClass(document.body, 'arris-fw-ver-' + fwVersion.replace('.', '-'));
            }
        }
    }

    public clearProfileTheme() {
        if (this.activeTheme) {
            this.renderer.removeClass(document.body, this.activeTheme);
        }
    }

    public initAnimations() {
        const animations = this.settingsService.getParsedSettingsValue<boolean>('animations');
        if (this.renderer) {
            if (!animations) {
                this.renderer.addClass(document.body, 'disable-animations');
            } else {
                this.renderer.removeClass(document.body, 'disable-animations');
            }
        }
        return animations;
    }

    public initUniversalLinks() {
        if (hal.cap.universalLinks && (<any>window).universalLinks) {
            this.subscription.universalLinks = (<any>window).universalLinks.subscribe(null, (eventData) => {
                this.ngZone.run(() => {
                    this.router.navigateByUrl(eventData.path);
                });
            });
        }
    }

    public initImageLinkFactory() {
        const portalSettings = this.portalSettingsService.getPortalSettings();
        if ([ 'TV.ARRIS' ].indexOf(hal.platform) >= 0 || this.generalService.isTizen2015()) {
            this.imageLinkFactoryService.mediaBase = portalSettings.mediaBaseStb;
            this.imageLinkFactoryService.thumbnailBase = portalSettings.thumbnailBaseStb;
        } else {
            this.imageLinkFactoryService.mediaBase = portalSettings.mediaBase;
            this.imageLinkFactoryService.thumbnailBase = portalSettings.thumbnailBase;
        }
    }

    public initCacheMap() {
        if (hal.platform === 'TV.ARRIS' && hal.deviceType === DeviceTypes.STB) {
            this.cacheMapService.init();
        }
    }

    public initSectionControl() {
        this.sectionControlService.setRenderer(this.renderer);
        this.sectionControlService.setScrollWrapper(this.mainEl);
    }

    public initTranslations() {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang('cs_CZ');
    }

    public initScreenSaver() {
        if (this.screenSaverService) {
            this.screenSaverService.init();
        }
    }

    public initAds() {
        const url = new URL(this.generalService.getAdsUrl());
        if (url?.hostname) {
            JwtOptionsClass.addToDomainWhitelist(url.hostname);
        }
    }

    public watchRegister() {
        this.subscription.onRegister = this.authService.onRegister$.subscribe((params) => {
            if (this.platformHalService.onDeviceRegistered) {
                this.platformHalService.onDeviceRegistered(params.token, this.authService.serial);
            }
            this.parentalControlService.init();
            if (this.restrictionService.isSessionCanPlayRestricted()) {
                this.notificationService.show(
                    this.translateService.instant('NOTIFICATIONS.GENERAL.CAN_PLAY_RESTRICTED'), { type: NotificationTypes.ALERT });
            }
        });
        this.subscription.sessionUpdated = this.sessionService.sessionUpdated$.subscribe(() => {
            // init storing
            if (this.restrictionService.isSessionRestricted()) {
                this.notificationService.show(
                    this.translateService.instant('NOTIFICATIONS.GENERAL.SESSION_RESTRICTED'), {
                        guid: 'sessionRestricted',
                        type: NotificationTypes.ALERT,
                        duration: 0,
                        persistent: true
                    });
            } else {
                this.notificationService.closeByGuid('sessionRestricted');
            }
        });
    }


    public watchRouter() {
        let isBackUrl = false;
        this.subscription.routerEvents = this.router.events.pipe().subscribe((e: RouterEvent) => {
            if (e instanceof NavigationStart) {
                // if (this.contextMenuService) {
                //     this.contextMenuService.close();
                // }
                if (this.navigationService.navigationOnPop) {
                    isBackUrl = this.isBackUrl(e.url);
                    if (isBackUrl) {
                        this.navigationService.onHistoryUndo.next();
                    } else {
                        this.navigationService.onHistoryRedo.next();
                    }
                }
                this.navigationService.storePageState(this.router.url);
            }
            if (e instanceof NavigationCancel) {
                if (this.navigationService.navigationOnPop) {
                    if (isBackUrl) {
                        this.navigationService.onHistoryRedo.next();
                    } else {
                        this.navigationService.onHistoryUndo.next();
                    }
                } else {
                    this.navigationService.onHistoryUndo.next();
                }
                this.navigationService.preventStoreNavState = false;
                this.navigationService.navigationOnPop = false;
            }
            if (e instanceof NavigationEnd) {
                if (this.isMobilePlatform()) {
                    if (!this.navigationService.navigationOnPop || !isBackUrl) {
                        this.navigationService.scrollTop();
                    }
                }
                this.navigationService.preventStoreNavState = false;
                this.navigationService.navigationOnPop = false;
            }
        });
    }

    public watchHistory() {
        this.subscription.onHistoryUndo = this.navigationService.onHistoryUndo$.subscribe(() => {
            this.historyService.undo();
        });
        this.subscription.onHistoryRedo = this.navigationService.onHistoryRedo$.subscribe(() => {
            this.historyService.redo();
        });
    }

    public watchRecord() {
        this.subscription.onRecordChanged = this.npvrService.onRecordChanged$.pipe(switchMap(() => {
            return this.npvrService.fetchNpvrStats();
        })).subscribe();
    }

    public watchExit() {
        this.subscription.onExitRequest = this.navigationService.onExitRequest$.subscribe(() => {
            if (hal.platform === 'TV.ARRIS') {
                return;
            }
            if (!navigator) {
                return;
            }
            if (this.exitModalOpened) {
                return;
            }
            this.ngZone.run(() => {
                this.exitModalOpened = this.modalsService.openConfirmModal(
                    { message: this.translateService.instant('GENERAL.EXIT_APP_CONFIRM_MESSAGE') }
                ).then(() => {
                    this.exitModalOpened = null;
                    this.platformHalService.exitApp();
                }).catch(() => {
                    this.exitModalOpened = null;
                    if (this.locked) {
                        this.controllerService.lockStackControl();
                    }
                });
            });
        });
    }

    public watchPortalSettingsUpdates() {
        this.subscription.portalSettingsUpdated = this.portalSettingsService.portalSettingsUpdated$.subscribe(() => {
            const portalSettings = this.portalSettingsService.getPortalSettings();
            if (hal.platform === 'TV.ARRIS' && hal.deviceType === DeviceTypes.STB) {
                (this.platformHalService as ArrisPlatformHalService).setIsObject('nbx.logserver', portalSettings[ 'platform.logserver' ]);
            }
        });
    }

    public watchLangChanges() {
        this.subscription.onLangChange = this.translateService.onLangChange.subscribe(() => {
            this.initLangTheme();
        });
    }

    public watchProfileTypeChanges() {
        this.subscription.profileTypeChange = this.profileService.onProfileTypeChanged$.subscribe(() => {
            this.initProfileTypeTheme();
            this.cacheControlService.clear();
        });
    }

    public watchPowerControl() {
        this.subscription.powerOff = this.powerControlService.powerOff$.subscribe(() => {
            const mediaPlayer = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
            if (!mediaPlayer) {
                this.loggingService.log(LogEvents.EXIT);
            }
            this.screenSaverService.destroy(false);
            this.wsService.sendPowerChange(this.authService.serial, false);
            this.loggingService.destroy();
        });
        this.subscription.powerOn = this.powerControlService.powerOn$.subscribe(() => {
            const mediaPlayer = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
            if (!mediaPlayer) {
                this.loggingService.log(LogEvents.RUN);
            }
            this.screenSaverService.init();
            this.wsService.sendPowerChange(this.authService.serial, true);
            this.loggingService.init();
        });
    }

    public watchVolume() {
        this.subscription.watchVolume = this.volumeService.volumeChanged$.subscribe(() => {
            this.loggingService.repeat();
        });
    }

    public watchResize() {
        this.subscription.onResize = fromEvent(window, 'resize').pipe(
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe(() => {
            UiHelper.resize();
        });
    }

    public isExitModalOpened() {
        return this.exitModalOpened;
    }

    public getMainElement() {
        return this.mainEl;
    }

    public getActiveProfileType() {
        const activeProfile = this.profileService.getActiveProfile();
        return activeProfile && activeProfile.profileType;
    }

    public getLanguages() {
        return this.generalService.getLanguages();
    }

    public isTvPlatform() {
        return [ 'TV.ARRIS', 'TV.TIZEN', 'TV.WEBOS', 'TV.ANDROID' ].indexOf(hal.platform) >= 0;
    }

    public isWebPlatform() {
        return hal.platform === 'WEB';
    }

    public isMobilePlatform() {
        return [ 'MOBILE.ANDROID', 'MOBILE.IOS' ].indexOf(hal.platform) >= 0;
    }

    public isProfileActive() {
        // return true;
        return !!this.profileService.getActiveProfile();
    }

    public isProfileTypeActive(...profileTypeList: Array<string>) {
        return profileTypeList.some(profileType => profileType === this.getActiveProfileType());
    }

    public setAppCssClass(cssClass: string) {
        if (this.appCssClass) {
            this.clearAppCssClass();
        }
        this.appCssClass = cssClass;
        document.body.classList.add(this.appCssClass);
    }

    public clearAppCssClass() {
        if (this.appCssClass) {
            document.body.classList.remove(this.appCssClass);
        }
    }


    public enableMouseControl() {
        this.onMouseControlEnabled.next(true);
    }

    public disableMouseControl() {
        this.onMouseControlEnabled.next(false);
    }

    public enableMouseControlUp() {
        this.onMouseControlUpEnabled.next(true);
    }

    public disableMouseControlUp() {
        this.onMouseControlUpEnabled.next(false);
    }

    public enableMouseControlDown() {
        this.onMouseControlDownEnabled.next(true);
    }

    public disableMouseControlDown() {
        this.onMouseControlDownEnabled.next(false);
    }

    public canShare() {
        return !!this.platformHalService.share;
    }

    public share() {
        if (this.canShare()) {
            const portalSettings = this.portalSettingsService.getPortalSettings();
            const shareLink = portalSettings.portalUrl + this.router.url;
            this.platformHalService.share(shareLink);
        }
    }

    public destroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }

    public isBackUrl(url: string) {
        const history = this.historyService.getHistory();
        return history?.past[ history.past.length - 1 ]?.nav?.url === url;
    }

    public isForwardUrl(url: string) {
        const history = this.historyService.getHistory();
        return history?.future[ 0 ]?.nav?.url === url;
    }
}
