import { Injectable, NgZone } from '@angular/core';
import { Channel } from '@kuki/global/shared/types/general';
import { MediaTile } from '@kuki/global/shared/types/tile';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { Observable, Subject } from 'rxjs';

export interface ParentalState {
    locked: boolean;
    unlockedAt: number;
}

@Injectable()
export class ParentalControlService {

    private checkLockIntervalValue = 60 * 1000; // minute
    private checkLockInterval: any;

    private onLockChanged: Subject<ParentalState> = new Subject<ParentalState>();
    public onLockChanged$: Observable<ParentalState> = this.onLockChanged.asObservable();

    private state: ParentalState = {
        locked: true,
        unlockedAt: null
    };

    constructor(
        private settingsService: SettingsService,
        private ngZone: NgZone) {
    }

    public init() {
        this.ngZone.runOutsideAngular(() => {
            this.checkLockInterval = setInterval(() => {
                this.checkLock();
            }, this.checkLockIntervalValue);
        });
    }

    public destroy() {
        if (this.checkLockInterval) {
            clearInterval(this.checkLockInterval);
        }
    }

    public isChannelPornRestricted(channel: Channel): boolean {
        const parsedSettings = this.settingsService.getParsedSettings();
        return parsedSettings.parentalLock && channel && channel.porn && this.isLocked();
    }

    public isChannelAgeRestricted(channel: Channel): boolean {
        const parsedSettings = this.settingsService.getParsedSettings();
        return parsedSettings.parentalLock && parsedSettings.ageLimit > 0 && channel &&
            channel.ageRestriction >= parsedSettings.ageLimit && this.isLocked();
    }

    public isTilePornRestricted(tile: MediaTile): boolean {
        const parsedSettings = this.settingsService.getParsedSettings();
        return parsedSettings.parentalLock && tile.porn && this.isLocked();
    }

    public isTileAgeRestricted(tile: MediaTile): boolean {
        const parsedSettings = this.settingsService.getParsedSettings();
        return parsedSettings.parentalLock && parsedSettings.ageLimit > 0 &&
            tile.ageRestriction >= parsedSettings.ageLimit && this.isLocked();
    }

    public lock() {
        this.state = {
            locked: true,
            unlockedAt: null
        };
        this.onLockChanged.next(this.state);
    }

    public unlock() {
        this.state = {
            locked: false,
            unlockedAt: Date.now()
        };
        this.onLockChanged.next(this.state);
    }

    public isLocked() {
        return this.state.locked;
    }

    private checkLock() {
        // is unlocked
        if (!this.state.locked) {
            const unlockTimeout = this.settingsService.getParsedSettingsValue<number>('unlockTimeout');
            if (this.state.unlockedAt < (Date.now() - unlockTimeout)) {
                this.lock();
            }
        }
    }
}
