import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate.pipe';
import { TimePipe } from './time.pipe';
import { FilterArrayByIndexPipe } from './filter-array-by-index.pipe';
import { RemoveFromArray } from '@kuki/global/shared/pipes/remove-from-array';
import { FilterArrayByParamPipe } from '@kuki/global/shared/pipes/filter-array-by-param';
import { DayPipe } from '@kuki/global/shared/pipes/day.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TruncatePipe,
        TimePipe,
        FilterArrayByIndexPipe,
        RemoveFromArray,
        FilterArrayByParamPipe,
        DayPipe
    ],
    exports: [
        TruncatePipe,
        TimePipe,
        FilterArrayByIndexPipe,
        RemoveFromArray,
        FilterArrayByParamPipe,
        DayPipe
    ],
    providers: [
        TruncatePipe
    ]

})
export class PipesModule {
}
