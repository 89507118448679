import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { RegisterGuardService } from '@kuki/global/shared/services/register-guard.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        AuthService,
        RegisterGuardService
    ]
})
export class AuthModule {
}
