import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { Vod } from '@kuki/global/shared/types/media';
import { Observable } from 'rxjs';
import { TileRow } from '@kuki/global/shared/types/general';
import { MediaTile, PersonTile } from '@kuki/global/shared/types/tile';

@Injectable()
export class VodService {

    constructor(private httpClient: HttpClient) {
    }

    getVods(): Observable<Array<MediaTile>> {
        return this.httpClient.get<Array<MediaTile>>(environment.apiUrl + 'vod-board');
    }

    getVod(vodId: number) {
        return this.httpClient
            .get<Vod>(environment.apiUrl + 'vod/' + vodId);
    }

    getActors(vodId: number): Observable<TileRow<PersonTile>> {
        return this.httpClient.get<TileRow<PersonTile>>(environment.apiUrl + 'vod/' + vodId + '/actors');
    }

    getCreators(vodId: number): Observable<TileRow<PersonTile>> {
        return this.httpClient.get<TileRow<PersonTile>>(environment.apiUrl + 'vod/' + vodId + '/creators');
    }
}
