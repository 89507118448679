import { Injectable } from '@angular/core';
import { ActionKey, GroupKeys } from '@kuki/global/shared/types/controller/keymap';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { merge, Observable, Subject } from 'rxjs';
import { Channel } from '@kuki/global/shared/types/general';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { debounceTime } from 'rxjs/operators';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { ChannelService } from '@kuki/global/shared/services/channel.service';
import { numKeyToNumberMap } from '@kuki/global/shared/types/controller/controller';

@Injectable({
    providedIn: 'root'
})
export class ChannelSwitcherService {

    private activeChannelNumber: string = '';

    private channelSwitched: Subject<Channel> = new Subject<Channel>();
    public channelSwitched$: Observable<Channel> = this.channelSwitched.asObservable();

    private channelSwitching: Subject<string> = new Subject<string>();
    private channelSwitchingFast: Subject<string> = new Subject<string>();

    public channelSwitching$: Observable<string> = merge(
        this.channelSwitching.asObservable(), this.channelSwitchingFast.asObservable()
    );

    private subscription: SubscriptionObject = {};

    constructor(
        private controllerService: ControllerService,
        private channelService: ChannelService,
        private notificationService: NotificationService) {
    }

    public init() {
        const maxChannelNumberLength = this.channelService.getMaxChannelNumberLength();
        this.controllerService.registerGlobalKeys(GroupKeys.GRP_NUMBER, (action) => {
            this.notificationService.clear();
            const channelNumber = this.getChannelNumberFromActionKey(action.actionKey);
            if (this.activeChannelNumber.length >= maxChannelNumberLength) {
                return;
            }
            this.activeChannelNumber += channelNumber;
            if (this.activeChannelNumber.length === maxChannelNumberLength) {
                this.channelSwitchingFast.next(this.activeChannelNumber);
            } else {
                this.channelSwitching.next(this.activeChannelNumber);
            }
        });
        this.watchChannelSwitching();
    }

    private watchChannelSwitching() {
        this.subscription.channelSwitching = merge(
            this.channelSwitching.pipe(debounceTime(2000)),
            this.channelSwitchingFast.pipe(debounceTime(500))
        ).subscribe(() => {
            const channel = this.channelService.getChannelByNumber(this.activeChannelNumber);
            if (channel) {
                this.channelSwitched.next(channel);
            }
            this.activeChannelNumber = '';
        });
    }

    public destroy() {
        this.controllerService.unregisterGlobalActionKeys(GroupKeys.GRP_NUMBER);
        SOM.clearSubscriptions(this.subscription.channelSwitching);
    }

    private getChannelNumberFromActionKey(key: ActionKey) {
        return numKeyToNumberMap[ key ];
    }
}
