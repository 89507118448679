import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';

@Injectable()
export class PasswordResolveService implements Resolve<string> {

    constructor(
        private settingsService: SettingsService,
        private navigationService: NavigationService,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string> {
        if (this.settingsService.passwordAccepted) {
            return Promise.resolve(null);
        }
        return this.modalsService.openPasswordModal().then((password) => {
            return password;
        }).catch(() => {
            this.navigationService.navigateBack(true);
            return null;
        });
    }
}
