import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { Notification, NotificationTypes } from '@kuki/global/shared/types/notification';
import { ActionButton } from '@kuki/global/shared/types/button';
import { ButtonsPanelComponent } from '@kuki/global/features/buttons-panel/buttons-panel.component';
import { MessagingService } from '@kuki/global/shared/services/messaging.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { CoreService } from '@kuki/global/shared/services/core.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.5s ease-out', style({ opacity: '1' })),
            ])
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit, OnDestroy {

    @ViewChildren('buttonsPanel') buttonsPanelComponents: QueryList<ButtonsPanelComponent>;

    private subscription: SubscriptionObject = {};
    public notifications: Array<Notification> = [];

    public activeNotificationWithButton: Notification;

    constructor(
        private coreService: CoreService,
        private messagingService: MessagingService,
        private ngZone: NgZone,
        private notificationService: NotificationService,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscription.notificationsChanged = this.notificationService.notificationsChanged$.subscribe(() => {
            this.notifications = this.notificationService.getNotifications();
            this.changeDetectorRef.detectChanges();
            if (!this.activeNotificationWithButton) {
                const notificationWithButton = this.notificationWithButtonsExists();
                if (notificationWithButton) {
                    this.activateButtonsPanel(notificationWithButton);
                }
            }
        });
        this.subscription.notificationClosed = this.notificationService.notificationClosed$.subscribe((notification) => {
            if (this.activeNotificationWithButton && this.activeNotificationWithButton.id === notification.id) {
                this.activeNotificationWithButton = undefined;
            }
        });
    }

    public getNotificationClass(notification: Notification) {
        if (notification.cssClass) {
            return notification.cssClass;
        }
        switch (notification.type) {
            case NotificationTypes.ALERT:
                return 'notification-alert';
            case NotificationTypes.MESSAGE:
                return 'notification-message';
            case NotificationTypes.NEWS:
                return 'notification-news';
        }
    }

    public getNotificationIcons(notification: Notification) {
        if (notification.iconCssClass) {
            return notification.iconCssClass;
        }
        switch (notification.type) {
            case NotificationTypes.ALERT:
                return 'notification-icon-alert';
            case NotificationTypes.MESSAGE:
                return 'notification-icon-message';
            case NotificationTypes.NEWS:
                return 'notification-icon-news';
        }
    }

    public onNotificationClick(notification: Notification, event: Event) {
        if (notification.messageId) {
            this.messagingService.openMessage(notification.messageId);
        }
        if (notification.persistent) {
            return;
        }
        if (this.coreService.isWebPlatform() || this.coreService.isMobilePlatform()) {
            this.notificationService.close(notification);
        }
        event.stopPropagation();
    }

    private notificationWithButtonsExists() {
        return this.notifications.find(notification => !!notification.buttons && notification.buttons.length > 0);
    }

    private getButtonsPanelComponent(notification: Notification) {
        return this.buttonsPanelComponents.find(item => item.ident === notification.guid);
    }

    public onButtonAction(notification: Notification, actionButton: ActionButton) {
        this.activeNotificationWithButton = undefined;
        // const mapAction = {
        //     'PluginVOD': Actions.SHOW_VOD_BOARD,
        //     'PluginVODBought': Actions.GO_TO_VOD_BOUGHT,
        //     'PluginVODFind': Actions.SEARCH,
        //     'TopMovies': Actions.GO_TO_TODAY_TOP,
        //     'PluginStbSettings': Actions.SHOW_SETTINGS,
        //     'PluginSeries': Actions.SHOW_SERIAL_BOARD,
        //     'PluginRecordings': Actions.SHOW_NPVR_BOARD,
        //     'PluginEPG4': Actions.SHOW_EPG,
        //     'PluginDevices': Actions.SHOW_DEVICES,
        //     'PluginChannelOrder': Actions.SHOW_DASHBOARD_MANAGEMENT
        // };
        this.ngZone.run(() => {
            this.navigationService.navigateOnAction(actionButton.action, actionButton.data);
            this.notificationService.close(notification);
        });
    }

    private activateButtonsPanel(notification: Notification) {
        const buttonsPanelComponent = this.getButtonsPanelComponent(notification);
        if (buttonsPanelComponent) {
            buttonsPanelComponent.activate(true);
            this.activeNotificationWithButton = notification;
        }
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
