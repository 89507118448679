import { ActionKey, CommonKeys } from '@kuki/global/shared/types/controller/keymap';

export type ControllerActionCallback = (action: ControllerAction, stackLevelIndex?: number) => any;

export interface ControllerAction {
    actionKey: ActionKey;
    type: string;
    keyCode?: number;
    event?: Event;
}

export interface ControllerActionItem {
    callback: ControllerActionCallback;
    propagate?: boolean;
}

export interface ControllerStackLevel {
    ident: string;
    actions: { [ type: string ]: { [ key: string ]: ControllerActionItem } };
    priv?: boolean;
}

// TODO: maybe rework to deduction
export const numKeyToNumberMap: { [ key: string ]: string } = {
    [ CommonKeys.N_0 ]: '0',
    [ CommonKeys.N_1 ]: '1',
    [ CommonKeys.N_2 ]: '2',
    [ CommonKeys.N_3 ]: '3',
    [ CommonKeys.N_4 ]: '4',
    [ CommonKeys.N_5 ]: '5',
    [ CommonKeys.N_6 ]: '6',
    [ CommonKeys.N_7 ]: '7',
    [ CommonKeys.N_8 ]: '8',
    [ CommonKeys.N_9 ]: '9',
};
