import { Injectable, NgZone } from '@angular/core';
import { ActionKey, AndroidTVKeyMapModel, KeyMapModel } from '@kuki/global/shared/types/controller/keymap';
import { hal } from '@kuki/platforms/hal';
import { Observable, Subject } from 'rxjs';
import {
    ControllerAction,
    ControllerActionCallback,
    ControllerActionItem,
    ControllerStackLevel
} from '@kuki/global/shared/types/controller/controller';
import { DeviceTypes } from '@kuki/global/shared/types/device';

export interface ControllerServiceConfig {
    logUnknownKey?: boolean;
}

@Injectable()
export class ControllerService {

    private readonly logs = false;

    private readonly holdTime: number = 300;
    private readonly longPressTime: number = 500;
    private readonly longPressTimeNew: number = 100;
    private keyMap: KeyMapModel;
    private keyUpChecker: boolean;

    private reverseKeyMap: { [ keyCode: string ]: Array<ActionKey> } = {};
    private globalLevel: { [ type: string ]: { [ key: string ]: ControllerActionItem } } = {};
    private keysStack: Array<ControllerStackLevel> = [];

    private currentActionKeyDown: ActionKey;
    private currentKeyDownTime: number;
    private lastHoldTime: number = 0;
    private pressLongOnce: boolean = false;
    private shortKeyFired: { [ keyCode: string ]: boolean } = {};
    private lockedControl: boolean = false;

    private keyDownEvent: Subject<Array<ActionKey>> = new Subject<Array<ActionKey>>();
    private keyUpEvent: Subject<Array<ActionKey>> = new Subject<Array<ActionKey>>();

    public keyDownEvent$: Observable<Array<ActionKey>> = this.keyDownEvent.asObservable();
    public keyUpEvent$: Observable<Array<ActionKey>> = this.keyUpEvent.asObservable();

    private config: ControllerServiceConfig = {};

    constructor(private ngZone: NgZone) {
    }

    public init(config: ControllerServiceConfig) {
        if (!hal.keymap) {
            return;
        }
        this.config = config;
        this.keyMap = new hal.keymap();
        this.ngZone.runOutsideAngular(() => {
            document.addEventListener('keydown', (event) => {
                this.keyUpChecker = true;
                if (hal.deviceType === DeviceTypes.SMART_TV || hal.deviceType === DeviceTypes.STB) {
                    // for preventing bad behaviour on atv (invisible cursor scrolling through page)
                    event.preventDefault();
                }
                this.keyDown(event);
            });
            document.addEventListener('keyup', (event) => {
                if (!this.keyUpChecker) {
                    return;
                }
                this.keyUpChecker = false;
                // for preventing bad behaviour on atv (invisible cursor scrolling through page)
                if (hal.deviceType === DeviceTypes.SMART_TV || hal.deviceType === DeviceTypes.STB) {
                    // for preventing bad behaviour on atv (invisible cursor scrolling through page)
                    event.preventDefault();
                }
                this.keyUp(event);
            });
            if (hal.platform === 'TV.ANDROID') {
                document.addEventListener('backbutton',
                    (event) => {
                        event.preventDefault();
                        this.emulateKey((this.keyMap as AndroidTVKeyMapModel).BACK as number);
                    });
            }
        });
    }

    public registerGlobalKey(actionKey: ActionKey, callback: ControllerActionCallback, type: string = 's') {
        this.globalLevel[ type ] = this.globalLevel[ type ] || {};
        if (!this.globalLevel[ type ][ actionKey ]) {
            this.globalLevel[ type ][ actionKey ] = {
                callback: callback
            };
        }
        this.generateReverseKeyMap(actionKey);

        if (this.logs) {
            console.log('registerGlobalKey', actionKey, this.globalLevel);
        }
    }

    public registerGlobalKeys(actionKeys: Array<ActionKey>, callback: ControllerActionCallback, type: string = 's') {
        actionKeys.forEach(actionKey => {
            this.registerGlobalKey(actionKey, callback, type);
        });
    }

    public unregisterGlobalActionKey(actionKey: ActionKey, type: string = 's') {
        if (!this.globalLevel || !this.globalLevel[ type ]) {
            return;
        }
        delete (this.globalLevel[ type ][ actionKey ]);
    }

    public unregisterGlobalActionKeys(actionKeys: Array<ActionKey>, type: string = 's') {
        actionKeys.forEach(actionKey => {
            this.unregisterGlobalActionKey(actionKey, type);
        });
    }

    public unregisterGlobalLevel() {
        this.globalLevel = {};
    }

    public registerKeyStackLevel(ident: string, priv = false) {
        const frontStack = this.keysStack[ 0 ];
        if (frontStack && frontStack.ident === ident) {
            return;
        } else {
            const newStack = {
                ident: ident,
                actions: {},
                priv: priv
            };
            if (frontStack?.priv) {
                const stackExists = this.keysStack.find(stack => stack.ident === ident);
                if (stackExists) {
                    return;
                } else if (!newStack.priv) {
                    const privStacks = this.keysStack.filter(stack => stack.priv);
                    const lastPrivIndex = this.keysStack.findIndex(item => item.ident === privStacks[ privStacks.length - 1 ].ident);
                    this.keysStack.splice(lastPrivIndex + 1, 0, newStack);
                } else {
                    this.keysStack.unshift(newStack);
                }
            } else {
                this.keysStack.unshift(newStack);
            }
        }
    }

    public registerActionKey(actionKey: ActionKey,
                             ident: string,
                             callback: ControllerActionCallback,
                             type: string = 's',
                             propagate: boolean = false): Observable<ControllerAction> {
        if (!this.keyMap || !actionKey || this.keyMap[ actionKey ] === undefined || this.keyMap[ actionKey ] === null) {
            return;
        }

        this.generateKeyStackLevel(actionKey, ident, callback, type, propagate);

        if (this.logs) {
            console.log('registerActionKey', ident, actionKey, this.keysStack);
        }
    }

    public registerActionKeys(actionKeys: Array<ActionKey>, ident: string,
                              callback: ControllerActionCallback,
                              type: string = 's',
                              propagate: boolean = false) {
        actionKeys.forEach(actionKey => {
            this.registerActionKey(actionKey, ident, callback, type, propagate);
        });
    }

    public propagateActionKey(actionKey: ActionKey, ident: string, type: string = 's') {
        if (!this.keyMap || !actionKey || this.keyMap[ actionKey ] === undefined || this.keyMap[ actionKey ] === null) {
            return;
        }
        this.generateKeyStackLevel(actionKey, ident, null, type, true);
        if (this.logs) {
            console.log('propagateActionKey', ident, actionKey, this.keysStack);
        }
    }

    public propagateActionKeys(actionKeys: Array<ActionKey>, ident: string, type: string = 's') {
        actionKeys.forEach(actionKey => {
            this.propagateActionKey(actionKey, ident, type);
        });
    }

    // Method to manually propagate action to deeper levels
    public propagateAction(action: ControllerAction, actualStackLevelIndex = 0) {
        this.processActionKeyEvent(action, actualStackLevelIndex + 1);
    }

    public unregisterActionKey(actionKey: ActionKey, ident: string, type: string = 's') {
        const frontStack = this.keysStack[ 0 ];
        if (frontStack && frontStack.ident === ident) {
            delete (frontStack.actions[ type ][ actionKey ]);
        }
        if (this.logs) {
            console.log('unregisterActionKey', ident, actionKey, this.keysStack);
        }
    }

    public unregisterStackLevel(ident: string) {
        this.keysStack = this.keysStack.filter(keyStack => keyStack.ident !== ident);
        if (this.logs) {
            console.log('unregisterStackLevel', ident, this.keysStack);
        }
    }

    public lockStackControl() {
        this.registerKeyStackLevel('controllerBlocked', true);
    }

    public unlockStackControl() {
        this.unregisterStackLevel('controllerBlocked');
    }

    public lockControl() {
        this.lockedControl = true;
    }

    public unlockControl() {
        this.lockedControl = false;
    }

    public getTopIdent() {
        return this.keysStack[ 0 ] ? this.keysStack[ 0 ].ident : null;
    }

    public emulatePress(keyCode: number, type: string = 's') {
        const actionKeys = this.reverseKeyMap[ keyCode ];
        if (!actionKeys) {
            return;
        }
        actionKeys.forEach((actionKey) => {
            this.processActionKeyEvent({ actionKey: actionKey, type: type, keyCode: keyCode });
        });
        this.triggerKeyUpEvent(actionKeys);
    }

    public emulatePressByActionKey(actionKey: ActionKey, type: string = 's') {
        this.processActionKeyEvent({ actionKey: actionKey, type: type });
        this.triggerKeyUpEvent([ actionKey ]);
    }

    public triggerKeyUpEvent(actionKeys?: Array<ActionKey>) {
        this.keyUpEvent.next(actionKeys);
    }

    private generateKeyStackLevel(actionKey: ActionKey,
                                  ident: string,
                                  callback: ControllerActionCallback,
                                  type: string,
                                  propagate: boolean) {
        const frontStack = this.keysStack[ 0 ];
        if (frontStack && frontStack.ident === ident) {
            frontStack.actions[ type ] = frontStack.actions[ type ] || {};
            frontStack.actions[ type ][ actionKey ] = {
                callback: callback,
                propagate: propagate
            };
        } else {
            const newStack = {
                ident: ident,
                actions: {
                    [ type ]: {
                        [ actionKey ]: { callback: callback, propagate: propagate }
                    }
                }
            };
            if (frontStack && frontStack.priv) {
                const stackExists = this.keysStack.find(stack => stack.ident === ident);
                if (stackExists) {
                    stackExists.actions[ type ] = stackExists.actions[ type ] || {};
                    stackExists.actions[ type ][ actionKey ] = {
                        callback: callback,
                        propagate: propagate
                    };
                } else {
                    const privStacks = this.keysStack.filter(stack => stack.priv);
                    const lastPrivIndex = this.keysStack.findIndex(item => item.ident === privStacks[ privStacks.length - 1 ].ident);
                    this.keysStack.splice(lastPrivIndex + 1, 0, newStack);
                }
            } else {
                this.keysStack.unshift(newStack);
            }
        }
        this.generateReverseKeyMap(actionKey);
    }

    private generateReverseKeyMap(actionKey: ActionKey) {
        if (!this.keyMap || this.keyMap[ actionKey ] === undefined || this.keyMap[ actionKey ] === null) {
            return;
        }
        const keyCodes = Array.isArray(this.keyMap[ actionKey ]) ? this.keyMap[ actionKey ] : [ this.keyMap[ actionKey ] ];
        keyCodes.forEach((keyCode) => {
            keyCode = (typeof keyCode === 'number') ? (keyCode as number).toString() : keyCode;
            this.reverseKeyMap[ keyCode ] = this.reverseKeyMap[ keyCode ] || [];
            if (this.reverseKeyMap[ keyCode ].indexOf(actionKey) === -1) {
                this.reverseKeyMap[ keyCode ].push(actionKey);
            }
        });
    }

    private keyDown(event: KeyboardEvent) {
        if (this.lockedControl) {
            return;
        }
        const keyCode = event.which || event.keyCode;
        const key = event.key;
        const now = Date.now();
        const actionKeys = this.reverseKeyMap[ keyCode ] || this.reverseKeyMap[ key ];
        if (!actionKeys) {
            if (this.config.logUnknownKey) {
                console.log('Unknown key ' + keyCode);
            }
            return;
        }
        actionKeys.forEach((actionKey) => {
            if (this.currentActionKeyDown !== actionKey) {
                this.currentActionKeyDown = actionKey;
                this.currentKeyDownTime = now;
                if (!this.actionExists({ actionKey: actionKey, type: 'l' }) && !this.actionExists({ actionKey: actionKey, type: 'plo' })) {
                    if (!this.shortKeyFired[ actionKey ]) {
                        this.shortKeyFired[ actionKey ] = true;
                        this.processActionKeyEvent({ actionKey: actionKey, type: 's', keyCode: keyCode, event: event });
                    }
                }
            } else {
                if (now - this.currentKeyDownTime >= this.holdTime) {
                    if (this.actionExists({ actionKey: actionKey, type: 'h' })) {
                        if (now - this.lastHoldTime >= this.holdTime) {
                            this.lastHoldTime = now;
                            this.processActionKeyEvent({ actionKey: actionKey, type: 'h', keyCode: keyCode, event: event });
                        }
                    }
                }
                if (now - this.currentKeyDownTime >= this.holdTime) {
                    if (this.actionExists({ actionKey: actionKey, type: 'hh' })) {
                        if (now - this.lastHoldTime >= this.holdTime * 3) {
                            this.lastHoldTime = now;
                            this.processActionKeyEvent({ actionKey: actionKey, type: 'hh', keyCode: keyCode, event: event });
                        }
                    }
                }
                if (now - this.currentKeyDownTime >= this.longPressTime) {
                    if (this.actionExists({ actionKey: actionKey, type: 'pl' })) {
                        this.processActionKeyEvent({ actionKey: actionKey, type: 'pl', keyCode: keyCode, event: event });
                    }
                }
                if (now - this.currentKeyDownTime >= this.longPressTimeNew) {
                    if (this.actionExists({ actionKey: actionKey, type: 'pl-new' })) {
                        this.processActionKeyEvent({ actionKey: actionKey, type: 'pl-new', keyCode: keyCode, event: event });
                    }
                }
                if (now - this.currentKeyDownTime >= this.longPressTime && !this.pressLongOnce) {
                    if (this.actionExists({ actionKey: actionKey, type: 'plo' })) {
                        this.pressLongOnce = true;
                        this.shortKeyFired[ actionKey ] = true;
                        this.processActionKeyEvent({ actionKey: actionKey, type: 'plo', keyCode: keyCode, event: event });
                    }
                }
                if (now - this.currentKeyDownTime >= this.longPressTimeNew && !this.pressLongOnce) {
                    if (this.actionExists({ actionKey: actionKey, type: 'plo-new' })) {
                        this.pressLongOnce = true;
                        this.shortKeyFired[ actionKey ] = true;
                        this.processActionKeyEvent({ actionKey: actionKey, type: 'plo-new', keyCode: keyCode, event: event });
                    }
                }
            }
        });
        this.keyDownEvent.next(actionKeys);
    }

    private keyUp(event: KeyboardEvent) {
        if (this.lockedControl) {
            return;
        }
        const keyCode = event.which || event.keyCode;
        const key = event.key;
        const now = Date.now();
        const actionKeys = this.reverseKeyMap[ keyCode ] || this.reverseKeyMap[ key ];
        if (!actionKeys) {
            return;
        }
        actionKeys.forEach((actionKey) => {
            this.lastHoldTime = 0;
            this.currentActionKeyDown = null;
            this.currentKeyDownTime = null;
            this.pressLongOnce = false;
            if ((now - this.currentKeyDownTime >= this.longPressTime) && this.actionExists({ actionKey: actionKey, type: 'l' })) {
                this.processActionKeyEvent({ actionKey: actionKey, type: 'l', keyCode: keyCode, event: event });
            } else {
                if (!this.shortKeyFired[ actionKey ]) {
                    this.processActionKeyEvent({ actionKey: actionKey, type: 's', keyCode: keyCode, event: event });
                }
                this.shortKeyFired[ actionKey ] = false;
            }
        });
        this.keyUpEvent.next(actionKeys);
    }

    private processActionKeyEvent(action: ControllerAction, stackLevelIndex = 0) {
        const stackLevel = this.keysStack[ stackLevelIndex ];
        const actionEvent = stackLevel && stackLevel.actions[ action.type ] ? stackLevel.actions[ action.type ][ action.actionKey ] : null;
        if (actionEvent) {
            if (actionEvent.callback) {
                actionEvent.callback(action, stackLevelIndex);
            }
            if (actionEvent.propagate) {
                if (this.keysStack[ stackLevelIndex + 1 ]) {
                    this.processActionKeyEvent(action, stackLevelIndex + 1);
                } else {
                    this.checkGlobalLevel(action);
                }
            }
        } else {
            this.checkGlobalLevel(action);
        }
    }

    private checkGlobalLevel(action: ControllerAction) {
        if (this.globalLevel[ action.type ] && this.globalLevel[ action.type ][ action.actionKey ]) {
            if (this.globalLevel[ action.type ][ action.actionKey ].callback) {
                this.globalLevel[ action.type ][ action.actionKey ].callback(action);
            }
        }
    }

    private emulateKey(kc: number) {
        this.keyDown({ keyCode: kc } as KeyboardEvent);
        this.keyUp({ keyCode: kc } as KeyboardEvent);
    }

    private actionExists(action: ControllerAction, stackLevel?: ControllerStackLevel) {
        stackLevel = stackLevel || this.keysStack[ 0 ];
        return (this.globalLevel && this.globalLevel[ action.type ] && this.globalLevel[ action.type ][ action.actionKey ]) ||
            (stackLevel && stackLevel.actions[ action.type ] && stackLevel.actions[ action.type ][ action.actionKey ]);
    }

    public getActionKeysByKeyCode(keyCode: number) {
        return this.reverseKeyMap[ keyCode ];
    }
}
