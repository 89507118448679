import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NpvrStatsComponent } from './npvr-stats.component';

@NgModule({
    declarations: [
        NpvrStatsComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        NpvrStatsComponent
    ]
})
export class NpvrStatsModule {
}
