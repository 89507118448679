import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeFromArray',
    pure: false
})
export class RemoveFromArray implements PipeTransform {

    transform(items: any[], value, condition?): any {
        if (!items) {
            return [];
        }
        return condition ? items.filter(item => item !== value) : items;
    }

}
