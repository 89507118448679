import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@kuki/environments/environment';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { Auth, Contract } from '@kuki/global/shared/types/general';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
    public serial: string;
    private _authData: Auth;

    private registered: boolean;

    private onRegister: Subject<any> = new ReplaySubject<string>(1);
    public onRegister$: Observable<any> = this.onRegister.asObservable();

    constructor(
        private httpClient: HttpClient,
        private deviceService: DeviceService,
        @Inject('PlatformHalService') private platformHalService: PlatformHal) {
    }

    public auth(): Observable<any> {
        const deviceType = hal.deviceType;
        this.serial = this.platformHalService.getSerial();
        return this.httpClient
            .post(environment.apiUrl + 'register', {
                sn: this.serial,
                device_type: deviceType,
                device_model: this.platformHalService.getDeviceModel(),
                product_name: this.platformHalService.getProductName(),
                mac: this.platformHalService.getMac(),
                version_fw: this.platformHalService.getVersionFw(),
                version_portal: this.getVersionPortal(),
                boot_mode: this.platformHalService.getBootMode(),
                claimed_device_id: this.platformHalService.getClaimedDeviceId(),
                cap: hal.cap,
                hw_platform: hal.platform,
                user_label: this.getUserLabel()
            })
            .pipe(tap((authResponse: any) => {
                this._authData = authResponse;
            }));
    }

    public login(payload: { username: string, password: string }) {
        return this.httpClient.post(`${ environment.apiUrl }login/validate`, payload);
    }

    public checkPassword(password: string) {
        return this.httpClient.post(`${ environment.apiUrl }account/check-password`, { password: password });
    }

    public getContractList(): Observable<Array<Contract>> {
        return this.httpClient.get<Array<Contract>>(`${ environment.apiUrl }account/contract-list`);
    }

    public isAccountPinActive(): Observable<boolean> {
        return this.httpClient.get<{ pin: boolean }>(`${ environment.apiUrl }account/pin/is-active`)
            .pipe(map(response => response.pin));
    }

    public setAccountPin(payload: { pin: string, password: string }): Observable<any> {
        return this.httpClient.post(`${ environment.apiUrl }account/pin/set`, payload);
    }

    public addDevice(payload: { contractCode: string }) {
        return this.httpClient.post(`${ environment.apiUrl }account/add-device`, payload);
    }

    public getCustomerCare(): Observable<string> {
        return this.httpClient.get<{ customerCare: string }>(`${ environment.apiUrl }account/customer-care`).pipe(
            map(customerCare => customerCare.customerCare));
    }

    public getAuthData(): Auth {
        return this._authData;
    }

    public isRegistered(): boolean {
        return this.registered;
    }

    public logout(deleteDevice: boolean = true) {
        const activeDevice = this.deviceService.getActiveDevice();
        const delete$ = deleteDevice && activeDevice ? this.deviceService.deleteDevice(activeDevice.id) : of(null);
        return delete$.pipe(tap(() => {
            this.registered = false;
        }));
    }

    public register() {
        this.onRegister.next({ token: this._authData.session_key });
        this.registered = true;
    }

    private getVersionPortal() {
        return `${ environment.version }(${ environment.versionBuild })`;
    }

    private getUserLabel() {
        return this.platformHalService.getUserLabel ? this.platformHalService.getUserLabel() : undefined;
    }
}
