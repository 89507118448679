import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';

@Component({
    selector: 'app-volume',
    templateUrl: './volume.component.html'
})
export class VolumeComponent implements AfterViewInit {

    @ViewChild('volumeBar') volumeBar: ElementRef<HTMLElement>;
    @ViewChild('volumeContainer') volumeContainer: ElementRef<HTMLElement>;
    private mouseIsDown: boolean;
    private firstClick: boolean;
    private volumeRangeWidth: number;
    muted: boolean = false;

    constructor(@Inject('MediaPlayerHalService') private mediaPlayerHalService: MediaPlayerHalInterface) {
    }

    ngAfterViewInit(): void {
        this.volumeRangeWidth = this.volumeContainer.nativeElement.getBoundingClientRect().width;
        const volume = this.mediaPlayerHalService.getVolume();
        this.volumeBar.nativeElement.style.width = (volume * this.volumeRangeWidth) + 'px';
        this.muted = this.mediaPlayerHalService.getMuted();
        fromEvent(window, 'mouseup').subscribe(($event) => {
            this.up($event);
        });
        fromEvent(this.volumeContainer.nativeElement, 'mousedown').subscribe(($event) => {
            this.down($event);
        });
        fromEvent(this.volumeContainer.nativeElement, 'mousemove').subscribe(($event) => {
            this.volumeSlide($event);
        });

    }

    down(event) {
        this.mouseIsDown = true;
        this.onVolume(Math.floor(event.offsetX));
    }

    up(event) {
        this.mouseIsDown = false;
        this.firstClick = true;
    }

    volumeSlide(event) {
        if (this.mouseIsDown && !this.firstClick) {
            this.onVolume(Math.floor(event.offsetX));
        }
        //  "firstClick" ignores the offset on the first click as it gives the offset of the volume button
        this.firstClick = false;
    }

    onVolume(x: number) {
        if (x < 3) {
            x = 0;
        } // check if it's too low
        if (x > this.volumeRangeWidth - 3) {
            x = this.volumeRangeWidth;
        } // check if it's too high
        this.volumeBar.nativeElement.style.width = x + 'px';
        const volume = Math.round((x / this.volumeRangeWidth) * 100) / 100;
        this.mediaPlayerHalService.setVolume(volume);
    }

    onMute() {
        this.muted = !this.muted;
        this.mediaPlayerHalService.setMute(this.muted);
    }
}
