import { Settings } from '@kuki/global/shared/types/settings';

export const defaultSettings: Settings = {
    'nbx.stblang': 'cs_CZ',
    parentalLock: '1',
    ageLimit: '18',
    unlockTimeout: '360000',
    bitrate: '0',
    debug: '0',
    audioPrefLevel1: 'cze',
    audioPrefLevel2: 'qaa',
    audioPrefLevel3: 'eng',
    subPrefLevel1: 'disabled',
    subPrefLevel2: 'cze',
    subPrefLevel3: 'cze',
    channelDisplayFormat: 'logo',
    useMobileData: '1',
    dashboardMode: 'modern',
    animations: '1',
    quickPlay: '0',
    infoPanel: '1',
    dashboardStartOnChannel: '0',
    continuingPlay: '0',
    videoPreview: '0',
    screenSaver: '1',
    broadcastGapSkipAuto: '0',
    preferredChannelTrack: '{}',
    lockPlayerLandscape: '0',
    backgroundPlay: '0'
};

export const getDefaultArrisSettings = (toi) => {
    return {
        dolbyDigital: '0',
        aspect: '2',
        scaleMode: '1',
        colorSystem: toi.consts.ToiVideoOutputConfiguration.COLOR_SYSTEM_PAL_B,
        scartMode: toi.consts.ToiVideoOutputConfiguration.SCART_MODE_RGB_CVBS,
        videoMode: null,
        colorSpace: toi.consts.ToiVideoOutputConfiguration.DIGITAL_COLOR_SPACE_SRGB,
        hdmiStbOff: '1',
        hdmiTvOff: '1',
        animations: '0'
    };
};

// Info about missing attributes:
// animation, audioPrefLevels, subPrefLevels are transferred across profiles
// lang is used by profile settings
export const defaultProfileSettings = {
    classic: {
        dashboardMode: 'modern',
        parentalLock: '1',
        ageLimit: '18',
        unlockTimeout: '360000',
        channelDisplayFormat: 'logo',
        quickPlay: '0',
        infoPanel: '1',
        dashboardStartOnChannel: '0'
    },
    senior: {
        dashboardMode: 'classic',
        parentalLock: '1',
        ageLimit: '18',
        unlockTimeout: '360000',
        channelDisplayFormat: 'number-logo',
        quickPlay: '1',
        infoPanel: '0',
        dashboardStartOnChannel: '1'
    },
    kid: {
        dashboardMode: 'modern',
        continuingPlay: '1',
        parentalLock: '1',
        ageLimit: '12',
        unlockTimeout: '60000',
        channelDisplayFormat: 'logo',
        quickPlay: '1',
        infoPanel: '1',
        dashboardStartOnChannel: '0'
    }
};

export const profileObjectKeys = [
    'profileName',
    'profileType',
    'profileIcon',
    'profilePin',
    'deleteProfile',
    'switchProfile'
];

export const settingsProfileObjectKeys = [
    'nbx.stblang',
    'parentalLock',
    'ageLimit',
    'unlockTimeout',
    'audioPrefLevel1',
    'audioPrefLevel2',
    'audioPrefLevel3',
    'subPrefLevel1',
    'subPrefLevel2',
    'subPrefLevel3',
    'channelDisplayFormat',
    'dashboardMode',
    'animations',
    'quickPlay',
    'infoPanel',
    'dashboardStartOnChannel',
    'continuingPlay',
    'videoPreview',
    'screenSaver',
    'broadcastGapSkipAuto',
    'preferredChannelTrack'
];
