import { Inject, Injectable } from '@angular/core';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { HttpClient } from '@angular/common/http';
import { map, timeout } from 'rxjs/operators';
import { timer } from 'rxjs';
import { SelfTest, SelfTestResult } from '@kuki/global/shared/modules/self-test/self-test';
import { selfTestConfig } from '@kuki/platforms/self-test.config';
import { TranslateService } from '@ngx-translate/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { hal } from '@kuki/platforms/hal';
import { DeviceTypes } from '@kuki/global/shared/types/device';

@Injectable()
export class SelfTestService {
    private subscription: SubscriptionObject = {};

    constructor(
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        private httpClient: HttpClient,
        private translateService: TranslateService) {
    }

    public selfTestIPAddress: SelfTest = {
        label: this.translateService.instant('SELF_TEST.IP_ADDRESS.LABEL'),
        run: () => {
            if (!this.platformHalService.checkConnection) {
                return this.simulateTest();
            }
            return Promise.resolve(this.platformHalService.checkConnection()).then(r => {
                if (r === 'ok') {
                    return Promise.resolve<SelfTestResult>({
                        state: 'ok'
                    });
                } else if (r === 'wireless') {
                    return Promise.resolve<SelfTestResult>({
                        state: 'warn',
                        desc: this.translateService.instant('SELF_TEST.TEST_IP_ADDRESS.WIRELESS_DESC'),
                        detail: r
                    });
                } else {
                    return Promise.resolve<SelfTestResult>({
                        state: 'error',
                        desc: this.translateService.instant('SELF_TEST.TEST_IP_ADDRESS.NO_CONNECTION_DESC'),
                        detail: r
                    });
                }
            });
        }
    };

    public selfTestHttpPortal: SelfTest = {
        label: this.translateService.instant('SELF_TEST.HTTP_PORTAL.LABEL'),
        run: () => {
            if (!selfTestConfig.portalHttpUrl) {
                return this.simulateTest();
            }
            return new Promise((resolve, reject) => {
                this.subscription.httpPortal = this.httpClient.get(`${ selfTestConfig.portalHttpUrl }?v=${ Date.now() }`, {
                    responseType: 'blob'
                }).pipe(timeout(5000)).subscribe(() => {
                    resolve({
                        state: 'ok'
                    });
                }, (e) => {
                    resolve({
                        state: 'error',
                        desc: this.translateService.instant('SELF_TEST.HTTP_PORTAL.CANT_ACCESS_DESC'),
                        detail: e
                    });
                });
            });
        }
    };

    public selfTestHttpsAS: SelfTest = {
        label: this.translateService.instant('SELF_TEST.HTTPS_PORTAL.LABEL'),
        run: () => {
            if (!selfTestConfig.apiHttpsUrl) {
                return this.simulateTest();
            }
            return new Promise((resolve, reject) => {
                this.subscription.httpsAs = this.httpClient.get(`${ selfTestConfig.apiHttpsUrl }get-time?v=${ Date.now() }`, {
                    responseType: 'blob'
                }).pipe(timeout(5000)).subscribe(() => {
                    resolve({
                        state: 'ok'
                    });
                }, (e) => {
                    resolve({
                        state: 'error',
                        desc: this.translateService.instant('SELF_TEST.HTTPS_PORTAL.CANT_ACCESS_DESC'),
                        detail: e
                    });
                });
            });
        }
    };

    public selfTestTime: SelfTest = {
        label: this.translateService.instant('SELF_TEST.TIME.LABEL'),
        run: (getTimeUrl = selfTestConfig.getTimeUrl) => {
            if (!getTimeUrl) {
                return this.simulateTest();
            }
            return new Promise((resolve, reject) => {
                this.subscription.time = this.httpClient.get<any>(`${ getTimeUrl }?v=${ Date.now() }`)
                    .pipe(timeout(10000)) // 10s 'cause of obsolete long api response time
                    .subscribe((data) => {
                        const clientNow = Date.now() / 1000;
                        const diffSec = Math.abs(clientNow - data.now);
                        if (diffSec < 30) {
                            resolve({
                                state: 'ok',
                                detail: `${ data.now } - ${ clientNow } = ${ diffSec }s"`
                            });
                        } else if (diffSec < 120) {
                            resolve({
                                state: 'warn',
                                desc: this.translateService.instant('SELF_TEST.TIME.DIFFERENT_TIME_SMALL_DESC', { diffSec: diffSec }),
                                detail: `${ data.now } - ${ clientNow } = ${ diffSec }s"`
                            });
                        } else {
                            resolve({
                                state: 'error',
                                desc: this.translateService.instant('SELF_TEST.TIME.DIFFERENT_TIME_BIG_DESC', { diffSec: diffSec }),
                                detail: `${ data.now } - ${ clientNow } = ${ diffSec }s"`
                            });
                        }
                    }, (e) => {
                        resolve({
                            state: 'error',
                            desc: this.translateService.instant('SELF_TEST.TIME.CANT_ACCESS_DESC'),
                            detail: e
                        });
                    });
            });
        }
    };

    public selfTestPlaylist: SelfTest = {
        label: this.translateService.instant('SELF_TEST.PLAYLIST.LABEL'),
        run: () => {
            if (!selfTestConfig.streamUrl) {
                return this.simulateTest();
            }
            return new Promise((resolve, reject) => {
                this.subscription.playlist = this.httpClient.get(`${ selfTestConfig.streamUrl }?v=${ Date.now() }`, {
                    responseType: 'blob'
                }).pipe(timeout(5000)).subscribe(() => {
                    resolve({
                        state: 'ok'
                    });
                }, (e) => {
                    resolve({
                        state: 'error',
                        desc: this.translateService.instant('SELF_TEST.PLAYLIST.CANT_ACCESS_DESC'),
                        detail: e
                    });
                });
            });
        }
    };

    public selfTestSpeed: SelfTest = {
        label: this.translateService.instant('SELF_TEST.SPEED.LABEL'),
        runSingleTest: (fn) => {
            if (!selfTestConfig.speedTestUrl) {
                return this.simulateTest();
            }
            return new Promise<SelfTestResult>((resolve, reject) => {
                const tsStart = Date.now();
                this.subscription[ fn ] = this.httpClient.get(`${ selfTestConfig.speedTestUrl }${ fn }.bin?v=${ Date.now() }`, {
                    // Blob size started return 0 on arris
                    responseType: 'text'
                }).subscribe((data) => {
                    const tsEnd = Date.now();
                    const speedMbps = data.length * 8 / ((tsEnd - tsStart) / 1000) / 1024 / 1024;
                    resolve({
                        state: 'ok',
                        desc: `${ Math.round(speedMbps * 10) / 10 }`,
                        detail: `downloaded ${ data.length } in ${ tsEnd - tsStart } msecs = ${ speedMbps } Mbps`,
                        speed: speedMbps
                    });
                }, (e) => {
                    resolve({
                        state: 'error',
                        detail: e
                    });
                });
            });
        },

        run: async () => {
            const speedFiles = [ '1M', '2M', '3M', '5M', '10M' ];
            const results = [];
            for (const speedFile of speedFiles) {
                const result = await this.selfTestSpeed.runSingleTest(speedFile);
                results.push(result);
            }
            let state = 'ok';
            results.forEach(r => {
                if (r.state === 'ok') {
                    if (r.speed <= 5) {
                        state = 'error';
                    } else if (r.speed <= 15) {
                        state = 'warn';
                    }
                } else {
                    state = r.state;
                    return;
                }
            });
            return {
                label: this.translateService.instant('SELF_TEST.SPEED.LABEL'),
                state: state as any,
                desc: results.map(r => r.desc).join(', ') + ' Mbps',
                detail: results.map(r => r.detail).join(', ')
            };
        }
    };

    public selfTestSTBModel: SelfTest = {
        label: this.translateService.instant('SELF_TEST.STB_MODEL.LABEL'),
        run: () => {
            return new Promise((resolve, reject) => {
                if (hal.platform !== 'TV.ARRIS' || hal.deviceType === DeviceTypes.WEB) {
                    return resolve({
                        state: 'ok'
                    });
                }
                const model = this.platformHalService.getDeviceModel();
                if ([ 'vip1003', 'vip1910', 'VIP4302', 'VIP4205' ].indexOf(model.substring(0, 7)) >= 0) {
                    return resolve({
                        state: 'ok'
                    });
                } else if ([ 'VIP1113' ].indexOf(model.substring(0, 7)) >= 0) {
                    this.subscription.platformInfo = this.platformHalService.getPlatformInfo().subscribe(platformInfo => {
                        if (platformInfo.mem_total >= 300 * 1024 * 1024) {
                            resolve({
                                state: 'ok'
                            });
                        } else {
                            resolve({
                                state: 'error',
                                desc: this.translateService.instant(
                                    'SELF_TEST.STB_MODEL.LOW_MEMORY',
                                    { memTotalMB: platformInfo.mem_total / 1024 / 1024 }),
                                detail: `${ platformInfo.mem_total / 1024 / 1024 } MB`
                            });
                        }
                    });
                } else {
                    resolve({
                        state: 'error',
                        desc: this.translateService.instant('SELF_TEST.STB_MODEL.UNKNOWN_MODEL'),
                        detail: model
                    });
                }
            });
        }
    };

    private simulateTest() {
        return timer(500).pipe(map(() => {
            return { state: 'ok' } as any;
        })).toPromise();
    }

    public destroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
