import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

    transform(text: string, length?: number, trail: string = '...'): string {
        const limit = length > 0 ? length : 200;
        return text && text.length > limit ? text.substr(0, limit) + trail : text;
    }
}
