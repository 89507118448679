<ng-template [appPlatformTv] [appPlatformTvNot]="true">
    <div class="osd-arrow-open"
         *ngIf="!contextMenuOpened"
         appClickStopPropagation
         (click)="openContextMenu()">
        <span class="icon icon-arrow-double-down"></span>
    </div>

    <div *ngIf="!isLocked()" class="osd-panel-center">
        <div class="seek seek-left"
             appClickStopPropagation
             (click)="seek.emit(-1)"
             (touchstart)="startSeek(-1)"
             (touchend)="endSeek()">
            <span class="icon icon-seek-left"></span></div>
        <div class="pause-play" appClickStopPropagation (click)="playPauseToggle.emit()">
            <ng-container *ngIf="isPlaying(); else paused">
                <span class="icon icon-pause-button"
                      [attr.aria-label]="'GENERAL.PLAYER.ARIA_PAUSE' | translate: { label: tile.label }"
                      role="text"></span>
            </ng-container>
            <ng-template #paused>
                <span class="icon icon-play-button"
                      [attr.aria-label]="'GENERAL.PLAYER.ARIA_PLAY' | translate: { label: tile.label }"
                      role="text"></span>
            </ng-template>
        </div>
        <div class="seek seek-right"
             appClickStopPropagation
             (click)="seek.emit(1)"
             (touchstart)="startSeek(1)"
             (touchend)="endSeek()"
             (touchcancel)="endSeek()"
             [ngClass]="{'seek-disabled': isLive()}">
            <span class="icon icon-seek-right"></span></div>
    </div>
    <div *appPlatform="'WEB'" class="osd-panel-right">
        <app-volume></app-volume>
    </div>

    <div *ngIf="showCastActions()" class="osd-cast-actions">
        <div *ngIf="chromecastAvailable$ | async"
             class="osd-cast-action"
             appClickStopPropagation
             [ngClass]="{'label-active': chromecastConnected$ | async }"
             (click)="onRequestChromecast()">
            <span class="icon icon-chromecast"></span>
        </div>
        <div *ngIf="airplayAvailable$ | async"
             class="osd-cast-action"
             appClickStopPropagation
             [ngClass]="{'label-active': airplayConnected$ | async }"
             (click)="onRequestAirplay()">
            <span class="icon icon-airplay"></span>
        </div>
    </div>
</ng-template>

<div *ngIf="showFreeTime() && protectionGapStartTime && protectionGapStartTime > 0" class="broadcast-gap-container-freetime">
    <app-countdown-box [duration]="protectionGapStartTime"
                       [total]="protectionGapStartFreetime"
                       [label]="'Nyní můžete reklamu přeskočit'"
                       class="broadcast-gap-action"></app-countdown-box>
</div>

<div *ngIf="activeBroadcastGapGroup" class="broadcast-gap-container">
    <ng-container *ngIf="activeBroadcastGapGroup.skippable; else countdownTpl">
        <app-progress-button
                *ngIf="!isLive() && !isStartOver()"
                (click)="onBroadcastGapSkipAction()"
                [label]="'Přeskočit reklamy'"
                [duration]="broadcastGapSkipDelay"
                (complete)="onBroadcastGapSkipComplete()"
                class="broadcast-gap-action">
        </app-progress-button>
    </ng-container>
    <ng-template #countdownTpl>
        <ng-container *ngIf="isObeySkippable() && activeBroadcastGapGroup.finished">
            <app-countdown-box [duration]="activeBroadcastGapGroup.datetimeTo - watchingProgressAbsTime"
                               [total]="activeBroadcastGapGroup.datetimeTo - activeBroadcastGapGroup.datetimeFrom"
                               [label]="'Reklamu lze přeskočit za'"
                               class="broadcast-gap-action"></app-countdown-box>
        </ng-container>
    </ng-template>
</div>


<div *appPlatform="'WEB'" class="osd-fullscreen-action" appClickStopPropagation (click)="onToggleFullscreen()">
    <span class="icon icon-minimalize" *ngIf="isFullscreen; else notFullscreen"></span>
    <ng-template #notFullscreen>
        <span class="icon icon-maximalize"></span>
    </ng-template>
</div>

<div *ngIf="contextMenuOpened" class="osd-context-menu" appClickStopPropagation (mouseleave)="closeContextMenu(true)">
    <div class="osd-context-menu-actions">
        <app-buttons-panel *ngIf="actionButtons"
                           #buttonsPanelActions
                           [buttons]="actionButtons"
                           [propagateActionsKeys]="propagateActionKeys"
                           [propagateActionsKeysHold]="propagateActionKeysHold"
                           [infinityScroll]="true"
                           appMouseEvents
                           (appMouseEnter)="onMouseButtonsEnter(buttonsPanelActions)"
                           (action)="onActionButton($event)"></app-buttons-panel>
        <span class="osd-close icon icon-times"
              *appPlatformMobile
              (click)="closeContextMenu()"></span>
    </div>
    <div class="osd-context-menu-content">
        <ng-template [appPlatformMobile] [appPlatformMobileNot]="true">
            <ng-container *appProfileType="['classic', 'senior']">
                <div *ngIf="media" class="osd-context-menu-detail">
                    <app-source-logo [media]="media"></app-source-logo>
                    <app-labels-panel [media]="media"></app-labels-panel>
                    <app-auto-scroll-container #autoScrollDescription
                                               class="osd-context-menu-detail-description"
                                               [type]="'vertical'">{{ media.description }}</app-auto-scroll-container>
                </div>
            </ng-container>
            <ng-container *appPlatform="'WEB'; not: true">
                <app-source-logo *appProfileType="['kid']; admin: false" [media]="media"></app-source-logo>
            </ng-container>
            <ng-container *ngTemplateOutlet="langsEl"></ng-container>
        </ng-template>
        <ng-container *appPlatformMobile>
            <ng-container *ngTemplateOutlet="langsEl"></ng-container>
        </ng-container>
    </div>
</div>

<div class="osd-panel">
    <app-auto-scroll-container #autoScrollLabel class="osd-label">
        <ng-container *ngIf="!isLocked(); else lockedTpl">{{ tile.label }}</ng-container>
        <ng-template #lockedTpl>{{ 'GENERAL.PLAYER.INFORMATION_NOT_AVAILABLE' | translate }}</ng-template>
    </app-auto-scroll-container>
    <div class="osd-progress-bar-wrapper" #osdProgressBarEl appClickStopPropagation>
        <div class="osd-progress-bar">
            <div class="osd-progress-bar-live" [ngStyle]="{ width: liveProgress + '%' }"></div>
            <div class="osd-progress-bar-watched" [ngStyle]="{ width: watchingProgress + '%' }">
                <div *ngIf="progressTimeVisible(watchingProgress, watchingProgressTime)"
                     class="osd-progress-bar-time">{{ watchingProgressTime | time }}</div>
            </div>
            <div *ngFor="let broadcastGapGroup of broadcastGapGroups"
                 class="osd-progress-bar-ad"
                 [ngStyle]="{left: getGapsLeft(broadcastGapGroup) + '%',  width: getGapsWidth(broadcastGapGroup) + '%' }">
            </div>
            <div class="osd-progress-bar-preview" *ngIf="hasPreview() && isSeeking()">
                <div class="osd-progress-bar-preview-line" [ngStyle]="{ left: getProgress() + '%' }"></div>
                <div class="osd-progress-bar-preview-box" [ngStyle]="getPreviewBoxStyle()">
                    <div class="osd-progress-bar-preview-time">{{ cursorProgressTime | time }}</div>
                </div>
            </div>
            <div class="osd-progress-bar-cursor" [ngStyle]="{ left: cursorProgress + '%' }"></div>
            <div class="osd-progress-bar-time osd-progress-bar-time-total">{{ endTime | time }}</div>
        </div>
    </div>
</div>

<ng-template #langsEl>
    <div class="osd-context-menu-langs">
        <div *ngIf="audioButtons && audioButtons.length" class="osd-context-menu-lang-row">
            <div class="osd-context-menu-lang-row-icon">
                <div *appPlatformTv class="icon icon-audio-surround"></div>
                <div class="icon icon-audio"></div>
            </div>
            <app-buttons-panel #buttonsPanelAudio
                               [buttons]="audioButtons"
                               [selectedButtons]="selectedAudioButtons"
                               [propagateActionsKeys]="propagateActionKeys"
                               [propagateActionsKeysHold]="propagateActionKeysHold"
                               [infinityScroll]="true"
                               appMouseEvents
                               (appMouseEnter)="onMouseButtonsEnter(buttonsPanelAudio)"
                               (action)="onChangeAudio($event)"></app-buttons-panel>
        </div>
        <div *ngIf="audioDDButtons && audioDDButtons.length" class="osd-context-menu-lang-row">
            <div class="osd-context-menu-lang-row-icon">
                <div class="icon icon-audio-dolby-digital"></div>
                <div class="icon icon-audio"></div>
            </div>
            <app-buttons-panel #buttonsPanelAudioDD
                               [buttons]="audioDDButtons"
                               [selectedButtons]="selectedAudioDDButtons"
                               [propagateActionsKeys]="propagateActionKeys"
                               [propagateActionsKeysHold]="propagateActionKeysHold"
                               [infinityScroll]="true"
                               appMouseEvents
                               (appMouseEnter)="onMouseButtonsEnter(buttonsPanelAudioDD)"
                               (action)="onChangeAudio($event)"></app-buttons-panel>
        </div>
        <div *ngIf="subtitleButtons && subtitleButtons.length" class="osd-context-menu-lang-row">
            <div class="osd-context-menu-lang-row-icon">
                <div class="icon icon-subtitles"></div>
            </div>
            <app-buttons-panel #buttonsPanelSubtitle
                               [buttons]="subtitleButtons"
                               [selectedButtons]="selectedSubtitleButtons"
                               [propagateActionsKeys]="propagateActionKeys"
                               [propagateActionsKeysHold]="propagateActionKeysHold"
                               [infinityScroll]="true"
                               appMouseEvents
                               (appMouseEnter)="onMouseButtonsEnter(buttonsPanelSubtitle)"
                               (action)="onChangeSubtitle($event)"></app-buttons-panel>
        </div>
    </div>
</ng-template>

<ng-container *appPlatformTv>
    <div *ngIf="isPaused()" class="osd-paused">
        <div class="icon icon-pause-big"></div>
    </div>
</ng-container>
<div *ngIf="debug && debug.length > 0 && !contextMenuOpened" class="osd-debug">{{ debug }}</div>
