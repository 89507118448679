import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaService } from '@kuki/global/shared/modules/media/media.service';
import { EpgEntityService } from '@kuki/global/shared/modules/media/epg-entity.service';
import { SerialService } from '@kuki/global/shared/modules/media/serial.service';
import { EpisodeService } from '@kuki/global/shared/modules/media/episode.service';
import { VodService } from '@kuki/global/shared/modules/media/vod.service';
import { NpvrService } from '@kuki/global/shared/modules/media/npvr.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        MediaService,
        EpgEntityService,
        SerialService,
        EpisodeService,
        NpvrService,
        VodService
    ]
})
export class MediaModule {
}
