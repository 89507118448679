import { Directive, ElementRef, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { auditTime, debounceTime, tap } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { hal } from '@kuki/platforms/hal';

@Directive({
    selector: '[appMouseMoveVisible]'
})
export class MouseMoveVisibleDirective implements OnInit, OnDestroy {

    private subscription: SubscriptionObject = {};

    constructor(private elementRef: ElementRef<HTMLElement>,
                private renderer: Renderer2,
                private ngZone: NgZone) {
    }

    ngOnInit() {
        this.renderer.setStyle(this.elementRef.nativeElement, 'visibility', 'hidden');
        if (hal.platform !== 'TV.WEBOS') {
            return;
        }
        const events = [
            'wheel',
            'mousemove',
            'mousedown',
            'mouseup'
        ];
        const eventStreams = events.map((ev) => fromEvent(document, ev));
        this.ngZone.runOutsideAngular(() => {
            this.subscription.mousemove = merge(...eventStreams).pipe(
                auditTime(100),
                tap(() => {
                    this.renderer.setStyle(this.elementRef.nativeElement, 'visibility', 'visible');
                })).pipe(debounceTime(1000)).subscribe(() => {
                this.renderer.setStyle(this.elementRef.nativeElement, 'visibility', 'hidden');
            });
        });
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
