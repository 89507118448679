import { Directive, ElementRef, HostListener, OnDestroy, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
    selector: '[appClickOrTap]'
})
export class ClickOrTapDirective implements OnDestroy {
    private clickOrTap$: Subject<Event> = new Subject<Event>();
    @Output() appClickOrTap: Observable<Event> = this.clickOrTap$.asObservable().pipe(throttleTime(100));

    constructor(
        private elementRef: ElementRef) {
    }

    @HostListener('click', [ '$event' ])
    public onClick(event: Event): void {
        event.stopPropagation();
        this.clickOrTap$.next(event);
    }

    @HostListener('tap', [ '$event' ])
    public onTap(event: any): void {
        event.srcEvent.stopPropagation();
        if (event.target === this.elementRef.nativeElement) {
            this.clickOrTap$.next(event);
        }
    }

    ngOnDestroy() {
        this.clickOrTap$.complete();
    }
}
