import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickStopPropagationDirective } from '@kuki/global/shared/directives/click-stop-propagation.directive';
import { ClickOrTapDirective } from '@kuki/global/shared/directives/click-or-tap.directive';
import { SettingsSelectedItemDirective } from '@kuki/global/shared/directives/settings-selected-item.directive';
import { PlatformTypeDirective } from './platform-type.directive';
import { DclHostDirective } from '@kuki/global/shared/directives/dcl-host.directive';
import { MouseMoveVisibleDirective } from '@kuki/global/shared/directives/mouse-move-visible.directive';
import { MouseEventsDirective } from '@kuki/global/shared/directives/mouse-events.directive';
import { ProfileTypeDirective } from '@kuki/global/shared/directives/profile-type.directive';
import { PlatformTvDirective } from '@kuki/global/shared/directives/platform-tv.directive';
import { PlatformMobileDirective } from '@kuki/global/shared/directives/platform-mobile.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ClickStopPropagationDirective,
        ClickOrTapDirective,
        SettingsSelectedItemDirective,
        PlatformTypeDirective,
        DclHostDirective,
        MouseMoveVisibleDirective,
        MouseEventsDirective,
        ProfileTypeDirective,
        PlatformTvDirective,
        PlatformMobileDirective
    ],
    exports: [
        ClickStopPropagationDirective,
        ClickOrTapDirective,
        SettingsSelectedItemDirective,
        PlatformTypeDirective,
        DclHostDirective,
        MouseMoveVisibleDirective,
        MouseEventsDirective,
        ProfileTypeDirective,
        PlatformTvDirective,
        PlatformMobileDirective
    ]
})
export class DirectivesModule {
}
