import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { Device, DeviceLicenceInfo, DeviceTypes } from '@kuki/global/shared/types/device';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class DeviceService {
    private activeDevice: Device;
    // ready for devices, when ready on api
    private devices: Array<Device>;

    private onRefreshDeviceState: Subject<number> = new Subject<number>();
    public onRefreshDeviceState$: Observable<number> = this.onRefreshDeviceState.asObservable();

    private onRefreshDevices: Subject<number> = new Subject<number>();
    public onRefreshDevices$: Observable<number> = this.onRefreshDevices.asObservable();

    constructor(
        private httpClient: HttpClient,
        private translateService: TranslateService) {
    }

    public fetchDevices(serial?: string) {
        return this.httpClient.get<Array<Device>>(`${ environment.apiUrl }device`).pipe(
            tap((devices) => {
                this.devices = devices;
                if (serial) {
                    this.activeDevice = devices.find(device => device.sn === serial);
                } else if (this.activeDevice) {
                    this.activeDevice = devices.find(device => device.sn === this.activeDevice.sn);
                }
                this.onRefreshDevices.next();
            })
        );
    }

    // deep copy
    public getDevices(): Array<Device> {
        return [ ...this.devices.map(device => ({ ...device })) ];
    }

    public getDevicesForTeleport(activeSerial: string): Array<Device> {
        return this.devices.filter(device =>
            device.cap?.teleport &&
            device.canTeleport &&
            device.canPlay &&
            (device.online || device.cap?.wakeOnRemote) &&
            device.sn !== activeSerial);
    }

    public getLicenceInfo(): Observable<DeviceLicenceInfo> {
        return this.httpClient.get<DeviceLicenceInfo>(`${ environment.apiUrl }device/license-info`);
    }

    public updateDevice(id: number,
                        payload: {
                            canPlay?: boolean,
                            canTeleport?: boolean,
                            confirmTeleport?: boolean,
                            alias?: string
                        }): Observable<Device> {
        return this.httpClient.post<Device>(`${ environment.apiUrl }device/${ id }`, payload)
            // re-fetch devices on update
            .pipe(switchMap((device) => this.fetchDevices().pipe(map(() => device))));
    }

    public deleteDevice(id: number) {
        return this.httpClient.delete(`${ environment.apiUrl }device/${ id }`).pipe(
            tap(() => {
                this.devices = this.devices.filter(device => device.id !== id);
            })
        );
    }

    public getDeviceType(deviceType: DeviceTypes) {
        switch (deviceType) {
            case DeviceTypes.MOBILE:
                return this.translateService.instant('GENERAL.DEVICE_TYPE.MOBILE');
            case DeviceTypes.SMART_TV:
                return this.translateService.instant('GENERAL.DEVICE_TYPE.SMART_TV');
            case DeviceTypes.WEB:
                return this.translateService.instant('GENERAL.DEVICE_TYPE.WEB');
            case DeviceTypes.STB:
                return this.translateService.instant('GENERAL.DEVICE_TYPE.STB');
        }
    }

    public getActiveDevice() {
        return this.activeDevice;
    }

    public getDevice(id: number) {
        return this.devices.find(device => device.id === id);
    }

    public refreshDeviceState(deviceId: number) {
        this.onRefreshDeviceState.next(deviceId);
    }

    public destroy() {
        this.devices = undefined;
        this.activeDevice = undefined;
    }
}
