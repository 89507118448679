import { Injectable } from '@angular/core';
import { TileRow } from '@kuki/global/shared/types/general';
import { MediaTile, Tile } from '@kuki/global/shared/types/tile';
import { Observable, Subject } from 'rxjs';
import { MediaTypes, Tags, TileTypes } from '@kuki/global/shared/types/enum';

@Injectable()
export class TagService {

    private tags: { [ key: string ]: Array<Tags> } = {};
    private tagsUpdated: { [ key: string ]: Subject<Array<Tags>> } = {};
    public tagsUpdated$: { [ key: string ]: Observable<Array<Tags>> } = {};

    constructor() {
    }

    public getTags(id: number, mediaType: MediaTypes): Array<Tags> {
        const tagId = this.getTagId(id, mediaType);
        return this.tags[ tagId ] ? this.tags[ tagId ] : [];
    }

    public getTagsUpdates(id: number, mediaType: MediaTypes): Observable<Array<Tags>> {
        const tagId = this.getTagId(id, mediaType);
        if (this.tagsUpdated$[ tagId ]) {
            return this.tagsUpdated$[ tagId ];
        }
        this.tagsUpdated[ tagId ] = new Subject<Array<Tags>>();
        this.tagsUpdated$[ tagId ] = this.tagsUpdated[ tagId ].asObservable();
        return this.tagsUpdated$[ tagId ];
    }

    public tagExists(id: number, mediaType: MediaTypes, tag: Tags): boolean {
        const tagId = this.getTagId(id, mediaType);
        if (!this.tags[ tagId ] || this.tags[ tagId ].length === 0) {
            return false;
        }
        return this.tags[ tagId ].indexOf(tag) >= 0;
    }

    public addTag(id: number, mediaType: MediaTypes, tag: Tags) {
        const tagId = this.getTagId(id, mediaType);
        this.tags[ tagId ] = this.tags[ tagId ] || [];
        if (this.tags[ tagId ].indexOf(tag) === -1) {
            this.tags[ tagId ].push(tag);
            this.updateTags(tagId);
        }
    }

    public removeTag(id: number, mediaType: MediaTypes, tag: Tags) {
        const tagId = this.getTagId(id, mediaType);
        if (!this.tags[ tagId ] || this.tags[ tagId ].length === 0) {
            return;
        }
        this.tags[ tagId ] = this.tags[ tagId ].filter(tagItem => tagItem !== tag);
        this.updateTags(tagId);

    }

    public storeTagsFromTileRows(tileRows: Array<TileRow>) {
        let tags = {};
        tileRows.forEach(tileRow => {
            tags = { ...tags, ...this.parseTagsFromTileRow(tileRow) };
        });
        this.tags = { ...this.tags, ...tags };
    }

    public storeTagsFromTileRow(tileRow: TileRow) {
        const tags = this.parseTagsFromTileRow(tileRow);
        this.tags = { ...this.tags, ...tags };
    }

    public storeTagsFromTiles(tiles: Array<Tile>) {
        const tags = {};
        tiles.forEach(tile => {
            const tileTags = this.parseTagsFromTile(tile);
            if (tileTags) {
                tags[ this.getTagIdFromMediaTile(tile as MediaTile) ] = tileTags;
            }
        });
        this.tags = { ...this.tags, ...tags };
    }

    public storeTagsFromTile(tile: Tile) {
        const tags = {};
        const tileTags = this.parseTagsFromTile(tile);
        if (tileTags) {
            tags[ this.getTagIdFromMediaTile(tile as MediaTile) ] = tileTags;
        }
        this.tags = { ...this.tags, ...tags };
    }

    public clear() {
        this.tags = {};
    }

    private parseTagsFromTileRow(tileRow: TileRow) {
        if (!tileRow) {
            return;
        }
        const tags = {};
        tileRow.tiles.forEach(tile => {
            const tileTags = this.parseTagsFromTile(tile);
            if (tileTags) {
                tags[ this.getTagIdFromMediaTile(tile as MediaTile) ] = tileTags;
            }
        });
        return tags;
    }

    private parseTagsFromTile(tile: Tile) {
        if (tile.type !== TileTypes.MEDIA) {
            return;
        }
        const mediaTile = tile as MediaTile;
        if (mediaTile.tags && mediaTile.tags.length > 0) {
            return mediaTile.tags;
        }
    }

    private getTagIdFromMediaTile(mediaTile: MediaTile) {
        return this.getTagId(mediaTile.epgEntityId || mediaTile.id, mediaTile.mediaType);
    }

    private getTagId(id: number, mediaType: MediaTypes) {
        const type = mediaType === MediaTypes.SERIAL ? MediaTypes.SERIAL : MediaTypes.EPG_ENTITY;
        return type + '#' + id;
    }

    private updateTags(tagId: string) {
        if (this.tagsUpdated[ tagId ]) {
            this.tagsUpdated[ tagId ].next(this.tags[ tagId ]);
        }
    }


}
