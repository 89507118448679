import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@kuki/environments/environment';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class PinWInterceptor implements HttpInterceptor {

    private xPinW: string;

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = request.headers;
        if (!request.url.match('^' + environment.apiUrl)) {
            return next.handle(request);
        }
        if (this.xPinW) {
            headers = headers.append('X-PinW', this.xPinW);
        }
        request = request.clone({
            withCredentials: true,
            headers: headers
        });
        return next.handle(request).pipe(
            tap((response: HttpResponse<any>) => {
                const xPinW = response.headers?.get('X-PinW');
                if (xPinW) {
                    this.xPinW = xPinW;
                }
            })
        );
    }
}
