import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Media } from '@kuki/global/shared/types/media';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { ImageTypes, MediaTypes } from '@kuki/global/shared/types/enum';

@Component({
    selector: 'app-source-logo',
    templateUrl: './source-logo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourceLogoComponent implements OnChanges {
    @Input() media: Media;

    public imageType: ImageTypes;
    public type: string;

    constructor(private tileService: TileService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('media')) {
            const isVod = this.media.tile.mediaType === MediaTypes.VOD;
            this.imageType = isVod ? ImageTypes.VOD_PROVIDER : ImageTypes.CHANNEL_LOGO;
            this.type = this.tileService.getSourceLogoType(this.media.tile);
        }
    }
}
