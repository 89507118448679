import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChannelSwitcherComponent } from './channel-switcher.component';
import { ChannelSwitcherService } from '@kuki/global/features/channel-switcher/channel-switcher.service';

@NgModule({
    declarations: [
        ChannelSwitcherComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ChannelSwitcherComponent
    ],
    providers: [
        ChannelSwitcherService
    ]
})
export class ChannelSwitcherModule {
}
