import {
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { ActionButton } from '@kuki/global/shared/types/button';
import { ActionKey, CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { ScrollService } from '@kuki/global/shared/services/scroll.service';

@Component({
    selector: 'app-buttons-panel',
    templateUrl: './buttons-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonsPanelComponent implements OnInit, OnChanges, OnDestroy {

    @Input() ident: string = 'buttons-panel';
    @Input() active: boolean = false;
    @Input() priv: boolean = false;
    @Input() type: string = 'horizontal';
    @Input() buttons: Array<ActionButton>;
    @Input() selectedButtons: Array<ActionButton | string>;
    @Input() locked: boolean = false;
    @Input() propagateDefault: boolean = true;
    @Input() propagateActionsKeys: Array<ActionKey> = [];
    @Input() propagateActionsKeysHold: Array<ActionKey> = [];
    @Input() infinityScroll: boolean = false;
    @Input() iconsOnly: boolean = false;

    @Output() action: EventEmitter<ActionButton> = new EventEmitter<ActionButton>();
    @Output() mouseEnter: EventEmitter<void> = new EventEmitter<void>();
    @Output() overflow: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('buttonsWrapper') buttonsWrapperEl: ElementRef<HTMLElement>;
    @ViewChildren('buttonEl') buttonEls: QueryList<ElementRef<HTMLElement>>;

    private buttonsPanelSize: number;
    private activeItem: number = 0;
    private scrollPos: number = 0;
    private margin: number = 0;
    private buttonsDims: Array<{ pos: number, size: number, margin?: number }>;
    private renderTimeout: any;

    constructor(
        private coreService: CoreService,
        private applicationRef: ApplicationRef,
        private changeDetectorRef: ChangeDetectorRef,
        private controllerService: ControllerService,
        private scrollService: ScrollService,
        private renderer: Renderer2,
        private ngZone: NgZone,
        private elementRef: ElementRef<HTMLElement>) {
    }

    ngOnInit() {
        if (this.propagateDefault) {
            if (this.type === 'horizontal') {
                this.propagateActionsKeys = [ ...this.propagateActionsKeys, CommonKeys.UP, CommonKeys.DOWN, CommonKeys.GRP_BACK ];
            } else {
                this.propagateActionsKeys = [ ...this.propagateActionsKeys, CommonKeys.GRP_BACK ];
            }
        }
        if (!this.isHorizontal()) {
            this.renderer.addClass(this.elementRef.nativeElement, 'buttons-panel-vertical');
        }
        this.ngZone.run(() => {
            // wait for right dimensions
            this.renderTimeout = setTimeout(() => {
                this.initDimensions();
                this.changeDetectorRef.detectChanges();
                if (this.active) {
                    this.registerControls(this.priv);
                }
            });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('active') && !changes[ 'active' ].firstChange) {
            if (changes[ 'active' ].currentValue === true) {
                this.activate();
            } else if (changes[ 'active' ].previousValue === true) {
                this.deactivate();
                this.reset();
            }
        }
    }

    public activate(priv: boolean = false) {
        this.active = true;
        this.registerControls(priv);
        this.changeDetectorRef.detectChanges();
    }

    public deactivate() {
        this.active = false;
        this.unregisterControls();
        this.changeDetectorRef.detectChanges();
    }

    private isHorizontal() {
        return this.type === 'horizontal';
    }

    private initDimensions() {
        if (this.isHorizontal()) {
            this.buttonsPanelSize = this.elementRef.nativeElement.clientWidth;
        } else {
            this.buttonsPanelSize = this.elementRef.nativeElement.clientHeight;
        }
        this.buttonsDims = [];
        this.buttonEls.forEach((el, index) => {
            const buttonsDim = {
                pos: this.isHorizontal() ? el.nativeElement.offsetLeft : el.nativeElement.offsetTop,
                size: this.isHorizontal() ? el.nativeElement.clientWidth : el.nativeElement.clientHeight,
            };
            this.buttonsDims.push(buttonsDim);
        });
        this.margin = this.calculateMargin(this.buttonsDims[ 0 ], this.buttonsDims[ 1 ]);
    }

    private registerControls(priv: boolean = false) {
        if (!this.coreService.isTvPlatform()) {
            return;
        }
        this.controllerService.registerKeyStackLevel(this.ident, priv);
        this.controllerService.registerActionKey(CommonKeys.OK, this.ident, () => {
            this.onButtonAction();
        });
        if (this.isHorizontal()) {
            this.controllerService.registerActionKey(CommonKeys.LEFT, this.ident, () => {
                this.movePrev();
            });
            this.controllerService.registerActionKey(CommonKeys.LEFT, this.ident, () => {
                this.movePrev();
            }, 'pl');
            this.controllerService.registerActionKey(CommonKeys.RIGHT, this.ident, () => {
                this.moveNext();
            });
            this.controllerService.registerActionKey(CommonKeys.RIGHT, this.ident, () => {
                this.moveNext();
            }, 'pl');
        } else {
            this.controllerService.registerActionKey(CommonKeys.UP, this.ident, () => {
                this.movePrev();
            });
            this.controllerService.registerActionKey(CommonKeys.UP, this.ident, () => {
                this.movePrev();
            }, 'pl');
            this.controllerService.registerActionKey(CommonKeys.DOWN, this.ident, () => {
                this.moveNext();
            });
            this.controllerService.registerActionKey(CommonKeys.DOWN, this.ident, () => {
                this.moveNext();
            }, 'pl');
        }
        if (this.propagateActionsKeys) {
            this.controllerService.propagateActionKeys(this.propagateActionsKeys, this.ident);
        }
        if (this.propagateActionsKeysHold) {
            this.controllerService.propagateActionKeys(this.propagateActionsKeysHold, this.ident, 'h');
        }
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    private movePrev() {
        if (this.locked) {
            return;
        }

        if (!this.buttonsDims) {
            return;
        }

        if (this.activeItem > 0) {
            this.activeItem--;
            const buttonDim = this.buttonsDims[ this.activeItem ];
            if (buttonDim && buttonDim.pos - this.scrollPos < 0) {
                this.scrollPanel(Math.max(this.scrollPos - buttonDim.size - this.margin, 0));
            } else if (!buttonDim) {
                this.scrollPanel(0);
            }
        } else {
            if (this.infinityScroll) {
                this.scrollPanel(this.calculateScroll(this.buttons.length - 1));
                this.activeItem = this.buttons.length - 1;
            }
            this.overflow.emit(-1);
        }
        this.changeDetectorRef.detectChanges();
    }

    private moveNext() {
        if (this.locked) {
            return;
        }
        if (!this.buttonsDims) {
            return;
        }

        if (this.activeItem < this.buttons.length - 1) {
            this.activeItem++;
            const buttonDim = this.buttonsDims[ this.activeItem ];
            if (buttonDim && (buttonDim.pos + buttonDim.size) - this.scrollPos > this.buttonsPanelSize) {
                this.scrollPanel(this.scrollPos + buttonDim.size + this.margin);
            }
        } else {
            if (this.infinityScroll) {
                this.activeItem = 0;
                this.scrollPanel(0);
            }
            this.overflow.emit(1);
        }
        this.changeDetectorRef.detectChanges();
    }


    private calculateScroll(item: number) {
        let offset = 0;
        for (let i = this.activeItem; i <= item; i++) {
            const buttonDim = this.buttonsDims[ i ];
            if (buttonDim && buttonDim.pos + buttonDim.size > this.buttonsPanelSize) {
                offset += this.buttonsDims[ i ].size + this.margin;
            }
        }
        return offset;
    }

    private onButtonAction() {
        if (this.locked) {
            return;
        }
        const activeButton = this.getActiveButton();
        if (!activeButton) {
            return;
        }
        this.action.emit(activeButton);
    }

    public onMouseEnter(button: ActionButton) {
        if (this.locked) {
            return;
        }
        this.activeItem = this.buttons.findIndex(buttonItem => button === buttonItem);
        this.changeDetectorRef.detectChanges();
        this.mouseEnter.emit();
    }

    public onMouseClick(button: ActionButton) {
        this.activeItem = this.buttons.findIndex(buttonItem => button === buttonItem);
        this.onButtonAction();
    }

    public buttonIsActive(i: number) {
        if (!this.coreService.isTvPlatform()) {
            return false;
        }
        return this.active && i === this.activeItem;
    }

    private buttonIsSelected(button) {
        return this.selectedButtons &&
            this.selectedButtons.some(selectedButton => selectedButton === button || selectedButton === button.guid);
    }

    private getActiveButton() {
        return this.buttons[ this.activeItem ];
    }

    public getButtonClass(button: ActionButton, i: number) {
        const cssClass = {
            [ button.class ]: !!button.class,
            'button-active': this.buttonIsActive(i),
            'button-with-icon': !!button.icon,
            'button-selected': this.buttonIsSelected(button),
            'button-with-icon-only': this.iconsOnly
        };
        if (this.buttonsDims && this.buttonsDims[ i ]) {
            const posStart = this.buttonsDims[ i ].pos;
            const posEnd = posStart + this.buttonsDims[ i ].size;
            const fadeOutFront = posStart - this.scrollPos < 0;
            const fadeOutBack = posEnd - this.scrollPos > this.buttonsPanelSize;
            cssClass[ 'button-fade-out' ] = fadeOutFront || fadeOutBack;
        }
        return cssClass;
    }

    private reset() {
        this.activeItem = 0;
        this.scrollPanel(0);
        this.changeDetectorRef.detectChanges();
    }

    private scrollPanel(pos: number) {
        if (this.isHorizontal()) {
            this.scrollService.scrollHorizontal(this.buttonsWrapperEl, pos, 100, {
                renderer: this.renderer,
                actualPosition: this.scrollPos
            }, 'request-animation-frame');
        } else {
            this.scrollService.scrollVertical(this.buttonsWrapperEl, pos, 100, {
                renderer: this.renderer,
                actualPosition: this.scrollPos
            }, 'request-animation-frame');
        }
        this.scrollPos = pos;
    }

    private calculateMargin(buttonDim, nextButtonDim) {
        if (!buttonDim || !nextButtonDim) {
            return 0;
        }
        return nextButtonDim.pos - (buttonDim.pos + buttonDim.size);
    }

    ngOnDestroy() {
        if (this.renderTimeout) {
            clearTimeout(this.renderTimeout);
        }
        this.unregisterControls();
    }
}
