import { Injectable } from '@angular/core';
import { Channel } from '@kuki/global/shared/types/general';
import { SessionService } from '@kuki/global/shared/services/session.service';

@Injectable()
export class RestrictionService {

    constructor(private sessionService: SessionService) {
    }

    public isSessionRestricted(): boolean {
        const session = this.sessionService.getSession();
        return !session || session.suspend;
    }

    public isSessionCanPlayRestricted(): boolean {
        const session = this.sessionService.getSession();
        return !session || !session.canPlay;
    }

    public isSessionPlayRestricted(): boolean {
        const session = this.sessionService.getSession();
        return !session || session.suspend || !session.canPlay;
    }

    public isChannelTsRestricted(channel: Channel, startTime: number): boolean {
        return channel && (startTime < Date.now() - channel.timeshiftTime);
    }

    public isChannelBroadcastRestricted(channel: Channel, startTime: number): boolean {
        if (!channel || !channel.broadcastFrom || !channel.broadcastTo) {
            return false;
        }
        const broadcastFrom = channel.raw.broadcastFrom;
        const broadcastTo = channel.raw.broadcastTo;
        const startDate = startTime ? new Date(startTime) : new Date();
        const broadcastNow = this.calcMs(startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        return (broadcastFrom < broadcastTo && (broadcastNow < broadcastFrom || broadcastNow >= broadcastTo)) ||
            (broadcastFrom > broadcastTo && (broadcastNow < broadcastFrom && broadcastNow >= broadcastTo));
    }

    private calcMs(hours: number, minutes: number, seconds: number): number {
        return ((hours * 3600) + (minutes * 60) + seconds) * 1000;
    }
}
