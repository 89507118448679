import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsPanelComponent } from './buttons-panel.component';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';


@NgModule({
    declarations: [ ButtonsPanelComponent ],
    imports: [
        CommonModule,
        DirectivesModule,
        AutoScrollContainerModule
    ],
    exports: [
        ButtonsPanelComponent
    ]
})
export class ButtonsPanelModule {
}
