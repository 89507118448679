export function wsLogger() {

    const _log = console.log;
    const _error = console.error;

    console.log = function (msg) {
        if ((window as any).ws_console_log) {
            (window as any).ws_console_log(msg);
        }
        _log.apply(console, arguments);
    };
    console.error = function (msg) {
        if ((window as any).ws_console_error) {
            (window as any).ws_console_error(msg);
        }
        _error.apply(console, arguments);
    };

    // console.log = function (...x) {
    //     if ((window as any).ws_console_log) {
    //         (window as any).ws_console_log(logHandler(x));
    //     }
    //     _log.apply(console, arguments);
    // };
    // console.error = function (...x) {
    //     if ((window as any).ws_console_error) {
    //         (window as any).ws_console_error(logHandler(x));
    //     }
    //     _error.apply(console, arguments);
    // };
    //
    // const logHandler = (x) => {
    //     let logs = '';
    //     x.forEach(item => {
    //         logs += `${ JSON.stringify(item) }`;
    //     });
    //
    //     return logs;
    // };
}
