import { Injectable } from '@angular/core';
import { map, share } from 'rxjs/operators';
import { ConnectableObservable, Subject, timer } from 'rxjs';
import { MediaTile } from '@kuki/global/shared/types/tile';

@Injectable()
export class TimeProgressService {

    public timeProgress$ = timer(0, 5000).pipe(
        map(() => Date.now()), share()) as ConnectableObservable<number>;

    private liveTileChanged: Subject<MediaTile> = new Subject<MediaTile>();
    public liveTileChanged$ = this.liveTileChanged.asObservable();

    constructor() {
    }

    public liveTileChange(tile: MediaTile) {
        this.liveTileChanged.next(tile);
    }
}
