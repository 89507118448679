import { Inject, Injectable, Injector } from '@angular/core';
import { SubscriptionObject, SubscriptionObjectMethods } from '@kuki/global/shared/others/subscription/subscription-object';
import { hal } from '@kuki/platforms/hal';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { forkJoin, from, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { plugins } from '@kuki/platforms/plugins';
import { Plugin } from '@kuki/global/shared/types/general';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { ConnectionCheckService } from '@kuki/global/shared/services/connection-check.service';
import { WatchDogService } from '@kuki/global/shared/services/watch-dog.service';
import { UpgraderService } from '@kuki/global/shared/services/upgrader.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { PowerControlService } from '@kuki/global/shared/services/power-control.service';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { environment } from '@kuki/environments/environment';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { SelfTestService } from '@kuki/global/shared/modules/self-test/self-test.service';
import { SelfTestResult } from '@kuki/global/shared/modules/self-test/self-test';
import { CacheControlService } from '@kuki/global/shared/services/cache-control.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';

declare function ga(a, b, c?);

@Injectable()
export class StartupService {
    private subscription: SubscriptionObject = {};
    private readonly defaultBackOff = 30 * 1000;
    private readonly maxBackOff = 2 * 60 * 1000;
    private readonly incrementalBackOff = 1000;
    private ready: boolean;
    private onReady: Subject<void> = new ReplaySubject<void>(1);
    public onReady$: Observable<void> = this.onReady.asObservable();

    constructor(
        private coreService: CoreService,
        private settingsService: SettingsService,
        private connectionCheckService: ConnectionCheckService,
        private notificationService: NotificationService,
        private watchDogService: WatchDogService,
        private upgraderService: UpgraderService,
        private injector: Injector,
        private controllerService: ControllerService,
        private powerControlService: PowerControlService,
        private selfTestService: SelfTestService,
        private cacheControlService: CacheControlService,
        private translateService: TranslateService,
        private profileService: ProfileService,
        private generalService: GeneralService,
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        @Inject('MediaPlayerHalService') private mediaPlayerHalService: MediaPlayerHalInterface) {
    }

    public start() {
        console.log('App starting');
        console.log('Version: ' + environment.version);
        console.log('Build Version: ' + environment.versionBuild);
        if (this.mediaPlayerHalService.getPlayerVersion) {
            console.log('Media Player Version: ' + this.mediaPlayerHalService.getPlayerVersion());
        }
        // init translations
        this.coreService.initTranslations();
        // init hal layer
        this.platformHalService.init();
        // init controller
        this.controllerService.init({
            logUnknownKey: this.coreService.isTvPlatform()
        });
        // init connection check
        if (hal.cap.connectionCheck) {
            this.connectionCheckService.init();
        }
        // init default settings
        this.settingsService.init();
        // init settings storing
        this.settingsService.initSettingsStoring();
        // init cache control
        this.cacheControlService.init();
        this.registerStartupExit();
        this.watchStartupConnection();
        return this.platformHalService.deviceReady$.pipe(
            switchMap(() => {
                return hal.cap.testTimeOnStartup ? from(this.selfTestService.selfTestTime.run(environment.apiUrl + 'get-time')) : of(null);
            }),
            switchMap((selfTestTimeResult: SelfTestResult) => {
                if (selfTestTimeResult) {
                    if (selfTestTimeResult.state !== 'ok') {
                        this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.WRONG_TIME'));
                    }
                }
                this.settingsService.loadSettingsFromPlatform();
                this.initPlugins();
                if (hal.cap.upgrader) {
                    this.upgraderService.init();
                }
                if (hal.cap.watchDog) {
                    this.watchDogService.init(hal.cap.watchDogUrl);
                }
                return this.coreService.authenticate();
            }),
            switchMap(() => forkJoin([ this.coreService.fetchPortalSettings(), this.coreService.fetchLanguages() ])),
            switchMap(() => {
                const activeProfile = this.profileService.getActiveProfile();
                // if profile active and has locale, it has been already loaded
                if (!activeProfile?.locale) {
                    // the lang to use, if the lang isn't available, it will use the current loader to get them
                    return this.translateService.use(this.settingsService.getParsedSettingsValue<string>('nbx.stblang'));
                }
                return of(undefined);
            }),
            tap(() => {
                this.unregisterStartupExit();
                this.ready = true;
                // init meta
                this.coreService.initMeta();
                this.onReady.next();
                // init power control
                this.powerControlService.init();
                // init media player hal if neccessary
                if (hal.mediaPlayer.initOnStartup) {
                    this.mediaPlayerHalService.initOnStartup();
                }
                this.coreService.initWsLog();
                SubscriptionObjectMethods.clearSubscriptions(
                    this.subscription.connectionUp);
            }), tap(() => {
                this.clearBackOff();
            }), catchError((error) => {
                setTimeout(() => {
                    console.log('auth failed, restarting...');
                    this.platformHalService.restart();
                }, this.getBackOffInterval());
                this.onReady.error(new Error());
                return throwError(error);
            })
        );
    }

    public isReady() {
        return this.ready;
    }

    private initPlugins() {
        if (!plugins) {
            return;
        }
        plugins.forEach(plugin => {
            const pluginService = this.injector.get<Plugin>(plugin);
            pluginService.init();
        });
    }

    private registerStartupExit() {
        if (!this.coreService.isTvPlatform() || hal.platform === 'TV.ARRIS') {
            return;
        }
        this.controllerService.registerGlobalKey(CommonKeys.GRP_BACK, () => {
            this.clearBackOff();
            this.platformHalService.exitApp();
        });
    }

    private unregisterStartupExit() {
        this.controllerService.unregisterGlobalLevel();
    }

    private watchStartupConnection() {
        this.subscription.connectionUp = this.connectionCheckService.connectionUp$.subscribe(() => {
            if (this.platformHalService.restart) {
                this.clearBackOff();
                this.platformHalService.restart();
            }
        });
    }

    private getBackOffInterval() {
        if (!this.platformHalService.loadValue || !this.platformHalService.storeValue) {
            return this.defaultBackOff + Math.floor(Math.random() * this.defaultBackOff);
        }
        const storedInterval = this.platformHalService.loadValue('expBackoff');
        const interval = storedInterval ?
            Math.min(2 * (+storedInterval), this.maxBackOff) :
            this.incrementalBackOff + Math.floor(Math.random() * this.incrementalBackOff);
        this.platformHalService.storeValue('expBackoff', interval.toString());
        return interval;
    }

    private clearBackOff() {
        if (this.platformHalService.deleteValue) {
            this.platformHalService.deleteValue('expBackoff');
        }
    }
}
