import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';

@Component({
    selector: 'app-arrow-back',
    templateUrl: './arrow-back.component.html'
})
export class ArrowBackComponent implements OnInit {

    constructor(
        private navigationService: NavigationService) {
    }

    ngOnInit() {
    }

    onClick() {
        this.navigationService.navigateBack();
    }
}
