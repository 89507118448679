import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Media } from '@kuki/global/shared/types/media';
import { Tags } from '@kuki/global/shared/types/enum';

@Component({
    selector: 'app-labels-panel',
    templateUrl: './labels-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsPanelComponent {

    @Input() media: Media;
    @Input() showTags: boolean = true;

    public tags: Array<Tags>;
}
