import { hal } from '@kuki/platforms/hal';
import { res } from '@kuki/platforms/tv/res';
import { UI_VARIABLES_BROWSER } from '@kuki/platforms/web/_ui.variables.browser';
import { UI_VARIABLES_ARRIS_1080 } from '@kuki/platforms/tv/arris/_ui.variables.arris-1080';
import { UI_VARIABLES_ARRIS } from '@kuki/platforms/tv/arris/_ui.variables.arris';
import { UI_VARIABLES_TV } from '@kuki/platforms/tv/_ui.variables.tv';

export abstract class UiHelper {

    private static readonly vwPrecision: number = 100;
    private static readonly pxPrecision: number = 1;
    public static resolution = UiHelper.getResolution();
    public static buildResolution: number = res;
    public static windowWidth = window.innerWidth;
    public static windowHeight = window.innerHeight;

    private static platformMinRes = {
        'WEB': 992
    };

    static getColumnsWidth(columns: number) {
        const variables = this.getVariables();
        return (columns * variables.gridColumn) + ((columns - 1) * variables.gridGutter);
    }

    static getColumnsWithGutter(columns: number) {
        const variables = this.getVariables();
        return columns * (variables.gridColumn + variables.gridGutter);
    }

    static getColumnsWithOffset(columns: number) {
        const variables = this.getVariables();
        return columns * (variables.gridColumn + variables.gridGutter) + variables.flush;
    }

    static getColumnsVwWidth(columns: number, xxlLimit: boolean = true) {
        if (xxlLimit && this.resolutionWeb === 'xxl') {
            return this.getColumnsWidth(columns);
        }
        const variables = this.getVariables();
        return columns * this.getVw(variables.gridColumn, variables.relativeWidth) +
            ((columns - 1) * this.getVw(variables.gridGutter, variables.relativeWidth));
    }

    static getProperty(uhdValue: number) {
        switch (this.buildResolution as number) {
            case 720:
                return Math.floor(uhdValue / 3);
            case 1080:
                return Math.floor(uhdValue / 2);
        }
        return uhdValue;
    }

    static getGutter() {
        return this.getVariables().gridGutter;
    }

    static getRowsHeight(rows: number) {
        const variables = this.getVariables();
        return rows * variables.baseline;
    }

    static get gridGutter() {
        const variables = this.getVariables();
        return variables.gridGutter;
    }

    public static getResolution() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            return 'hdready';
        } else {
            return 'fhd';
        }
    }

    static get resolutionWeb() {
        const windowWidth = window.innerWidth;
        if (windowWidth >= 1400) {
            return 'xxl';
        }
        if (windowWidth >= 992) {
            return 'lg';
        }
        if (windowWidth >= 768) {
            return 'md';
        }
        if (windowWidth >= 576) {
            return 'sm';
        }
        return 'xs';
    }

    static get browserVariableKey() {
        let variableRes = 0;
        // TODO: now just force refresh, but better should be recalculation after resolution changed
        this.windowWidth = window.innerWidth;
        Object.keys(UI_VARIABLES_BROWSER.res).forEach(key => {
            const keyRes = +key;
            if (this.windowWidth > keyRes && keyRes > variableRes) {
                variableRes = keyRes;
            }
        });
        return variableRes;
    }

    static getVariables() {
        if (hal.platform === 'TV.ARRIS') {
            if ((this.buildResolution as number) === 1080) {
                return UI_VARIABLES_ARRIS_1080;
            } else {
                return UI_VARIABLES_ARRIS;
            }
        } else if ([ 'WEB', 'MOBILE.ANDROID', 'MOBILE.IOS' ].indexOf(hal.platform) >= 0) {
            const maxRes = this.browserVariableKey;
            return maxRes ? UI_VARIABLES_BROWSER.res[ maxRes ] : UI_VARIABLES_BROWSER.default;
        } else {
            return UI_VARIABLES_TV[ this.buildResolution ];
        }
    }

    static getPerFromValue(value, from) {
        return (value / from) * 100;
    }

    static getValueFromPer(per, from) {
        return from * (per / 100);
    }

    static getVw(target: number, resolution?: number) {
        resolution = resolution !== undefined ? resolution : this.getVariables().relativeWidth;
        return Math.ceil((target / (resolution / 100) * this.vwPrecision)) / this.vwPrecision;
    }

    static getPxFromVw(target: number, resolution?: number) {
        resolution = resolution !== undefined ? resolution : this.getVariables().relativeWidth;
        return Math.ceil((target * (resolution / 100) * this.pxPrecision)) / this.pxPrecision;
    }

    static getResponsiveValue(config: any, platformMinRes = this.platformMinRes[ hal.platform ] || 0) {
        // TODO: now just force refresh, but better should be recalculation after resolution changed
        this.windowWidth = window.innerWidth;
        if (!config) {
            return;
        }
        const maxRes = Math.max(this.getResponsiveKey(config.res), platformMinRes);
        return maxRes ? config.res[ maxRes ] : config.default;
    }

    static getResponsiveKey(resObject: any) {
        if (!resObject) {
            return;
        }
        let maxRes = 0;
        Object.keys(resObject).forEach(key => {
            const resKey = parseInt(key, 10);
            if (UiHelper.windowWidth >= resKey && maxRes < resKey) {
                maxRes = resKey;
            }
        });
        return maxRes;
    }

    public static resize() {
        this.resolution = UiHelper.getResolution();
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }
}
