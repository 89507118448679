import { Injectable, NgZone } from '@angular/core';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { ConnectableObservable, Observable, ReplaySubject, Subject } from 'rxjs';
import { HalFeature } from '@kuki/global/shared/types/general';
import { BootModeTypes } from '@kuki/global/shared/types/enum';
import { publish, publishReplay, refCount } from 'rxjs/operators';

declare var cordova: any;
declare var device: any;
declare var Connection: any;

@Injectable()
export class IosPlatformHalService implements PlatformHal {

    private readonly serialKey = 'kuki-sn';

    // TODO: check and maybe rework to this
    private deviceReady: ReplaySubject<void> = new ReplaySubject<void>(1);
    public deviceReady$: Observable<void> = this.deviceReady.asObservable();
    private appStateChanged: Subject<string> = new Subject<string>();
    public appStateChanged$: ConnectableObservable<string> = this.appStateChanged
        .pipe(publish(), refCount()) as ConnectableObservable<string>;
    public features: HalFeature = {
        hevc: true,
        wv: false
    };

    private deviceData: {
        sn: string;
        deviceName: string,
    };

    constructor(private ngZone: NgZone) {
    }

    public init() {
        console.log(`Kuki @ ${ device.model } / ${ device.manufacturer }`);
        cordova.exec(
            (data) => {
                this.deviceData = data;
                if (!localStorage.getItem(this.serialKey) && data.sn) {
                    localStorage.setItem(this.serialKey, data.sn);
                }
                console.log('ShakaPlayerPlugin initPlugin success');
                this.ngZone.run(() => {
                    this.deviceReady.next();
                    this.deviceReady.complete();
                });
            },
            () => console.error('ShakaPlayerPlugin initPlugin error'),
            'ShakaPlayerPlugin',
            'initPlugin'
        );
    }

    public getProductName(): string {
        return device.model || device.manufacturer || '';
    }

    public getMac(): string {
        return '00:00:00:00:00:00';
    }

    public getVersionFw(): string {
        return `${ device.platform } ${ device.version }` || '1.0';
    }

    public getBootMode(): BootModeTypes {
        return BootModeTypes.UNKNOWN;
    }

    public getSerial(): string {
        let serial = localStorage.getItem(this.serialKey);
        if (!serial) {
            serial = this.generateSerial();
        }
        return serial;
    }

    public getDeviceModel(): string {
        return `${ device.manufacturer || '' } ${ device.model || '' }`;
    }

    public getClaimedDeviceId(): string {
        return `${ device.serial } $ ${ device.uuid }`;
    }

    public getUserLabel(): string {
        return this.deviceData?.deviceName;
    }

    private generateSerial(): string {
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let serial = 'kuki2.0_';
        for (let i = 0; i < 56; i++) {
            serial += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        localStorage.setItem(this.serialKey, serial);
        return serial;
    }

    public checkConnection() {
        const connectionType = (navigator as any).connection.type;
        if ([ Connection.ETHERNET ].indexOf(connectionType) >= 0) {
            return 'ok';
        } else if ([ Connection.WIFI,
            Connection.CELL_2G,
            Connection.CELL_3G,
            Connection.CELL_4G,
            Connection.CELL ].indexOf(connectionType) >= 0) {
            return 'wireless';
        }
        return 'fail';
    }

    public getConnectionType() {
        const connectionType = (navigator as any).connection.type;
        if (connectionType === Connection.WIFI) {
            return 'wifi';
        } else if (connectionType === Connection.ETHERNET) {
            return 'ethernet';
        } else if ([
            Connection.CELL_2G,
            Connection.CELL_3G,
            Connection.CELL_4G,
            Connection.CELL ].indexOf(connectionType) >= 0) {
            return 'cell';
        }
    }

    public restart() {
        (window as any).location = 'index.html';
    }

    public reboot() {
        (window as any).location = 'index.html';
    }

    public getFeatures() {
        return this.features;
    }

    public share(link: string) {
        cordova.exec(() => {
                console.log('share success');
            }, () => {
                console.log('share error');
            }, 'ShakaPlayerPlugin',
            'share',
            [ link ]);
    }

    public onAppPause() {
        this.appStateChanged.next('appPause');
    }

    public onAppResume() {
        this.appStateChanged.next('appResume');
    }

    public setStopVideoOnClose(stopVideoOnClose: boolean) {
        cordova.exec(() => {
                console.log('stopVideoOnClose success');
            }, () => {
                console.log('stopVideoOnClose error');
            }, 'ShakaPlayerPlugin',
            'stopVideoOnClose',
            [ stopVideoOnClose ]);
    }

    public exitApp() {
        (navigator as any).app.exitApp();
    }
}
