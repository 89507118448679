import { Inject, Injectable } from '@angular/core';
import { Vod } from '@kuki/global/shared/types/media';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { VodService } from '@kuki/global/shared/modules/media/vod.service';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { NotificationTypes } from '@kuki/global/shared/types/notification';
import { VodTile } from '@kuki/global/shared/types/tile';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ShoppingService {

    constructor(
        @Inject('ModalsService') private modalsService: ModalsInterface,
        private vodService: VodService,
        private httpClient: HttpClient,
        private notificationService: NotificationService,
        private translateService: TranslateService) {
    }

    public buyVod(tile: VodTile) {
        if (!tile.own) {
            return of(null);
        }
        if (tile.own.transaction) {
            return of(true);
        }
        if (tile.own.isFree || tile.own.subscription) {
            return this.buyVodRequest(tile.id).pipe(tap(() => this.onVodBuySuccess(tile)));
        }
        return from(this.modalsService.openPasswordModal()).pipe(switchMap((password) => {
            return this.buyVodRequest(tile.id, password);
        }), tap(() => this.onVodBuySuccess(tile)), catchError(() => of(null)));
    }

    private buyVodRequest(id: number, password?: string): Observable<Vod> {
        const data = {};
        if (password) {
            data[ 'password' ] = password;
        }
        return this.httpClient.post(`${ environment.apiUrl }vod/${ id }/buy`, data)
            .pipe(switchMap(() => {
                return this.vodService.getVod(id);
            }));
    }

    private onVodBuySuccess(tile: VodTile) {
        if (tile.raw.price > 0) {
            this.notificationService.show(
                this.translateService.instant('NOTIFICATIONS.SHOP.VOD_BUY_SUCCESS_MESSAGE'),
                { type: NotificationTypes.MESSAGE });
        }
    }
}
