import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { Npvr } from '@kuki/global/shared/types/media';
import { map, tap } from 'rxjs/operators';
import { NpvrStats } from '@kuki/global/shared/types/state/npvr-stats';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { NpvrTile } from '@kuki/global/shared/types/tile';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';

@Injectable()
export class NpvrService {

    private npvrStats: NpvrStats;
    private npvrStatsUpdated: ReplaySubject<void> = new ReplaySubject<void>(1);
    public npvrStatsUpdated$: Observable<void> = this.npvrStatsUpdated.asObservable();

    public npvrFullness$: Observable<number> = this.npvrStatsUpdated$.pipe(map(() => this.getNpvrFullness()));

    // value is epg entity ID
    public onRecordChanged$: Subject<number> = new Subject<number>();

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService) {
    }

    public getNpvrs(): Observable<Array<NpvrTile>> {
        return this.httpClient.get<Array<NpvrTile>>(environment.apiUrl + 'npvr-board');
    }

    public getNpvr(npvrId: number) {
        return this.httpClient
            .get<Npvr>(environment.apiUrl + 'npvr/' + npvrId);
    }

    public getNpvrStats(): NpvrStats {
        return this.npvrStats;
    }

    public fetchNpvrStats() {
        return this.httpClient.get<NpvrStats>(environment.apiUrl + 'npvr/stats')
            .pipe(tap(npvrStats => {
                this.npvrStats = npvrStats;
                this.npvrStatsUpdated.next();
            }));
    }

    public getNpvrFullness() {
        if (!this.npvrStats || this.npvrStats.totalMins === 0) {
            return 0;
        }
        const result = (this.npvrStats.usedMins / this.npvrStats.totalMins) * 100;
        if (result > 1) {
            return Math.floor(result);
        } else if (result > 0) {
            return 1;
        }
        return 0;
    }

    public createRecord(id: number) {
        return this.httpClient.post(`${ environment.apiUrl }epg-entity/${ id }/record`, undefined).pipe(
            map(() => {
                return 1;
            }));
    }

    public deleteRecord(id: number) {
        return this.httpClient.delete(`${ environment.apiUrl }epg-entity/${ id }/record`).pipe(
            map(() => {
                return -1;
            }));
    }

    public clearNpvrStats() {
        this.npvrStats = null;
        this.npvrStatsUpdated.next(null);
    }
}
