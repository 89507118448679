import { Injectable } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Injectable()
export class WsLogService {

    private prefix: string;
    private subscription: SubscriptionObject = {};
    private readonly IGNORE_PATTERNS = [
        'isConnectedToGateway message data',
        'getActiveConnectionType message data'
    ];

    constructor(
        private authService: AuthService) {
    }

    public init(logserver) {
        if ((window as any).ws_log_socket) {
            this.ws_console_log = null;
            this.ws_console_error = null;
            (window as any).ws_log_socket.complete();
            SOM.clearSubscriptions(this.subscription.wsLogSocket);
        }

        if (window[ 'WebSocket' ]) {
            (window as any).ws_log_socket = webSocket({
                url: `ws://${ logserver }/logit`,
                serializer: (msg: string) => msg
            });
            this.subscription.wsLogSocket = (window as any).ws_log_socket.subscribe();
            const vispcode = this.authService.getAuthData().visp_code;
            this.prefix = `${ this.authService.serial.replace('.', '_') }.${ vispcode }: `;

            (window as any).ws_console_log = this.ws_console_log;
            (window as any).ws_console_error = this.ws_console_error;
        } else {
            console.error('WebSocket support not available - will not log');
        }
    }

    private ws_console_log = (msg: string) => {
        try {
            if (this.IGNORE_PATTERNS.some(pattern => msg.indexOf(pattern) >= 0)) {
                return;
            }
            if ((window as any).ws_log_socket) {
                (window as any).ws_log_socket.next(`${ this.prefix } LOG: ${ JSON.stringify(msg) }`);
            }
        } catch (e) {
            console.warn(e);
        }
    };

    private ws_console_error = (msg) => {
        try {
            if ((window as any).ws_log_socket) {
                (window as any).ws_log_socket.next(`${ this.prefix } ERROR: ${ JSON.stringify(msg) }`);
            }
        } catch (e) {
            console.warn(e);
        }
    };
}
