import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ProfileService } from '@kuki/global/shared/services/profile.service';

@Directive({
    selector: '[appProfileType]'
})
export class ProfileTypeDirective implements OnInit, OnChanges, OnDestroy {
    @Input() appProfileType: Array<string>;
    @Input() appProfileTypeElse: TemplateRef<any>;
    @Input() appProfileTypeNot = false;
    @Input() appProfileTypeAdmin = true;

    private hasView = false;
    private subscription: SubscriptionObject = {};

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private changeDetectorRef: ChangeDetectorRef,
        private profileService: ProfileService,
        private coreService: CoreService) {
    }

    ngOnInit() {
        this.subscription.onProfileChanged = this.profileService.onProfileChanged$.subscribe(() => {
            this.check();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('appProfileType')) {
            this.check();
        }
    }

    private check() {
        const success = this.coreService.isProfileTypeActive(...this.appProfileType);
        const condition = this.appProfileTypeNot ? !success : success || (this.appProfileTypeAdmin && !this.coreService.isProfileActive());
        if (condition && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!condition) {
            if (this.appProfileTypeElse) {
                this.viewContainer.createEmbeddedView(this.appProfileTypeElse);
            } else if (this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        }
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
