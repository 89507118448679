import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SOM } from '@kuki/global/shared/others/subscription/subscription-object';

@Injectable()
export class WatchDogService {

    private watchDogSubscription: Subscription;

    constructor(
        private httpClient: HttpClient,
        private ngZone: NgZone) {
    }

    public init(watchDogUrl: string) {
        this.ngZone.runOutsideAngular(() => {
            this.watchDogSubscription = timer(0, 1000 * 30).pipe(
                switchMap(() => this.pingWatchdog(watchDogUrl))
            ).subscribe();
        });
    }

    private pingWatchdog(watchDogUrl: string) {
        console.log('pingWatchdog: ' + watchDogUrl);
        return this.httpClient.get(watchDogUrl);
    }

    public destroy() {
        SOM.clearSubscriptions(this.watchDogSubscription);
    }
}
