import { Injectable } from '@angular/core';
import * as bowser from 'bowser';

@Injectable()
export class NavigatorService {

    public isSafari() {
        const browser = this.getBrowser();
        return browser.name === 'Safari';
    }

    public isIphone() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    public isAndroidPhone() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('android') >= 0;
    }

    public isIE() {
        const ua = navigator.userAgent;
        return ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./);
    }

    public getBrowser() {
        return bowser.getParser(window.navigator.userAgent).getBrowser();
    }
}
