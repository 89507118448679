import { Injectable, NgZone } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MediaTile, Tile, BtnTile } from '@kuki/global/shared/types/tile';
import { DashboardNavParams, TileRow } from '@kuki/global/shared/types/general';
import { tap } from 'rxjs/operators';
import { hal } from '@kuki/platforms/hal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NavState } from '@kuki/global/shared/types/state/nav-state';
import { HistoryService } from '@kuki/global/shared/services/history.service';
import { CacheControlService } from '@kuki/global/shared/services/cache-control.service';
import { Actions, MediaTypes, TileTypes } from '@kuki/global/shared/types/enum';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { Location } from '@angular/common';

@Injectable()
export class NavigationService {
    // TODO: Check initial false on web platform
    public preventStoreNavState: boolean = false;
    public navigationOnPop: boolean = false;

    public onHistoryUndo: Subject<void> = new Subject<void>();
    public onHistoryUndo$: Observable<void> = this.onHistoryUndo.asObservable().pipe(tap(() => {
        this.preventStoreNavState = true;
    }));

    public onHistoryRedo: Subject<void> = new Subject<void>();
    public onHistoryRedo$: Observable<void> = this.onHistoryRedo.asObservable().pipe(tap(() => {
        this.preventStoreNavState = true;
    }));

    private onRequestStorePageState: Subject<any> = new Subject<any>();
    public onRequestStorePageState$: Observable<any> = this.onRequestStorePageState.asObservable();

    private onResetRequest: Subject<void> = new Subject<void>();
    public onResetRequest$: Observable<void> = this.onResetRequest.asObservable();

    private onExitRequest: Subject<void> = new Subject<void>();
    public onExitRequest$: Observable<void> = this.onExitRequest.asObservable();

    private onEpqRequest: Subject<void> = new Subject<void>();
    public onEpgRequest$: Observable<void> = this.onEpqRequest.asObservable();

    private onDashboardSetup: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public onDashboardSetup$: Observable<any> = this.onDashboardSetup.asObservable();

    public resetBeforeExit: boolean = false;
    public resetEpg: boolean = false;

    private dashboardNavParams: DashboardNavParams;

    constructor(
        private ngZone: NgZone,
        private router: Router,
        private historyService: HistoryService,
        private location: Location,
        private componentRegisterService: ComponentRegisterService,
        private cacheControlService: CacheControlService) {
    }

    public navigateOnTile(params: { tile: Tile, tileRow?: TileRow }, state?: {}) {
        if ([ TileTypes.BUTTON, TileTypes.CHANNEL ].indexOf(params.tile.type) >= 0) {
            return this.navigateOnAction(params.tile.action, params, state);
        } else if (params.tile.type === TileTypes.MEDIA) {
            return this.navigateOnMediaType((params.tile as MediaTile).mediaType, params.tile, state);
        }
    }

    public navigateOnAction(action: Actions, data: any = {}, state = {}, extra = {}): Promise<void> {
        const navigatePromise = this.getNavigateByAction(action, data, extra);
        if (navigatePromise) {
            if (state) {
                this.historyService.addToSave(state);
            }
            return navigatePromise;
        }
        return Promise.resolve();
    }

    public navigateOnMediaType(mediaType: MediaTypes, tile: Tile, state = {}): Promise<any> {
        const navigateUrl = this.getNavigateUrlByMediaType(mediaType, tile);
        if (navigateUrl) {
            if (state) {
                this.historyService.addToSave(state);
            }
            return this.router.navigate([ navigateUrl ]);
        }
        return Promise.resolve();
    }

    public navigateBack(preventClear: boolean = false) {
        this.ngZone.run(() => {
            const history = this.historyService.getHistory();
            // clearing process
            if (!history || !history.past || history.past.length === 0) {
                this.clearNavHistory();
                if ([ '/wizard/select-lang', '/wizard/login', '/profile' ].indexOf(this.router.url) >= 0) {
                    if (hal.appMode === 'TV' || ['MOBILE.ANDROID', 'MOBILE.IOS'].indexOf(hal.platform) >= 0) {
                        this.onExitRequest.next();
                    }
                } else if (this.router.url === '/' && !preventClear) {
                    if (hal.appMode === 'TV' || ['MOBILE.ANDROID', 'MOBILE.IOS'].indexOf(hal.platform) >= 0) {
                        if (this.resetBeforeExit) {
                            this.resetBeforeExit = false;
                            this.onResetRequest.next();
                        } else {
                            this.onExitRequest.next();
                        }
                    }
                } else {
                    this.preventStoreNavState = true;
                    this.router.navigate([ '/' ]).then(() => {
                        // navigation cancel can preventStoreNavState, so therefore clearNavHistory is neccessary
                        this.clearNavHistory();
                    });
                }
            } else {
                // NOT USED - to prevent scroll jumps on old browsers
                if (hal.platform === 'MOBILE.IOS') {
                    this.location.back();
                } else {
                    const navigateBackUrl = history.past[ history.past.length - 1 ].nav.url;
                    this.preventStoreNavState = true;
                    if(navigateBackUrl == '/') preventClear = false;
                    if (preventClear) {
                        this.cacheControlService.delete(navigateBackUrl)
                    }
                    this.router.navigateByUrl(navigateBackUrl);
                    this.onHistoryUndo.next();
                }
            }
        });
    }

    public navigateHome(clearHistory = true) {
        if (clearHistory) {
            this.historyService.clear();
            this.preventStoreNavState = true;
        }
        return this.router.navigate([ '/' ]);
    }

    public navigateEpg(extra: any = {}) {
        this.dashboardNavParams = {
            activeSection: 'epg'
        };
        this.onEpqRequest.next();
        this.onDashboardSetup.next(this.dashboardNavParams);
        this.dashboardNavParams = undefined;
        return this.router.navigate([ '/' ], extra);
    }

    public navigateTileRow(tileRowGuid: string, extra: any = {}) {
        this.onDashboardSetup.next({ activeTileRow: tileRowGuid });
        return this.router.navigate([ '/' ], extra);
    }

    public navigateWizard() {
        return this.router.navigate([ '/wizard' ]);
    }

    public navigateSearch(params = {}) {
        return this.router.navigate([ '/search', params ]);
    }

    public navigateSettings(section: string) {
        return this.router.navigate([ '/settings/' + section ]);
    }

    public navigateVod() {
        return this.router.navigate([ '/media-board/vod' ]);
    }

    public navigateSelfTest(returnBack: boolean = false) {
        const params = returnBack ? { returnBack: true } : {};
        return this.router.navigate([ '/self-test', params ]);
    }

    public navigateDashboardManagement() {
        return this.router.navigate([ '/dashboard-management' ]);
    }

    public navigateDeviceInfo() {
        return this.router.navigate([ '/settings/device/device-info' ]);
    }

    public navigateSelectProfile(clearHistory = true, params = {}) {
        if (clearHistory) {
            this.historyService.clear();
            this.preventStoreNavState = true;
        }
        return this.router.navigate([ '/profile', params ]);
    }

    public navigateCreateProfile() {
        return this.router.navigate([ '/profile/create' ]);
    }

    public navigateUpdateProfileTv(profileId: number, type?: string) {
        if (!profileId) {
            return this.navigateHome();
        }
        if (type) {
            return this.router.navigate([ '/profile/', type, { profileId: profileId, edit: true } ]);
        } else {
            return this.router.navigate([ '/settings/profile', { profileId: profileId } ]);
        }
    }

    public navigateUpdateProfileWebMobile(profileId: number, type?: string) {
        if (type) {
            return this.router.navigate([ '/profile/', type, { profileId: profileId, edit: true } ]);
        } else {
            return this.router.navigate([ '/settings', { profileId: profileId } ]);
        }
    }

    public navigateSelectProfileType(params: any = {}, extra?: NavigationExtras) {
        return this.router.navigate([ '/profile/type', params ], extra);
    }

    public navigateSelectProfileIcon(params: any = {}, extra?: NavigationExtras) {
        return this.router.navigate([ '/profile/icon', params ], extra);
    }

    public navigateMessage(messageId: number) {
        return this.router.navigate([ '/settings/profile/messaging', { messageId: messageId } ]);
    }

    public navigateRemoteControl(deviceId: number) {
        return this.router.navigate([ '/remote-control', deviceId ]);
    }

    public clearDashboardSetup() {
        this.onDashboardSetup.next(undefined);
    }

    public clearNavHistory() {
        this.historyService.clear();
    }

    public requestExit() {
        this.onExitRequest.next();
    }

    private getNavigateByAction(action: Actions, data: any = {}, extra = {}): Promise<any> {
        switch (action) {
            case Actions.SHOW_MEDIA_BOARD:
                if (!data || !data.tile || !data.tile.actionData) {
                    return;
                }
                return this.router.navigate([ '/media-board', data.tile.actionData.tileRowGuid ], extra);
            case Actions.SHOW_VOD_BOARD:
                return this.router.navigate([ '/media-board/vod' ], extra);
            case Actions.SHOW_NPVR_BOARD:
                return this.router.navigate([ '/media-board/npvr' ], extra);
            case Actions.SHOW_SERIAL_BOARD:
                return this.router.navigate([ '/media-board/series' ], extra);
            case Actions.SHOW_PROGRAM_BOARD:
                if (!data || !data.tileRow || !data.tile) {
                    return;
                }
                return this.router.navigate([ '/program-board',
                    data.tileRow.id,
                    data.tile && data.tile.preselect ? data.tile.preselect : {} ], extra);
            case Actions.PLAY:
                if (!data) {
                    return;
                }
                return this.router.navigate([ '/media-player', data.mediaPlayerType, data.id, data.params ? data.params : {} ], extra);
            case Actions.SEARCH:
                return this.router.navigate([ '/search' ], extra);
            case Actions.SHOW_SETTINGS:
                if (data?.tile) {
                    return this.router.navigate([ '/settings', (data.tile as BtnTile).section ], extra);
                } else if (typeof data === 'string') {
                    return this.router.navigate([ '/settings', data ], extra);
                }
                return this.router.navigate([ '/settings' ]);
            case Actions.CREATE_PROFILE:
                return this.router.navigate([ '/profile/create' ], extra);
            case Actions.SHOW_EPG:
                return this.navigateEpg();
            case Actions.GO_TO_TILES_ROW:
                return this.navigateTileRow(data.tile.actionData.tileRowGuid, extra);
            case Actions.GO_TO_VOD_BOUGHT:
                return this.navigateTileRow('vod', extra);
            case Actions.GO_TO_TODAY_TOP:
                return this.navigateTileRow('today-top', extra);
            case Actions.SHOW_DEVICES:
                return this.router.navigate([ '/settings/admin/device-management' ]);
            case Actions.SHOW_DASHBOARD_MANAGEMENT:
                return this.router.navigate([ '/dashboard-management' ]);
            case Actions.OPEN_URI:
                return this.router.navigateByUrl(data.tile.btnActionParam);
        }
    }

    private getNavigateUrlByMediaType(mediaType: MediaTypes, tile: Tile): string {
        switch (mediaType) {
            case MediaTypes.EPG_ENTITY:
                return `/media-detail/epg-entity/${ tile.id }`;
            case MediaTypes.SERIAL:
                return `/media-detail/serial/${ tile.id }`;
            case MediaTypes.VOD:
                return `/media-detail/vod/${ tile.id }`;
            case MediaTypes.NPVR:
                return `/media-detail/npvr/${ tile.id }`;
        }
    }

    public loadPresentState(url: string): any {
        if (!this.cacheControlService.cacheExists(url)) {
            return null;
        }
        const history = this.historyService.getHistory();
        if (history && history.present.nav && history.present.nav.url === url) {
            return history.present;
        }
        return null;
    }

    public storeNavState(url: string) {
        this.historyService.save({ nav: this.getNavState(url) });
    }

    public initNavState(state: NavState) {
        if (!state) {
            return;
        }
        window.scrollTo(0, state.scrollTop);
    }

    private getNavState(url: string): NavState {
        return {
            url: url,
            scrollTop: this.getScrollTop()
        };
    }

    public storePageState(url: string) {
        if (!this.preventStoreNavState) {
            this.onRequestStorePageState.next();
            this.storeNavState(url);
            this.cacheControlService.create(url);
        }
    }

    public addToPageState(state: {}) {
        this.historyService.addToSave(state);
    }

    public setupDashboardNavParams(params: DashboardNavParams) {
        this.dashboardNavParams = {
            ...this.dashboardNavParams,
            ...params
        };
    }

    public getScrollTop() {
        const doc = document.documentElement;
        return Math.ceil((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0));
    }

    public scrollTop() {
        window.scrollTo(0, 0);
    }
}
