import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Media } from '@kuki/global/shared/types/media';
import { environment } from '@kuki/environments/environment';


@Injectable()
export class EpisodeService {

    constructor(private httpClient: HttpClient) {
    }

    getEpisode(episodeId: number) {
        return this.httpClient
            .get<Media>(environment.apiUrl + 'episode/' + episodeId);
    }
}
