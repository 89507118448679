import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SubscriptionObject, SOM } from '@kuki/global/shared/others/subscription/subscription-object';

@Injectable()
export class LoaderService {

    private running: { [ key: string ]: BehaviorSubject<boolean> } = {};
    private runningCounterArray: Array<string> = [];
    private globalLoaderTimeout: any;

    private runningSnapshot: { [ key: string ]: boolean } = {};

    private subscription: SubscriptionObject = {};

    constructor() {
    }

    start(name) {
        this.isRunning(name).pipe(take(1)).subscribe(running => {
            if (!running) {
                this.registerLoader(name);
                this.running[ name ] ?
                    this.running[ name ].next(true) : this.running[ name ] = new BehaviorSubject(true);
                this.startGlobalLoader();
                this.createSnapshot(name);
            }
        });
    }

    done(name) {
        this.isRunning(name)
            .pipe(take(1)).subscribe(running => {
            if (running) {
                this.unregisterLoader(name);
                this.running[ name ] ?
                    this.running[ name ].next(false) : this.running[ name ] = new BehaviorSubject(false);

                this.doneGlobalLoader();
            }
        });
        this.destroySnapshot(name);
    }

    registerLoader(name) {
        if (this.runningCounterArray.indexOf(name) === -1) {
            this.runningCounterArray.push(name);
        }
    }

    unregisterLoader(name) {
        this.runningCounterArray = this.runningCounterArray.filter(loaderName => loaderName !== name);
    }

    someLoaderRunning() {
        return this.runningCounterArray.length > 0;
    }

    startGlobalLoader() {
        // this.ngProgress.start();
    }

    doneGlobalLoader() {
        clearTimeout(this.globalLoaderTimeout);
        this.globalLoaderTimeout = setTimeout(() => {
            // if (!this.someLoaderRunning()) {
            //     this.ngProgress.done();
            // }
        }, 200);
    }

    clearLoaders(loaders?: Array<string>) {
        this.runningCounterArray.forEach(loader => {
            if (loaders) {
                if (loaders.indexOf(loader) !== -1) {
                    this.done(loader);
                }
            } else {
                this.done(loader);
            }
        });
        this.doneGlobalLoader();
    }

    isRunning(name): BehaviorSubject<boolean> {
        this.running[ name ] = this.running[ name ] || new BehaviorSubject(false);
        return this.running[ name ];
    }

    isRunningSnapshot(name): boolean {
        return this.runningSnapshot [ name ];
    }

    createSnapshot(name) {
        if (this.running[ name ]) {
            SOM.clearSubscriptions(this.subscription[ name ]);
            this.subscription[ name ] = this.running[ name ].subscribe(running => {
            // TODO: CHECK THIS VARIANT
            // this.subscription[ name ] = this.isRunning(name).subscribe(running => {
                this.runningSnapshot [ name ] = running;
            });
        }
    }

    destroySnapshot(name) {
        SOM.clearSubscriptions(this.subscription[ name ]);
        this.runningSnapshot [ name ] = false;
    }

}
