import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-progress-button',
    templateUrl: './progress-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressButtonComponent implements OnInit, OnDestroy {

    @Input() label: string;
    @Input() duration: number;
    @Output() complete: EventEmitter<void> = new EventEmitter<void>();

    public progress: number = 0;
    private requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame;
    private cancelAnimationFrame = window.cancelAnimationFrame || window.webkitCancelAnimationFrame;
    private animationFrame: any;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        const now = Date.now();
        const progressAnimation = () => {
            const delta = Date.now() - now;
            this.progress = Math.min((delta / this.duration) * 100, 100);
            this.changeDetectorRef.detectChanges();
            if (delta >= this.duration) {
                this.cancelAnimationFrame(this.animationFrame);
                this.complete.emit();
                return;
            }
            this.animationFrame = this.requestAnimationFrame(progressAnimation);
        };
        this.animationFrame = this.requestAnimationFrame(progressAnimation);
    }

    public getStyle() {
        return {
            width: this.progress + '%'
        };
    }

    ngOnDestroy() {
        this.cancelAnimationFrame(this.animationFrame);
    }
}
