import { HttpClient } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { MediaPlayerV2Component } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.component';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { CacheMapService } from '@kuki/global/shared/services/cache-map.service';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { AudioTrack, Cert, SubtitleTrack } from '@kuki/global/shared/types/general';
import { MediaPlayerError, MediaPlayerTypes } from '@kuki/global/shared/types/media-player';
import { TizenPlatformHalService } from '@kuki/platforms/tv/tizen/tizen-platform-hal.service';
import { deployUrl } from '@kuki/root/deploy-url';
import { BehaviorSubject, ReplaySubject, Subject, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

declare var webapis: any;

export class TizenPlayerV2Service implements MediaPlayerHalInterface {
    public readonly hevc = true;
    public readonly nativeBuffer = false;

    private videoPlayer: any;
    private naclInitialized: boolean;
    private expectStreamEndTimeout: any;
    private audioTracks: Array<AudioTrack> = [];
    private subtitleTracks: Array<SubtitleTrack> = [];
    private activeAudioTrack: AudioTrack;
    private activeSubtitleTrack: SubtitleTrack;

    public buffering$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public error$: Subject<MediaPlayerError> = new Subject<MediaPlayerError>();
    public tracksUpdated$?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public trackActivated$?: ReplaySubject<void> = new ReplaySubject<void>(1);

    private subscription: SubscriptionObject = {};

    private langMap = {
        cz: 'cze',
        en: 'eng',
        sl: 'slo',
        ru: 'rus',
        fr: 'fra',
        vi: 'vie',
        de: 'deu',
        po: 'pol',
        hu: 'hun',
        qa: 'qaa',
        sp: 'spa',
        da: 'dan',
        sw: 'swe',
        fi: 'fin'
    };

    private playerListener = {
        onbufferingstart: () => {
            console.log('buffering started');
            this.buffering$.next(true);
        },
        onbufferingprogress: (percent: number) => {
            console.log('on buffering: ' + percent);
        },
        onbufferingcomplete: () => {
            console.log('buffering complete');
            this.buffering$.next(false);
            this.onTracksChanged();
        },
        oncurrentplaytime: (time) => {
            console.log('playing time : ' + time);
            if (this.buffering$.getValue() === false) {
                this.onTracksChanged();
            }
        },
        onresolutionchanged: (width, height) => {
            console.log('resolution changed : ' + width + ', ' + height);
        },
        onstreamcompleted: () => {
            console.log('onstreamcompleted');
        },
        onerror: (eventType) => {
            console.log('event type error : ' + eventType);
        },
        onevent: (eventType, eventData) => {
            console.log('event type error : ' + eventType + ', data: ' + eventData);
        },
        ondrmevent: (drmEvent, drmData) => {
            console.log('DRM callback: ' + drmEvent + ', data: ' + drmData);
        },
    };

    constructor(
        private tizenPlatformHalService: TizenPlatformHalService,
        private generalService: GeneralService,
        private cacheMapService: CacheMapService,
        private componentRegisterService: ComponentRegisterService,
        private httpClient: HttpClient,
        private ngZone: NgZone) {

    }

    public initOnStartup() {
        const videoplane = document.createElement('object') as HTMLObjectElement;
        videoplane.id = 'videoplane';
        videoplane.type = 'application/avplayer';
        document.body.appendChild(videoplane);
        document.body.classList.add('tizen-2015');
        // const showdebug = root.$portal.getIsObject('nbx.show-debug')
        const embedWrapper = document.createElement('div') as HTMLElement;
        embedWrapper.id = 'nacl_listener';
        const embed = document.createElement('embed') as HTMLEmbedElement;
        embed.id = 'nacl_module';
        embed.type = 'application/x-nacl';
        embed.src = deployUrl + 'platforms/tv/tizen/proxy2/proxy2.nmf';
        embed.width = '0px';
        embed.height = '0px';
        // const logElement = this.renderer.createElement('script');
        // logElement.src = 'http://nxserver.netbox.cz:8891/js/remote.js?pica';
        embedWrapper.addEventListener('load', () => {
            console.log('NaCL loaded in appcomponent');
            (embed as any).postMessage('start');
            this.onNaclready();
        }, true);
        embedWrapper.addEventListener('message', (m: any) => {
            // console.log(m.data);

            if (m.data[ 0 ] === 'F') {
                console.log('got stream finish expectStreamEnd');
                this.expectStreamEnd();
            }
        }, true);
        embedWrapper.addEventListener('crash', (m) => {
            console.error('NaCL CRASH!!!');
            this.onNaclready();
        }, true);
        embedWrapper.appendChild(embed);
        document.body.appendChild(embedWrapper);
    }

    public init() {
        this.videoPlayer = webapis.avplay;
    }

    public play(streamUrl: string, params: { cert: Cert, proxy: boolean, pipe: string, cssClass?: string }) {
        return new Promise((resolve, reject) => {
            SOM.clearSubscriptions(this.subscription.play);
            // wait until naclInitialized ready
            this.ngZone.runOutsideAngular(() => {
                this.subscription.play = timer(0, 500).pipe(
                    filter(() => this.naclInitialized)).subscribe(() => {
                    SOM.clearSubscriptions(this.subscription.play);
                    this.cacheMapService.setCacheMap(params.cert.cache_map_chunk, params.cert.cache_map_playlist).toPromise().then(() => {
                        return this.doPlay(streamUrl, params.pipe);
                    }).then(() => {
                        resolve();
                    }).catch((e) => {
                        reject(e);
                    });
                });
            });
        });
    }

    public pause() {
        return new Promise((resolve) => {
            this.videoPlayer.pause();
            resolve();
        });
    }

    public stop() {
        return new Promise((resolve, reject) => {
            this.buffering$.next(false);
            try {
                this.videoPlayer.stop();
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }

    public activateAudioTrack(id: number) {
        let retries = 0;
        // we love shitsung
        SOM.clearSubscriptions(this.subscription.activateAudioTrack);
        this.ngZone.runOutsideAngular(() => {
            this.subscription.activateAudioTrack = timer(0, 500).subscribe(() => {
                const audioTrack = this.audioTracks.find(track => track.id === id);
                try {
                    this.videoPlayer.setSelectTrack(audioTrack.type, audioTrack.id);
                    this.activeAudioTrack = audioTrack;
                    this.trackActivated$.next();
                    SOM.clearSubscriptions(this.subscription.activateAudioTrack);
                } catch (e) {
                    console.error('Unable to activate component: ' + e);
                    retries++;
                }
            });
        });
    }

    public activateSubtitleTrack(id: number) {
        // doesnt work, not necessary
    }

    public refreshTracks() {
        this.activeAudioTrack = undefined;
        this.activeSubtitleTrack = undefined;
        this.audioTracks = [];
        this.subtitleTracks = [];
        this.tracksUpdated$.next(false);
        this.trackActivated$.next();
    }

    public getAudioTracks(): Array<AudioTrack> {
        return this.audioTracks;
    }

    public getSubtitleTracks(): Array<SubtitleTrack> {
        return this.subtitleTracks;
    }

    public getActiveAudioTrack(): AudioTrack {
        return this.activeAudioTrack;
    }

    public getActiveSubtitleTrack(): SubtitleTrack {
        return this.activeSubtitleTrack;
    }

    public getPlayerDebug(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.subscription.updateDebug) {
                SOM.clearSubscriptions(this.subscription.updateDebug);
            }
            this.subscription.updateDebug =
                this.httpClient.get<any>('http://127.0.0.1:7744/stats.json').subscribe((d) => {
                    resolve(
                        `${ d.last_chunk_speed }kbps / ${ d.avg_speed }kbps / ${ d.selected_profile }kbps / ${ d.cbuff_size }kB`);
                }, () => reject('?'), () => this.subscription.updateDebug = undefined);
        });
    }

    public onNaclready() {
        this.naclInitialized = true;
    }

    public expectStreamEnd() {
        if (this.expectStreamEndTimeout) {
            clearTimeout(this.expectStreamEndTimeout);
        }
        const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
        if (!mediaPlayerV2Component) {
            return;
        }
        if (this.tizenPlatformHalService.getDeviceModel().slice(0, 2) === 'UJ') {
            console.log('expectStreamEnd: 2015 model, immediate streamEnd');
            return mediaPlayerV2Component.mediaPlayerV2Service.stop();
        }

        if ([ MediaPlayerTypes.VOD, MediaPlayerTypes.NPVR ]
            .indexOf(mediaPlayerV2Component.mediaPlayerV2Service.getMediaPlayerType()) >= 0) {
            const curPos = mediaPlayerV2Component.mediaPlayerV2Service.getCursorTime();
            const mediaEnd = mediaPlayerV2Component.mediaPlayerV2Service.getMediaEnd();
            if (mediaEnd && curPos + 5000 < mediaEnd) {
                this.expectStreamEndTimeout = setTimeout(() => {
                    console.log(`expectStreamEnd: waiting for stream end ${ curPos } + 5000 < ${ mediaEnd }`);
                }, 500);
            } else {
                console.log('expectStreamEnd: this is end of stream');
                return mediaPlayerV2Component.mediaPlayerV2Service.stop();
            }
        } else {
            return mediaPlayerV2Component.mediaPlayerV2Service.stop();
        }
    }

    public destroy() {
        this.stop();
        try {
            this.videoPlayer.close();
        } catch {
        }
        if (this.expectStreamEndTimeout) {
            clearTimeout(this.expectStreamEndTimeout);
        }

        this.videoPlayer = undefined;
        this.expectStreamEndTimeout = undefined;
        this.audioTracks = [];
        this.subtitleTracks = [];
        this.activeAudioTrack = undefined;
        this.activeSubtitleTrack = undefined;

        this.buffering$ = new BehaviorSubject<boolean>(true);
        this.error$ = new Subject<MediaPlayerError>();
        this.tracksUpdated$ = new BehaviorSubject<boolean>(false);
        this.trackActivated$ = new ReplaySubject<void>(1);
        SOM.clearSubscriptionsObject(this.subscription);
    }

    public doPlay(streamUrl: string, pipe: string = '') {
        return new Promise((resolve, reject) => {
            this.stop();
            const playUrl = `http://127.0.0.1:7744/stream.ts?url=${ encodeURIComponent(streamUrl) }`;
            this.videoPlayer.open(playUrl + pipe);
            this.videoPlayer.setListener(this.playerListener);
            this.videoPlayer.setTimeoutForBuffering(5000);
            // TODO - startovat na poslednim znamem
            // prumernem nebo tak neco? neumi si to kram pamatovat sam
            // const bitRateString = 'STARTBITRATE=HIGHEST';
            // const bitRateString = 'BITRATES=1500000~3000000|STARTBITRATE=AVERAGE|SKIPBITRATE=LOWEST';
            // this.videoPlayer.setStreamingProperty('ADAPTIVE_INFO', bitRateString);
            // this.videoPlayer.setStreamingProperty('PREBUFFER_MODE', '5000');
            // this.videoPlayer.setBufferingParam('PLAYER_BUFFER_FOR_RESUME', 'PLAYER_BUFFER_SIZE_IN_SECOND', 2);

            this.videoPlayer.prepareAsync(() => {
                this.videoPlayer.setDisplayRect(0, 0, 1920, 1080);
                // console.log('PLAYER_DISPLAY_MODE_FULL_SCREEN');
                this.videoPlayer.setDisplayMethod('PLAYER_DISPLAY_MODE_FULL_SCREEN');
                try {
                    this.videoPlayer.play();
                } catch {
                    console.error('play failed');
                    reject();
                }
                resolve();
            }, () => {
                console.error('prepareAsync failed');
                reject();
            });
        });
    }


    private onTracksChanged() {
        const data = this.videoPlayer.getTotalTrackInfo();
        const audioTracks: Array<AudioTrack> = [];
        const subtitleTracks: Array<SubtitleTrack> = [];
        data.forEach(item => {
            switch (item.type) {
                case 'AUDIO':
                    const audioLang = this.mapTrackLanguage(this.getTrackLanguage(item));
                    const audioTrack: AudioTrack = {
                        id: item.index,
                        type: item.type,
                        label: audioLang,
                        language: audioLang,
                        channels: this.getChannelFormat(item)
                    };
                    switch (audioTrack.channels) {
                        case 6: {
                            audioTrack.layout = '5.1';
                            break;
                        }
                        default: {
                            audioTrack.layout = '2.0';
                            break;
                        }
                    }
                    audioTracks.push(audioTrack);
                    break;
                case 'TEXT':
                    const subtitleLang = this.mapTrackLanguage(this.getTrackLanguage(item));
                    const subtitleTrack: SubtitleTrack = {
                        id: item.index,
                        type: item.type,
                        label: subtitleLang,
                        language: subtitleLang
                    };
                    subtitleTracks.push(subtitleTrack);
            }
        });
        this.audioTracks = audioTracks;
        this.subtitleTracks = subtitleTracks;
        this.tracksUpdated$.next(true);
    }

    private getTrackLanguage(item) {
        if (!item.extra_info) {
            return '';
        }
        const parsedItem = JSON.parse(item.extra_info);
        return parsedItem.language || 'Stopa ' + (parsedItem.track_num || parsedItem.id || item.index);
    }

    private mapTrackLanguage(lang) {
        return this.langMap[ lang ] ? this.langMap[ lang ] : lang;
    }

    private getChannelFormat(item) {
        if (!item.extra_info) {
            return 0;
        }
        const parsedItem = JSON.parse(item.extra_info);
        if (parsedItem.channels) {
            const chf = parseInt(parsedItem.channels, 10);
            switch (chf) {
                case 1:
                    return 1;
                case 2:
                    return 3;
                case 6:
                    return 6;
                default:
                    return 3;
            }
        }
        return 0;
    }
}
