import { Actions } from '@kuki/global/shared/types/enum';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionButton } from '@kuki/global/shared/types/button';

export function getButtonsTemplates(translateService: TranslateService): {[key: string]: ActionButton } {
    return {
        PLAY: {
            label: translateService.instant(_('GENERAL.ACTION.PLAY')),
            action: Actions.PLAY,
            icon: 'icon-play-new',
        },
        PAUSE: {
            label: translateService.instant(_('GENERAL.ACTION.PAUSE')),
            action: Actions.PAUSE,
            icon: 'icon-pause-new'
        },
        PLAY_START_OVER: {
            label: translateService.instant(_('GENERAL.ACTION.START_OVER')),
            action: Actions.PLAY_START_OVER,
            icon: 'icon-start-over-new'
        },
        PLAY_LIVE: {
            label: translateService.instant(_('GENERAL.ACTION.PLAY_LIVE')),
            action: Actions.PLAY_LIVE,
            icon: 'icon-play-live'
        },
        PLAY_CONTINUING: {
            label: translateService.instant(_('GENERAL.ACTION.PLAY_CONTINUING')),
            action: Actions.PLAY_CONTINUING,
            icon: 'icon-play-new'
        },
        TELETEXT: {
            label: translateService.instant(_('GENERAL.ACTION.TELETEXT')),
            action: Actions.TELETEXT,
            icon: 'icon-teletext',
        },
        SHOW_MEDIA_DETAIL: {
            label: translateService.instant(_('GENERAL.ACTION.SHOW_MEDIA_DETAIL')),
            action: Actions.SHOW_MEDIA_DETAIL,
            icon: 'icon-detail',
        },
        RECORD: {
            label: translateService.instant(_('GENERAL.ACTION.RECORD')),
            action: Actions.RECORD,
            class: 'btn-record',
            icon: 'icon-record',
        },
        NOTIFY: {
            label: translateService.instant(_('GENERAL.ACTION.NOTIFY')),
            action: Actions.NOTIFY,
            class: 'btn-notify',
            icon: 'icon-notify',
        },
        FAVOURITE: {
            label: translateService.instant(_('GENERAL.ACTION.FAVOURITE')),
            action: Actions.FAVOURITE,
            class: 'btn-favourite',
            icon: 'icon-favourite',
        },
        BUY_VOD: {
            label: translateService.instant(_('GENERAL.ACTION.BUY_VOD')),
            action: Actions.BUY_VOD,
            icon: 'icon-buy'
        },
        HIDE_TILES_ROW: {
            label: translateService.instant(_('GENERAL.ACTION.HIDE_TILES_ROW')),
            action: Actions.HIDE_TILES_ROW,
            icon: 'icon-hide'
        },
        SHOW_TILES_ROW: {
            label: translateService.instant(_('GENERAL.ACTION.SHOW_TILES_ROW')),
            action: Actions.SHOW_TILES_ROW,
            icon: 'icon-show'
        },
        EDIT_PROFILE: {
            label: translateService.instant(_('GENERAL.ACTION.EDIT_PROFILE')),
            action: Actions.EDIT_PROFILE,
            icon: 'icon-pen',
        },
        DELETE_PROFILE: {
            label: translateService.instant(_('GENERAL.ACTION.DELETE_PROFILE')),
            action: Actions.DELETE_PROFILE,
            icon: 'icon-trash'
        },
        ACTIVATE_PROFILE: {
            label: translateService.instant(_('GENERAL.ACTION.ACTIVATE_PROFILE')),
            action: Actions.ACTIVATE_PROFILE,
            icon: 'icon-check-circle'
        },
        DEACTIVATE_PROFILE: {
            label: translateService.instant(_('GENERAL.ACTION.DEACTIVATE_PROFILE')),
            action: Actions.DEACTIVATE_PROFILE,
            icon: 'icon-remove-circle'
        },
        OPEN_TELEPORT_TO: {
            label: translateService.instant(_('GENERAL.ACTION.OPEN_TELEPORT_TO')),
            action: Actions.OPEN_TELEPORT_TO,
            icon: 'icon-teleport'
        },
        TELEPORT_TO: {
            action: Actions.TELEPORT_TO
        },
        TELEPORT_SWITCH: {
            label: translateService.instant(_('GENERAL.ACTION.TELEPORT_SWITCH')),
            action: Actions.TELEPORT_SWITCH,
            icon: 'icon-teleport'
        },
        REMOTE_CONTROL: {
            label: translateService.instant(_('GENERAL.ACTION.REMOTE_CONTROL')),
            action: Actions.REMOTE_CONTROL,
            icon: 'icon-remote'
        },
        SHARE: {
            label: translateService.instant(_('GENERAL.ACTION.SHARE')),
            action: Actions.SHARE,
            icon: 'icon-share'
        }
    };
}
