export enum MediaPlayerErrors {
    MISSING_CHANNEL_ID = 'MISSING_CHANNEL_ID',
    MISSING_CHANNEL = 'MISSING_CHANNEL',
    SESSION_PLAY_RESTRICTED = 'SESSION_PLAY_RESTRICTED',
    CELL_PLAY_RESTRICTED = 'CELL_PLAY_RESTRICTED',
    MISSING_NPVR_ID = 'MISSING_NPVR_ID',
    MISSING_NPVR = 'MISSING_NPVR',
    MISSING_EPISODE_ID = 'MISSING_EPISODE_ID',
    MISSING_EPISODE = 'MISSING_EPISODE',
    EPISODE_PLAYABLE_RESTRICTED = 'EPISODE_PLAYABLE_RESTRICTED',
    MISSING_VOD_ID = 'MISSING_VOD_ID',
    MISSING_VOD = 'MISSING_VOD',
    VOD_BOUGTH_RESTRICTED = 'VOD_BOUGTH_RESTRICTED',
    CANT_START_OVER = 'CANT_START_OVER',
    CANT_CHANGE_CHANNEL = 'CANT_CHANGE_CHANNEL',
    CANT_REFRESH_PLAYER = 'CANT_REFRESH_PLAYER',
    MISSING_SIGN_IDENT = 'MISSING_SIGN_IDENT',
    MISSING_CERT = 'MISSING_CERT',
    PLAY_RETRIES_RUN_OUT = 'PLAY_RETRIES_RUN_OUT',
    MISSING_CONTINUING_ENTITY = 'MISSING_CONTINUING_ENTITY',
    MEDIA_PLAYER_HAL_ERROR = 'MEDIA_PLAYER_HAL_ERROR',
    SIGN_FAILED = 'SIGN_FAILED',
    CANT_PLAY = 'CANT_PLAY',
    AUTOPLAY_FAILED = 'AUTOPLAY_FAILED',
    DRM_FAILED = 'DRM_FAILED',
    MEDIA_PLAYER_STOP_ERROR = 'MEDIA_PLAYER_STOP_ERROR'
}
