import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingService } from '@kuki/global/shared/modules/shopping/shopping.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        ShoppingService
    ]
})
export class ShoppingModule {
}
