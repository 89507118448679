import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageTypes } from '@kuki/global/shared/types/enum';
import { ProfileTile } from '@kuki/global/shared/types/tile';

@Component({
    selector: 'app-profile-icon',
    templateUrl: './profile-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileIconComponent {

    @Input() profile: ProfileTile;

    public ImageTypes = ImageTypes;
}
