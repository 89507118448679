import { ElementRef, Injectable } from '@angular/core';
import { animate, AnimationBuilder, AnimationFactory, style } from '@angular/animations';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { hal } from '@kuki/platforms/hal';
import { lock, unlock } from 'tua-body-scroll-lock';
import { SettingsService } from '@kuki/global/shared/services/settings.service';

@Injectable()
export class ScrollService {

    private requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame;

    constructor(
        private animationBuilder: AnimationBuilder,
        private settingsService: SettingsService,
        private generalService: GeneralService) {
    }

    public scrollVertical(el: ElementRef,
                          position: number,
                          duration: number = 300,
                          options: any = null,
                          mode: string = hal.animationType) {
        if (!el) {
            // TODO: maybe reject, but must be catched in every call
            return Promise.resolve();
        }
        const animations = this.settingsService.getParsedSettingsValue<boolean>('animations');
        if (!animations) {
            duration = 0;
        }
        if (duration === 0) {
            return this.scrollFixed(el, position, options);
        }
        switch (mode) {
            case 'anim':
                return this.scrollWappi(el, position, duration);
            case 'angular-anim':
                return this.scrollAngularAnim(el, position, duration);
            case 'request-animation-frame':
                return this.scrollRequestFrame(el, position, duration, options);
            case 'css-transition':
                return this.scrollTransition(el, position, duration, options);
            case 'js-timeout':
                return this.scrollJsTimeout(el, position, duration, options);
            case 'fixed':
                return this.scrollFixed(el, position, options);
            case 'fixed-abs':
                return this.scrollFixedAbs(el, position, options);
            case 'noop':
                return this.scrollNoop();
        }
        return Promise.reject();
    }

    public scrollHorizontal(el: ElementRef,
                            position: number,
                            duration: number = 300,
                            options: any = null,
                            mode: string = hal.animationType) {
        const animations = this.settingsService.getParsedSettingsValue<boolean>('animations');
        if (!animations) {
            duration = 0;
        }
        switch (mode) {
            case 'anim':
                return this.scrollWappi(el, position, duration, 'hor');
            case 'angular-anim':
                return this.scrollAngularAnim(el, position, duration, 'hor');
            case 'request-animation-frame':
                return this.scrollRequestFrame(el, position, duration, options, 'hor');
            case 'css-transition':
                return this.scrollTransition(el, position, duration, options, 'hor');
            case 'js-timeout':
                return this.scrollJsTimeout(el, position, duration, options, 'hor');
            case 'fixed':
                return this.scrollFixed(el, position, options, 'hor');
            case 'fixed-abs':
                return this.scrollFixedAbs(el, position, options, 'hor');
        }
    }

    public disableScroll(elementRef: ElementRef) {
        if (!elementRef) {
            return;
        }
        lock(elementRef.nativeElement);
    }

    public enableScroll() {
        unlock();
    }

    private scrollWappi(el: ElementRef, position: number, duration: number, direction: string = 'ver') {
        return new Promise((resolve) => {
            const actualPos = (el.nativeElement.getBoundingClientRect() as any).y;
            const transform = direction === 'ver' ?
                [
                    `translate3d(0, ${ actualPos }px, 0)`,
                    `translate3d(0, ${ (-1) * position }px, 0)`
                ] :
                [
                    `translate3d(${ actualPos }px, 0, 0)`,
                    `translate3d(${ (-1) * position }px, 0, 0)`
                ];
            const waapiAnimation = el.nativeElement.animate({
                transform: transform
            } as any, { duration: duration, fill: 'forwards' });
            waapiAnimation.addEventListener('finish', () => {
                resolve(position);
            });
        });
    }

    private scrollAngularAnim(el: ElementRef, position: number, duration: number, direction: string = 'ver') {
        return new Promise((resolve) => {
            const transform = direction === 'ver' ?
                `translate3d(0, ${ position * (-1) }px, 0)` : `translate3d( ${ position * (-1) }px, 0, 0)`;
            const myAnimation: AnimationFactory = this.animationBuilder.build([
                animate(duration, style({
                    transform: transform
                }))
            ]);
            const animationPlayer = myAnimation.create(el.nativeElement);
            animationPlayer.play();
            animationPlayer.onDone(() => {
                resolve(position);
            });
        });
    }

    private scrollJsTimeout(el: ElementRef, position: number, duration: number, options: any, direction: string = 'ver') {
        return new Promise((resolve) => {
            const startTime = Date.now();
            const actualPosition = options.actualPosition;
            const getTransform = (pos) => {
                return direction === 'ver' ?
                    `translate3d(0, ${ pos * (-1) }px, 0)` : `translate3d( ${ pos * (-1) }px, 0, 0)`;
            };
            const moveUpAnimation = () => {
                const newPosition = Math.floor(this.generalService.easingLogic.ease(
                    Date.now() - startTime,
                    actualPosition,
                    position - actualPosition,
                    duration
                ));
                if (newPosition < position) {
                    options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, getTransform(position));
                    resolve();
                } else {
                    options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, getTransform(newPosition));
                    setTimeout(() => moveUpAnimation(), 17);
                }
            };
            const moveDownAnimation = () => {
                const newPosition = Math.floor(this.generalService.easingLogic.ease(
                    Date.now() - startTime,
                    actualPosition,
                    position - actualPosition,
                    duration
                ));
                if (newPosition >= position) {
                    options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, getTransform(position));
                    resolve();
                } else {
                    options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, getTransform(newPosition));
                    setTimeout(() => moveDownAnimation(), 17);
                }
            };
            if (actualPosition < position) {
                setTimeout(() => moveDownAnimation(), 50);
            } else {
                setTimeout(() => moveUpAnimation(), 50);
            }
        });
    }

    private scrollRequestFrame(el: ElementRef, position: number, duration: number, options: any, direction: string = 'ver') {
        return new Promise((resolve) => {
            const startTime = Date.now();
            const actualPosition = options.actualPosition;
            const getTransform = (pos) => {
                return direction === 'ver' ?
                    `translate3d(0, ${ pos * (-1) }px, 0)` : `translate3d( ${ pos * (-1) }px, 0, 0)`;
            };
            const moveUpAnimation = () => {
                const newPosition = Math.floor(this.generalService.easingLogic.ease(
                    Date.now() - startTime,
                    actualPosition,
                    position - actualPosition,
                    duration
                ));
                if (newPosition <= position) {
                    options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, getTransform(position));
                    resolve();
                } else {
                    options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, getTransform(newPosition));
                    this.requestAnimationFrame(moveUpAnimation);
                }
            };
            const moveDownAnimation = () => {
                const newPosition = Math.floor(this.generalService.easingLogic.ease(
                    Date.now() - startTime,
                    actualPosition,
                    position - actualPosition,
                    duration
                ));
                if (newPosition >= position) {
                    options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, getTransform(position));
                    resolve();
                } else {
                    options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, getTransform(newPosition));
                    this.requestAnimationFrame(moveDownAnimation);
                }
            };
            if (actualPosition === position) {
                resolve();
            }
            if (actualPosition < position) {
                this.requestAnimationFrame(moveDownAnimation);
            } else {
                this.requestAnimationFrame(moveUpAnimation);
            }
        });
    }

    private scrollTransition(el: ElementRef, position: number, duration: number, options: any, direction: string = 'ver') {
        return new Promise((resolve) => {
            const transform = direction === 'ver' ?
                `translate3d(0, ${ position * (-1) }px, 0)` : `translate3d( ${ position * (-1) }px, 0, 0)`;
            // options.renderer.setStyle(el.nativeElement, 'transition', 'none');
            // el.nativeElement.offsetHeight;

            // this.tilesRowsWrapperEl.nativeElement.offsetHeight;
            options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, transform);
            options.renderer.setStyle(
                el.nativeElement, this.generalService.transitionFunction, this.generalService.transformFunction + ' 300ms ease');
            setTimeout(() => {
                resolve();
                options.renderer.setStyle(el.nativeElement, this.generalService.transitionFunction, 'none');
            }, duration);
        });
    }

    private scrollFixed(el: ElementRef, position: number, options: any, direction: string = 'ver') {
        return new Promise((resolve) => {
            const transform = direction === 'ver' ?
                `translate3d(0, ${ position * (-1) }px, 0)` : `translate3d( ${ position * (-1) }px, 0, 0)`;
            options.renderer.setStyle(el.nativeElement, this.generalService.transformFunction, transform);
            resolve();
        });
    }

    private scrollFixedAbs(el: ElementRef, position: number, options: any, direction: string = 'ver') {
        return new Promise((resolve) => {
            if (direction === 'ver') {
                options.renderer.setStyle(el.nativeElement, 'top', (-1) * position + 'px');
            } else {
                options.renderer.setStyle(el.nativeElement, 'left', (-1) * position + 'px');
            }
            resolve();
        });
    }

    private scrollNoop() {
        return Promise.resolve(null);
    }

    public scrollLeft(el: ElementRef, position: number, options: any) {
        return this.scrollToRequestFrame(el, position, options, 'hor');
    }

    public scrollTop(el: ElementRef, position: number, options: any) {
        return this.scrollToRequestFrame(el, position, options, 'ver');
    }

    private scrollToRequestFrame(el: ElementRef, position: number, options: any, direction: string = 'ver') {
        return new Promise((resolve) => {
            const startTime = Date.now();
            const actualPosition = direction === 'ver' ? el.nativeElement.scrollTop : el.nativeElement.scrollLeft;
            const moveUpAnimation = () => {
                const newPosition = Math.floor(this.generalService.easingLogic.ease(
                    Date.now() - startTime,
                    actualPosition,
                    position - actualPosition,
                    options.duration
                ));
                if (newPosition < position) {
                    this.scrollTo(el, position, direction);
                    resolve();
                } else {
                    this.scrollTo(el, newPosition, direction);
                    this.requestAnimationFrame(moveUpAnimation);
                }
            };
            const moveDownAnimation = () => {
                const newPosition = Math.floor(this.generalService.easingLogic.ease(
                    Date.now() - startTime,
                    actualPosition,
                    position - actualPosition,
                    options.duration
                ));
                if (newPosition >= position) {
                    this.scrollTo(el, position, direction);
                    resolve();
                } else {
                    this.scrollTo(el, newPosition, direction);
                    this.requestAnimationFrame(moveDownAnimation);
                }
            };
            if (actualPosition === position) {
                resolve();
            }
            if (actualPosition < position) {
                this.requestAnimationFrame(moveDownAnimation);
            } else {
                this.requestAnimationFrame(moveUpAnimation);
            }
        });
    }

    private scrollTo(el: ElementRef<HTMLElement>, position: number, direction: string) {
        const getTransform = direction === 'ver' ? (pos) => ({ top: pos }) : (pos) => ({ left: pos });
        try {
            el.nativeElement.scrollTo(getTransform(position));
        } catch {
            if (direction === 'ver') {
                el.nativeElement.scrollTop = position;
            } else {
                el.nativeElement.scrollLeft = position;
            }
        }
    }
}
