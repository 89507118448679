import { Inject, Injectable } from '@angular/core';
import { getButtonsTemplates } from '@kuki/global/features/buttons-panel/buttons-templates';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { MediaService } from '@kuki/global/shared/modules/media/media.service';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { ShoppingService } from '@kuki/global/shared/modules/shopping/shopping.service';
import { ChannelService } from '@kuki/global/shared/services/channel.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
// import { ContextMenuService } from '@kuki/web/features/context-menu/context-menu.service';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { ParentalControlService } from '@kuki/global/shared/services/parental-control.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { RestrictionService } from '@kuki/global/shared/services/restriction.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { TagService } from '@kuki/global/shared/services/tag.service';
import { TeleportService } from '@kuki/global/shared/services/teleport.service';
import { WatchedService } from '@kuki/global/shared/services/watched.service';
import { ActionButton } from '@kuki/global/shared/types/button';
import { Device, DeviceTypes } from '@kuki/global/shared/types/device';
import {
    Actions,
    ImageTypes,
    MediaStates,
    MediaTypes,
    NotPlayableReasons,
    PlaybackTypes,
    Tags,
    TileTypes
} from '@kuki/global/shared/types/enum';
import { Errors } from '@kuki/global/shared/types/enum/errors';
import { AudioTrack, Channel, SubtitleTrack } from '@kuki/global/shared/types/general';
import { Playback } from '@kuki/global/shared/types/general/playback';
import { MediaPlayerTypes } from '@kuki/global/shared/types/media-player';
import { Watched } from '@kuki/global/shared/types/state/watched';
import { EpgTile, EpisodeTile, MediaTile, NpvrTile, Tile, VodTile } from '@kuki/global/shared/types/tile';
import { PlaybackTile } from '@kuki/global/shared/types/tile/playback-tile';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class TileService {

    private buttonsTemplates = getButtonsTemplates(this.translateService);

    constructor(
        private authService: AuthService,
        private coreService: CoreService,
        private channelService: ChannelService,
        private restrictionService: RestrictionService,
        private parentalControlService: ParentalControlService,
        private notificationService: NotificationService,
        private navigationService: NavigationService,
        private mediaService: MediaService,
        private tagService: TagService,
        private shoppingService: ShoppingService,
        private watchedService: WatchedService,
        private profileService: ProfileService,
        private settingsService: SettingsService,
        private teleportService: TeleportService,
        private deviceService: DeviceService,
        private translateService: TranslateService,
        // @Optional() private contextMenuService: ContextMenuService,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    public detectTileState(tile: MediaTile | EpgTile) {
        if (!tile.raw) {
            return;
        }
        const actualTime = Date.now();
        // if is later then program end
        if (actualTime >= tile.raw.end) {
            return MediaStates.PAST;
        }
        // if is earlier then program start
        if (actualTime < tile.raw.start) {
            return MediaStates.FUTURE;
        }
        // if is later then program start and earlier then program end
        if (actualTime >= tile.raw.start && actualTime < tile.raw.end) {
            return MediaStates.LIVE;
        }
    }

    public getTileTags(tile: MediaTile): Array<Tags> {
        return this.tagService.getTags(tile.epgEntityId || tile.id, tile.mediaType);
    }

    public getTileTagsUpdates(tile: MediaTile): Observable<Array<Tags>> {
        return this.tagService.getTagsUpdates(tile.epgEntityId || tile.id, tile.mediaType);
    }

    public tagExists(tile: MediaTile, tag: Tags): boolean {
        return this.tagService.tagExists(tile.epgEntityId || tile.id, tile.mediaType, tag);
    }

    public calculateProgressPer(tile: MediaTile, playerTime: number, absolute = true, ignoreOverlap = false) {
        const overlap = ignoreOverlap ? { before: 0, after: 0 } : this.getOverlap(tile);
        const delta = absolute ? playerTime - (tile.raw.start - overlap.before) : playerTime + overlap.before;
        return Math.max(Math.min(Math.floor((delta / (tile.raw.duration + overlap.before + overlap.after)) * 1000) / 10, 100), 0);
    }

    public calculateProgressTime(tile: MediaTile, playerTime: number, absolute = true) {
        const overlap = this.getOverlap(tile);
        if (absolute) {
            const delta = Math.max(playerTime - (tile.raw.start - overlap.before), 0);
            return tile.raw.start - overlap.before + delta;
        } else {
            return Math.max(playerTime - tile.raw.start, 0);
        }
    }

    public calculateProgressTimeFromPer(tile: MediaTile, per: number, absolute = true) {
        const overlap = this.getOverlap(tile);
        const time = Math.floor(((tile.raw.duration + overlap.before + overlap.after) * (per / 100)));
        if (absolute) {
            return time + (tile.raw.start - overlap.before);
        } else {
            if (time - overlap.before > tile.raw.duration + overlap.after) {
                return 0;
            }
            return Math.max(time - overlap.before, 0);
        }
    }

    public calculateTileLiveProgress(tile: MediaTile) {
        if (!tile.raw) {
            return;
        }
        const delta = Date.now() - tile.raw.start;
        return Math.min(Math.floor((delta / tile.raw.duration) * 1000) / 10, 100);
    }

    public isLocked(tile: MediaTile | EpgTile): boolean {
        const pornRestricted = this.isPornRestricted(tile);
        const ageRestricted = this.isAgeRestricted(tile);
        return ageRestricted || pornRestricted;
    }

    public hasAbsoluteWatchedTime(mediaType: MediaTypes) {
        return [ MediaTypes.EPG_ENTITY, MediaTypes.NPVR ].indexOf(mediaType) >= 0;
    }

    public getMediaPlayerEpgEntityParams(tile: MediaTile) {
        const state = this.detectTileState(tile);
        if (tile.watched) {
            const progress = this.calculateProgressPer(tile, tile.watched.position, this.hasAbsoluteWatchedTime(tile.mediaType));
            return {
                type: 'ts',
                start: progress <= 95 || state === MediaStates.LIVE ? tile.watched.position : tile.raw.start
            };
        } else {
            if (state === MediaStates.LIVE) {
                return {
                    type: 'live'
                };
            } else if (state === MediaStates.PAST) {
                return {
                    type: 'ts',
                    start: tile.raw.start
                };
            }
        }
    }

    public getMediaPlayerData(tile: MediaTile) {
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
                const params = this.getMediaPlayerEpgEntityParams(tile);
                if (!params) {
                    return;
                }
                return {
                    mediaPlayerType: 'channel',
                    id: tile.channelId,
                    tile: tile,
                    params: params
                };
            case MediaTypes.NPVR:
                return {
                    mediaPlayerType: 'npvr',
                    id: tile.id,
                    tile: tile
                };
            case MediaTypes.EPISODE:
                return {
                    mediaPlayerType: 'episode',
                    id: tile.id,
                    tile: tile
                };
            case MediaTypes.VOD:
                return {
                    mediaPlayerType: 'vod',
                    id: tile.id,
                    tile: tile
                };
        }
    }

    public getMediaPlayerDataFromChannel(channel: Channel, params?: any) {
        return {
            mediaPlayerType: 'channel',
            id: channel.id,
            params: {
                type: 'live',
                ...params
            }
        };
    }

    public getIcon(type: Tags): string {
        switch (type) {
            case Tags.REC:
                return 'icon icon-record-pink';
            case Tags.NOTIFY:
                return 'icon icon-notify-pink';
            case Tags.FAVOURITE:
                return 'icon icon-favourite-pink';
        }
    }

    public isChannelRestricted(tile: MediaTile): boolean {
        if (tile.mediaType === MediaTypes.EPG_ENTITY) {
            const channel = this.channelService.getChannel(tile.channelId);
            return channel === undefined ||
                (!channel.ident && !(tile.state === MediaStates.LIVE && channel.source?.length > 0));
        }
        return false;
    }

    public isTsRestricted(tile: MediaTile | EpgTile): boolean {
        if (tile.mediaType === MediaTypes.EPG_ENTITY) {
            if (tile.state === MediaStates.LIVE) {
                return false;
            }
            const channel = this.channelService.getChannel(tile.channelId);
            return this.restrictionService.isChannelTsRestricted(channel, tile.raw.start);
        }
        return false;
    }

    public isBroadcastRestricted(tile: MediaTile): boolean {
        if (tile.mediaType === MediaTypes.EPG_ENTITY) {
            const channel = this.channelService.getChannel(tile.channelId);
            return this.restrictionService.isChannelBroadcastRestricted(channel, tile.raw.start);
        }
        return false;
    }

    public isBw(tile: MediaTile, known: any = {}): boolean {
        // vod missing bw images
        if ([ MediaTypes.SERIAL, MediaTypes.VOD ].indexOf(tile.mediaType) >= 0) {
            return false;
        }
        return this.isPlayRestricted(tile, known);
    }

    public isFutureRestricted(tile: MediaTile): boolean {
        if ([ MediaTypes.EPG_ENTITY, MediaTypes.NPVR ].indexOf(tile.mediaType) >= 0) {
            return tile.state === MediaStates.FUTURE;
        }
        return false;
    }

    public getRestrictionLabel(tile: MediaTile): string {
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
                const channelRestricted = this.isChannelRestricted(tile);
                const tsRestricted = this.isTsRestricted(tile);
                const broadcastRestricted = this.isBroadcastRestricted(tile);
                if (channelRestricted) {
                    return this.translateService.instant('GENERAL.LABEL.CHANNEL_RESTRICTED');
                }
                if (tsRestricted) {
                    return this.translateService.instant('GENERAL.LABEL.TS_RESTRICTED');
                }
                if (broadcastRestricted) {
                    return this.translateService.instant('GENERAL.LABEL.BROADCAST_RESTRICTED');
                }
                return null;
            case MediaTypes.EPISODE:
                return this.getEpisodeShortNotPlayableReason(tile as EpisodeTile);
        }
        return null;
    }

    public notifyEpisodeNotPlayableReasons(episodeTile: EpisodeTile) {
        const reason = this.getEpisodeNotPlayableReason(episodeTile);
        if (reason) {
            this.notificationService.show(reason);
        }
    }

    public getEpisodeNotPlayableReason(episodeTile) {
        if (episodeTile.playable) {
            return;
        }

        const reasons = {
            [ NotPlayableReasons.SERIAL_MANAGEMENT ]:
                this.translateService.instant('GENERAL.EPISODE_NOT_PLAYABLE_REASON.SERIAL_MANAGEMENT'),
            [ NotPlayableReasons.MISSING_CHANNEL ]:
                this.translateService.instant('GENERAL.EPISODE_NOT_PLAYABLE_REASON.MISSING_CHANNEL'),
            [ NotPlayableReasons.SERIAL_MANAGEMENT_TOO_OLD_EPISODE ]:
                this.translateService.instant('GENERAL.EPISODE_NOT_PLAYABLE_REASON.SERIAL_MANAGEMENT_TOO_OLD_EPISODE'),
            [ NotPlayableReasons.LEGAL_REASON ]:
                this.translateService.instant('GENERAL.EPISODE_NOT_PLAYABLE_REASON.LEGAL_REASON'),
            [ NotPlayableReasons.NOT_INGESTED ]:
                this.translateService.instant('GENERAL.EPISODE_NOT_PLAYABLE_REASON.NOT_INGESTED')
        };
        return reasons[ episodeTile.notPlayableReason ];
    }

    public getEpisodeShortNotPlayableReason(episodeTile) {
        if (episodeTile.playable) {
            return;
        }
        const reasons = {
            [ NotPlayableReasons.SERIAL_MANAGEMENT ]:
                this.translateService.instant('GENERAL.EPISODE_SHORT_NOT_PLAYABLE_REASON.SERIAL_MANAGEMENT'),
            [ NotPlayableReasons.MISSING_CHANNEL ]:
                this.translateService.instant('GENERAL.EPISODE_SHORT_NOT_PLAYABLE_REASON.MISSING_CHANNEL'),
            [ NotPlayableReasons.SERIAL_MANAGEMENT_TOO_OLD_EPISODE ]:
                this.translateService.instant('GENERAL.EPISODE_SHORT_NOT_PLAYABLE_REASON.SERIAL_MANAGEMENT_TOO_OLD_EPISODE'),
            [ NotPlayableReasons.LEGAL_REASON ]:
                this.translateService.instant('GENERAL.EPISODE_SHORT_NOT_PLAYABLE_REASON.LEGAL_REASON'),
            [ NotPlayableReasons.NOT_INGESTED ]:
                this.translateService.instant('GENERAL.EPISODE_SHORT_NOT_PLAYABLE_REASON.NOT_INGESTED'),
        };
        return reasons[ episodeTile.notPlayableReason ];
    }

    public isMediaType(tile, ...mediaTypes: Array<MediaTypes>): boolean {
        return mediaTypes.indexOf(tile.mediaType) >= 0;
    }

    public getSourceLogoImageType(tile) {
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
            case MediaTypes.NPVR:
            case MediaTypes.EPISODE:
                return ImageTypes.CHANNEL_LOGO;
            case MediaTypes.VOD:
                return ImageTypes.VOD_PROVIDER;
        }
    }

    public getSourceLogoType(tile) {
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
            case MediaTypes.NPVR:
            case MediaTypes.EPISODE:
                return 'channel-info-panel';
            case MediaTypes.VOD:
                return 'vod-info-panel';
        }
    }

    public getOverlap(tile) {
        const overlap = {
            before: 0,
            after: 0
        };
        if (this.isMediaType(tile, MediaTypes.NPVR, MediaTypes.EPISODE)) {
            overlap.before = tile.npvrOverlapBefore || 0;
            overlap.after = tile.npvrOverlapAfter || 0;
        }
        return overlap;
    }

    public isInProgress(tile: MediaTile): boolean {
        return tile.liveProgress >= 0 && tile.liveProgress < 100;
    }

    public isPlayRestricted(tile: MediaTile, known: any = {}, requireBought: boolean = true): boolean {
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
                return this.isEpgEntityPlayRestricted(tile, known);
            case MediaTypes.NPVR:
                return this.isNpvrPlayRestricted(tile);
            case MediaTypes.EPISODE:
                return this.isEpisodePlayRestricted(tile as EpisodeTile);
            case MediaTypes.VOD:
                return this.isVodPlayRestricted(tile, requireBought);
        }
        return true;
    }

    public isRecordRestricted(tile: MediaTile): boolean {
        if (tile.mediaType === MediaTypes.NPVR) {
            return false;
        }
        if (tile.mediaType === MediaTypes.EPG_ENTITY) {
            const sessionPlayRestricted = this.restrictionService.isSessionPlayRestricted();
            const channelRestricted = this.isChannelRestricted(tile);
            const pornRestricted = this.isPornRestricted(tile);
            const ageRestricted = this.isAgeRestricted(tile);
            const tsRestricted = this.isTsRestricted(tile);
            const broadcastRestricted = this.isBroadcastRestricted(tile);
            return sessionPlayRestricted ||
                channelRestricted ||
                pornRestricted ||
                ageRestricted ||
                tsRestricted ||
                broadcastRestricted;
        }
        return true;
    }

    public isNotifyRestricted(tile: MediaTile): boolean {
        return !this.isMediaType(tile, MediaTypes.EPG_ENTITY) || !this.isFutureRestricted(tile);
    }

    public isFavouriteRestricted(tile: MediaTile): boolean {
        return !this.isMediaType(tile, MediaTypes.SERIAL);
    }

    public isTeleportRestricted(tile: MediaTile): boolean {
        // force isSessionPlayRestricted to false
        return this.teleportService.isTeleportRestricted() ||
            !this.isMediaType(tile, MediaTypes.EPG_ENTITY, MediaTypes.NPVR, MediaTypes.EPISODE, MediaTypes.VOD) ||
            this.restrictionService.isSessionRestricted() ||
            this.isChannelRestricted(tile) ||
            this.isPornRestricted(tile) ||
            this.isAgeRestricted(tile) ||
            this.isTsRestricted(tile) ||
            this.isBroadcastRestricted(tile) ||
            this.isFutureRestricted(tile) ||
            (tile.mediaType === MediaTypes.EPISODE && (tile as EpisodeTile).playable === false) ||
            (tile.mediaType === MediaTypes.VOD && !this.isVodBought(tile as VodTile));
    }

    public isEpgEntityPlayRestricted(tile: MediaTile, known: any = {}): boolean {
        if (tile.mediaType !== MediaTypes.EPG_ENTITY) {
            return false;
        }
        const sessionPlayRestricted = known.isSessionPlayRestricted || this.restrictionService.isSessionPlayRestricted();
        const channelRestricted = known.isChannelRestricted || this.isChannelRestricted(tile);
        const pornRestricted = known.isPorn || this.isPornRestricted(tile);
        const ageRestricted = known.isAgeRestricted || this.isAgeRestricted(tile);
        const tsRestricted = known.isTsRestricted || this.isTsRestricted(tile);
        const broadcastRestricted = known.isBroadcastRestricted || this.isBroadcastRestricted(tile);
        const futureRestricted = known.isFutureRestricted || this.isFutureRestricted(tile);
        return sessionPlayRestricted ||
            channelRestricted ||
            pornRestricted ||
            ageRestricted ||
            tsRestricted ||
            broadcastRestricted ||
            futureRestricted;
    }

    public isNpvrPlayRestricted(tile: MediaTile, known: any = {}): boolean {
        if (tile.mediaType !== MediaTypes.NPVR) {
            return false;
        }
        const sessionPlayRestricted = known.isSessionPlayRestricted || this.restrictionService.isSessionPlayRestricted();
        const pornRestricted = known.isPorn || this.isPornRestricted(tile);
        const ageRestricted = known.isAgeRestricted || this.isAgeRestricted(tile);
        const futureRestricted = known.isFutureRestricted || this.isFutureRestricted(tile);
        return sessionPlayRestricted ||
            pornRestricted ||
            ageRestricted ||
            futureRestricted;
    }

    public isEpisodePlayRestricted(tile: EpisodeTile, known: any = {}): boolean {
        if (tile.mediaType !== MediaTypes.EPISODE) {
            return false;
        }
        const sessionPlayRestricted = known.isSessionPlayRestricted || this.restrictionService.isSessionPlayRestricted();
        const pornRestricted = known.isPorn || this.isPornRestricted(tile);
        const ageRestricted = known.isAgeRestricted || this.isAgeRestricted(tile);
        return sessionPlayRestricted ||
            pornRestricted ||
            ageRestricted ||
            !tile.playable;
    }

    public isVodPlayRestricted(tile: MediaTile, requireBought: boolean = true, known: any = {}): boolean {
        if (tile.mediaType !== MediaTypes.VOD) {
            return false;
        }
        const sessionRestricted = known.isSessionRestricted || this.restrictionService.isSessionRestricted();
        const pornRestricted = known.isPorn || this.isPornRestricted(tile);
        const ageRestricted = known.isAgeRestricted || this.isAgeRestricted(tile);
        return sessionRestricted ||
            pornRestricted ||
            ageRestricted ||
            (requireBought && !this.isVodBought(tile as VodTile));
    }

    public isPornRestricted(tile: MediaTile | EpgTile): boolean {
        if (tile.porn) {
            return this.parentalControlService.isTilePornRestricted(tile);
        } else if ([ MediaTypes.EPISODE, MediaTypes.NPVR, MediaTypes.EPG_ENTITY ].indexOf(tile.mediaType) >= 0) {
            const channel = this.channelService.getChannel(tile.channelId);
            return this.parentalControlService.isChannelPornRestricted(channel);
        }
        return false;
    }

    public isAgeRestricted(tile: MediaTile | EpgTile): boolean {
        if (tile.ageRestriction) {
            return this.parentalControlService.isTileAgeRestricted(tile);
        } else if ([ MediaTypes.EPISODE, MediaTypes.NPVR, MediaTypes.EPG_ENTITY ].indexOf(tile.mediaType) >= 0) {
            const channel = this.channelService.getChannel(tile.channelId);
            return this.parentalControlService.isChannelAgeRestricted(channel);
        }
        return false;
    }

    public isVodBought(tile: VodTile): boolean {
        if (tile.mediaType !== MediaTypes.VOD) {
            return false;
        }
        return tile.own.transaction;
    }

    public play(tile: MediaTile) {
        const mediaPlayerSetupData = this.getMediaPlayerData(tile);
        if (mediaPlayerSetupData) {
            return this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerSetupData);
        }
    }

    public playChannel(channel: Channel) {
        const mediaPlayerSetupData = this.getMediaPlayerDataFromChannel(channel);
        if (mediaPlayerSetupData) {
            this.navigationService.preventStoreNavState = true;
            return this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerSetupData, {}, { replaceUrl: true });
        }
    }

    public playLive(tile: MediaTile) {
        const mediaPlayerSetupData = this.getMediaPlayerData(tile);
        if (mediaPlayerSetupData) {
            if (tile.mediaType === MediaTypes.EPG_ENTITY) {
                mediaPlayerSetupData.params = {
                    type: 'live'
                };
            }
            return this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerSetupData);
        }
        return of(undefined);
    }

    public playStartOver(tile: MediaTile) {
        const mediaPlayerSetupData = this.getMediaPlayerData(tile);
        if (mediaPlayerSetupData) {
            if (tile.mediaType === MediaTypes.EPG_ENTITY) {
                mediaPlayerSetupData.params = {
                    start: tile.raw.start,
                    type: 'ts'
                };
            } else {
                const overlap = this.getOverlap(tile);
                mediaPlayerSetupData.params = {
                    start: overlap.before * (-1)
                } as any;
            }
            return this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerSetupData);
        }
        return of(undefined);
    }

    public playContinuing(tile: MediaTile) {
        const mediaPlayerSetupData = this.getMediaPlayerData(tile) as any;
        if (mediaPlayerSetupData) {
            mediaPlayerSetupData.params = {
                ...mediaPlayerSetupData.params,
                continuingPlay: true
            };
            return this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerSetupData);
        }
        return of(undefined);
    }

    public openMediaDetail(tile: MediaTile) {
        return this.navigationService.navigateOnMediaType(tile.mediaType, tile);
    }

    public record(tile: MediaTile) {
        return this.mediaService.record(
            tile,
            tile.mediaType === MediaTypes.NPVR ? (tile as NpvrTile).epgEntityId : null);
    }

    public notify(tile: MediaTile) {
        return this.mediaService.notify(
            tile,
            tile.mediaType === MediaTypes.NPVR ? (tile as NpvrTile).epgEntityId : null);
    }

    public favourite(tile: MediaTile) {
        return this.mediaService.favourite(tile);
    }

    public buyVod(tile: VodTile) {
        if (this.isVodBought(tile)) {
            return from(this.play(tile));
        }
        return this.shoppingService.buyVod(tile).pipe(switchMap((vodBought) => {
            if (!vodBought) {
                return of(null);
            }
            return this.play(tile);
        }));
    }

    public checkLock(tile: MediaTile): Promise<void> {
        if (!this.isLocked(tile)) {
            return Promise.resolve();
        }
        return this.modalsService.openPasswordModal().then(() => {
            this.parentalControlService.unlock();
        }).catch((error) => {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.ACCESS_DENIED'));
            throw error;
        });
    }

    public getWatched(tile: MediaTile, mediaPlayerType?: MediaPlayerTypes): Watched {
        return {
            entityId: tile.id,
            playing: mediaPlayerType ? this.convertMediaPlayerTypeToPlaying(mediaPlayerType) : this.convertMediaTypeToPlaying(tile),
            position: tile.watched ? tile.watched.position : undefined
        };
    }

    public convertMediaTypeToPlaying(tile: MediaTile) {
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
                return 'ts';
            case MediaTypes.EPISODE:
                return 'episode';
            case MediaTypes.NPVR:
                return 'npvr-k2';
            case MediaTypes.VOD:
                return 'vod';
        }
    }

    public convertMediaPlayerTypeToPlaying(mediaPlayerType: MediaPlayerTypes) {
        switch (mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
                return 'ts';
            case MediaPlayerTypes.EPISODE:
                return 'episode';
            case MediaPlayerTypes.NPVR:
                return 'npvr-k2';
            case MediaPlayerTypes.VOD:
                return 'vod';
        }
    }

    public convertMediaPlayerTypeToMediaType(mediaPlayerType: MediaPlayerTypes) {
        switch (mediaPlayerType) {
            case MediaPlayerTypes.CHANNEL:
                return MediaTypes.EPG_ENTITY;
            case MediaPlayerTypes.EPISODE:
                return MediaTypes.EPISODE;
            case MediaPlayerTypes.NPVR:
                return MediaTypes.NPVR;
            case MediaPlayerTypes.VOD:
                return MediaTypes.VOD;
        }
    }


    public convertPlaybackTypeToMediaType(playbackType: string) {
        switch (playbackType) {
            case 'live':
            case 'timeshift':
                return MediaTypes.EPG_ENTITY;
            case 'npvr':
                return MediaTypes.NPVR;
            case 'serial':
                return MediaTypes.EPISODE;
            case 'vod':
                return MediaTypes.VOD;
        }
    }

    public getTileWatched(tile: MediaTile | EpgTile, params: { ignoreOverlap?: boolean, progressCheck?: boolean } = {}): {
        position: number,
        lastWatched?: number,
        progress?: number
    } {
        const calcProgress = (t) => {
            const progress = this.calculateProgressPer(
                t, t.watched.position, this.hasAbsoluteWatchedTime(t.mediaType), params.ignoreOverlap);
            const progressCheck = params.progressCheck && progress > 95;
            return !progressCheck ? {
                ...tile.watched,
                progress: Math.max(progress, 0)
            } : null;
        };

        const cachedItem = this.watchedService.getWatchedItem(tile.id, this.convertMediaTypeToPlaying(tile));
        if (!cachedItem) {
            return tile.watched ? calcProgress(tile) : null;
        }
        // update watched from cache if not out of date
        const lastWatched = tile.watched ? tile.watched.lastWatched : 0;
        if (cachedItem.insertIfNotFound &&
            (!tile.watched || !tile.watched.lastWatched || cachedItem.lastWatched > lastWatched)) {
            tile.watched = {
                position: cachedItem.position,
                lastWatched: cachedItem.lastWatched
            };
        }
        // return tile with progress
        return tile.watched ? calcProgress(tile) : null;
    }

    public getTileTimeProgress(tile: PlaybackTile) {
        return this.calculateProgressPer(tile, tile.time, this.hasAbsoluteWatchedTime(tile.mediaType));
    }

    /* Used in views, moved here for preventing duplicities */

    public showPlayAction(tile: MediaTile): boolean {
        return !this.isPlayRestricted(tile) && !this.isMediaType(tile, MediaTypes.SERIAL);
    }

    public showPlayLive(tile: MediaTile): boolean {
        return this.showPlayAction(tile) &&
            this.isMediaType(tile, MediaTypes.EPG_ENTITY) &&
            (tile.state === MediaStates.LIVE && !!tile.watched);
    }

    public showStartOver(tile: MediaTile): boolean {
        return this.showPlayAction(tile) && (tile.state === MediaStates.LIVE || !!tile.watched);
    }

    public showContinuingAction(tile: MediaTile): boolean {
        const settings = this.settingsService.getParsedSettings();
        return this.showPlayAction(tile) &&
            [ MediaTypes.EPG_ENTITY, MediaTypes.EPISODE ].indexOf(tile.mediaType) >= 0 && !settings.continuingPlay;
    }

    public showMediaDetail(tile: MediaTile): boolean {
        return this.isMediaType(tile, MediaTypes.EPG_ENTITY, MediaTypes.NPVR, MediaTypes.VOD);
    }

    public showBuyAction(tile: MediaTile): boolean {
        return !this.isVodPlayRestricted(tile, false) &&
            this.isMediaType(tile, MediaTypes.VOD) &&
            !this.isVodBought(tile as VodTile);
    }

    public showRecordAction(tile: MediaTile): boolean {
        return !this.isRecordRestricted(tile);
    }

    public showNotifyAction(tile: MediaTile): boolean {
        return !this.isNotifyRestricted(tile);
    }

    public showFavouriteAction(tile: MediaTile): boolean {
        return !this.isFavouriteRestricted(tile);
    }

    public showTeleportAction(tile: MediaTile): boolean {
        return !this.isTeleportRestricted(tile);
    }

    public doAction(action: Actions, tile: Tile, data?: any) {
        switch (tile.type) {
            case TileTypes.MEDIA:
                const mediaTile = tile as MediaTile;
                switch (action) {
                    case Actions.PLAY:
                        return from(this.play(mediaTile));
                    case Actions.PLAY_LIVE:
                        return from(this.playLive(mediaTile));
                    case Actions.PLAY_START_OVER:
                        return from(this.playStartOver(mediaTile));
                    case Actions.SHOW_MEDIA_DETAIL:
                        return from(this.openMediaDetail(mediaTile));
                    case Actions.BUY_VOD:
                        return this.buyVod(mediaTile as VodTile);
                    case Actions.FAVOURITE:
                        return this.favourite(mediaTile);
                    case Actions.RECORD:
                        return this.record(mediaTile);
                    case Actions.NOTIFY:
                        return this.notify(mediaTile);
                    case Actions.OPEN_TELEPORT_TO:
                        return this.openTeleportToModal(mediaTile);
                    case Actions.TELEPORT_TO:
                        return this.teleportTo(data.id, mediaTile, data);
                    case Actions.PLAY_CONTINUING:
                        return from(this.playContinuing(mediaTile));
                }
                break;
            case TileTypes.PROFILE:
                switch (action) {
                    case Actions.EDIT_PROFILE:
                        if (this.coreService.isTvPlatform()) {
                            return from(this.navigationService.navigateUpdateProfileTv(tile.id));
                        } else {
                            return from(this.navigationService.navigateUpdateProfileWebMobile(tile.id));
                        }
                    case Actions.DELETE_PROFILE:
                        return this.profileService.deleteProfileProcesss(tile.id);
                    case Actions.DEACTIVATE_PROFILE:
                        return this.profileService.deactivateProfileProcess();
                    case Actions.ACTIVATE_PROFILE:
                        return this.profileService.activateProfileProcess(tile.id);
                }
                break;
            case TileTypes.DEVICE_TELEPORT:
                switch (action) {
                    case Actions.TELEPORT_SWITCH:
                        const deviceId = this.authService.getAuthData().device_id;
                        return this.teleportService.switch(tile.id, deviceId);
                    case Actions.REMOTE_CONTROL:
                        return from(this.navigationService.navigateRemoteControl(tile.id));
                }
                break;
        }
        return of(null);
    }

    public convertActionToTag(action: Actions) {
        switch (action) {
            case Actions.RECORD:
                return Tags.REC;
            case Actions.NOTIFY:
                return Tags.NOTIFY;
            case Actions.FAVOURITE:
                return Tags.FAVOURITE;
        }
    }

    public getMediaTypeName(tile: MediaTile) {
        switch (tile.mediaType) {
            case MediaTypes.EPG_ENTITY:
                return this.translateService.instant('GENERAL.MEDIA_TYPE.EPG_ENTITY');
            case MediaTypes.VOD:
                return this.translateService.instant('GENERAL.MEDIA_TYPE.VOD');
            case MediaTypes.NPVR:
                return this.translateService.instant('GENERAL.MEDIA_TYPE.NPVR');
            case MediaTypes.SERIAL:
                return this.translateService.instant('GENERAL.MEDIA_TYPE.SERIAL');
            case MediaTypes.EPISODE:
                return this.translateService.instant('GENERAL.MEDIA_TYPE.EPISODE');
        }
    }

    public openTeleportToModal(mediaTile: MediaTile, data?: any) {
        const devices = this.deviceService.getDevicesForTeleport(this.authService.serial);
        const buttons = devices.map(device => (
            {
                ...this.buttonsTemplates.TELEPORT_TO,
                label: device.alias,
                id: device.id,
                icon: this.getDeviceIcon(device)
            }
        ));
        return from(this.modalsService.openActionsModalV2({
            message: this.translateService.instant('MODAL.TELEPORT.MESSAGE'),
            emptyMessage: this.translateService.instant('MODAL.TELEPORT.EMPTY'),
            buttons: buttons,
            onAction: (activeButton, ngbActiveModal: NgbActiveModal) => this.doAction(activeButton.action, mediaTile, {
                ngbActiveModal: ngbActiveModal,
                ...activeButton,
                ...data
            })
        }).catch((error) => {
            if (error.message === Errors.MODAL_CLOSE) {
                return;
            }
            throw error;
        }));
    }

    public teleportTo(deviceId: number,
                      mediaTile: MediaTile,
                      data?: { audioTrack?: Partial<AudioTrack>, subtitleTrack?: Partial<SubtitleTrack>, ngbActiveModal: NgbActiveModal }) {
        let teleport$: Observable<any>;
        switch (mediaTile.mediaType) {
            case MediaTypes.EPG_ENTITY:
                if (mediaTile.watched?.position) {
                    teleport$ = this.teleportService.teleport(deviceId, {
                        type: PlaybackTypes.TIMESHIFT,
                        id: mediaTile.channelId,
                        start: mediaTile.watched?.position,
                        audioTrack: data.audioTrack,
                        subtitleTrack: data.subtitleTrack
                    });
                } else {
                    if (mediaTile.state === MediaStates.LIVE) {
                        teleport$ = this.teleportService.teleport(deviceId, {
                            type: PlaybackTypes.LIVE,
                            id: mediaTile.channelId,
                            audioTrack: data.audioTrack,
                            subtitleTrack: data.subtitleTrack
                        });
                    } else if (mediaTile.state === MediaStates.PAST) {
                        teleport$ = this.teleportService.teleport(deviceId, {
                            type: PlaybackTypes.TIMESHIFT,
                            id: mediaTile.channelId,
                            start: mediaTile.raw.start,
                            audioTrack: data.audioTrack,
                            subtitleTrack: data.subtitleTrack
                        });
                    }
                }
                break;
            case MediaTypes.NPVR:
                const npvrStart = mediaTile.watched?.position || mediaTile.raw.start;
                teleport$ = this.teleportService.teleport(deviceId, {
                    type: PlaybackTypes.NPVR,
                    id: mediaTile.id,
                    start: npvrStart,
                    audioTrack: data.audioTrack,
                    subtitleTrack: data.subtitleTrack
                });
                break;
            case MediaTypes.EPISODE:
                const episodeStart = mediaTile.watched?.position || 0;
                teleport$ = this.teleportService.teleport(deviceId, {
                    type: PlaybackTypes.EPISODE,
                    id: mediaTile.id,
                    start: episodeStart,
                    audioTrack: data.audioTrack,
                    subtitleTrack: data.subtitleTrack
                });
                break;
            case MediaTypes.VOD:
                const vodStart = mediaTile.watched?.position || 0;
                teleport$ = this.teleportService.teleport(deviceId, {
                    type: PlaybackTypes.VOD,
                    id: mediaTile.id,
                    start: vodStart,
                    audioTrack: data.audioTrack,
                    subtitleTrack: data.subtitleTrack
                });
                break;
        }
        if (data.ngbActiveModal) {
            data.ngbActiveModal.close();
        }
        if (!teleport$) {
            return of(undefined);
        }
        return teleport$.pipe(
            switchMap(() => {
                if (this.coreService.isTvPlatform()) {
                    return of(undefined);
                }
                const device = this.deviceService.getDevice(deviceId);
                return (!device.confirmTeleport ? this.modalsService.openConfirmModal({
                    message: this.translateService.instant('GENERAL.REMOTE_CONTROL_CONFIRM_MESSAGE')
                }) : Promise.resolve(true)).then(() => {
                    return this.navigationService.navigateRemoteControl(deviceId);
                }).catch((error) => {
                    if (error.message === Errors.MODAL_CLOSE) {
                        return;
                    }
                    console.log(error);
                    throw error;
                });
            })
        );
    }

    public getDeviceIcon(device: Device) {
        switch (device.deviceType) {
            case DeviceTypes.STB:
            case DeviceTypes.SMART_TV:
                return 'icon-device-tv';
            case DeviceTypes.WEB:
                return 'icon-device-web';
            case DeviceTypes.MOBILE:
                return 'icon-device-mobile';
        }
    }

    public initTileActionsButtons(tile: Tile): Array<ActionButton> {
        const actionButtons: Array<ActionButton> = [];
        switch (tile.type) {
            case TileTypes.MEDIA:
                const mediaTile = tile as MediaTile;
                if (!this.coreService.isProfileActive() || this.coreService.isProfileTypeActive('classic', 'senior')) {
                    if (this.showPlayAction(mediaTile)) {
                        actionButtons.push(this.buttonsTemplates.PLAY);
                    }
                    if (this.showPlayLive(mediaTile)) {
                        actionButtons.push(this.buttonsTemplates.PLAY_LIVE);
                    }
                    if (this.showStartOver(mediaTile)) {
                        actionButtons.push(this.buttonsTemplates.PLAY_START_OVER);
                    }
                    if (this.showBuyAction(mediaTile)) {
                        const vodTile = tile as VodTile;
                        if (!vodTile.own.subscription) {
                            if (vodTile.canBuy) {
                                actionButtons.push({
                                    ...this.buttonsTemplates.BUY_VOD,
                                    label: vodTile.raw.price > 0 ?
                                        this.translateService.instant('GENERAL.ACTION.BUY_VOD_FOR', { price: vodTile.price }) :
                                        this.translateService.instant('GENERAL.ACTION.BUY_VOD_FREE')
                                });
                            }
                        } else {
                            actionButtons.push({
                                ...this.buttonsTemplates.BUY_VOD,
                                label: this.translateService.instant('GENERAL.ACTION.PLAY')
                            });
                        }
                    }

                    if (this.showMediaDetail(mediaTile)) {
                        actionButtons.push({ ...this.buttonsTemplates.SHOW_MEDIA_DETAIL });
                    }

                    if (this.showNotifyAction(mediaTile)) {
                        actionButtons.push({
                            ...this.buttonsTemplates.NOTIFY,
                            label: this.tagExists(mediaTile, Tags.NOTIFY) ?
                                this.translateService.instant('GENERAL.ACTION.DELETE') : this.buttonsTemplates.NOTIFY.label
                        });
                    }
                    if (this.showFavouriteAction(mediaTile)) {
                        actionButtons.push({
                            ...this.buttonsTemplates.FAVOURITE,
                            label: this.tagExists(mediaTile, Tags.FAVOURITE) ?
                                this.translateService.instant('GENERAL.ACTION.DELETE') : this.buttonsTemplates.FAVOURITE.label
                        });
                    }
                    if (this.showRecordAction(mediaTile)) {
                        actionButtons.push({
                            ...this.buttonsTemplates.RECORD,
                            label: this.tagExists(mediaTile, Tags.REC) ?
                                this.translateService.instant('GENERAL.ACTION.DELETE') : this.buttonsTemplates.RECORD.label
                        });
                    }
                    if (this.showTeleportAction(mediaTile)) {
                        actionButtons.push({
                            ...this.buttonsTemplates.OPEN_TELEPORT_TO
                        });
                    }
                    if (this.showContinuingAction(mediaTile)) {
                        actionButtons.push({
                            ...this.buttonsTemplates.PLAY_CONTINUING
                        });
                    }
                }
                break;
            case TileTypes.PROFILE:
                const activeProfile = this.profileService.getActiveProfile();
                if (!activeProfile || activeProfile.profileType !== 'kid') {
                    actionButtons.push({ ...this.buttonsTemplates.EDIT_PROFILE });
                    actionButtons.push({ ...this.buttonsTemplates.DELETE_PROFILE });
                }
                if (activeProfile && activeProfile.id === tile.id) {
                    actionButtons.push({ ...this.buttonsTemplates.DEACTIVATE_PROFILE });
                }
                break;
        }
        return actionButtons;
    }

    public getMediaByPlayback(playback: Playback) {
        const mediaType = this.convertPlaybackTypeToMediaType(playback.type);
        switch (mediaType) {
            case MediaTypes.EPG_ENTITY:
                return this.mediaService.getMedia(playback.entity.epg.id, mediaType);
            case MediaTypes.NPVR:
                return this.mediaService.getMedia(playback.entity.npvr.id, mediaType);
            case MediaTypes.EPISODE:
                return this.mediaService.getMedia(playback.entity.episode.id, mediaType);
            case MediaTypes.VOD:
                return this.mediaService.getMedia(playback.entity.vod.id, mediaType);
        }
    }
}
