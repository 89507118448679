import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoScrollContainerComponent } from './auto-scroll-container.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [ AutoScrollContainerComponent ],
    exports: [
        AutoScrollContainerComponent
    ]
})
export class AutoScrollContainerModule {
}
