import {
    ChangeDetectorRef,
    Directive,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { VisibleService } from '@kuki/global/shared/modules/visible/visible.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { debounceTime } from 'rxjs/operators';
import { CoreService } from '@kuki/global/shared/services/core.service';

@Directive({
    selector: '[appVisible]'
})
export class VisibleDirective implements OnInit, OnChanges, OnDestroy {
    @Input() appVisible: string;

    private hasView = false;
    private subscription: SubscriptionObject = {};

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private coreService: CoreService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private visibleService: VisibleService) {
    }

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this.subscription.visibilityChanged = this.visibleService.visibilityChanged$
                .pipe(
                    // to ignore true -> false -> true immediate changes
                    debounceTime(0)
                )
                .subscribe(() => {
                    if (!this.appVisible) {
                        return;
                    }
                    const isVisible = this.visibleService.isVisible(this.appVisible);
                    if (isVisible && !this.hasView) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                        this.hasView = true;
                    } else if (!isVisible && this.hasView) {
                        this.viewContainer.clear();
                        this.hasView = false;
                    }
                    this.changeDetectorRef.detectChanges();
                });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('appVisible')) {
            this.checkVisibility();
        }
    }

    private checkVisibility() {
        if (!this.appVisible) {
            return;
        }
        const isVisible = this.visibleService.isVisible(this.appVisible);
        if (isVisible && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!isVisible && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
        return this.hasView;
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
