import { Injectable } from '@angular/core';
import { RegisteredComponent } from '@kuki/global/shared/types/general';

@Injectable()
export class ComponentRegisterService {
    private registeredComponents: Array<RegisteredComponent<any>> = [];

    public registerComponent<T>(id: string, component: T, type?: string) {
        const registeredComponent: RegisteredComponent<T> = {
            id: id,
            component: component,
            type: type
        };
        this.registeredComponents.push(registeredComponent);
    }

    public unregisterComponent(id: string) {
        this.registeredComponents = this.registeredComponents.filter(registeredComponent => registeredComponent.id !== id);
    }

    public getComponents(): Array<RegisteredComponent<any>> {
        return [ ...this.registeredComponents ];
    }

    public getComponentsByType<T>(type: string): Array<T> {
        return this.registeredComponents
            .filter(registeredComponent => registeredComponent.type === type)
            .map(registeredComponent => registeredComponent.component);
    }

    public getComponent<T>(id: string): T {
        const registeredComponent = this.registeredComponents.find(registeredComponentItem => registeredComponentItem.id === id);
        return registeredComponent ? registeredComponent.component : null;
    }
}
