import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelfTestComponent } from '@kuki/global/shared/modules/self-test/self-test.component';
import { SelfTestService } from '@kuki/global/shared/modules/self-test/self-test.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
    ],
    declarations: [
        SelfTestComponent
    ],
    exports: [
        SelfTestComponent
    ],
    providers: [
        SelfTestService
    ]
})
export class SelfTestModule {
}
