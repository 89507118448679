import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownBoxModule } from '../countdown-box/countdown-box.module';
import { ProgressButtonModule } from '../progress-button/progress-button.module';
import { VolumeModule } from '@kuki/web/features/volume/volume.module';
import { OsdComponent } from './osd.component';
import { SourceLogoModule } from '@kuki/global/features/source-logo/source-logo.module';
import { LabelsPanelModule } from '@kuki/global/features/labels-panel/labels-panel.module';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';
import { PipesModule } from '@kuki/global/shared/pipes/pipes.module';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { ButtonsPanelModule } from '@kuki/global/features/buttons-panel/buttons-panel.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [ OsdComponent ],
    imports: [
        CommonModule,
        ButtonsPanelModule,
        SourceLogoModule,
        LabelsPanelModule,
        AutoScrollContainerModule,
        PipesModule,
        DirectivesModule,
        TranslateModule,
        ProgressButtonModule,
        CountdownBoxModule,
        VolumeModule
    ],
    exports: [
        OsdComponent
    ]
})
export class OsdModule {
}
