export const UI_VARIABLES_ARRIS = {
    maxWidth: 1280,
    maxHeight: 720,
    containerWidth: 1248,
    gridColumns: 42,
    gridColumn: 18,
    gridGutter: 12,
    baseline: 10,
    flush: 16,
};
