import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NpvrService } from '@kuki/global/shared/modules/media/npvr.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Component({
    selector: 'app-npvr-stats',
    templateUrl: './npvr-stats.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NpvrStatsComponent implements OnInit, OnDestroy {

    public npvrFullness: number;
    private subscription: SubscriptionObject = {};

    constructor(
        private npvrService: NpvrService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscription.npvrFullness = this.npvrService.npvrFullness$.subscribe((npvrFullness) => {
            this.npvrFullness = npvrFullness;
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
