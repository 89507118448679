import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisibleDirective } from './visible.directive';
import { VisibleService } from '@kuki/global/shared/modules/visible/visible.service';

@NgModule({
    declarations: [ VisibleDirective ],
    imports: [
        CommonModule
    ],
    exports: [
        VisibleDirective
    ]
})
export class VisibleModule {
    static forRoot(): ModuleWithProviders<VisibleModule> {
        return {
            ngModule: VisibleModule,
            providers: [
                VisibleService
            ]
        };
    }
}
