import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { hal } from '@kuki/platforms/hal';
import { toiVersion } from '@kuki/platforms/tv/arris/toi-version';

@Directive({
    selector: '[appPlatform]'
})
export class PlatformTypeDirective implements OnChanges {

    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) {
    }

    @Input() appPlatform: string;
    @Input() appPlatformNot = false;
    @Input() appPlatformToi = toiVersion;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('appPlatform')) {
            let condition = !this.appPlatformNot ? this.appPlatform === hal.platform : this.appPlatform !== hal.platform;
            if (hal.platform === 'TV.ARRIS') {
                condition = condition && this.appPlatformToi === toiVersion;
            }
            if (condition && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!condition && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        }
    }
}
