import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderService } from '@kuki/global/shared/modules/loader/loader.service';
import { LoaderDirective } from '@kuki/global/shared/modules/loader/loader.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LoaderDirective
    ],
    exports: [
        LoaderDirective
    ]
})
export class LoaderModule {
    static forRoot(): ModuleWithProviders<LoaderModule> {
        return {
            ngModule: LoaderModule,
            providers: [
                LoaderService
            ]
        };
    }
}
