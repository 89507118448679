<div class="label-tracks" *ngIf="media.tracks">
    <ng-container *ngIf="media.tracks.audio20Langs?.length">
        <span class="icon icon-audio-surround"></span>
        <span class="label">{{ getTracksString(media.tracks.audio20Langs) }}</span>
    </ng-container>
    <ng-container *ngIf="media.tracks.audio51Langs?.length">
        <span class="icon icon-audio-dolby-digital"></span>
        <span class="label">{{ getTracksString(media.tracks.audio51Langs) }}</span>
    </ng-container>
    <ng-container *ngIf="media.tracks.subtitleLangs?.length">
        <span class="icon icon-subtitles"></span>
        <span class="label">{{ getTracksString(media.tracks.subtitleLangs) }}</span>
    </ng-container>
    <span class="labels-dot">●</span>
</div>
<ng-container *ngIf="media.tile.startDate">
    <span class="label">{{ media.tile.startDate }}</span>
    <span class="labels-dot">●</span>
</ng-container>
<ng-container *ngIf="media.tile.start">
    <span class="label">{{ media.tile.start }}</span>
    <span class="labels-dot">●</span>
</ng-container>
<span class="label"
      *ngIf="media.duration">{{ media.duration }}</span>
<ng-container *ngIf="media.year">
    <span class="labels-dot">●</span><span class="label">{{ media.year }}</span>
</ng-container>
<ng-container *ngIf="media.genre">
    <ng-container *ngFor="let genre of media.genre; index as i">
        <span class="label">{{ genre }}</span>
        <span *ngIf="i < media.genre.length - 1" class="labels-dot">●</span>
    </ng-container>
</ng-container>
