import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@kuki/environments/environment';
import { tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PortalSettings } from '@kuki/global/shared/types/general';


@Injectable()
export class PortalSettingsService {

    private portalSettings: PortalSettings;
    private portalSettingsUpdated: Subject<void> = new Subject<void>();
    public portalSettingsUpdated$: Observable<void> = this.portalSettingsUpdated.asObservable();

    constructor(
        private httpClient: HttpClient) {
    }

    public fetchPortalSettings() {
        return this.httpClient.get<PortalSettings>(environment.apiUrl + 'portal-settings')
            .pipe(tap(portalSettings => {
                this.portalSettings = portalSettings;
                this.portalSettingsUpdated.next();
            }));
    }

    public getPortalSettings(): PortalSettings {
        return this.portalSettings;
    }

    public getLanguagesAudio() {
        return this.portalSettings.mediaPlayer.languagesAudio;
    }

    public getLanguagesSubtitles() {
        return this.portalSettings.mediaPlayer.languagesSubtitles;
    }

    public clearPortalSettings() {
        this.portalSettings = null;
    }
}
