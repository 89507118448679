import { Injectable, NgZone } from '@angular/core';
import { AudioTrack, Cert, SubtitleTrack } from '@kuki/global/shared/types/general';
import { ArrisPlatformHalService } from '@kuki/platforms/tv/arris/arris-platform-hal.service';
import { CacheMapService } from '@kuki/global/shared/services/cache-map.service';
import { HttpClient } from '@angular/common/http';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, of, ReplaySubject, Subject, timer } from 'rxjs';
import { TrackInfo } from '@kuki/global/features/media-player/media-player-hals/kreatv-player/track-info';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { MediaPlayerError, MediaPlayerErrors } from '@kuki/global/shared/types/media-player';

declare var toi;

@Injectable()
export class KreaTVPlayerV2Service implements MediaPlayerHalInterface {
    public readonly hevc = true;
    public readonly nativeBuffer = false;

    private mediaPlayerSession: any;
    private doPlayTimeout: any;
    private doPlayRetries: number;
    private mediaPlayerState: any;
    private audioTracks: Array<AudioTrack> = [];
    private subtitleTracks: Array<SubtitleTrack> = [];
    private availableAudioTracks: Array<any> = [];
    private availableSubtitleTracks: Array<any> = [];
    private activeAudioTrack: AudioTrack;
    private activeSubtitleTrack: SubtitleTrack;
    private tracksInfo: TrackInfo;
    private playerStateChangedListener: any;
    private updateAvailableTracksListener: any;

    public buffering$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public end$: Subject<void> = new Subject<void>();
    public error$: Subject<MediaPlayerError> = new Subject<MediaPlayerError>();
    public tracksUpdated$?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public trackActivated$?: ReplaySubject<void> = new ReplaySubject<void>(1);
    private availableTracksUpdated$: Subject<void> = new Subject<void>();

    private subscription: SubscriptionObject = {};
    private pauseRequired: boolean = false;
    private stopRequired: boolean = false;

    constructor(
        private arrisPlatformHalService: ArrisPlatformHalService,
        private cacheMapService: CacheMapService,
        private httpClient: HttpClient,
        private ngZone: NgZone) {
    }

    // initMediaPlayerSession
    public init() {
        if (this.mediaPlayerSession) {
            console.log('media player session exists');
            return this.mediaPlayerSession;
        }
        try {
            this.mediaPlayerSession = toi.mediaService.createPlayerInstance();
        } catch (e) {
            console.log('Unable to get mediaPlayer: ' + e);
        }
        // TODO: check why doesn't work
        // this.mediaPlayerSession.setParameter('AudioEncodingPriorityList', 'aac');
        this.updateAvailableTracksListener = this.updateAvailableTracks.bind(this);
        this.playerStateChangedListener = this.playerStateChanged.bind(this);
        this.mediaPlayerSession.addEventListener(
            this.mediaPlayerSession.ON_STREAM_INFO_CHANGED,
            this.updateAvailableTracksListener
        );
        this.arrisPlatformHalService.reloadAdaptiveRules();
        this.mediaPlayerSession.addEventListener(
            this.mediaPlayerSession.ON_STATE_CHANGED,
            this.playerStateChangedListener
        );
        this.watchAvailableTracksUpdated();
        return this.mediaPlayerSession;
    }

    public play(streamUrl: string, params: {
        cert: Cert,
        proxy: boolean,
        pipe: string,
        forceMute?: boolean;
    }) {
        this.stopRequired = false;
        this.pauseRequired = false;
        if (params.forceMute) {
            this.arrisPlatformHalService.setMute(true, true);
        }
        return new Promise((resolve, reject) => {
            this.buffering$.next(true);
            this.tracksUpdated$.next(false);
            const proxy = params.proxy !== undefined ? params.proxy : true;
            const promise = proxy ?
                this.cacheMapService.setCacheMap(params.cert.cache_map_chunk, params.cert.cache_map_playlist).toPromise() :
                Promise.resolve(undefined);
            promise.then(() => {
                try {
                    this.doPlayIfIdle(streamUrl, proxy, params.pipe);
                } catch (e) {
                    return Promise.reject(e);
                }
                resolve();
            }).catch((e) => {
                this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR });
                console.error('Failed opening stream: ' + e);
                reject(e);
            });
        });
    }

    public pause() {
        return new Promise((resolve, reject) => {
            if (this.mediaPlayerSession) {
                try {
                    this.pauseRequired = true;
                    this.buffering$.next(false);
                    this.mediaPlayerSession.play(0);
                    resolve();
                } catch (e) {
                    console.error('Unable to pause mediaPlayer: ');
                    console.error(e);
                    reject();
                }
            }
            reject();
        });
    }

    public stop() {
        return new Promise((resolve, reject) => {
            if (this.mediaPlayerSession) {
                try {
                    this.stopRequired = true;
                    this.mediaPlayerSession.close();
                    this.buffering$.next(false);
                    resolve();
                } catch (e) {
                    console.error('Unable to close mediaPlayer: ');
                    console.error(e);
                    reject(e);
                }
            }
            reject();
        });
    }

    public activateAudioTrack(id: number) {
        const audioTrack = this.audioTracks.find(track => track.id === id);
        try {
            console.log('activateAudioTrack Component');
            console.log(audioTrack.id);
            this.mediaPlayerSession.activateComponent(audioTrack.native);
            this.activeAudioTrack = audioTrack;
            this.trackActivated$.next();
        } catch (e) {
            console.error('Unable to activate component: ' + e);
        }
    }

    public activateSubtitleTrack(id: number) {
        if (!this.mediaPlayerSession) {
            return;
        }
        if (id !== undefined) {
            const subtitleTrack = this.subtitleTracks.find(track => track.id === id);
            try {
                console.log('activateSubtitle Component');
                console.log(subtitleTrack.id);
                this.mediaPlayerSession.activateComponent(subtitleTrack.native);
                this.activeSubtitleTrack = subtitleTrack;
                this.trackActivated$.next();
            } catch (e) {
                console.error('Unable to activate component: ' + e);
            }
        } else {
            try {
                this.mediaPlayerSession.deactivateComponent(this.mediaPlayerSession.COMPONENT_SUBTITLE);
                this.activeSubtitleTrack = undefined;
                this.trackActivated$.next();
            } catch (e) {
                console.error('Unable to deactivate component: ' + e);
            }
        }
    }

    public refreshTracks() {
        this.activeAudioTrack = undefined;
        this.activeSubtitleTrack = undefined;
        this.audioTracks = [];
        this.subtitleTracks = [];
        this.tracksUpdated$.next(false);
        this.tracksInfo = undefined;
    }

    public getAudioTracks(): Array<AudioTrack> {
        return this.audioTracks;
    }

    public getSubtitleTracks(): Array<SubtitleTrack> {
        return this.subtitleTracks;
    }

    public getActiveAudioTrack(): AudioTrack {
        return this.activeAudioTrack;
    }

    public getActiveSubtitleTrack(): SubtitleTrack {
        return this.activeSubtitleTrack;
    }

    public getPlayerDebug(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.subscription.updateDebug) {
                SOM.clearSubscriptions(this.subscription.updateDebug);
            }
            this.subscription.updateDebug =
                this.httpClient.get<any>('http://127.0.0.1:7744/stats.json').subscribe((d) => {
                    resolve(
                        `${ d.last_chunk_speed }kbps / ${ d.avg_speed }kbps / ${ d.selected_profile }kbps / ${ d.cbuff_size }kB`);
                }, () => reject('?'), () => this.subscription.updateDebug = undefined);
        });
    }

    // releaseMediaPlayerSession
    public destroy() {
        if (this.mediaPlayerSession) {
            this.mediaPlayerSession.removeEventListener(
                this.mediaPlayerSession.ON_STREAM_INFO_CHANGED,
                this.updateAvailableTracksListener
            );
            this.mediaPlayerSession.removeEventListener(
                this.mediaPlayerSession.ON_STATE_CHANGED,
                this.playerStateChangedListener
            );
            this.mediaPlayerSession.releaseInstance();
            this.mediaPlayerSession = undefined;
        }
        SOM.clearSubscriptionsObject(this.subscription);
        this.subscription = {};
        if (this.doPlayTimeout) {
            clearTimeout(this.doPlayTimeout);
        }
        this.arrisPlatformHalService.unlockMute();

        this.audioTracks = [];
        this.subtitleTracks = [];
        this.availableAudioTracks = [];
        this.availableSubtitleTracks = [];
        this.activeAudioTrack = undefined;
        this.activeSubtitleTrack = undefined;
        this.tracksInfo = undefined;

        this.buffering$ = new BehaviorSubject(true);
        this.error$ = new Subject<MediaPlayerError>();
        this.tracksUpdated$ = new BehaviorSubject(false);
        this.trackActivated$ = new ReplaySubject<void>(1);
    }

    private doPlay(streamUrl: string, proxy: boolean = true, pipe: string = '') {
        this.doPlayRetries = 0;
        const playurl = proxy ? `http://127.0.0.1:7744/stream.ts?url=${ encodeURIComponent(streamUrl) }` : streamUrl;
        console.log('playurl');
        console.log(playurl);
        this.mediaPlayerSession.open(playurl + pipe);
        this.mediaPlayerSession.play(1000);
        this.buffering$.next(false);
    }

    private doPlayIfIdle(streamUrl: string, proxy: boolean = true, pipe: string = '') {
        if (!this.mediaPlayerSession) {
            return;
        }
        if (this.mediaPlayerSession.getState() !== this.mediaPlayerSession.STATE_IDLE) {
            console.log(`player not idle, closing, current state: ${ this.mediaPlayerSession.getState() }"`);
            try {
                this.mediaPlayerSession.close();
            } catch (e) {
                console.error('unable to mediaPlayer.close(): ' + e);
            }

            if (this.doPlayTimeout) {
                clearTimeout(this.doPlayTimeout);
            }
            this.doPlayTimeout = setTimeout(() => {
                this.doPlayRetries++;
                if (this.doPlayRetries <= 50) {
                    console.log(`doPlayRetries: ${ this.doPlayRetries }`);
                    this.doPlayIfIdle(streamUrl, proxy, pipe);
                } else {
                    console.error('unable to get player to IDLE state');
                }
            }, 100);
        } else {
            this.doPlay(streamUrl, proxy, pipe);
        }
    }

    private watchAvailableTracksUpdated() {
        this.subscription.availableTracksUpdated = this.availableTracksUpdated$.pipe(debounceTime(100)).subscribe(() => {
            const audioTracks = [];
            const subtitleTracks = [];
            this.availableAudioTracks.forEach((availableTrack) => {
                const audioTrackInfo = this.getAudioStreamInfo(availableTrack);
                if (!audioTrackInfo) {
                    return;
                }
                const audioTrack: AudioTrack = {
                    id: availableTrack.id,
                    label: audioTrackInfo.language,
                    language: audioTrackInfo.language,
                    channels: (this.tracksInfo && this.tracksInfo[ availableTrack.id ]) ?
                        this.tracksInfo[ availableTrack.id ].channels : audioTrackInfo.channelFormat,
                    encoding: audioTrackInfo.encoding,
                    native: availableTrack,
                    layout: '2.0' // default
                };
                switch (audioTrack.channels) {
                    case toi.consts.ToiMediaPlayerBase.AUDIO_CHANNEL_FORMAT_3_FRONT_2_BACK:
                    case toi.consts.ToiMediaPlayerBase.AUDIO_CHANNEL_FORMAT_3_FRONT_2_BACK_LFE:
                        audioTrack.layout = '5.1';
                        break;
                    case toi.consts.ToiMediaPlayerBase.AUDIO_CHANNEL_FORMAT_STEREO: {
                        audioTrack.layout = '2.0';
                        break;
                    }
                }
                audioTracks.push(audioTrack);
            });
            this.availableSubtitleTracks.forEach((availableTrack) => {
                const subtitleTrackInfo = this.getSubtitleStreamInfo(availableTrack);
                if (!subtitleTrackInfo) {
                    return;
                }
                const subtitleTrack: SubtitleTrack = {
                    id: availableTrack.id,
                    label: subtitleTrackInfo.language,
                    language: subtitleTrackInfo.language,
                    native: availableTrack
                };
                subtitleTracks.push(subtitleTrack);
            });
            this.audioTracks = audioTracks;
            this.subtitleTracks = subtitleTracks;
            if (audioTracks.length > 0) {
                this.tracksUpdated$.next(true);
            }
        });
    }

    private updateAvailableTracks() {
        if (!this.mediaPlayerSession) {
            return;
        }
        const components = this.mediaPlayerSession.getStreamInfo().availableComponents;
        this.availableAudioTracks = [];
        this.availableSubtitleTracks = [];
        components.forEach(component => {
            switch (component.type) {
                case this.mediaPlayerSession.COMPONENT_AUDIO:
                    this.availableAudioTracks.push(component);
                    break;
                case this.mediaPlayerSession.COMPONENT_SUBTITLE:
                    this.availableSubtitleTracks.push(component);
                    break;
            }
        });
        if (!this.subscription.fetchTracksInfo) {
            this.ngZone.runOutsideAngular(() => {
                this.subscription.fetchTracksInfo = this.fetchTracksInfo().subscribe((data) => {
                    if (data) {
                        SOM.clearSubscriptions(this.subscription.fetchTracksInfo);
                        this.subscription.fetchTracksInfo = undefined;
                        this.availableTracksUpdated$.next();
                    }
                });
            });
        }
    }

    private playerStateChanged() {
        if (!this.mediaPlayerSession) {
            return;
        }
        const newState = this.mediaPlayerSession.getState();
        // tslint:disable-next-line:max-line-length
        // console.log(`mediaPlayer.stateChanged ${ this.mediaPlayerState } -> ${ newState } (preq: ${ root.$stb.pauseRequired }, sreq: ${ root.$stb.stopRequired }, io.state: ${ this.arrisPlatformHalService.getIsObject('var.io.state') }`);
        if (this.mediaPlayerState === this.mediaPlayerSession.STATE_PLAYING && [
            this.mediaPlayerSession.STATE_IDLE,
            this.mediaPlayerSession.STATE_PAUSED,
            this.mediaPlayerSession.STATE_FAILED,
        ].indexOf(newState) >= 0) {
            if (!(newState === this.mediaPlayerSession.STATE_PAUSED && this.pauseRequired) &&
                !(newState === this.mediaPlayerSession.STATE_IDLE && this.stopRequired)) {
                if (this.arrisPlatformHalService.getDeviceState() !== 'standby') {
                    console.log('mediaPlayer.stateChanged - calling onVideoStopped');
                    this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR });
                    // this.end$.next();
                }
            }
        }
        this.pauseRequired = false;
        this.stopRequired = false;
        this.mediaPlayerState = newState;
    }

    private getAudioStreamInfo(component) {
        if (!this.mediaPlayerSession) {
            return;
        }
        try {
            return this.mediaPlayerSession.getAudioStreamInfo(component);
        } catch {
        }
    }

    private getSubtitleStreamInfo(component) {
        if (!this.mediaPlayerSession) {
            return;
        }
        try {
            return this.mediaPlayerSession.getSubtitleStreamInfo(component);
        } catch {
        }
    }

    private fetchTracksInfo() {
        return timer(500, 2000).pipe(switchMap(() => {
            return this.httpClient.get<TrackInfo>('http://127.0.0.1:7744/stream-info.json');
        }), tap((data: TrackInfo) => {
            if (!data) {
                return;
            }
            this.tracksInfo = data;
        }), catchError(() => {
            console.log('Can\'t fetch steam info');
            return of({});
        }));
    }
}
