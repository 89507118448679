import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'day'
})
export class DayPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    private readonly days = [
        this.translateService.instant('DAY.SUNDAY'),
        this.translateService.instant('DAY.MONDAY'),
        this.translateService.instant('DAY.TUESDAY'),
        this.translateService.instant('DAY.WEDNESDAY'),
        this.translateService.instant('DAY.THURSDAY'),
        this.translateService.instant('DAY.FRIDAY'),
        this.translateService.instant('DAY.SATURDAY'),
    ];

    transform(time: number): string {
        let output = '';
        if (!time) {
            return;
        }
        const date = new Date(time);
        const day = date.getUTCDay();
        output += this.days[ day ];
        return output;
    }
}
