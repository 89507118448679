import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Media } from '@kuki/global/shared/types/media';

@Component({
    selector: 'app-text-labels',
    templateUrl: './text-labels.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextLabelsComponent {

    @Input() media: Media;

    public genresOpened: boolean = false;

    constructor() {
    }

    public getTracksString(tracks: Array<string>) {
        return tracks.join(', ');
    }

    public onOpenGenres() {
        this.genresOpened = true;
    }
}
