import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BroadcastGap, BroadcastGapGroup } from '../types/general';
import { Interval } from './broadcast-gaps.service';
import { GeneralService } from './general.service';
import { PortalSettingsService } from './portal-settings.service';

@Injectable()
export class BroadcastGapsV3Service {
    private readonly FETCH_OFFSET_BEFORE = 2 * 60 * 60 * 1000; // 2 hours
    private readonly FETCH_OFFSET_AFTER = 2 * 60 * 60 * 1000; // 2 hours
    private readonly CACHE_LENGTH_LIMIT_PER_CHANNEL = 100;

    private broadcastGaps: { [ key: number ]: Array<BroadcastGap> } = {};
    private broadcastGapGroups: { [ key: number ]: Array<BroadcastGapGroup> } = {};
    private broadcastGapGroupsStartOver: { [ key: number ]: Array<BroadcastGapGroup> } = {};
    private broadcastIntervals: { [ key: number ]: Array<Interval> } = {};
    private broadcastIntervalsMargins: { [ key: number ]: Interval } = {};
    private lastTimeHit: { [ key: number ]: number } = {};

    private broadcastGapAdded: Subject<void> = new Subject<void>();
    broadcastGapAdded$: Observable<void> = this.broadcastGapAdded.asObservable();

    constructor(
        private generalService: GeneralService,
        private portalSettingsService: PortalSettingsService,
        private httpClient: HttpClient) {
    }

    public getBroadcastGroupAtTime(channelId: number, time: number, startOver: boolean = false): BroadcastGapGroup {
        const broadcastGapGroups = this.getBroadcastGapsGroup(startOver);
        if (!broadcastGapGroups[ channelId ]) {
            return;
        }
        this.lastTimeHit[ channelId ] = time;
        return broadcastGapGroups[ channelId ].find((broadcastGroup) =>
            time >= broadcastGroup.datetimeFrom && time < broadcastGroup.datetimeTo
        );
    }

    public getActiveOrNextBroadcastGroup(channelId: number, interval: Interval, startOver: boolean = false): BroadcastGapGroup {
        const activeBroadcastGroup = this.getBroadcastGroupAtTime(channelId, interval.from, startOver);
        if (activeBroadcastGroup && activeBroadcastGroup.skippable === false) {
            return activeBroadcastGroup;
        }
        const broadcastGapGroups = this.getBroadcastGapsGroup(startOver);
        if (!broadcastGapGroups[ channelId ]) {
            return;
        }
        return broadcastGapGroups[ channelId ].find((broadcastGroup) =>
            interval.from < broadcastGroup.datetimeFrom && interval.to >= broadcastGroup.datetimeFrom &&
            broadcastGroup.skippable === false);
    }

    public getBroadcastGapGroupsInInterval(channelId: number, interval: Interval, startOver: boolean = false) {
        const broadcastGapGroups = this.getBroadcastGapsGroup(startOver);
        if (!broadcastGapGroups[ channelId ]) {
            return;
        }
        const broadcastGapGroupsWithin: Array<BroadcastGapGroup> = [];
        broadcastGapGroups[ channelId ].forEach((broadcastGroup) => {
            if (broadcastGroup.skippable !== false) {
                return;
            }
            const limitedInterval = this.generateLimitInterval({
                from: broadcastGroup.datetimeFrom,
                to: broadcastGroup.datetimeTo
            }, interval);

            if (limitedInterval) {
                const broadcastGapGroup = {
                    ...broadcastGroup,
                    datetimeFrom: limitedInterval.from,
                    datetimeTo: limitedInterval.to
                };
                if (broadcastGapGroupsWithin.length === 0 ||
                    broadcastGapGroupsWithin[ broadcastGapGroupsWithin.length - 1 ].datetimeTo !== broadcastGapGroup.datetimeFrom) {
                    broadcastGapGroupsWithin.push(broadcastGapGroup);
                } else {
                    broadcastGapGroupsWithin[ broadcastGapGroupsWithin.length - 1 ].datetimeTo = broadcastGapGroup.datetimeTo;
                }
            }
        });
        return broadcastGapGroupsWithin;
    }

    public isStartOver(time: number) {
        return time > Date.now();
    }

    public fetchBroadcastGaps(channelId: number, time: number): Observable<Array<BroadcastGap>> {
        if (this.isCached(channelId, time)) {
            return of(undefined);
        }
        const interval = this.generateFetchInterval(channelId, this.generateInterval(time));
        return this.getBroadcastGaps(channelId, interval).pipe(
            tap((broadcastGaps) => {
                if (this.isCacheFull(channelId)) {
                    this.clearCache(channelId);
                }
                this.storeBroadcastGaps(channelId, broadcastGaps);
                this.storeBroadcastInterval(channelId, interval);
                [ this.broadcastGapGroups[ channelId ], this.broadcastGapGroupsStartOver[ channelId ] ] =
                    this.generateBroadcastGroups(this.broadcastGaps[ channelId ]);
            })
        );
    }

    public addBroadcastGap(channelId: number, broadcastGap: BroadcastGap) {
        const portalSettings = this.portalSettingsService.getPortalSettings();
        if (!portalSettings.ads?.enabled) {
            return;
        }
        this.storeBroadcastGap(channelId, broadcastGap);
        this.storeBroadcastInterval(channelId, { from: broadcastGap.datetimeFrom, to: broadcastGap.datetimeTo });
        [ this.broadcastGapGroups[ channelId ], this.broadcastGapGroupsStartOver[ channelId ] ] =
            this.generateBroadcastGroups(this.broadcastGaps[ channelId ]);
        this.broadcastGapAdded.next();
    }

    private storeBroadcastGaps(channelId: number, broadcastGaps: Array<BroadcastGap>) {
        if (broadcastGaps.length === 0) {
            return;
        }
        this.broadcastGaps[ channelId ] = this.broadcastGaps[ channelId ] || [];
        let newIndex = -1;
        this.broadcastGaps[ channelId ].forEach((item, index) => {
            if (broadcastGaps.length > 0) {
                if (item.id === broadcastGaps[ 0 ].id) {
                    this.broadcastGaps[ channelId ][ index ] = broadcastGaps[ 0 ];
                    newIndex = index;
                    broadcastGaps.splice(0, 1);
                } else if (item.datetimeFrom < broadcastGaps[ 0 ].datetimeFrom) {
                    newIndex = index;
                }
            }
        });
        this.broadcastGaps[ channelId ].splice(newIndex + 1, 0, ...broadcastGaps);
    }

    private storeBroadcastGap(channelId: number, broadcastGap: BroadcastGap) {
        this.broadcastGaps[ channelId ] = this.broadcastGaps[ channelId ] || [];
        const existingIndex = this.broadcastGaps[ channelId ].findIndex(item => item.id === broadcastGap.id);
        if (existingIndex >= 0) {
            this.broadcastGaps[ channelId ][ existingIndex ] = broadcastGap;
        } else {
            let newIndex = -1;
            this.broadcastGaps[ channelId ].forEach((item, index) => {
                if (item.datetimeFrom < broadcastGap.datetimeFrom) {
                    newIndex = index;
                }
            });
            this.broadcastGaps[ channelId ].splice(newIndex + 1, 0, broadcastGap);
        }
    }

    private storeBroadcastInterval(channelId: number, interval: Interval) {
        this.broadcastIntervals[ channelId ] = this.broadcastIntervals[ channelId ] || [];
        if (this.broadcastIntervals[ channelId ].length === 0) {
            this.broadcastIntervals[ channelId ] = [ interval ];
            this.broadcastIntervalsMargins[ channelId ] = {
                from: interval.from,
                to: interval.to
            };
            return;
        }
        let intervals = [];
        for (const broadcastInterval of this.broadcastIntervals[ channelId ]) {
            // before
            if (interval.from < broadcastInterval.from && interval.to < broadcastInterval.to) {
                intervals = [ ...intervals, interval, broadcastInterval ];
                break;
            }
            // over OR same
            if (interval.from <= broadcastInterval.from && interval.to >= broadcastInterval.to) {
                intervals = [ ...intervals, interval ];
                break;
            }
            // inside
            if (interval.from > broadcastInterval.from && interval.to < broadcastInterval.to) {
                intervals = [ ...intervals, broadcastInterval ];
                break;
            }
            // cross left
            if (interval.from <= broadcastInterval.from && interval.to > broadcastInterval.from) {
                intervals = [ ...intervals, { from: interval.from, to: broadcastInterval.to } ];
                break;
            }
            // cross right
            if (interval.from <= broadcastInterval.to && (interval.to > broadcastInterval.to)) {
                intervals = [ ...intervals, { from: broadcastInterval.from, to: interval.to } ];
                break;
            }
            // after
            if (interval.from > broadcastInterval.to) {
                intervals = [ ...intervals, broadcastInterval, interval ];
                break;
            }
        }
        this.broadcastIntervals[ channelId ] = intervals;
        if (interval.from < this.broadcastIntervalsMargins[ channelId ].from) {
            this.broadcastIntervalsMargins[ channelId ].from = interval.from;
        }
        if (interval.to > this.broadcastIntervalsMargins[ channelId ].to) {
            this.broadcastIntervalsMargins[ channelId ].to = interval.to;
        }
    }

    private generateBroadcastGroups(broadcastGaps: BroadcastGap[]): [ BroadcastGapGroup[], BroadcastGapGroup[] ] {
        const broadcastGroups: BroadcastGapGroup[] = [];
        const broadcastGroupsStartOver: BroadcastGapGroup[] = [];
        broadcastGaps.forEach((item, index: number) => {
            this.generateBroadcastGroup(broadcastGroups, item, index, item.skippable);
            this.generateBroadcastGroup(broadcastGroupsStartOver, item, index, item.skippableStartOver);
        });
        return [ broadcastGroups, broadcastGroupsStartOver ];
    }

    private generateBroadcastGroup(broadcastGroups: BroadcastGapGroup[], item: BroadcastGap, index: number, skippable: boolean) {
        const broadcastGroup: BroadcastGapGroup = {
            id: index,
            datetimeFrom: item.datetimeFrom,
            datetimeTo: item.datetimeTo || Date.now(),
            skippable: skippable,
            finished: !!item.datetimeTo
        };
        if (broadcastGroups.length === 0 ||
            broadcastGroups[ broadcastGroups.length - 1 ].skippable !== broadcastGroup.skippable ||
            (broadcastGroups[ broadcastGroups.length - 1 ].datetimeTo !== broadcastGroup.datetimeFrom &&
                broadcastGroups[ broadcastGroups.length - 1 ].finished)) {
            broadcastGroup.broadcastGaps = [ item ];
            broadcastGroups.push(broadcastGroup);
        } else {
            broadcastGroups[ broadcastGroups.length - 1 ].datetimeTo = broadcastGroup.datetimeTo;
            broadcastGroups[ broadcastGroups.length - 1 ].finished = broadcastGroup.finished;
            broadcastGroups[ broadcastGroups.length - 1 ].broadcastGaps.push(item);
        }
    }

    private getBroadcastGaps(channelId: number, interval: Interval): Observable<Array<BroadcastGap>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('from', interval.from.toString());
        httpParams = httpParams.append('to', interval.to.toString());
        return this.httpClient
            .get<Array<BroadcastGap>>(`${ this.generalService.getAdsUrl() }${ channelId }`, { params: httpParams });
    }

    private getBroadcastGapsGroup(startOver: boolean) {
        return startOver ? this.broadcastGapGroupsStartOver : this.broadcastGapGroups;
    }


    private isCached(channelId: number, time: number) {
        if (!this.broadcastIntervals[ channelId ]) {
            return;
        }
        return this.broadcastIntervals[ channelId ]
            .some(broadcastInterval => broadcastInterval.from <= time && (broadcastInterval.to > time));
    }

    private isCacheFull(channelId: number) {
        return this.broadcastGaps[ channelId ] && this.broadcastGaps[ channelId ].length >= this.CACHE_LENGTH_LIMIT_PER_CHANNEL;
    }

    private clearCache(channelId: number) {
        if (this.broadcastIntervals[ channelId ].length === 0) {
            return;
        }
        const middleTime = Math.floor(this.broadcastIntervalsMargins[ channelId ].from +
            ((this.broadcastIntervalsMargins[ channelId ].to || Date.now()) - this.broadcastIntervalsMargins[ channelId ].from) / 2);
        let deleteInterval: Interval;
        if (this.lastTimeHit[ channelId ] > middleTime) {
            deleteInterval = {
                from: this.broadcastIntervalsMargins[ channelId ].from,
                to: middleTime
            };
        } else {
            deleteInterval = {
                from: middleTime,
                to: this.broadcastIntervalsMargins[ channelId ].to
            };
        }
        let intervals = [];
        for (const broadcastInterval of this.broadcastIntervals[ channelId ]) {
            // inside, delete
            if (broadcastInterval.from >= deleteInterval.from && broadcastInterval.to <= deleteInterval.to) {
                intervals = [ ...intervals ];
                continue;
            }
            // cross left
            if (broadcastInterval.from < deleteInterval.from && broadcastInterval.to > deleteInterval.from) {
                intervals = [ ...intervals, { from: broadcastInterval.from, to: deleteInterval.from } ];
                continue;
            }
            // cross right
            if (broadcastInterval.from < deleteInterval.to && (broadcastInterval.to > deleteInterval.to)) {
                intervals = [ ...intervals, { from: deleteInterval.to, to: broadcastInterval.to } ];
                continue;
            }
            // outside
            intervals = [ ...intervals, broadcastInterval ];
        }
        this.broadcastIntervals[ channelId ] = intervals;
        this.recalculateMarginIntervals(channelId);
        this.broadcastGaps[ channelId ] = this.broadcastGaps[ channelId ].filter(item =>
            item.datetimeFrom >= this.broadcastIntervalsMargins[ channelId ].from &&
            item.datetimeTo <= this.broadcastIntervalsMargins[ channelId ].to
        );
    }

    private recalculateMarginIntervals(channelId: number) {
        if (this.broadcastIntervals[ channelId ].length === 0) {
            this.broadcastIntervalsMargins[ channelId ] = undefined;
            return;
        }
        const firstItem = this.broadcastIntervals[ channelId ][ 0 ];
        const lastItem = this.broadcastIntervals[ channelId ][ this.broadcastIntervals[ channelId ].length - 1 ];
        this.broadcastIntervalsMargins[ channelId ] = {
            from: firstItem.from,
            to: lastItem.to
        };
    }

    private generateLimitInterval(broadcastGroupInterval: Interval, limitedInterval: Interval) {
        // inside
        if (broadcastGroupInterval.from >= limitedInterval.from && broadcastGroupInterval.to <= limitedInterval.to) {
            return broadcastGroupInterval;
        }
        // over
        if (broadcastGroupInterval.from < limitedInterval.from && broadcastGroupInterval.to > limitedInterval.to) {
            return limitedInterval;
        }
        // cross left
        if (broadcastGroupInterval.from < limitedInterval.from && broadcastGroupInterval.to >= limitedInterval.from) {
            return { from: limitedInterval.from, to: broadcastGroupInterval.to };
        }
        // cross right
        if (broadcastGroupInterval.from <= limitedInterval.to && (broadcastGroupInterval.to > limitedInterval.to)) {
            return { from: broadcastGroupInterval.from, to: limitedInterval.to };
        }
    }

    private generateInterval(time: number) {
        return {
            from: time - this.FETCH_OFFSET_BEFORE,
            to: Math.min(time + this.FETCH_OFFSET_AFTER, Date.now())
        };
    }

    private generateFetchInterval(channelId: number, interval: Interval): Interval {
        if (this.broadcastIntervals[ channelId ]) {
            for (const broadcastInterval of this.broadcastIntervals[ channelId ]) {
                // inside
                if (interval.from >= broadcastInterval.from && interval.to <= broadcastInterval.to) {
                    return;
                }
                // cross left
                if (interval.from < broadcastInterval.from && interval.to >= broadcastInterval.from) {
                    return { from: interval.from, to: broadcastInterval.from };
                }
                // cross right
                if (interval.from <= broadcastInterval.to && interval.to > broadcastInterval.to) {
                    return { from: broadcastInterval.to, to: interval.to };
                }
            }
        }
        return interval;
    }
}
