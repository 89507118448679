import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { hal } from '@kuki/platforms/hal';

@Injectable()
export class VisibleService {

    private visibleList: Array<string> = [];
    private visibleDefaultList = {
        TV: [ 'npvr-stats', 'beta-label', 'time', 'profile-icon' ],
        WEB: [ 'header', 'footer', 'npvr-stats', 'beta-label', 'arrow-back', 'profile', 'search' ]
    };
    private visibilityChanged: Subject<Array<string>> = new Subject<Array<string>>();
    public visibilityChanged$: Observable<Array<string>> = this.visibilityChanged.asObservable();

    constructor() {
    }

    public show(...visibleList) {
        if (visibleList.length === 0) {
            this.visibleList = this.getVisibleDefaultList(hal.appMode);
        }
        visibleList.forEach(visibleItem => {
            if (!this.isVisible(visibleItem)) {
                this.visibleList.push(visibleItem);
            }
        });
        this.visibilityChanged.next(visibleList);
    }

    public hide(...visibleList) {
        this.visibleList = this.visibleList.filter(visibleItem => !visibleList.some(item => item === visibleItem));
        this.visibilityChanged.next();
        this.visibilityChanged.next(visibleList);
    }

    public clear() {
        this.visibleList = [];
        this.visibilityChanged.next(null);
    }

    public isVisible(visible) {
        return this.visibleList.some(item => item === visible);
    }

    public getVisibleDefaultList(type: string) {
        return [ ...this.visibleDefaultList[ type ] ];
    }
}
