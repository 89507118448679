import { Injectable } from '@angular/core';
import { Watched } from '@kuki/global/shared/types/state/watched';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class WatchedService {

    private watched: Array<Watched> = [];
    private watchedUpdated: Subject<void> = new Subject<void>();
    public watchedUpdated$: Observable<void> = this.watchedUpdated.asObservable();

    public storeWatched(watched: Watched, tsDelayLimit: number) {
        const existsIndex = this.watched.findIndex(watchedItem =>
            watchedItem.entityId === watched.entityId && watchedItem.playing === watched.playing);
        if (existsIndex >= 0) {
            const prevWatchedItem = this.watched[ existsIndex ];
            this.watched [ existsIndex ] = {
                ...this.watched [ existsIndex ],
                // lock if locked or greater then delay limit
                locked:
                // using prev data because of possible seeking
                    prevWatchedItem.locked || !tsDelayLimit || ((prevWatchedItem.position - prevWatchedItem.startTime) > tsDelayLimit),
                lastWatched: Date.now(),
                published: false,
                ...watched
            };
        } else {
            this.watched.push({
                ...watched,
                startTime: watched.position,
                lastWatched: Date.now(),
                published: false,
                locked: !tsDelayLimit
            });
        }
        this.watchedUpdated.next();
    }

    public seekWatched(watched: Watched) {
        const existSeekItem = this.watched.findIndex(watchedItem =>
            watchedItem.entityId === watched.entityId && watchedItem.playing === watched.playing);
        if (existSeekItem >= 0) {
            this.watched [ existSeekItem ] = {
                ...this.watched [ existSeekItem ],
                ...watched,
                // rewrite start time
                startTime: watched.position,
                lastWatched: Date.now()
            };
            this.watchedUpdated.next();
        }
    }

    public publishWatched(watched: Watched) {
        const existPublishItem = this.watched.findIndex(watchedItem =>
            watchedItem.entityId === watched.entityId && watchedItem.playing === watched.playing);
        if (existPublishItem >= 0) {
            this.watched[ existPublishItem ] = {
                ...this.watched [ existPublishItem ],
                published: true
            };
        }
        this.watchedUpdated.next();
    }

    public clearWatched(watched: Watched) {
        this.watched = this.watched.filter(watchedItem =>
            !(watchedItem.entityId === watched.entityId && watchedItem.playing === watched.playing));
        this.watchedUpdated.next();
    }

    public clearDelayWatched(delayLimit: number) {
        this.watched = this.watched.map(item => {
            let insertIfNotFound = true;
            if (item.playing === 'ts' && !item.locked && item.position - item.startTime < delayLimit) {
                insertIfNotFound = false;
            }
            return {
                ...item,
                insertIfNotFound: insertIfNotFound
            };
        });
        this.watchedUpdated.next();
    }

    public clear() {
        this.watched = [];
        this.watchedUpdated.next();
    }

    public getWatched() {
        return this.watched;
    }

    public getWatchedItem(epgEntityId: number, playing: 'vod' | 'npvr-k2' | 'episode' | 'ts') {
        return this.watched.find(item => item.entityId === epgEntityId && item.playing === playing);
    }
}
