import { environment } from '@kuki/environments/environment';
import { AuthService } from '@kuki/global/sections/auth/auth.service';

export class JwtOptionsClass {
    static allowedDomainsDynamic = environment.whitelistedDomains;

    authScheme = '';
    headerName = 'X-SessionKey';
    disallowedRoutes = environment.blacklistedRoutes;
    allowedDomains = JwtOptionsClass.allowedDomainsDynamic;

    constructor(private authService: AuthService) {
    }

    tokenGetter = () => {
        return this.authService.getAuthData()?.session_key;
    };

    static addToDomainWhitelist(domain) {
        JwtOptionsClass.allowedDomainsDynamic.push(domain);
    }
}
