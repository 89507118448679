import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { environment } from '@kuki/environments/environment';
import { Channel, EpgEntityAtTime } from '@kuki/global/shared/types/general';

@Injectable()
export class ChannelService {

    private readonly CHANNELS_REFRESH_INTERVAL = 2 * 60 * 60 * 1000; // 2 hours

    private channelList: Array<Channel>;
    private channelListMap: { [ key: number ]: Channel };
    private channelListIndexMap: { [ key: number ]: number };
    private channelListNumberMap: { [ key: string ]: Channel };

    private channelListVisible: Array<Channel>;
    private channelListVisibleIndexMap: { [ key: number ]: number };

    private channelsUpdated: ReplaySubject<void> = new ReplaySubject<void>();
    public channelsUpdated$: Observable<void> = this.channelsUpdated.asObservable();

    private subscription: SubscriptionObject = {};

    constructor(
        private httpClient: HttpClient,
        private ngZone: NgZone) {
    }


    public fetchChannelList(): Observable<Array<Channel>> {
        if (!this.subscription.channelsRefreshInterval) {
            this.ngZone.runOutsideAngular(() => {
                this.startChannelsRefreshInterval();
            });
        }
        return this.httpClient
            .get<Array<Channel>>(environment.apiUrl + 'channel-list')
            .pipe(tap(channelList => {
                this.channelList = channelList;
                this.channelListMap = {};
                this.channelListIndexMap = {};
                this.channelListNumberMap = {};
                this.channelListVisible = [];
                this.channelListVisibleIndexMap = {};
                this.channelList.forEach((channel, index) => {
                    channel.guid = 'channel:' + channel.id;
                    this.channelListMap[ channel.id ] = channel;
                    this.channelListIndexMap[ channel.id ] = index;
                    this.channelListNumberMap[ channel.channelNumber ] = channel;
                    if (!channel.hidden) {
                        this.channelListVisible.push(channel);
                    }
                });
                this.channelListVisible.forEach((channel, index) => {
                    this.channelListVisibleIndexMap[ channel.id ] = index;
                });
                this.channelsUpdated.next();
            }));
    }

    public getChannel(id: number): Channel {
        return this.channelListMap[ id ];
    }

    public getChannelList() {
        return this.channelList;
    }

    public getChannelIdent(id: number): string {
        const channel = this.getChannel(id);
        return channel ? channel.ident : undefined;
    }

    public getEpgEntityAtTime(id: number, startTime: number): Observable<EpgEntityAtTime> {
        return this.httpClient.get<EpgEntityAtTime>(`${ environment.apiUrl }epg-entity-at-time/${ id }/${ startTime }`);
    }

    public getChannelBefore(id: number): Channel {
        const activeChannelIndex = this.channelListVisibleIndexMap[ id ];
        return (activeChannelIndex === 0) ?
            this.channelListVisible[ this.channelListVisible.length - 1 ] : this.channelListVisible[ activeChannelIndex - 1 ];
    }

    public getChannelAfter(id: number): Channel {
        const activeChannelIndex = this.channelListVisibleIndexMap[ id ];
        return (activeChannelIndex === this.channelListVisible.length - 1) ?
            this.channelListVisible[ 0 ] : this.channelListVisible[ activeChannelIndex + 1 ];
    }

    public getChannelByNumber(channelNumber: string): Channel {
        const channel = this.channelListNumberMap[ channelNumber ];
        return !channel?.hidden ? channel : undefined;
    }

    public getMaxChannelNumberLength() {
        let max = 0;
        this.channelList.forEach((channel) => {
            if (channel.channelNumber.length > max) {
                max = channel.channelNumber.length;
            }
        });
        return max;
    }

    private startChannelsRefreshInterval() {
        SOM.clearSubscriptions(this.subscription.channelsRefreshInterval);
        this.subscription.channelsRefreshInterval =
            timer(Math.floor(Math.random() * this.CHANNELS_REFRESH_INTERVAL), this.CHANNELS_REFRESH_INTERVAL).pipe(switchMap(() => {
                return this.fetchChannelList();
            })).subscribe();
    }

    public clearChannels() {
        this.channelList = null;
    }

    public destroy() {
        SOM.clearSubscriptionsObject(this.subscription);
        this.subscription = {};
    }
}
