import { Injectable } from '@angular/core';

export interface History {
    past: Array<any>;
    present: any;
    future: Array<any>;
}

@Injectable()
export class HistoryService {
    private readonly historyCap = 20;
    private history: History = {
        past: [],
        present: {},
        future: []
    };

    public undo() {
        const previous = this.history.past[ this.history.past.length - 1 ];
        const newPast = this.history.past.slice(0, this.history.past.length - 1);
        this.history = {
            past: newPast,
            present: previous || {},
            future: [ this.history.present, ...this.history.future ]
        };
    }

    public redo() {
        const next = this.history.future[ 0 ];
        const newFuture = this.history.future.slice(1);
        this.history = {
            past: [ ...this.history.past, this.history.present ],
            present: next || {},
            future: newFuture
        };
    }

    public addToSave(payload) {
        this.history = {
            past: this.history.past,
            present: { ...this.history.present, ...payload },
            future: this.history.future
        };
    }

    public save(payload) {
        const newPresent = { ...this.history.present, ...payload };
        const newPast = this.history.past.length >= this.historyCap ? this.history.past.slice(1) : [ ...this.history.past ];
        this.history = {
            past: [ ...newPast, newPresent ],
            present: {},
            future: []
        };
    }

    public clear() {
        this.history = {
            past: [],
            present: {},
            future: []
        };
    }

    public getHistory() {
        return { ...this.history };
    }

    public log() {
        console.log('past', this.history.past);
        console.log('present', this.history.present);
        console.log('future', this.history.future);
    }

}
