import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionControlDirective } from './section-control.directive';
import { SectionControlRootDirective } from './section-control-root.directive';
import { SectionControlService } from './section-control.service';

@NgModule({
    declarations: [
        SectionControlDirective,
        SectionControlRootDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SectionControlDirective,
        SectionControlRootDirective
    ],
    providers: []
})
export class SectionControlModule {
    static forRoot(): ModuleWithProviders<SectionControlModule> {
        return {
            ngModule: SectionControlModule,
            providers: [
                SectionControlService
            ]
        };
    }
}
