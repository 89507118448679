type KeyDefValue = number | string | Array<number> | Array<string>;

export enum CommonKeys {
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',

    CH_UP = 'CH_UP',
    CH_DOWN = 'CH_DOWN',

    OK = 'OK',
    BACK = 'BACK',

    PLAY = 'PLAY',
    PAUSE = 'PAUSE',
    STOP = 'STOP',
    REWIND = 'REWIND',
    FORWARD = 'FORWARD',
    PREV = 'PREV',
    NEXT = 'NEXT',

    REC = 'REC',

    N_0 = 'N_0',
    N_1 = 'N_1',
    N_2 = 'N_2',
    N_3 = 'N_3',
    N_4 = 'N_4',
    N_5 = 'N_5',
    N_6 = 'N_6',
    N_7 = 'N_7',
    N_8 = 'N_8',
    N_9 = 'N_9',

    COLOR_RED = 'COLOR_RED',
    COLOR_GREEN = 'COLOR_GREEN',
    COLOR_YELLOW = 'COLOR_YELLOW',
    COLOR_BLUE = 'COLOR_BLUE',

    PC_BACK = 'PC_BACK',
    PC_TURN_ON = 'PC_TURN_ON',
    PC_TURN_OFF = 'PC_TURN_OFF',

    GRP_BACK = 'GRP_BACK',
    GRP_MENU = 'GRP_MENU',
    GRP_INFO = 'GRP_INFO',
    GRP_TV = 'GRP_TV',
    GRP_NUM_KEY = 'GRP_NUM_KEY',
    GRP_EPG = 'GRP_EPG',
    GRP_REC = 'GRP_REC',
    GRP_ARROW = 'GRP_ARROW',
    GRP_PLAY = 'GRP_PLAY'
}

export enum AndroidTVKeys {
    DOUBLE_VOLUME = 'DOUBLE_VOLUME',
    TV = 'TV',
    INFO = 'INFO',
    TELETEXT = 'TELETEXT',
    AUDIO = 'AUDIO',
    TVNETWORK = 'TVNETWORK',
    EXIT = 'EXIT',
    REFRESH = 'REFRESH',
    GUIDE = 'GUIDE',
    PHILIPS_TV = 'PHILIPS_TV',
    SEARCH = 'SEARCH',
    SUBTITLES = 'SUBTITLES',
    GAMEPAD_BUTTON_X = 'GAMEPAD_BUTTON_X',
    GAMEPAD_BUTTON_Y = 'GAMEPAD_BUTTON_Y',
    GAMEPAD_BUTTON_A = 'GAMEPAD_BUTTON_A',
    GAMEPAD_BUTTON_B = 'GAMEPAD_BUTTON_B'
}

export enum TizenKeys {
    TV = 'TV',
    EXTRA = 'EXTRA',
    MENU = 'MENU',
    EPG = 'EPG',
    CHLIST = 'CHLIST',
    PAUSEPLAY_COMBO = 'PAUSEPLAY_COMBO'
}

export enum WebosKeys {
    INFO = 'INFO',
    SUBTITLE = 'SUBTITLE',
    N_0 = 'N_0',
    N_1 = 'N_1',
    N_2 = 'N_2',
    N_3 = 'N_3',
    N_4 = 'N_4',
    N_5 = 'N_5',
    N_6 = 'N_6',
    N_7 = 'N_7',
    N_8 = 'N_8',
    N_9 = 'N_9'
}

export enum ArrisKeys {
    TXT = 'TXT',
    INFO = 'INFO',
    EPG = 'EPG',
    MUTE = 'MUTE',
    VOLUME_UP = 'VOLUME_UP',
    VOLUME_DOWN = 'VOLUME_DOWN',
    SEARCH = 'SEARCH',
    PAUSEPLAY_COMBO = 'PAUSEPLAY_COMBO',
    VOD = 'VOD',
    POWERBTN = 'POWERBTN'
}

export enum WebKeys {
    SPACE = 'SPACE',
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    SLASH = 'SLASH'
}

export enum AndroidKeys {
    VOLUME_UP = 'VOLUME_UP',
    VOLUME_DOWN = 'VOLUME_DOWN'
}

export type ActionKey = CommonKeys | AndroidTVKeys | TizenKeys | WebosKeys | ArrisKeys | WebKeys | AndroidKeys;

export type CommonKeyMapModel = { [key in CommonKeys]: KeyDefValue };
export type AndroidTVKeyMapModel = { [key in CommonKeys | AndroidTVKeys]: KeyDefValue };
export type TizenKeyMapModel = { [key in CommonKeys | TizenKeys]: KeyDefValue };
export type WebosKeyMapModel = { [key in CommonKeys | WebosKeys]: KeyDefValue };
export type ArrisKeyMapModel = { [key in CommonKeys | ArrisKeys]: KeyDefValue };
export type WebKeyMapModel = { [key in WebKeys]: KeyDefValue };
export type AndroidKeyMapModel = { [key in AndroidKeys]: KeyDefValue };
export type KeyMapModel = CommonKeyMapModel
    | AndroidTVKeyMapModel
    | TizenKeyMapModel
    | WebosKeyMapModel
    | ArrisKeyMapModel
    | WebKeyMapModel
    | AndroidKeyMapModel;

export const GroupKeys = {
    GRP_NUMBER: [
        CommonKeys.N_0, CommonKeys.N_1, CommonKeys.N_2, CommonKeys.N_3, CommonKeys.N_4,
        CommonKeys.N_5, CommonKeys.N_6, CommonKeys.N_7, CommonKeys.N_8, CommonKeys.N_9
    ]
};
