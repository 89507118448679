import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelsPanelComponent } from './labels-panel.component';
import { TextLabelsComponent } from './text-labels/text-labels.component';
import { BoxLabelsComponent } from './box-labels/box-labels.component';
import { TranslateModule } from '@ngx-translate/core';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';


@NgModule({
    declarations: [ LabelsPanelComponent, TextLabelsComponent, BoxLabelsComponent ],
    imports: [
        CommonModule,
        TranslateModule,
        AutoScrollContainerModule
    ],
    exports: [
        LabelsPanelComponent,
        BoxLabelsComponent,
        TextLabelsComponent
    ]
})
export class LabelsPanelModule {
}
