export const UI_VARIABLES_ARRIS_1080 = {
    maxWidth: 1920,
    maxHeight: 1080,
    containerWidth: 1870,
    gridColumns: 42,
    gridColumn: 25,
    gridGutter: 20,
    baseline: 15,
    flush: 25,
};
