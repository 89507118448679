<span class="label label-live"
      *ngIf="media.tile.state === MediaState.LIVE">{{ 'GENERAL.LABEL.LIVE' | translate }}</span>
<ng-container *ngIf="media.tile.stickers">
        <span *ngFor="let sticker of media.tile.stickers"
              class="label label-sticker">{{ sticker }}</span>
</ng-container>
<span *ngIf="media.tile.restrictionLabel; let reason"
      class="label label-restriction">{{ reason }}</span>
<span *ngIf="media.tile.isAgeRestricted" class="label label-locked">{{ 'GENERAL.LABEL.LOCKED' | translate }}</span>
<span class="label label-quality"
      *ngIf="media.quality">{{ media.quality }}</span>
<span class="label label-age-limit"
      *ngIf="media.ageLimit">{{ media.ageLimit }}</span>
<ng-container *ngIf="showTags">
        <span class="label label-tag" *ngFor="let tag of tags">
        <span [ngClass]="getIcon(tag)"></span>
    </span>
</ng-container>
