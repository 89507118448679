import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArrayByIndex',
    pure: false
})
export class FilterArrayByIndexPipe implements PipeTransform {

    transform(items: any[], tiles: Array<any>): any {
        if (!items || !tiles) {
            return [];
        }
        return items.filter(item => !!tiles[ item ]);
    }

}
