import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BetaLabelComponent } from './beta-label.component';

@NgModule({
    declarations: [
        BetaLabelComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        BetaLabelComponent
    ]
})
export class BetaLabelModule {
}
