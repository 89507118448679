export enum TileTypes {
    BUTTON = 'BUTTON',
    CARROUSEL = 'CARROUSEL',
    DEVICE_TELEPORT = 'DEVICE_TELEPORT',
    EPG = 'EPG',
    EPISODE = 'EPISODE',
    IMAGE = 'IMAGE',
    MEDIA = 'MEDIA',
    NPVR = 'NPVR',
    PERSON = 'PERSON',
    PROFILE = 'PROFILE',
    VOD = 'VOD',
    PROFILE_ICON = 'PROFILE_ICON',
    CHANNEL = 'CHANNEL'
}
