import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {

    transform(time: number): string {
        let output = '';
        if (!time) {
            return;
        }
        const date = new Date(time);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        if (hours) {
            output += `${hours}h`;
        }
        if (minutes) {
            output += ((output.length > 0) ? ' ' : '') + `${minutes}m`;
        }
        return output;
    }
}
