import { Injectable } from '@angular/core';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { AudioTrack, SubtitleTrack } from '@kuki/global/shared/types/general';
import { MediaPlayerError, MediaPlayerErrors } from '@kuki/global/shared/types/media-player';
import { IosPlatformHalService } from '@kuki/platforms/mobile/ios/ios-platform-hal.service';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

declare var cordova: any;

@Injectable()
export class ShakaPlayerEmbeddedService implements MediaPlayerHalInterface {
    public readonly hevc = false;
    public readonly nativeBuffer = false;

    private audioTracks: Array<AudioTrack> = [];
    private subtitleTracks: Array<SubtitleTrack> = [];
    private activeAudioTrack: AudioTrack;
    private activeSubtitleTrack: SubtitleTrack;
    private wvServer: string;
    private sessionKey: string;

    public buffering$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public error$: Subject<MediaPlayerError> = new Subject<MediaPlayerError>();
    public tracksUpdated$?: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public trackActivated$?: ReplaySubject<void> = new ReplaySubject(1);
    public airplayAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public airplayConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public requestAirplayUrl$: ReplaySubject<void> = new ReplaySubject<void>(1);

    constructor(
        private iosPlatformHalService: IosPlatformHalService,
        private portalSettingsService: PortalSettingsService) {
    }

    public initOnStartup() {
        const portalSettings = this.portalSettingsService.getPortalSettings();
        this.wvServer = portalSettings.mediaPlayer.widevineProxy + '?sessionKey=' + this.sessionKey;
        cordova.exec(
            (data) => this.onPlayerCallback(data),
            () => {
                console.log('ShakaPlayerPlugin callback error');
            },
            'ShakaPlayerPlugin',
            'playerCallback'
        );
    }

    public init() {
        cordova.exec(
            () => {
                console.log('ShakaPlayerPlugin initPlayer success');
            },
            () => {
                console.log('ShakaPlayerPlugin initPlayer error');
            },
            'ShakaPlayerPlugin',
            'initPlayer',
            [ this.wvServer ]
        );
    }

    public play(streamUrl: string) {
        return new Promise((resolve, reject) => {
            this.tracksUpdated$.next(false);
            if (this.airplayConnected$.getValue()) {
                this.requestAirplayUrl$.next();
                resolve();
                return;
            }
            cordova.exec(
                () => {
                    resolve();
                    console.log('startVideoPlayback success');
                },
                () => {
                    this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR });
                    reject();
                    console.error('startVideoPlayback error');
                },
                'ShakaPlayerPlugin',
                'play',
                [ streamUrl ]
            );
        });
    }

    public pause() {
        return new Promise((resolve, reject) => {
            cordova.exec(
                () => {
                    console.log('pauseVideoPlayback success');
                    resolve();
                },
                () => {
                    console.error('pauseVideoPlayback error');
                    reject();
                },
                'ShakaPlayerPlugin',
                'pause'
            );
        });
    }

    public stop() {
        return new Promise((resolve, reject) => {
            cordova.exec(
                () => {
                    console.log('stopVideoPlayback success');
                    resolve();
                },
                () => {
                    console.error('stopVideoPlayback error');
                    reject();
                },
                'ShakaPlayerPlugin',
                'stop'
            );
        });
    }

    public activateAudioTrack(id: number) {
        return new Promise((resolve, reject) => {
            const audioTrack = this.audioTracks.find(audioTrackItem => audioTrackItem.id === id);
            cordova.exec(
                () => {
                    this.activeAudioTrack = audioTrack;
                    this.trackActivated$.next();
                    console.log('setTrack success');
                    resolve();
                },
                () => {
                    console.error('setTrack error');
                    reject();
                },
                'ShakaPlayerPlugin',
                'selectAudioTrack',
                [ audioTrack.language, audioTrack.layout ]
            );
        });
    }

    public activateSubtitleTrack(id: number) {
        return new Promise((resolve, reject) => {
            if (id !== undefined) {
                const subtitleTrack = this.subtitleTracks.find(subtitleTrackItem => subtitleTrackItem.id === id);
                cordova.exec(
                    () => {
                        this.activeSubtitleTrack = subtitleTrack;
                        this.trackActivated$.next();
                        console.log('setTrack success');
                        resolve();
                    },
                    () => {
                        console.error('setTrack error');
                        reject();
                    },
                    'ShakaPlayerPlugin',
                    'selectSubtitleTrack',
                    [ subtitleTrack.language, subtitleTrack.role ]
                );
            } else {
                cordova.exec(
                    () => {
                        this.activeSubtitleTrack = undefined;
                        this.trackActivated$.next();
                        resolve();
                    },
                    () => {
                        console.error('disableSubtitles error');
                        reject();
                    },
                    'ShakaPlayerPlugin',
                    'disableSubtitles',
                );
            }
        });
    }

    public refreshTracks() {
        cordova.exec(
            () => {
                console.log('resetTracks success');
                this.activeAudioTrack = undefined;
                this.activeSubtitleTrack = undefined;
                this.audioTracks = [];
                this.subtitleTracks = [];
                this.tracksUpdated$.next(false);
            },
            () => console.error('resetTracks error'),
            'ShakaPlayerPlugin',
            'resetTracks'
        );
    }

    public requestAirplay() {
        cordova.exec(
            () => {
                console.log('requestAirplay success');
            },
            () => console.error('requestAirplay error'),
            'ShakaPlayerPlugin',
            'requestAirplay'
        );
    }

    public playAirplay(streamUrl: string) {
        return new Promise((resolve, reject) => {
            this.tracksUpdated$.next(false);
            cordova.exec(
                () => {
                    resolve();
                    console.log('startVideoPlayback success');
                },
                () => {
                    this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR });
                    reject();
                    console.error('startVideoPlayback error');
                },
                'ShakaPlayerPlugin',
                'play',
                [ streamUrl ]
            );
        });
    }

    public playBackground(streamUrl: string) {
        return new Promise((resolve, reject) => {
            cordova.exec(
                () => {
                    resolve();
                    console.log('playBackground success');
                },
                () => {
                    this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR });
                    reject();
                    console.error('playBackground error');
                },
                'ShakaPlayerPlugin',
                'playBackground',
                [ streamUrl ]
            );
        });
    }

    public playForeground(streamUrl: string) {
        return new Promise((resolve, reject) => {
            this.tracksUpdated$.next(false);
            cordova.exec(
                () => {
                    resolve();
                    console.log('playForeground success');
                },
                () => {
                    this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR });
                    reject();
                    console.error('playForeground error');
                },
                'ShakaPlayerPlugin',
                'playForeground',
                [ streamUrl ]
            );
        });
    }

    public getAudioTracks(): Array<AudioTrack> {
        return this.audioTracks;
    }

    public getSubtitleTracks(): Array<SubtitleTrack> {
        return this.subtitleTracks;
    }

    public getActiveAudioTrack(): AudioTrack {
        return this.activeAudioTrack;
    }

    public getActiveSubtitleTrack(): SubtitleTrack {
        return this.activeSubtitleTrack;
    }

    public setSessionKey(sessionKey: string): void {
        this.sessionKey = sessionKey;
    }

    public getPlayerDebug(): Promise<string> {
        return new Promise((resolve, reject) => {
            cordova.exec(
                (data) => {
                    const s = data.stats;
                    const bi = data.bufferedInfo;
                    const currentTime = data.currentTime;
                    if (!s) {
                        return Promise.resolve('');
                    }
                    let dbg = `BW:${ Math.round(s.estimatedBandwidth / 1024) || '' } / ${ Math.round(s.streamBandwidth / 1024) || '' } ` +
                        `BT:${ (Math.round(s.bufferingTime * 100) / 100) || '' } DF:${ s.droppedFrames || '' }`;
                    if (bi) {
                        dbg += ` BI:${ Math.round(bi.start) || '' }-` +
                            `${ Math.round(currentTime) || '' }-` +
                            `${ Math.round(bi.end) || '' } (${ bi.total || '' })`;
                    }
                    resolve(dbg);
                },
                () => reject('---'),
                'ShakaPlayerPlugin',
                'getPlayerDebug'
            );
        });
    }

    public destroy() {
        console.log('destroy shaka player embedded');
        this.audioTracks = [];
        this.subtitleTracks = [];
        this.activeAudioTrack = undefined;
        this.activeSubtitleTrack = undefined;

        this.buffering$ = new BehaviorSubject(false);
        this.error$ = new Subject<MediaPlayerError>();
        this.tracksUpdated$ = new BehaviorSubject(false);
        this.trackActivated$ = new ReplaySubject<void>(1);

        cordova.exec(
            () => {
                console.log('ShakaPlayerPlugin destroyPlayer success');
            },
            () => {
                console.log('ShakaPlayerPlugin destroyPlayer error');
            },
            'ShakaPlayerPlugin',
            'destroyPlayer'
        );
    }

    private onPlayerCallback(data: any) {
        console.log('onPlayerCallback', JSON.stringify(data));
        switch (data.type) {
            case 'BUFFERING':
                this.buffering$.next(data.value);
                break;
            case 'TRACKS_UPDATED':
                this.audioTracks = data.audioTracks;
                this.subtitleTracks = data.subtitleTracks;
                if (this.activeAudioTrack) {
                    let activeAudioTrack = this.audioTracks
                        .find(track => track.language === this.activeAudioTrack.language && track.layout === this.activeAudioTrack.layout);
                    if (!activeAudioTrack) {
                        activeAudioTrack = this.audioTracks
                            .find(track => track.language === this.activeAudioTrack.language);
                    }
                    this.activeAudioTrack = activeAudioTrack;
                }
                this.tracksUpdated$.next(true);
                break;
            case 'AIRPLAY_AVAILABILITY_CHANGED':
                this.airplayAvailable$.next(data.value);
                break;
            case 'AIRPLAY_CONNECTED':
                this.airplayConnected$.next(data.value);
                break;
            case 'AIRPLAY_REQUEST_PLAY':
                if (this.airplayConnected$.getValue()) {
                    this.requestAirplayUrl$.next();
                }
                break;
            case 'APP_PAUSE':
                this.iosPlatformHalService.onAppPause();
                break;
            case 'APP_RESUME':
                this.iosPlatformHalService.onAppResume();
                break;
        }
    }
}
