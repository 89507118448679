import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { StartupService } from '@kuki/global/shared/services/startup.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProfileService } from '@kuki/global/shared/services/profile.service';

@Injectable()
export class ProfileGuardService implements CanActivate {

    constructor(
        private startupService: StartupService,
        private navigationService: NavigationService,
        private profileService: ProfileService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.startupService.onReady$.pipe(switchMap(() => {
            if (!this.profileService.getActiveProfile()) {
                const profiles = this.profileService.getProfiles();
                if (profiles.length && profiles.length === 1) {
                    if (!profiles[ 0 ].pin) {
                        return this.profileService.activateProfileProcess(profiles[ 0 ].id).pipe(map(() => true), catchError(() => {
                            return of(false);
                        }));
                    }
                }
            }
            return of(true);
        }), catchError(() => {
            return of(false);
        }));
    }
}
