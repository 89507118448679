import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowBackComponent } from './arrow-back.component';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        TranslateModule
    ],
    declarations: [ ArrowBackComponent ],
    exports: [ ArrowBackComponent ]
})
export class ArrowBackModule {
}
