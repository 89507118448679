import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-countdown-box',
    templateUrl: './countdown-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownBoxComponent {

    @Input() label: string;
    @Input() duration: number;
    @Input() total: number;

    constructor() {
    }

    public getStyle() {
        return {
            width: (this.duration / this.total) * 100 + '%'
        };
    }

    getProgress() {
        return Math.floor(this.duration / 1000) + 1;
    }
}
