import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ChannelSwitcherService } from '@kuki/global/features/channel-switcher/channel-switcher.service';
import { switchMap } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { timer } from 'rxjs';

@Component({
    selector: 'app-channel-switcher',
    templateUrl: './channel-switcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelSwitcherComponent implements OnInit, OnDestroy {

    public channelNumber: string = '';

    private subscription: SubscriptionObject = {};

    constructor(
        private channelSwitcherService: ChannelSwitcherService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone) {
    }

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this.subscription.channelSwitching = this.channelSwitcherService.channelSwitching$
                .pipe(switchMap((channelNumber) => {
                    this.channelNumber = channelNumber;
                    this.changeDetectorRef.detectChanges();
                    return timer(2000);
                })).subscribe(() => {
                    this.channelNumber = '';
                    this.changeDetectorRef.detectChanges();
                });
        });
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
