<div *ngFor="let notification of notifications"
     class="notification"
     [ngClass]="getNotificationClass(notification)"
     (click)="onNotificationClick(notification, $event)">
    <div class="notification-icon" [ngClass]="getNotificationIcons(notification)"></div>
    <div class="notification-content">
        <div class="notification-title">{{ notification.label | translate }}</div>
        <div *ngIf="notification.text"
             class="notification-text"
             [innerHTML]="notification.text"></div>
        <app-buttons-panel *ngIf="notification.buttons && notification.buttons.length > 0"
                           [ident]="notification.guid"
                           [buttons]="notification.buttons"
                           [propagateDefault]="false"
                           (action)="onButtonAction(notification, $event)"
                           #buttonsPanel></app-buttons-panel>
    </div>
</div>
