import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CoreService } from '@kuki/global/shared/services/core.service';

@Directive({
    selector: '[appPlatformTv]'
})
export class PlatformTvDirective implements OnInit {

    private hasView = false;

    constructor(
        private coreService: CoreService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) {
    }

    @Input() appPlatformTv;
    @Input() appPlatformTvNot = false;

    ngOnInit() {
        let condition = this.coreService.isTvPlatform();
        condition = !this.appPlatformTvNot ? condition : !condition;
        if (condition && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!condition && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
