import { Injectable } from '@angular/core';
import { MediaBase } from '@kuki/global/shared/types/state/portal-settings/media-base';
import { SafeUrl } from '@angular/platform-browser';
import { UiHelper } from '@kuki/global/shared/helpers/ui.helper';
import { hal } from '@kuki/platforms/hal';
import { ImageTypes, MediaTypes } from '@kuki/global/shared/types/enum';
import { ProfileService } from '@kuki/global/shared/services/profile.service';

@Injectable()
export class ImageLinkFactoryService {
    public mediaBase: MediaBase;
    public thumbnailBase: string;

    constructor(private profileService: ProfileService) {
    }

    public generateUrl(
        imageType: ImageTypes, type: string, image: SafeUrl, defaultImage?: SafeUrl, local: boolean = false, noGrad?: boolean) {
        if (!this.mediaBase) {
            return;
        }
        if (local) {
            return image as string;
        }
        return `${ this.getUrlPrefix(imageType, image) }${ this.getImageType(imageType, type) }/` +
            `${ this.getImageSize(imageType, type, noGrad) }/${ this.getImage(imageType, image, defaultImage) }`;
    }

    private getUrlPrefix(imageType, image?) {
        if (!this.mediaBase) {
            return;
        }
        switch (imageType) {
            case ImageTypes.EPG_ENTITY:
                return this.mediaBase.epgEntityWide;
            case ImageTypes.VOD:
                return this.mediaBase.vodWide;
            case ImageTypes.SERIES:
                return this.mediaBase.seriesWide;
            case ImageTypes.EPISODE:
                return image ? this.mediaBase.episodeWide : this.mediaBase.episodeDefaultWide;
            case ImageTypes.BUTTON:
                return this.mediaBase.buttonWide;
            case ImageTypes.CHANNEL_LOGO:
                return this.mediaBase.channelLogoWide;
            case ImageTypes.PERSON_PORTRAIT:
                return this.mediaBase.personPortrait;
            case ImageTypes.VOD_PROVIDER:
                return this.mediaBase.vodProviderLogoWide;
            case ImageTypes.PROFILE_IMAGE:
                return this.mediaBase.profileImage;
            case ImageTypes.PROFILE_IMAGE_SMALL:
                return this.mediaBase.profileImageSmall;
            case ImageTypes.PROFILE_TYPE:
                return this.mediaBase.profileTypeWide;
            case ImageTypes.CARROUSEL:
                return this.mediaBase.carrousel;
            case ImageTypes.SETTINGS:
                return this.mediaBase.sectionSettings;
            case ImageTypes.DEVICE:
                return this.mediaBase.deviceWide;
            case ImageTypes.FLAG:
                return this.mediaBase.flagWide;
            case ImageTypes.WEATHER:
                return this.mediaBase.weather;
        }
    }

    private getImageType(imageType: ImageTypes, type: string) {
        if ([ 'WEB', 'MOBILE.ANDROID', 'MOBILE.IOS' ].indexOf(hal.platform) >= 0) {
            return type;
        }
        const activeProfile = this.profileService.getActiveProfile();
        if (activeProfile) {
            switch (activeProfile.profileType) {
                case 'kid':
                    if ([ 'tile', 'tile-bw', 'backdrop' ].indexOf(type) >= 0) {
                        return `${ type }-kid`;
                    }
                    break;
                case 'senior':
                    // TODO: return person-tile when generated on backend
                    if ([ 'tile', 'tile-bw', 'backdrop', 'person-tile' ].indexOf(type) >= 0) {
                        return `${ type }-senior`;
                    }
            }
        }
        return type;
    }

    private getImageSize(imageType: ImageTypes, type: string, noGrad: boolean) {
        if ([ 'TV.ARRIS' ].indexOf(hal.platform) >= 0 && UiHelper.buildResolution === 720) {
            if (imageType === ImageTypes.DEVICE && type === 'tile') {
                return 'hdready';
            }
            if ([ 'tile', 'tile-bw', 'fullscreen', 'backdrop', 'person-tile' ].indexOf(type) >= 0 && !noGrad) {
                return 'hdready-grad';
            }
            return 'hdready';
        }
        if ([ 'WEB', 'MOBILE.ANDROID', 'MOBILE.IOS' ].indexOf(hal.platform) >= 0) {
            if (imageType === ImageTypes.DEVICE && [ 'tile', 'tile-bw' ].indexOf(type) >= 0) {
                return 'fhd';
            }
            if (imageType === ImageTypes.FLAG && type === 'icon') {
                return 'fhd';
            }
            if ([ 'tile', 'tile-bw' ].indexOf(type) >= 0 && !noGrad) {
                return 'fhd-grad';
            } else {
                return UiHelper.resolution;
            }
        }
        if (imageType === ImageTypes.DEVICE && type === 'tile') {
            return 'fhd';
        }
        if ([ 'tile', 'tile-bw', 'fullscreen', 'backdrop', 'person-tile' ].indexOf(type) >= 0 && !noGrad) {
            return 'fhd-grad';
        }
        return 'fhd';
    }

    private getImage(imageType: ImageTypes, image: SafeUrl, defaultImage?: SafeUrl) {
        switch (imageType) {
            case ImageTypes.EPISODE:
                return image ? image : defaultImage;
        }
        return image;
    }

    public getImageTypeFromMediaType(mediaType: MediaTypes) {
        switch (mediaType) {
            case MediaTypes.EPG_ENTITY:
            case MediaTypes.NPVR:
                return ImageTypes.EPG_ENTITY;
            case MediaTypes.VOD:
                return ImageTypes.VOD;
            case MediaTypes.SERIAL:
                return ImageTypes.SERIES;
            case MediaTypes.EPISODE:
                return ImageTypes.EPISODE;
        }
    }

    public preloadImage(url, callback) {
        const image = new Image();
        image.src = url;
        image.onload = () => {
            callback(url);
        };
    }
}
