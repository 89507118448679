import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';

@Injectable()
export class ProfilePasswordResolveService implements Resolve<string> {

    constructor(
        private profileService: ProfileService,
        private coreService: CoreService,
        private navigationService: NavigationService,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string> {
        const activeProfile = this.profileService.getActiveProfile();
        const edit = route.params.edit;
        const forceRequirePin = route.data.forceRequirePin;
        let profileId = route.data.type === 'WEB_SETTINGS' ? +route.parent.parent.parent.params.profileId : +route.params.profileId;
        profileId = profileId || (activeProfile ? activeProfile.id : null);
        const profile = this.profileService.getProfile(profileId);
        if (edit || forceRequirePin) {
            if (activeProfile) {
                if (activeProfile?.profileType === 'kid') {
                    this.navigationService.navigateHome();
                    return Promise.reject(null);
                }
                if (activeProfile.id === profileId) {
                    if (!activeProfile.pin) {
                        return Promise.resolve(null);
                    }
                }
            } else if (!profile?.pin) {
                return Promise.resolve(null);
            }
            return this.modalsService.openPasswordModal({
                type: 'profile',
                profileId: profileId
            }).then((password) => {
                return password;
            }).catch(() => {
                this.navigationService.navigateBack(true);
                return Promise.reject(null);
            });
        } else {
            return Promise.resolve(null);
        }
    }
}
