import { Directive, Input, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appDclHost]',
})
export class DclHostDirective {

    constructor(public viewConainerRef: ViewContainerRef) {
    }
}
