import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { fromEvent } from 'rxjs';
import { skipUntil } from 'rxjs/operators';
import { hal } from '@kuki/platforms/hal';

@Directive({
    selector: '[appMouseEvents]'
})
export class MouseEventsDirective implements OnInit, OnDestroy {

    @Output() appMouseEnter: EventEmitter<void> = new EventEmitter<void>();
    @Output() appMouseClick: EventEmitter<void> = new EventEmitter<void>();

    private subscription: SubscriptionObject = {};

    constructor(
        private ngZone: NgZone,
        private elementRef: ElementRef) {
    }

    ngOnInit() {
        if ([ 'TV.WEBOS', 'WEB', 'MOBILE.ANDROID', 'MOBILE.IOS' ].indexOf(hal.platform) === -1) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            this.subscription.mouseenter = fromEvent(this.elementRef.nativeElement, 'mouseenter')
                .pipe(skipUntil(fromEvent(document, 'mousemove')))
                .subscribe((event: Event) => {
                    this.appMouseEnter.emit();
                    event.stopPropagation();
                });
            this.subscription.mouseUp = fromEvent(this.elementRef.nativeElement, 'mouseup')
                .subscribe((event: Event) => {
                    this.appMouseClick.emit();
                    event.stopPropagation();
                });
        });
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
