import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { NotificationsComponent } from './notifications.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsPanelModule } from '@kuki/global/features/buttons-panel/buttons-panel.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ButtonsPanelModule
    ],
    declarations: [
        NotificationsComponent
    ],
    exports: [
        NotificationsComponent
    ]
})
export class NotificationModule {
    static forRoot(): ModuleWithProviders<NotificationModule> {
        return {
            ngModule: NotificationModule,
            providers: [
                NotificationService
            ]
        };
    }
}
