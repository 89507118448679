import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { environment } from '@kuki/environments/environment';
import { switchMap, tap } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { timer } from 'rxjs';
import { Message } from '@kuki/global/shared/types/general';
import { MessageTypes } from '@kuki/global/shared/types/enum/message-types';
import { TruncatePipe } from '@kuki/global/shared/pipes/truncate.pipe';
import { NotificationTypes } from '@kuki/global/shared/types/notification';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';

@Injectable()
export class MessagingService {
    private readonly MESSAGES_REFRESH_INTERVAL: number = 4 * 60 * 60 * 1000;
    private readonly DEFAULT_DURATION: number = 8000;
    private subscription: SubscriptionObject = {};

    private activeMessages: Array<Message> = [];
    private persistentMessages: Array<Message> = [];
    // private passiveMessages: Array<Message> = [];

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService,
        private navigationService: NavigationService,
        private truncatePipe: TruncatePipe) {
    }

    public fetchNewMessages() {
        if (!this.subscription.startMessagingRefreshInterval) {
            this.startMessagingRefreshInterval();
        }
        if (!this.subscription.watchNotificationClosed) {
            this.subscription.notificationClosed = this.notificationService.notificationClosed$
                .subscribe((notification) => {
                    const closedMessage = this.activeMessages.find(message => this.getMessageGuid(message) === notification.guid);
                    this.activeMessages = this.activeMessages.filter(message => this.getMessageGuid(message) !== notification.guid);
                    if (closedMessage) {
                        this.markMessageAsRead(closedMessage);
                    }
                });
        }
        return this.httpClient.get<Array<Message>>(`${ environment.apiUrl }messages/new`)
            .pipe(
                tap((messages) => this.processNewMessages(messages))
            );
    }

    public getMessageHeaders() {
        return this.httpClient.get<Array<Message>>(`${ environment.apiUrl }messages/headers`);
    }

    public getMessageDetail(id: number) {
        return this.httpClient.get<Message>(`${ environment.apiUrl }messages/${ id }`);
    }

    public markMessageAsRead(message: Message) {
        const soKey = 'message-read-' + message.id;
        SOM.clearSubscriptions(this.subscription[ soKey ]);
        this.subscription [ soKey ] = this.httpClient.post(`${ environment.apiUrl }messages/${ message.id }`, undefined)
            .subscribe();
    }

    public destroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }

    private processNewMessages(messages: Array<Message>) {
        this.persistentMessages.forEach(persistentMessage => {
            if (persistentMessage.notification && !messages.some(message => message.id === persistentMessage.id)) {
                this.notificationService.close(persistentMessage.notification);
            }
        });
        this.activeMessages = [];
        this.persistentMessages = [];
        // this.passiveMessages = [];
        messages.forEach(message => {
            switch (message.type) {
                case MessageTypes.INFO:
                    message.notification = this.notificationService.show(message.label, {
                        guid: this.getMessageGuid(message),
                        text: this.getMessageText(message),
                        duration: this.getDuration(message),
                        buttons: message.buttons,
                        type: this.getMessageType(message),
                        persistent: message.persistent,
                        messageId: message.id
                    });
                    if (message.persistent) {
                        this.persistentMessages.push(message);
                    }
                    this.activeMessages.push(message);
                    break;
                case MessageTypes.PASSIVE:
                    // this.passiveMessages.push(message);
                    // if (this.passiveMessages.length > 0) {
                    //     this.notificationService.show('NOTIFICATIONS.GENERAL.NEW_MESSAGES', {
                    //         guid: 'message-default',
                    //         text: 'NOTIFICATIONS.GENERAL.NEW_MESSAGES_TEXT'
                    //     });
                    // }
                    break;
            }
        });
    }

    private getMessageGuid(message: Message) {
        return 'message-' + message.id;
    }

    private getDuration(message: Message) {
        if (message.persistent) {
            return 0;
        }
        if (message.duration) {
            return message.duration;
        }
        return this.DEFAULT_DURATION;
    }

    private startMessagingRefreshInterval() {
        SOM.clearSubscriptions(this.subscription.startMessagingRefreshInterval);
        this.subscription.startMessagingRefreshInterval =
            timer(this.MESSAGES_REFRESH_INTERVAL, this.MESSAGES_REFRESH_INTERVAL)
                .pipe(
                    switchMap(() => {
                        return this.fetchNewMessages();
                    })
                )
                .subscribe();
    }

    private getMessageText(message: Message) {
        return this.truncatePipe.transform(message.text, 150);
    }

    private getMessageType(message: Message) {
        switch (message.image) {
            case 'warning':
                return NotificationTypes.ALERT;
            case 'settings':
                return NotificationTypes.NEWS;
        }
        return NotificationTypes.MESSAGE;
    }

    public openMessage(messageId: number) {
        return this.navigationService.navigateMessage(messageId);
    }
}
