import { Injectable } from '@angular/core';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { BootModeTypes } from '@kuki/global/shared/types/enum';
import { HalFeature } from '@kuki/global/shared/types/general';
import { MediaPlayerStates } from '@kuki/global/shared/types/media-player';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { ConnectableObservable, Observable, of, Subject } from 'rxjs';
import { publish, refCount } from 'rxjs/operators';

declare var cordova: any;
declare var device: any;
declare var Connection: any;

@Injectable()
export class SharedAndroidPlatformHalService implements PlatformHal {

    private readonly serialKey = 'kuki-sn';

    // TODO: check and maybe rework to this
    // public deviceReady: Observable<Event> = fromEvent(document, 'deviceready');
    public deviceReady$: Observable<void> = of(null);
    public features: HalFeature = {
        wv: false
    };

    private appStateChanged: Subject<string> = new Subject<string>();
    public appStateChanged$: ConnectableObservable<string> = this.appStateChanged
        .pipe(publish(), refCount()) as ConnectableObservable<string>;

    private playbackErrorOccurred: Subject<void> = new Subject<void>();
    private clearPlaybackError: Subject<void> = new Subject<void>();
    private notifyTracksChanged: Subject<void> = new Subject<void>();
    private mediaPlayerStateChanged: Subject<MediaPlayerStates> = new Subject<MediaPlayerStates>();
    private notifyChromecastStateChange: Subject<string> = new Subject<string>();

    public playbackErrorOccurred$: Observable<void> = this.playbackErrorOccurred.asObservable();
    public clearPlaybackError$: Observable<void> = this.clearPlaybackError.asObservable();
    public notifyTracksChanged$: Observable<void> = this.notifyTracksChanged.asObservable();
    public mediaPlayerStateChanged$: Observable<MediaPlayerStates> = this.mediaPlayerStateChanged.asObservable();
    public notifyChromecastStateChange$: Observable<string> = this.notifyChromecastStateChange.asObservable();

    constructor(
        private portalSettingsService: PortalSettingsService,
        private notificationService: NotificationService) {
    }

    public init() {
        console.log(`Kuki @ ${ device.model } / ${ device.manufacturer }`);
        cordova.exec((data) => this.androidCallback(data),
            () => console.error('registerCallback error'),
            'NXPlayer',
            'registerCallback'
        );
    }

    public onDeviceRegistered(token: string, serial: string): void {
        const maxbitrate = 0;
        const reqRate = 0;
        const liveChunksBehind = 0;
        const maxLumaSamplesPerSec = 0;

        cordova.exec(
            (data) => {
                console.log('setRegValues success');
                this.features.hevc = !!data.supportsHEVC;
            },
            () => console.error('setRegValues error'),
            'NXPlayer',
            'setRegValues',
            [ token, maxbitrate, reqRate, liveChunksBehind, serial, maxLumaSamplesPerSec ]
        );
    }

    private androidCallback(data: string) {
        if (data === 'playerError') {
            this.playbackErrorOccurred.next();
        } else {
            if (data !== 'playerState:I') {
                this.clearPlaybackError.next();
            }
        }

        if (data === 'appPause') {
            // hlavni duvod je, ze pri pauznute aplikaci / vyplem stb nechceme konzumovat stream
            this.appStateChanged.next(data);
        } else if (data === 'appResume') {
            this.appStateChanged.next(data);
        } else if (data === 'newTracks') {
            this.notifyTracksChanged.next();
        } else if (data === 'playerStop') {
            this.appStateChanged.next(data);
        } else if (data.startsWith('playerState:')) {
            switch (data.substr('playerState:'.length)) {
                case 'B':
                    this.mediaPlayerStateChanged.next(MediaPlayerStates.BUFFERING);
                    break;
                case 'E':
                    this.mediaPlayerStateChanged.next(MediaPlayerStates.END);
                    break;
                case 'I':
                    this.mediaPlayerStateChanged.next(MediaPlayerStates.IDLE);
                    break;
                case 'R':
                    this.mediaPlayerStateChanged.next(MediaPlayerStates.PLAYING);
                    break;
            }
        } else if (data.startsWith('CAST:')) {
            const [ k, state ] = data.split(':');
            this.notifyChromecastStateChange.next(state);
        } else if (data.startsWith('KEY:')) {
            // gamepad events
            const [ k, op, code ] = data.split(':');
            const e = new KeyboardEvent((op === 'DOWN') ? 'keydown' : 'keyup', {
                'key': `BUTTON_${ code }`
            });
            document.dispatchEvent(e);
        }
    }

    public catchVolumeKeys(param: boolean = true) {
        console.log('catchVolumeKeys', param);
        cordova.exec(
            () => {
                console.log('catchVolumeKeys success', param);
            },
            () => console.error('catchVolumeKeys error', param),
            'NXPlayer',
            'catchVolumeKeys',
            [ param ]
        );
    }

    public getProductName(): string {
        return `${ device.model || device.manufacturer || '' }`;
    }

    public getMac(): string {
        return '00:00:00:00:00:00';
    }

    public getVersionFw(): string {
        return device.version || '1.0';
    }

    public getBootMode(): BootModeTypes {
        return BootModeTypes.UNKNOWN;
    }

    public getSerial(): string {
        let serial = localStorage.getItem(this.serialKey);
        if (!serial) {
            serial = this.generateSerial();
        }
        return serial;
    }

    public getDeviceModel(): string {
        return `${ device.manufacturer || '' } ${ device.model || '' }`;
    }

    public getClaimedDeviceId(): string {
        return `${ device.serial } $ ${ device.uuid }`;
    }

    private generateSerial(): string {
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let serial = 'kuki2.0_';
        for (let i = 0; i < 56; i++) {
            serial += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        localStorage.setItem(this.serialKey, serial);
        return serial;
    }

    public checkConnection() {
        const connectionType = (navigator as any).connection.type;
        if ([ Connection.ETHERNET ].indexOf(connectionType) >= 0) {
            return 'ok';
        } else if ([ Connection.WIFI,
            Connection.CELL_2G,
            Connection.CELL_3G,
            Connection.CELL_4G,
            Connection.CELL ].indexOf(connectionType) >= 0) {
            return 'wireless';
        } else if ([ Connection.UNKNOWN ].indexOf(connectionType) >= 0) {
            return 'unknown';
        }
        return 'fail';
    }

    public getConnectionType() {
        const connectionType = (navigator as any).connection.type;
        if (connectionType === Connection.WIFI) {
            return 'wifi';
        } else if (connectionType === Connection.ETHERNET) {
            return 'ethernet';
        } else if ([
            Connection.CELL_2G,
            Connection.CELL_3G,
            Connection.CELL_4G,
            Connection.CELL ].indexOf(connectionType) >= 0) {
            return 'cell';
        }
    }

    public releaseMediaPlayer() {
        cordova.exec(
            () => console.log('unregisterCallback success'),
            () => console.error('unregisterCallback error'),
            'NXPlayer',
            'unregisterCallback'
        );
    }

    public restart() {
        (window as any).location = 'index.html';
    }

    public reboot() {
        (window as any).location = 'index.html';
    }

    public getFeatures() {
        return this.features;
    }

    public exitApp() {
        (navigator as any).app.exitApp();
    }

    public uploadLogs() {
        const now = Date.now();
        const portalSettings = this.portalSettingsService.getPortalSettings();
        if (!portalSettings) {
            return;
        }
        const dstUrl = `${ portalSettings[ 'platform.uploadLogsUrl' ] }/log.${ this.getMac() }.${ this.getSerial() }.${ now }.txt`;

        cordova.exec(() => {
                console.log('uploadLogs success');
                this.notificationService.show(`OK: ${ now }`);
            }, () => {
                console.log('uploadLogs error');
            }, 'NXPlayer',
            'sendLogs',
            [ dstUrl, ]);
    }

    public share(link: string) {
        cordova.exec(() => {
                console.log('share success');
            }, () => {
                console.log('share error');
            }, 'NXPlayer',
            'share',
            [ link ]);
    }

    public setStopVideoOnClose(stopVideoOnClose: boolean) {
        cordova.exec(() => {
                console.log('stopVideoOnClose success');
            }, () => {
                console.log('stopVideoOnClose error');
            }, 'NXPlayer',
            'setStopVideoOnClose',
            [ stopVideoOnClose ]);
    }
}
