import { SettingsOption } from '@kuki/global/shared/types/settings';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SelectOption } from '@kuki/global/shared/types/general';

export const unlockTimeoutOptions: Array<SelectOption> = [
    {
        value: '60000',
        text: _('SECTIONS.SETTINGS.OPTIONS.TIME.MINUTES_1')
    },
    {
        value: '600000',
        text: _('SECTIONS.SETTINGS.OPTIONS.TIME.MINUTES_10')
    },
    {
        value: '180000',
        text: _('SECTIONS.SETTINGS.OPTIONS.TIME.MINUTES_30')
    },
    {
        value: '360000',
        text: _('SECTIONS.SETTINGS.OPTIONS.TIME.MINUTES_60')
    },
    {
        value: '720000',
        text: _('SECTIONS.SETTINGS.OPTIONS.TIME.HOURS_2')
    },
    {
        value: '8640000',
        text: _('SECTIONS.SETTINGS.OPTIONS.TIME.HOURS_24')
    },
];

export const ageLimitOptions: Array<SelectOption> = [
    {
        value: '0',
        text: _('SECTIONS.SETTINGS.OPTIONS.AGE_LIMIT.NO_LIMIT')
    },
    {
        value: '12',
        text: '12+'
    },
    {
        value: '15',
        text: '15+'
    },
    {
        value: '18',
        text: '18+'
    }
];

export const channelDisplayFormatOptions: Array<SelectOption> = [
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.CHANNEL_DISPLAY.LOGO'),
        value: 'logo'
    },
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.CHANNEL_DISPLAY.NUMBER_LOGO'),
        value: 'number-logo'
    },
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.CHANNEL_DISPLAY.NUMBER_TEXT'),
        value: 'number-text'
    },
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.CHANNEL_DISPLAY.TEXT'),
        value: 'text'
    },
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.CHANNEL_DISPLAY.NUMBER'),
        value: 'number'
    }
];

export const subOptionsCustom: Array<SettingsOption> = [
    {
        text: _('SECTIONS.SETTINGS.AUDIO_SUB.DISABLE_SUBTITLES'),
        value: 'disabled'
    }
];

export const dolbyDigitalOptions: Array<SettingsOption> = [
    {
        text: _('GENERAL.CHECKBOX.NO'),
        value: '0'
    },
    {
        text: _('GENERAL.CHECKBOX.YES'),
        value: '1'
    },
    {
        text: _('GENERAL.CHECKBOX.FORCE'),
        value: '2'
    }
];

export const aspectOptions: Array<SettingsOption> = [
    {
        text: '4:3',
        value: '1'
    },
    {
        text: '16:9',
        value: '2'
    }
];

export const scaleModeOptions: Array<SettingsOption> = [
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.SCALE.ORIGINAL'),
        value: '1'
    },
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.SCALE.FIT'),
        value: '2'
    }
];

export const dashboardModeOptions: Array<SettingsOption> = [
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.DASHBOARD_MODE.MODERN'),
        value: 'modern'
    },
    {
        text: _('SECTIONS.SETTINGS.OPTIONS.DASHBOARD_MODE.CLASSIC'),
        value: 'classic'
    }
];
