import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Media } from '@kuki/global/shared/types/media';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { startWith } from 'rxjs/operators';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { MediaStates, Tags } from '@kuki/global/shared/types/enum';

@Component({
    selector: 'app-box-labels',
    templateUrl: './box-labels.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxLabelsComponent implements OnChanges, OnDestroy {

    @Input() media: Media;
    @Input() showTags: boolean = true;

    MediaState = MediaStates;
    public tags: Array<Tags>;
    private subscription: SubscriptionObject = {};

    constructor(
        private tileService: TileService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('media')) {
            if (!this.media) {
                return;
            }
            SOM.clearSubscriptions(this.subscription.getTileTagsUpdates);
            this.subscription.getTileTagsUpdates = this.tileService.getTileTagsUpdates(this.media.tile)
                .pipe(startWith(this.tileService.getTileTags(this.media.tile)))
                .subscribe((tags) => {
                    this.tags = tags;
                    this.changeDetectorRef.detectChanges();
                });
        }
    }

    public getIcon(type: Tags): string {
        return this.tileService.getIcon(type);
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
