import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownBoxComponent } from './countdown-box.component';


@NgModule({
    declarations: [
        CountdownBoxComponent
    ],
    exports: [
        CountdownBoxComponent
    ],
    imports: [
        CommonModule
    ]
})
export class CountdownBoxModule {
}
