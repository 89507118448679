import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { AudioTrack, SubtitleTrack } from '@kuki/global/shared/types/general';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { SharedAndroidPlatformHalService } from '@kuki/platforms/shared/android-platform-hal.service';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { MediaPlayerError, MediaPlayerErrors, MediaPlayerStates } from '@kuki/global/shared/types/media-player';

declare var cordova: any;

@Injectable()
export class ExoPlayerV2Service implements MediaPlayerHalInterface {
    public readonly hevc = true;
    public readonly nativeBuffer = false;

    private audioTracks: Array<AudioTrack> = [];
    private subtitleTracks: Array<SubtitleTrack> = [];
    private activeAudioTrack: AudioTrack;
    private activeSubtitleTrack: SubtitleTrack;
    private wvServer: string;
    private sessionKey: string;
    private mediaErrorEndTimeout: any;

    public buffering$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public error$: Subject<MediaPlayerError> = new Subject<MediaPlayerError>();
    public end$: Subject<void> = new Subject<void>();
    public tracksUpdated$?: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public trackActivated$?: ReplaySubject<void> = new ReplaySubject(1);
    public chromecastAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public chromecastConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private subscription: SubscriptionObject = {};

    constructor(
        private androidPlatformHalService: SharedAndroidPlatformHalService,
        private portalSettingsService: PortalSettingsService) {
    }

    public initOnStartup() {
        const portalSettings = this.portalSettingsService.getPortalSettings();
        this.wvServer = portalSettings.mediaPlayer.widevineProxy + '?sessionKey=' + this.sessionKey;
        this.subscription.playbackErrorOccurred = this.androidPlatformHalService.playbackErrorOccurred$.subscribe(() => {
            this.notifyPlaybackErrorOccurred();
        });
        this.subscription.clearPlaybackError = this.androidPlatformHalService.clearPlaybackError$.subscribe(() => {
            this.clearPlaybackError();
        });
        this.subscription.notifyTracksChanged = this.androidPlatformHalService.notifyTracksChanged$.subscribe(() => {
            this.notifyTracksChanged();
        });
        this.subscription.mediaPlayerStateChanged = this.androidPlatformHalService.mediaPlayerStateChanged$.subscribe(state => {
            switch (state) {
                case MediaPlayerStates.BUFFERING:
                    console.log('ON BUFFERING');
                    this.buffering$.next(true);
                    break;
                case MediaPlayerStates.END:
                    this.buffering$.next(false);
                    this.end$.next();
                    console.log('ON END');
                    break;
                case MediaPlayerStates.IDLE:
                    this.buffering$.next(false);
                    console.log('ON IDLE');
                    break;
                case MediaPlayerStates.PLAYING:
                    this.buffering$.next(false);
                    console.log('ON PLAYING');
                    break;
            }
        });
        this.subscription.notifyChromecastStateChange = this.androidPlatformHalService.notifyChromecastStateChange$.subscribe(state => {
            this.notifyChromecastStateChange(state);
        });
    }


    public play(streamUrl: string,
                params: {
                    justSeek?: boolean,
                    forceMute?: boolean,
                    forceFormat?: string,
                    left?: number,
                    top?: number,
                    width?: number,
                    height?: number
                }) {
        return new Promise((resolve, reject) => {
            this.clearPlaybackError();
            this.tracksUpdated$.next(false);
            cordova.exec(
                () => {
                    resolve();
                    console.log('startVideoPlayback success');
                },
                () => {
                    this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR });
                    reject();
                    console.error('startVideoPlayback error');
                },
                'NXPlayer',
                'play',
                [ streamUrl, params.justSeek === true, params.forceMute, params.forceFormat,
                    params.left, params.top, params.width, params.height, this.wvServer ]
            );
        });
    }

    public pause() {
        return new Promise((resolve, reject) => {
            cordova.exec(
                () => {
                    console.log('pauseVideoPlayback success');
                    resolve();
                },
                () => {
                    console.error('pauseVideoPlayback error');
                    reject();
                },
                'NXPlayer',
                'pause'
            );
        });
    }

    public stop() {
        return new Promise((resolve, reject) => {
            cordova.exec(
                () => {
                    console.log('stopVideoPlayback success');
                    resolve();
                },
                () => {
                    console.error('stopVideoPlayback error');
                    reject();
                },
                'NXPlayer',
                'stop'
            );
        });
    }

    public activateAudioTrack(id: number) {
        return new Promise((resolve, reject) => {
            const audioTrack = this.audioTracks.find(audioTrackItem => audioTrackItem.id === id);
            cordova.exec(
                () => {
                    this.activeAudioTrack = audioTrack;
                    this.trackActivated$.next();
                    console.log('setTrack success');
                    resolve();
                },
                () => {
                    console.error('setTrack error');
                    reject();
                },
                'NXPlayer',
                'setTrack',
                [ id, ]
            );
        });
    }

    public activateSubtitleTrack(id: number) {
        return new Promise((resolve, reject) => {
            if (id !== undefined) {
                const subtitleTrack = this.subtitleTracks.find(subtitleTrackItem => subtitleTrackItem.id === id);
                cordova.exec(
                    () => {
                        this.activeSubtitleTrack = subtitleTrack;
                        this.trackActivated$.next();
                        console.log('setTrack success');
                        resolve();
                    },
                    () => {
                        console.error('setTrack error');
                        reject();
                    },
                    'NXPlayer',
                    'setTrack',
                    [ id, ]
                );
            } else {
                cordova.exec(
                    () => {
                        this.activeSubtitleTrack = undefined;
                        this.trackActivated$.next();
                        resolve();
                    },
                    () => {
                        console.error('disableSubtitles error');
                        reject();
                    },
                    'NXPlayer',
                    'disableSubtitles',
                );
            }
        });
    }

    public refreshTracks() {
        cordova.exec(
            () => {
                console.log('resetTracks success');
                this.activeAudioTrack = undefined;
                this.activeSubtitleTrack = undefined;
                this.audioTracks = [];
                this.subtitleTracks = [];
                this.tracksUpdated$.next(false);
            },
            () => console.error('resetTracks error'),
            'NXPlayer',
            'resetTracks'
        );
    }

    public requestChromecast() {
        cordova.exec(
            () => {
                console.log('requestChromecast success');
            },
            () => console.error('requestChromecast error'),
            'NXPlayer',
            'toggleChromecast',
        );
    }

    public getAudioTracks(): Array<AudioTrack> {
        return this.audioTracks;
    }

    public getSubtitleTracks(): Array<SubtitleTrack> {
        return this.subtitleTracks;
    }

    public getActiveAudioTrack(): AudioTrack {
        return this.activeAudioTrack;
    }

    public getActiveSubtitleTrack(): SubtitleTrack {
        return this.activeSubtitleTrack;
    }

    public setSessionKey(sessionKey: string): void {
        this.sessionKey = sessionKey;
    }

    public getPlayerDebug(): Promise<string> {
        return new Promise((resolve, reject) => {
            cordova.exec(
                (data) => {
                    const bused = Math.round(data.bufferUsed / 1024);
                    const bsize = Math.round(data.bufferTarget / 1024) || 1;
                    let bpct = Math.round(bused * 100 / bsize);
                    if (bpct > 100) {
                        bpct = 100;
                    }
                    if (bpct < 0) {
                        bpct = 0;
                    }
                    resolve(
                        `speed ${ Math.round(data.bandwidth / 1024) || '?' } / ` +
                        `profile ${ Math.round(data.currentBitrate / 1024) || '?' } / ` +
                        `cache ${ bused } ${ bpct }%`
                    );
                },
                () => reject('---'),
                'NXPlayer',
                'getStats'
            );
        });
    }

    public destroy() {
        console.log('destroy exo player');
        this.audioTracks = [];
        this.subtitleTracks = [];
        this.activeAudioTrack = undefined;
        this.activeSubtitleTrack = undefined;

        this.buffering$ = new BehaviorSubject(true);
        this.error$ = new Subject<MediaPlayerError>();
        this.tracksUpdated$ = new BehaviorSubject(false);
        this.trackActivated$ = new ReplaySubject<void>(1);
        this.chromecastAvailable$ = new BehaviorSubject(false);
        this.chromecastConnected$ = new BehaviorSubject(false);
    }

    private notifyPlaybackErrorOccurred() {
        console.error('error_playback_connectivity');
        if (this.mediaErrorEndTimeout) {
            clearTimeout(this.mediaErrorEndTimeout);
        }
        this.mediaErrorEndTimeout = setTimeout(() => {
            this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR, async: true });
        }, 2500);
    }

    private clearPlaybackError() {
        if (this.mediaErrorEndTimeout) {
            clearTimeout(this.mediaErrorEndTimeout);
            this.mediaErrorEndTimeout = undefined;
        }
    }

    private notifyTracksChanged() {
        cordova.exec(
            (data: Array<any>) => {
                const audioTracks: Array<AudioTrack> = [];
                const subtitleTracks: Array<SubtitleTrack> = [];
                data.forEach(item => {
                    switch (item.type) {
                        case 'audio':
                            const audioTrack: AudioTrack = {
                                id: item.id,
                                label: item.language,
                                language: item.language,
                                channels: item.channels
                            };
                            switch (audioTrack.channels) {
                                case 6: {
                                    audioTrack.layout = '5.1';
                                    break;
                                }
                                default: {
                                    audioTrack.layout = '2.0';
                                    break;
                                }
                            }
                            audioTracks.push(audioTrack);
                            break;
                        case 'text':
                            const subtitleTrack: SubtitleTrack = {
                                id: item.id,
                                label: item.language,
                                language: item.language,
                            };
                            subtitleTracks.push(subtitleTrack);
                            break;
                    }
                });
                this.audioTracks = audioTracks;
                this.subtitleTracks = subtitleTracks;
                this.tracksUpdated$.next(true);
            },
            () => {
                this.audioTracks = [];
                this.subtitleTracks = [];
                this.activeAudioTrack = undefined;
                this.activeSubtitleTrack = undefined;
                this.tracksUpdated$.next(false);
            },
            'NXPlayer',
            'getTracks'
        );
    }

    private notifyChromecastStateChange(newState: string) {
        switch (newState) {
            case 'NO_DEVICES_AVAILABLE': {
                this.chromecastConnected$.next(false);
                this.chromecastAvailable$.next(false);
                break;
            }
            case 'NOT_CONNECTED': {
                this.chromecastConnected$.next(false);
                this.chromecastAvailable$.next(true);
                break;
            }
            case 'CONNECTING': {
                this.chromecastConnected$.next(true);
                this.chromecastAvailable$.next(true);
                break;
            }
            case 'CONNECTED': {
                this.chromecastConnected$.next(true);
                this.chromecastAvailable$.next(true);
                break;
            }
            default: {
                this.chromecastConnected$.next(false);
                this.chromecastAvailable$.next(false);
                break;
            }
        }
    }
}
