export enum Actions {
    SEARCH = 'SEARCH',
    SHOW_PROGRAM_BOARD = 'SHOW_PROGRAM_BOARD',
    SHOW_MEDIA_BOARD = 'SHOW_MEDIA_BOARD',
    SHOW_VOD_BOARD = 'SHOW_VOD_BOARD',
    SHOW_SERIAL_BOARD = 'SHOW_SERIAL_BOARD',
    SHOW_NPVR_BOARD = 'SHOW_NPVR_BOARD',
    SHOW_MEDIA_DETAIL = 'SHOW_MEDIA_DETAIL',
    PLAY = 'PLAY',
    PAUSE = 'PAUSE',
    PLAY_START_OVER = 'PLAY_START_OVER',
    PLAY_LIVE = 'PLAY_LIVE',
    PLAY_CONTINUING = 'PLAY_CONTINUING',
    SHOW_SETTINGS = 'SHOW_SETTINGS',
    RECORD = 'RECORD',
    NOTIFY = 'NOTIFY',
    FAVOURITE = 'FAVOURITE',
    OPEN_URI = 'OPEN_URI',
    BUY_VOD = 'BUY_VOD',
    CHANGE_AUDIO = 'CHANGE_AUDIO',
    CHANGE_SUBTITLES = 'CHANGE_SUBTITLES',
    TELETEXT  = 'TELETEXT',
    HIDE_TILES_ROW = 'HIDE_TILES_ROW',
    SHOW_TILES_ROW = 'SHOW_TILES_ROW',
    CREATE_PROFILE = 'CREATE_PROFILE',
    EDIT_PROFILE = 'EDIT_PROFILE',
    DELETE_PROFILE = 'DELETE_PROFILE',
    ACTIVATE_PROFILE = 'ACTIVATE_PROFILE',
    DEACTIVATE_PROFILE = 'DEACTIVATE_PROFILE',
    LOGOUT = 'LOGOUT',
    SHOW_ACTORS_CREATORS = 'SHOW_ACTORS_CREATORS',
    OPEN_TELEPORT_TO = 'OPEN_TELEPORT_TO',
    TELEPORT_TO = 'TELEPORT_TO',
    TELEPORT_SWITCH = 'TELEPORT_SWITCH',
    REMOTE_CONTROL = 'REMOTE_CONTROL',
    GO_TO_VOD_BOUGHT = 'GO_TO_VOD_BOUGHT',
    GO_TO_TODAY_TOP = 'GO_TO_TODAY_TOP',
    GO_TO_TILES_ROW = 'GO_TO_TILES_ROW',
    SHOW_EPG = 'SHOW_EPG',
    SHOW_DEVICES = 'SHOW_DEVICES',
    SHOW_DASHBOARD_MANAGEMENT = 'SHOW_DASHBOARD_MANAGEMENT',
    BACK = 'BACK',
    SHARE = 'SHARE'
}
